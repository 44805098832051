/*
# Copyright ©2020-2021. Biorithm Pte Ltd. All Rights Reserved. 
# This software is the property of Biorithm Pte Ltd. 
# It must not be copied, printed, distributed, or reproduced in whole or in part
# or otherwise disclosed without prior written consent from Biorithm.
# This document may follow best coding practices for JavaScript as suggested in 
# ES2021
#
# Filename: STG001A_rawONON_mSmoothedHR.js
# Original Author: PHAM DUY HOAN
# Date created: 15 JUL 2021
# Purpose: Maternal Heart Rate From STG001A
# Revision History Raises (if any issues):
*/
export const mHR = `77.41204616640480651313
77.18951469358276540333
76.99479965486345633963
76.51951122758001133661
76.04422280029658054445
75.56893437301313554144
75.02777065455296678920
74.48660693609278382610
73.94544321763260086300
73.48385959919576748689
73.35668146162632297091
73.22950332405689266579
73.61693062446346402794
74.01678073779240207841
74.41663085112135433974
74.81648096445030660107
74.90977324917835744600
75.00306553390640829093
75.09635781863445913586
75.36777689627018617102
75.63919597390591320618
75.91061505154164024134
76.53396094414036099352
77.15730683673908174569
77.78065272933781670872
78.26848159738446497613
78.42190498456372438341
78.57532837174298379068
78.44277695786124127153
78.29780273105711785320
78.15282850425299443486
78.04327619180654096454
78.04327619180655517539
78.04327619180655517539
78.15282850425299443486
78.03807317960593081807
77.92331785495886720128
77.91029882982346066456
77.73551753052618096262
77.56073623122888704984
77.66007773894914123503
77.89493627122146790498
78.12979480349379457493
78.37916122805953023089
78.91450245368626781328
79.44984367931300539567
80.02919761189727410056
80.57312963012387285744
81.11706164835044319261
81.21419114787451576376
81.20176833495213486458
81.18934552202976817625
80.70721932306787493872
80.12335682459431041025
79.53949432612074588178
78.95563182764718135331
78.10776361059291161837
77.25989539353867030513
76.41202717648440057019
75.46025656589182517564
74.50848595529924978109
73.55671534470667438654
72.56093202715658208035
71.72670220804748453247
70.89247238893838698459
70.50504508853182983330
70.09675838053243523973
69.68847167253304064616
69.74988835057315839094
69.74988835057315839094
69.74988835057315839094
69.74988835057315839094
69.73977126213631549945
70.16246941116337154654
70.58516756019042759362
71.09726021046236610346
71.50200006924367812644
71.90673992802496172771
72.31147978680625953984
72.25969679482639662638
72.20791380284654792376
72.15613081086669922115
72.12520722647968796082
72.41269064089806306583
72.70017405531646659256
73.04907414777500207492
73.49412253937011030303
73.93917093096521853113
74.38421932256034097009
74.18540813549381596204
73.98659694842730516484
73.78778576136079436765
73.83489720190300431568
73.88200864244524268543
73.92912008298745263346
74.39324020180220031762
74.85736032061694800177
75.32148043943170989678
75.63988743823712468384
75.63988743823712468384
75.63988743823712468384
75.63988743823712468384
75.66454243268152879409
75.68919742712594711520
75.71385242157035122545
76.06371086964652761253
76.41356931772270399961
76.76342776579889459754
76.78734129447919087852
76.81125482315948715950
76.83516835183976922963
76.28082496049994176701
75.72648156916011430440
75.17213817782030105263
74.96929293521739623429
74.76644769261447720510
74.56360245001155817590
74.36075720740865335756
74.32386905883302574694
74.28698091025741234716
74.25009276168178473654
74.30258436302555935526
74.35507596436934818485
74.40756756571313701443
74.76972394828824519664
75.13188033086333916799
75.49403671343844735020
76.20341886446819046341
76.91280101549794778748
77.62218316652769090069
78.15258994030438088885
78.68299671408107087700
79.21340348785776086515
79.65121113647570894045
79.80225839748538874119
79.95330565849505433107
79.90499087803740962954
79.65313723522632471941
79.40128359241522559842
78.91098434245350290439
78.29839539453826091631
77.68580644662301892822
76.43483931236795569930
75.29286400372598109243
74.15088869508400648556
73.00891338644203187869
72.43578544518128126128
71.86265750392053064388
71.28952956265976581562
70.80900074655775711108
70.17524893583831158139
69.54149712511883763000
69.10710735586670239172
68.84719920514709201598
68.58729105442748164023
68.56582851085852325923
68.92693858652854999036
69.28804866219857672149
70.28753692420843890432
71.28702518621830108714
72.38931795517771661252
73.49161072413716055962
73.99824647424085810599
74.40207771739494546637
74.80590896054904703760
75.20974020370314860884
75.51616109498641549180
75.82258198626969658562
76.12900287755297767944
76.20503341721050105662
76.28106395686802443379
76.35709449652556202182
76.10646115003729050841
75.85582780354901899500
75.60519445706074748159
75.66894134621256284845
75.62988372841479645103
75.59082611061700163191
75.55176849281923523449
75.76182310701892674842
75.97187772121861826236
76.45624510973108556300
77.19124584473181016619
77.92624657973254898025
78.78583927605994574606
79.70134070548053273342
80.61684213490113393163
81.69650189174534204994
82.72025291549635994670
83.74400393924739205431
84.73413925774092092524
85.40989434059436291591
85.86112733475088987234
86.31236032890743103962
86.76359332306395799606
85.99648447109271387490
85.22937561912148396459
84.18795399283747826757
83.14653236655345835970
81.77710965179176128004
80.28309497570342045947
78.78908029961506542804
78.21078186283526179068
77.36225984660278243155
76.38319598171914037721
75.24095480602156271743
73.06368050101382038974
70.88640619600609227291
69.00849467592759367562
67.13058315584908086748
65.76772341073710492765
65.83424249373162240317
65.90076157672616830041
65.96728065972069998679
66.43680965100168123172
67.34367342691957958323
68.25053720283749214559
69.74740529421551116229
70.65312328161699895190
71.42945298510397833525
72.31720696688800842367
73.09399170094904718553
74.42654267382323496349
75.75909364669742274145
77.09164461957161051942
78.42419559244579829738
79.43099042174642931968
80.43778525104704613113
81.26699523425455140568
82.09620521746204246938
82.62315776945469281145
83.15011032144735736438
83.67706287344000770645
83.76151218883754268063
83.44956675849149974056
83.13762132814545680048
82.62417442396005640148
82.48273520476641351706
82.32109038283086022147
82.13457712675133848279
81.91697832799192724451
80.06316476866989262362
78.12864095914608242310
76.19411714962227222259
74.49637313488931056327
73.32424074095145272167
72.15210834701359488008
70.97997595307573703849
69.80784355913787919690
68.96972737612010462271
68.66013752076050025153
68.35054766540089588034
68.20741061008003214283
68.71553037707751343532
69.15106160593249740032
69.91733491822517976289
70.58782406648126084292
71.72189664712641388178
72.92463847273950250383
74.12738029835256270417
75.33012212396565132622
76.23799674170265916473
77.14587135943966700324
78.05374597717666063090
78.68749778789612037144
79.07073744042550345057
79.45397709295488652970
79.73548044597259831789
79.89214419896126173626
80.04880795194992515462
80.15616074344058006318
79.89900142810154193285
79.64184211276250380251
79.64184211276250380251
79.57317286779458243018
79.50450362282666105784
79.53249809858344576696
79.46115106661997629089
79.38980403465652102568
79.47808087081469352597
79.84048051399042833509
80.20288015716616314421
80.62581248799324384890
81.15048111833198163367
81.67514974867071941844
81.62583878717271090864
81.62583878717271090864
81.62583878717271090864
81.26484533318733838314
80.64669256386292772731
80.02853979453853128234
79.98743169481959114364
79.82997818574115456158
79.64830105988140473983
79.43634441304503468473
77.21915876069975581686
75.00197310835444852728
72.78478745600915544856
70.48689155346208679020
68.18899565091503234271
66.84660254518085764630
66.26951556189567327237
65.69242857861048889845
65.44232853859448084677
65.67355310389228861823
65.90477766919009638968
66.32168256722707155859
67.04752050494620618792
67.66966730870545632115
68.06031693910547630821
68.40213536570551866589
69.36400267076707848446
70.32586997582863830303
71.19936058405761514223
72.07285119228660619228
72.94634180051556882063
73.20287337955295470238
73.45940495859034058412
73.71593653762772646587
73.74997793196322959375
73.78401932629874693248
73.81806072063426427121
73.71284186541539895643
73.35883450206225120382
73.00482713870908924036
72.79008002491029571956
72.87030226343163974434
72.95052450195298376912
73.03074674047432779389
72.89416457264215409850
72.75758240480999461397
72.62100023697782091858
72.38475000072757836733
71.87175685673007308196
71.35876371273255358574
70.82302054598503104899
70.82302054598501683813
70.82302054598503104899
70.82302054598503104899
71.07180905411932769766
71.16590826675286507452
71.26000747938643087309
71.05913733969984491523
70.56859472302724611836
70.07805210635463311064
69.89269059286812080245
70.19787169605422150198
70.50305279924032220151
70.80823390242639447933
71.39015791335977212384
71.81434373963429607102
72.23852956590883422905
72.12697222543582142862
71.82759663737664368455
71.52822104931745172962
71.22884546125825977470
71.41808423160699703658
71.60732300195572008761
71.79656177230445734949
72.27547301963920745038
72.41048166019220388989
72.54549030074520032940
72.18995632462559797204
71.51201365464808645811
70.83407098467057494418
70.15612831469306343024
69.63592382937437719193
69.64683704746413184239
69.65775026555388649285
69.85648173122982029781
69.94839750098013553270
70.04031327073045076759
69.79830397757361026834
69.55629468441674134738
69.39932711840066303921
69.24235955238459894190
69.42929459315028850597
69.89218136076054577188
70.35506812837081724865
71.14036358983899788200
71.73808402129515116030
72.33580445275131864946
72.93352488420748613862
72.81630408284345890024
72.69908328147943166186
72.58186248011541863434
72.57145737467700996604
72.81346666783386467614
73.05547596099071938625
73.29748525414758830721
73.19168216639026525172
73.08587907863295640709
72.98007599087564756246
72.22051787578412529456
71.46095976069258881580
70.70140164560106654790
70.12941856052154321333
70.06281583247380240209
69.99621310442606159086
70.11343390579008882924
70.26371299552816651612
70.41399208526624420301
70.56427117500433610076
70.46213586614716462009
70.02092973464252168014
69.57972360313787874020
69.40128812540662295305
69.22285264767538137676
69.04441716994412558961
69.24378499270250131303
69.44315281546087703646
70.10704766524628439583
70.77094251503169175521
70.92945700778531659125
71.28328400053894142729
71.63711099329256626334
71.95787969767212644001
72.22831012885581003502
72.49874056003949363003
72.76917099122317722504
73.13055009648981297232
73.49192920175644871961
73.85330830702308446689
73.94068016163632250937
74.02805201624954634099
74.11542387086278438346
74.20279572547602242594
74.34776995228014584427
74.49274417908426926260
74.63771840588839268094
74.52589827230060848251
74.41407813871282428408
74.30225800512502587480
73.94472411244979070943
73.58719021977455554406
73.22965632709930616784
73.12024458298856188776
73.12024458298857609861
73.12024458298857609861
73.39425183364197380342
73.92886824636583753545
74.46348465908971547833
74.99810107181357921036
75.03575671513932832113
75.07341235846507743190
75.11106800179081233182
75.03932375567936219340
74.96757950956789784414
74.89583526345643349487
75.24094634320930197191
75.58605742296217044895
75.93116850271505313685
75.71759180494979091236
75.39460336307382704035
75.07161492119786316835
74.74862647932188508548
74.42173752816843546043
74.09484857701498583538
73.76795962586153621032
73.43778132958154003518
73.10760303330155807089
72.77742473702156189574
72.22640670521639094659
71.67538867341121999743
71.12437064160603483742
70.44339207540639336003
69.88470429788830529105
69.32601652037018880037
69.32601652037018880037
69.32601652037018880037
69.81148832704451479003
70.29696013371886920140
70.52572328760021491689
70.69515099844358019254
70.86457870928694546819
71.01212713543708332509
71.30464978839131617860
71.59717244134556324298
72.28141657965412036901
73.11988861881810919385
73.95836065798211222955
74.80598993795956630493
75.46588614411561479756
76.12578235027166329019
76.78567855642771178282
76.98860063434979394970
76.70605090559752170520
76.42350117684526367157
75.64912336092592681780
74.93408098804458461473
74.21903861516321398994
73.50399624228185757602
72.84746856336192877279
72.19094088444199996957
71.53441320552205695549
70.72365762574666803175
70.40113460256471000775
70.07861157938275198376
69.82153084134067455579
69.57608147263644582381
69.33063210393223130268
69.54215686346196889644
69.77711926947701215340
70.01208167549205541036
70.73887216867416327659
71.70062506787130018893
72.66237796706845131212
73.62413086626558822445
74.18303280322989223805
74.74193474019419625165
75.30083667715848605440
75.85973861412277585714
75.49601822524149952187
75.13229783636022318660
74.76857744747893264048
74.73385608257436274471
74.69913471766979284894
74.66441335276522295317
74.51613610625088313100
74.36785885973654330883
74.21958161322218927580
73.83634196069280619668
73.49771966967114167346
73.15909737864947715025
73.01983712909510870759
72.68639709495084844093
72.35295706080658817427
72.01951702666232790762
72.23366219847039815249
72.47839953767960707864
72.76078877522871835026
72.63606902794991526662
72.28907171139583454078
71.94207439484175381494
71.71523472512032526538
71.48839505539888250496
71.41563772728078163254
71.34288039916269497098
71.21063325570098356820
71.28665622071825680450
71.36267918573553004080
71.69760853020599711272
72.20661494967518478916
72.71562136914438667645
73.00908220574081042287
73.37450490533076674637
73.69120457830871373517
73.96831679216440136315
74.47028817066907890876
74.54312875938752824823
74.61596934810597758769
74.68880993682442692716
74.05838644056989039655
73.42796294431535386593
72.79753944806080312446
72.01091337044704232540
71.67858440908582906559
71.34625544772460159493
70.88336868011433011816
70.55388712502198700349
70.22440556992964388883
69.79527478014341568269
69.36614399035720168740
69.20625340471487163541
69.04636281907255579426
69.31560302321646815926
69.34756724601703581357
69.37953146881761767872
69.99919802038867544525
70.47349641672315101459
70.94779481305761237309
71.42209320939210215329
71.44209448947403018337
71.33530113784559034684
71.22850778621715051031
70.98830922207079652253
70.84497233944321692434
70.70163545681565153700
70.55829857418808614966
70.14572148741660839733
70.20596283836505335785
70.26620418931349831837
70.56372152160525956788
70.86123885389702081739
71.15875618618881048860
71.60164167371715393529
72.24806602359893759058
72.89449037348073545672
73.54091472336253332287
74.46441787866498884796
75.38792103396743016219
76.31142418926987147643
76.92233920420335380186
77.53325421913683612729
78.14416923407031845272
78.61698767179021274387
78.61698767179021274387
78.61698767179021274387
78.58065984952207827519
78.54433202725391538479
78.50800420498578091610
78.23205247814118479255
77.75256188894314846038
77.27307129974511212822
76.79358071054709000691
76.00694243701944685654
75.22030416349181791702
74.43366588996417476665
74.05235762129437659951
73.67104935262456422151
73.28974108395476605438
72.76794145501227717432
72.24614182606978829426
71.72434219712729941421
71.23887039045295921369
71.17953494741499298470
71.12019950437701254486
71.30048796591547954904
71.71453626997362107431
72.12858457403177681044
72.54263287808991833572
73.03724609055777250433
73.53185930302561246208
74.02647251549348084154
73.74788457800723051605
73.46929664052098019056
73.19070870303474407592
73.19070870303474407592
73.04118717193426846279
72.89166564083377863881
72.74214410973331723653
72.28714975307417489603
71.83215539641501834467
71.37716103975587600416
70.68840684057705914256
70.55031026336348531913
70.41221368614992570656
70.35041571114597047654
70.42671431335557485909
70.50301291556519345249
71.16290912172124194512
71.69694190479494011470
72.23097468786863828427
72.76500747094235066470
72.80139545417476654166
72.83778343740718241861
72.87417142063959829557
72.78989586579432113922
73.08637572969983864368
73.38285559360535614815
73.67933545751087365261
72.91116148556663745239
72.14298751362240125218
71.37481354167816505196
70.46854299252035502832
69.65912525526383092256
68.84970751800730681680
68.16615320383311882324
67.59372975736874877839
67.02130631090437873354
67.09604919538179501615
67.17079207985918287704
66.94515613362302985934
66.71952018738686263077
66.11312882239988653055
65.50673745741293885203
65.38951665604891161365
65.78629206856938083092
66.18306748108985004819
66.65372412074231078805
67.12438076039478573875
67.49818458814598898243
67.87198841589717801526
68.00222775859782586849
68.02133623358865577302
68.04044470857947146669
68.33554402476312361614
68.63064334094677576559
69.22612148784399721535
70.02644925994226809962
70.82677703204053898389
71.62710480413880986816
71.93826201261413189059
72.51319943439136750385
73.08813685616857469540
73.58919305081380457523
73.97367698792930923446
74.35816092504482810455
74.74264486216034697463
75.06260720981697431853
75.38256955747361587328
75.70253190513025742803
75.18232741981158540057
74.66212293449291337311
74.14191844917424134564
73.41686433865450567282
72.77002938081855631935
72.12319442298263538760
71.47635946514668603413
70.96459767161599074825
70.45283587808529546237
69.94107408455460017649
69.54588454855360168949
69.39867659278141331924
69.25146863700922494900
69.41234675574648349539
69.49139206903691956541
69.57043738232736984628
70.21365868740031146444
71.08592075369222129666
71.95818281998414533973
72.83044488627606938280
73.87730874942644732073
74.92417261257685368037
75.97103647572723161829
76.59120483725052963564
77.21137319877382765299
77.83154156029712567033
78.45170992182042368768
78.82389670311491158827
79.19608348440939948887
79.06874980534584551606
79.02324893172907138705
78.97774805811229725805
78.66691299077183430200
78.12703716221241734274
77.58716133365297196178
77.04728550509355500253
76.48637848104185366083
75.92547145699016652998
75.36456443293846518827
75.20366626360208783808
75.04276809426569627703
74.88186992492931892684
74.88186992492931892684
74.88186992492931892684
74.88186992492930471599
75.38139038528737501110
75.88091084564543109536
76.38043130600348717962
77.18716356580290494094
77.99389582560232270225
78.80062808540175467442
79.45707617149089685427
79.88173450353002635893
80.30639283556917007445
79.62633661758847836154
78.57411366891244597355
77.52189072023639937470
76.46966777156036698671
75.24053845735149081975
74.01140914314261465279
72.78227982893373848583
71.55315051472486231887
70.97611410822932498377
70.39907770173377343781
69.78016368952053483099
69.18312896200055206464
68.58609423448058350914
68.13934368067087632426
67.80524701206837789869
67.47115034346586526226
68.24176822488317384341
69.01238610630051084627
69.92579494809808693390
70.83920378989567723238
71.76862082788971974878
72.92945109792094626755
74.09028136795218699717
75.25111163798341351594
75.70651414988779492887
76.16191666179217634181
76.61731917369655775474
76.96018009984666718992
77.30304102599680504682
77.64590195214691448200
77.78869291252718198848
77.93148387290744949496
78.07427483328771700144
78.27918232759853367497
78.34129886152906863117
78.40341539545960358737
77.94829675380003664031
77.26176488010329990175
76.57523300640653474147
76.06649564408863284370
75.61109313218425143077
75.15569062027987001784
74.70028810837548860491
74.20857631095159945289
73.71686451352771030088
73.22515271610382114886
72.76001033823692409896
72.29486796037002704907
71.82972558250311578831
71.30246667070568378222
71.14420183763854765857
70.98593700457143995664
71.34490734709440573624
71.51184110777816727023
71.67877486846192880421
71.66791411776684128654
71.84908994891097222535
72.03026578005508895330
72.21144161119921989211
72.66530214893317918268
73.11916268666715268409
73.57302322440111197466
73.98960462586865105550
74.40618602733619013634
74.82276742880372921718
75.23934883027125408717
75.47431123628629734412
75.70927364230132639022
75.94423604831636964718
76.31983700985482244050
76.69543797139328944468
77.07103893293175644885
77.03596991710861630054
77.00090090128547615222
76.96583188546233600391
76.94092205903400838451
76.91601223260568076512
76.89110240617735314572
76.90315021187034005834
76.91519801756332697096
76.92724582325631388358
76.59217508610069558017
76.06972926566726300734
75.54728344523383043452
75.02483762480038365084
74.96207653868214038084
74.89931545256388289999
74.83655436644563963000
74.87162338226877977831
74.90669239809190571577
74.94176141391506007494
74.73993660101851332911
74.53811178812199500499
74.33628697522547668086
74.27665184679526078071
74.21701671836504488056
74.15738158993482898040
74.38670599296337115902
74.61603039599191333764
74.84535479902044130540
75.07467920204898348402
74.83897986640684507620
74.60328053076469245752
74.36758119512256826056
74.52584602818969017335
74.68411086125681208614
74.84237569432393399893
75.23751807729593110707
75.63266046026794242607
76.02780284323992532336
76.24981456545886260301
76.47182628767779988266
76.69383800989672295145
76.79958434402382749795
76.90533067815090362274
77.01107701227797974752
76.88806019252371015682
76.82178040344074076984
76.75550061435774296115
76.63543425040386125602
76.24220701132169608627
75.84897977223954512738
75.45575253315737995763
75.11819402301561865443
74.78063551287385735122
74.44307700273211025888
74.23287243659433443099
74.02266787045655860311
73.81246330431876856437
73.69525749091043564931
73.57805167750208852340
73.46084586409374139748
73.85115053828789655199
74.24145521248206591736
74.63175988667622107187
74.90232265723898308352
75.17288542780175930602
75.44344819836453552853
75.71401096892729754018
75.31902241292004873685
74.92403385691281414438
74.52904530090557955191
74.45907913343455675204
74.38911296596353395216
74.31914679849251115229
74.37701716886289204922
74.43488753923324452444
74.49275790960362542137
74.27188094625283554251
73.60558543086585814308
72.93928991547886653279
72.44652287859418038352
71.73527954396028860629
71.02403620932639682906
70.31279287469250505183
70.05557610709888649581
70.41584329820155119251
70.77611048930421588921
70.77611048930421588921
70.76465101002295909893
70.75319153074170230866
70.69532116037133562259
70.75465660340930185157
70.81399204644728229141
70.87332748948526273125
70.70733299804319926807
70.54133850660113580489
70.37534401515907234170
70.42782582146622871733
70.03794160148419223333
69.64805738150215574933
69.25817316152011926533
68.25080498284178531776
67.30066075582065820981
66.35051652879951689101
65.41183178105966078419
64.47314703331980467738
63.95535950895332177879
63.32036617117850596514
62.68537283340369015150
62.05037949562887433785
62.66179253910713242703
63.27320558258539051621
63.88461862606365571082
64.43816129917155421936
65.43406999856861716580
66.42997869796568011225
67.54309321077110439546
68.65620772357652867868
69.71209828472473191141
70.76798884587293514414
71.61699553783307692356
72.46600222979319028127
72.89411169837990200904
73.05334020815091378154
73.21256871792192555404
73.37179722769292311568
72.77818369057503389286
72.18457015345711624832
71.56431527276296833406
71.00193076243920131674
70.49318025368870621605
69.98442974493818269366
69.35847342277932625620
68.73251710062048402960
68.67318165758251780062
68.61384621454453736078
68.76139464069464679596
68.93249834322668334607
69.10360204575871989618
69.54358670710644219071
70.11747733238235014142
70.69136795765825809212
71.44244273508624587521
72.40246462799929361154
73.38912786448858582844
74.37579110097789225620
75.39189828146862737412
76.40800546195936249205
77.42411264245011182084
78.25046307302217485358
78.51019262447337609956
78.76992217592454892383
78.95255204605442145294
79.11162663980238107797
79.27070123355034070300
79.16652556404804386148
78.92844393061756136376
78.69036229718710728775
78.22624217837234539275
77.55317494407252354449
76.88010770977270169624
76.20704047547289405884
75.18189627936618535387
74.15675208325947664889
73.13160788715276794392
72.29622044096474553498
71.15996649926965744726
70.02371255757455514868
68.96455829720079577783
68.47526954856624570311
67.98598079993170983926
67.75994231454743044196
67.53390382916313683381
67.23736904986975559950
67.16687275596062534078
67.09637646205149508205
67.19047567468506088062
67.28457488731861246833
67.64767311618433609510
68.01077134505004551102
68.38457517280124875469
68.75837900055245199837
69.43304932381070670999
69.84504668702757612664
70.25704405024444554329
70.09917590172210566379
69.94130775319973736259
68.98199947841975188112
68.02269120363973797794
67.13387922276886854434
66.24506724189798489988
66.23518112990811346208
66.06069951137556017784
65.88621789284300689360
65.71173627431046782021
65.53725465577791453597
65.35206743835989584568
65.16688022094187715538
65.18844582638905649219
65.47268439187762112397
65.75692295736618575575
65.91479110588853984609
66.07265925441089393644
67.03196752919089362877
67.99127580397089332109
68.20346388748812671565
68.41565197100534589936
67.74891418564158129811
67.08217640027780248602
67.24600339896718992350
67.40983039765657736098
67.57365739634596479846
67.71632664283492886170
67.85899588932390713580
67.79491231294767317195
67.73082873657145341895
66.76804063856360471618
65.93162295752195234400
65.09520527648031418266
64.25878759543867602133
64.24857584457271286738
64.98548428496953022204
65.72239272536634757671
66.27393965227665262319
66.82548657918694345881
66.54646872204409646656
66.26745086490123526346
66.04524659857932533669
65.84420008445781036244
65.64315357033629538819
65.44210705621478041394
65.24106054209329386140
65.93871854960340783691
66.63637655711352181243
67.40229794424602971503
68.16821933137856603935
68.10793478833539893458
68.32165749594564374547
68.53538020355586013466
68.93446442465263146460
69.33354864574937437283
69.77791041823616069451
70.22227219072291859447
70.60982037238875363983
70.75883962700106621924
70.90785888161337879865
71.05687813622569137806
71.64727091506600231696
72.23766369390634167758
72.82805647274665261648
73.38784151529031873906
73.94762655783398486165
74.50741160037765098423
74.79318939226790519115
74.94368579887677128681
75.09418220548565159334
75.24467861209453189986
74.77247692038064030839
74.30027522866674871693
73.82807353695287133633
74.03256106967087646353
74.23704860238888159074
74.44153613510688671795
74.20465014359687927481
73.95652799503235996781
73.70840584646785487166
73.42262805457758645389
73.12566891615503550383
72.82870977773248455378
72.53175063930993360373
72.38125423270105329721
72.23075782609217299068
72.08026141948329268416
72.14580645454051932575
72.21135148959776017819
72.27689652465500103062
71.90428126233383920862
71.68953414853504568782
71.47478703473626637788
71.26003992093747285708
71.01596562047564020759
70.77189132001382176895
70.52781701955197490861
70.29492406562243900225
69.79784029043686643945
69.30075651525129387664
68.79249139353342457071
68.28422627181555526477
68.17481452770482519554
68.42678188886073087360
68.67874925001663655166
69.26346858473596057593
69.84818791945531302190
70.27503910565228295582
70.81545782667663502252
71.35587654770100130008
71.93685861244290435934
72.66414840223268356567
73.39143819202249119371
74.11872798181227040004
74.92545352529052138379
75.73217906876880078926
76.53890461224705177301
77.09244728535493607069
77.24713658085569534251
77.40182587635645461432
77.11215339937042756446
76.48972894882096795754
75.86730449827152256148
75.24488004772207716542
74.75624431867757380132
74.26760858963305622638
73.77897286058856707314
72.75235356888305204848
71.72573427717756544553
70.69911498547205042087
69.85725076133412869694
69.27489697693044945481
68.69254319252678442353
68.36337227849350028919
68.24095418732539997109
68.11853609615729965299
68.44047977747598565657
68.76242345879467166014
69.37255202108914886594
69.98268058338364028259
70.34545288934580753448
70.73288018975236468577
71.12030749015893604792
71.89941062817861450185
72.75402371001889889612
73.60863679185916907954
74.46324987369945347382
75.09700168441891321436
75.73075349513837295490
76.36450530585784690629
76.55654188769705115192
76.74857846953625539754
76.94061505137547385402
77.19228676164490821066
77.15577359093853715422
77.11926042023215188692
77.05871248564329789588
76.97350955661003979458
76.88830662757676748242
76.80310369854350938112
76.66642558957211406323
76.52974748060074716705
76.39306937162933763830
75.90198680739885617186
75.41090424316837470542
74.91982167893789323898
74.61167426121326684552
74.30352684348862624120
73.99537942576398563688
73.62759687960912913240
73.50980273978906609500
73.39200859996898884674
73.29824922403139453309
73.43353060931278264434
73.56881199459417075559
73.70409337987555886684
73.72837339645070642291
73.75265341302586818983
73.77693342960102995676
74.26801599383151142320
74.75909855806199288963
75.25018112229247435607
75.57013569609826220130
75.89009026990405004653
76.21004484370985210262
75.76631256218638554856
75.07259187432811131657
74.37887118646986550630
73.68515049861160548517
72.82143088489198134994
71.95771127117237142556
71.09399165745276150119
70.31723864855689498654
70.07402695710064222112
69.83081526564440366656
69.43655631317849952211
69.16652489134031611684
68.89649346950213271157
68.84961729759784532234
68.81437249503139241824
68.77912769246495372499
69.50756974522778364189
70.23601179799061355880
70.97614337693798347573
71.71627495588535339266
72.39736469947509078793
73.22342866986895160153
74.04949264026282662599
74.87555661065668743959
75.21841753680681108563
75.56127846295692052081
75.90413938910704416685
75.97480876947791728071
76.04547814984879039457
76.11614753021967771929
75.86250686772496010235
75.60886620523025669627
75.35522554273555329019
75.10158488024083567325
75.05517398915173998830
75.00876309806263009250
74.96235220697352019670
74.46013202173983813736
73.95791183650615607803
73.45569165127245980784
73.13492294689289963117
72.81415424251333945449
72.49338553813376506696
72.54170031859140976849
72.59001509904905447002
72.63832987950668496069
72.99932333349204327533
73.26465352217130089230
73.52998371085054429841
73.79531389952978770452
73.97427620450064011948
74.15323850947150674529
74.33220081444235916024
74.83442099967604121957
75.33664118490972327891
75.83886137014341954909
75.82778844830939135591
75.81671552647534895186
75.80564260464133496953
75.55146484658158101411
75.29728708852184126954
75.04310933046208731412
74.78893157240234756955
74.59783445010017999266
74.40673732779802662662
74.21564020549587326059
73.90280033007564952641
73.58996045465544000308
73.27712057923523047975
73.07427598206075458620
72.87143138488625027094
72.66858678771176016653
72.74724554355498185032
72.71434695892520494453
72.68144837429542803875
72.67053515620567338829
72.82904964895928401347
72.98756414171290884951
73.14607863446653368555
73.37259767112638542130
73.59911670778622294620
73.82563574444606047109
74.21398556368686172391
74.60233538292769139844
74.99068520216849265125
75.33453037359987547461
75.67837554503125829797
76.02222071646262691047
76.28815244453534205604
76.66564151308108421290
77.04313058162679794805
77.51176042012974676254
77.81096254778931609053
78.11016467544889962937
78.57376357654780463236
79.00194056328902547648
79.43011755003023210975
80.03390018112710890819
80.46504336887929298427
80.89618655663149127122
81.45120659427797704666
81.92831318856579514431
82.40541978285362745282
82.81987601627537287641
83.31224569305578597778
83.80461536983619907915
84.11738734546717921603
84.33901855114095269528
84.56064975681471196367
84.35711089446125754421
83.98917525866846744975
83.62123962287569156615
83.39140056429647529512
82.98595586136161728064
82.58051115842674505529
82.11131956634008588480
81.51825112435912501496
80.92518268237816414512
80.19752320138699985819
79.53251408126192245618
78.86750496113685926503
78.37513528435644616366
78.06236330872546602677
77.74959133309448588989
77.26417991411882724151
77.20393856317038228099
77.14369721222195153132
77.19300817371994583027
77.10422255800438051665
77.01543694228882941388
77.08087922742871000992
77.21006840172040597281
77.33925757601210193570
77.31421884944836619979
77.42377116189479124841
77.53332347434124471874
77.74461208629934105829
77.78326125491278730806
77.82191042352621934697
77.86055959213966559673
78.07184820409776193628
78.28313681605587248669
78.49442542801398303709
78.70227378185501265762
78.91012213569607069985
79.11797048953710032038
79.01408086187753099239
78.91019123421796166440
78.80630160655837812556
78.69674929411192465523
78.58719698166549960661
78.47764466921906034713
77.85377897048947204439
77.22991327175988374165
76.60604757303030964977
76.12551875692830094522
75.64498994082627802982
75.16446112472426932527
74.68393230862224640987
73.95395455556331398839
73.22397680250435314520
72.49399904944539230200
72.07547226373111470821
71.65694547801683711441
71.23841869230253109890
70.92726148382720907648
70.61610427535188705406
70.30494706687656503163
70.40636694517269233984
70.74862757664357104659
71.09088820811442133163
71.28981195695772044019
71.34334178520549585301
71.39687161345324284412
71.45040144170101825694
71.25189871937794805490
71.05339599705490627457
70.85489327473186449424
70.50244966570930671423
70.46033376274434090192
70.41821785977937508960
70.42862296521778375791
70.19280480721961623658
69.95698664922144871525
69.72116849122328119392
69.24450958005040490661
69.02452823446850516120
68.80454688888661962665
68.72995946390025778783
68.71915080313655721511
68.70834214237285664240
69.09290291480753865017
69.47746368724222065794
69.91659245576548187273
70.35572122428872887667
70.48452228675438391292
70.76160059573437877134
71.03867890471437362976
71.56198047713094467781
72.02712303815764016690
72.49226559918434986685
72.95740816021105956679
73.29577641314809000050
73.63414466608512043422
73.97251291902216507879
74.24710240773653424640
74.54609594947604023218
74.84508949121553200712
75.14408303295502378205
75.41316357305036888192
75.68224411314571398179
75.95132465324104487081
76.18628705925608812777
76.42124946527113138472
76.65621187128616043083
76.79828602768139944601
76.94036018407662425034
77.08243434047184905467
77.09460523936576237247
77.10677613825967569028
77.11894703715358900808
77.18042889754548241399
77.21750670491226742342
77.25458451227905243286
77.20602353587969446380
77.13280756503590396278
77.05959159419214188347
76.62038130180572181871
76.06701189698526377470
75.51364249216479151983
74.96027308734433347581
75.00958404884232777476
75.05889501034033628457
75.10820597183834479438
75.05989119138070009285
75.01157641092305539132
74.96326163046542490065
74.91395066896743060170
74.86463970746942209189
74.81532874597141358208
75.05519543059301668109
75.29506211521461978009
75.53492879983622287909
76.03690017834088621385
76.53887155684557797031
77.04084293535024130506
77.33581228767037885063
77.17914853468171543227
77.02248478169305201391
76.86582102870438859554
76.87780798689388461753
76.88979494508336642866
76.90178190327286245065
76.70297071620635165345
76.50415952913982664541
76.30534834207333005907
75.88069001003418634355
75.45603167799504262803
75.03137334595591312336
74.48883862373369879606
73.94630390151147025790
73.40376917928924171974
73.06823648325155318162
72.25840539087940328500
71.44857429850722496667
70.63874320613507507005
69.75788714454040473356
68.91730170150242429372
68.07671625846444385388
67.39861418022483974255
66.97169003990097735368
66.54476589957711496481
66.14015004187240265310
65.73553418416770455224
65.90833887339576335762
66.30290958046647631363
66.69748028753717505879
67.09205099460787380394
67.48662170167858675995
68.35549080508377528531
69.13060053255136949701
69.90571026001894949786
70.68081998748654370957
71.41565909639743381376
72.34188332320155723210
73.26810755000568065043
73.94315383889406234630
74.15635527160769413513
74.36955670432132592396
74.58275813703494350193
73.69801232159097992280
72.81326650614701634368
71.92852069070303855369
71.04377487525908918542
70.64920416818837622941
70.25463346111766327340
69.95382212998455884190
69.65301079885144019954
69.16934962785629181781
68.68568845686114343607
68.01064216797277595106
67.57957639187286247306
67.14851061577297741678
67.17928969584781384583
67.21006877592267869659
67.44631901217290703698
68.20309594964800226080
68.95987288712308327376
69.66074109150497406517
70.36160929588686485658
70.57230239189550502488
70.62312531669383020017
70.67394824149218379716
70.72477116629050897245
70.93797259900414076128
71.15117403171777255011
71.36437546443139012808
71.33359638435655369904
71.30281730428168884828
71.27203822420682399752
71.24125914413198756847
71.36922802210085592378
71.49719690006973848995
71.62516577803860684526
71.80904338910067963297
72.00490795835224844268
72.20077252760381725238
72.55650726806567263338
72.90025505033804620325
73.24400283261043398397
73.60822194682953067968
74.02075584150628628777
74.43328973618304189586
74.84582363085979750394
75.15100473404588399262
75.45618583723197048130
75.76136694041807118083
75.63339806244918861466
75.50542918448032025935
75.37746030651143769319
75.33011182166295327534
75.27077637862497283550
75.21144093558700660651
75.15210549254902616667
74.96822788148693916810
74.78435027042486638038
74.60047265936279359266
74.58830176046888027486
74.57613086157496695705
74.56395996268106785010
74.58739760916635930244
74.61083525565166496563
74.63427290213694220711
74.21266115240018734767
73.79104940266344669908
73.36943765292669183964
72.86720551006955304274
72.82079461898044314694
72.77438372789133325114
72.72797283680222335533
73.00947618981993514353
73.29097954283764693173
73.57248289585535871993
73.63396475624728054754
73.69544661663918816430
73.75692847703109578106
73.94049285673034432875
74.12405723642959287645
74.30762161612884142414
75.16267633963113325990
76.01773106313345351737
76.87278578663574535312
77.63474748094407118515
77.94088792348438232693
78.24702836602466504701
78.32886117147145910167
78.21930885902503405305
78.10975654657858058272
78.00020423413215553410
77.93872237374023370649
77.87724051334834030058
77.81575865295641847297
77.52853438825053444816
77.24131012354465042336
76.95408585883876639855
76.84797380450939385810
76.74186175018002131765
76.63574969585064877720
76.71532979587399836419
76.79490989589734795118
76.87448999592068332731
77.20547643735815768196
77.53646287879561782574
77.86744932023307796953
78.26747822554753497570
78.61943667880744612830
78.97139513206737149176
79.37996077670487693467
79.96393055246048220397
80.54790032821608747327
81.18974226051973630547
81.49296190180172061446
81.79618154308369071259
82.09940118436566081073
82.31002170048888899601
82.52064221661213139214
82.48053469279716409801
82.41332846466158912335
82.34612223652602835955
82.33253646137603709576
82.24990822234906318045
82.12695740267693622627
82.00400658300480927210
81.82444857195508802761
81.51502043103522510137
81.20559229011537638598
80.83829199264748410769
80.47099169517957761855
80.24693051153903411432
80.02286932789849061010
79.79880814425796131673
79.62894554422774717750
79.70981098413574272854
79.79067642404373827958
79.84429687702933620130
79.84429687702933620130
79.84429687702933620130
79.65198918472162858961
79.45968149241394939963
79.26737380010625599880
78.76787330060574277013
78.26837280110524375232
77.76887230160475894536
77.58624243147488641625
77.26037344751765090223
76.93450446356044380991
76.47210635338963413687
75.95550965960846667713
75.43891296582731342824
74.92231627204614596849
74.06726154854383992188
73.21220682504153387526
72.35715210153924203951
72.27238170820461959920
72.18761131486998294804
72.10284092153536050773
72.35043815189121119147
72.59803538224706187520
72.84563261260291255894
72.77635921358813675397
72.77635921358813675397
72.77635921358813675397
72.91288833980172512383
73.04941746601531349370
73.18594659222890186356
73.32247571844249023343
73.05980200355905651577
72.79712828867560858725
72.53445457379217486960
71.73412680169390398532
71.13208400821534382885
70.53004121473676946152
70.03269373463066926888
70.04409676327509259863
70.05549979191950171753
70.06690282056391083643
70.00903245019352993950
69.90588452843314826168
69.80273660667275237302
69.69958868491235648435
69.45521664699766972717
69.21084460908298296999
69.73137842890886872738
70.30518716434730208675
70.87899589978573544613
71.45280463522415459465
71.82832839204287722623
72.59026177116376743470
73.35219515028464343231
73.60537802065499590753
73.89700823354506553642
74.18863844643512095445
74.48026865932517637248
74.79147793878792072064
75.10268721825065085795
75.41389649771339520612
75.99757818710261858541
76.58125987649184196471
77.16494156588105113315
77.64201348924422063646
78.11908541260739013978
78.59615733597055964310
79.08645658593229654798
79.19034621359188008682
79.29423584125143520396
79.64885350884921422221
79.96502383392729029765
80.28119415900536637309
80.49692061785555097231
80.73834556152306163312
80.97977050519058650480
80.39455397507443024097
79.67808915118611423622
78.96162432729778402063
78.17689612378705987794
77.44550277068984200923
76.71410941759265256223
75.98271606449543469353
74.74601393683586536554
73.50931180917628182669
72.27260968151671249871
70.78517951391893348045
69.78983079428498115249
68.79448207465101461366
67.89957722124495376192
67.13322029050445394205
66.36686335976396833303
66.42714790280713543780
66.48743244585028833171
66.78396722514369798773
67.14876538405950157085
67.51356354297530515396
68.07917440270381348455
68.64478526243232181514
69.70247757012462841431
70.55935717700423026599
71.41623678388384632854
72.27311639076344818022
72.46365905627482106866
72.65420172178619395709
72.84474438729758105637
72.90673913014339291294
73.12046183775362351298
73.33418454536385411302
73.54790725297411313477
73.02599496635593823157
72.50408267973776332838
71.98217039311958842518
71.25944540568872298536
70.66523624406383419227
70.07102708243894539919
69.67763062162674714273
69.65510309298676361323
69.63257556434675166201
69.78430352911118461634
70.32515421669701538576
70.86600490428284615518
71.40685559186867692461
71.54542542798674276128
71.68399526410482280880
71.82256510022290285633
72.46051969431914585584
73.05136284787317890732
73.64220600142718353709
74.23304915498121658857
74.66065511782468888669
75.08826108066816118480
75.51586704351163348292
75.30214433590140288288
75.08842162829117228284
74.87469892068092747195
74.55382957867699644794
74.23296023667305121307
73.91209089466912018906
73.84177443936852114348
73.63692481416745749812
73.43207518896639385275
73.22722556376534441824
73.27433700430756857713
73.32144844484977852517
73.36855988539200268406
73.51063404178722748838
73.65270819818246650357
73.79478235457769130790
74.21954940338565620550
74.64431645219363531396
75.06908350100160021157
75.28660791207440183825
75.50413232314718925409
75.72165673422000509163
75.93918114529279250746
76.25358308294029541230
76.56798502058778410628
76.88238695823528701112
76.84696504387760285226
76.81154312951991869340
76.77612121516223453455
76.75184119858707276762
76.72756118201192521155
76.70328116543676344463
76.31964945188177296131
75.93601773832679668885
75.55238602477180620554
75.21124166188830884039
74.87009729900481147524
74.52895293612132832095
74.18780857323783095580
73.54060220357655452972
72.89339583391529231449
72.24618946425403009926
72.12073325987446992258
71.99527705549490974590
71.86982085111534956923
71.67298139800482204009
71.33626796059461128152
70.99955452318440052295
71.00995962862279498040
71.03081903621561821183
71.05167844380844144325
70.95531705003725164715
71.06742065170176658739
71.17952425336628152763
71.29162785503078225702
71.74744910679882536897
71.95328195223208922471
72.15911479766533886959
71.98817170462100989425
72.14904982335825422979
72.30992794209549856532
72.47080606083275711171
72.67826587914161962090
72.88572569745048213008
73.09318551575935885012
73.35980952258495335627
73.62643352941051944072
73.89305753623608552516
73.95121654762594687327
74.04497592356355539778
74.13873529950113550058
74.23249467543872981423
74.43698220815673494144
74.64146974087475427950
74.84595727359274519586
74.84595727359274519586
74.84595727359274519586
74.84595727359274519586
74.84595727359274519586
74.84595727359274519586
74.84595727359274519586
74.76493575427721793858
74.68391423496169068130
74.60289271564616342403
74.52187119633063616675
74.46283318529761174887
74.40379517426458733098
74.34475716323157712395
74.18179304141261809491
74.01882891959368748758
73.85586479777474266939
73.76257251304667761360
73.66928022831862676867
73.57598794359057592374
73.57598794359057592374
73.53071039220056093200
73.48543284081054594026
73.45155831806492585656
73.49751082155731296552
73.54346332504971428534
73.58941582854210139431
73.79641785472664139434
74.00341988091116718351
74.21042190709570718354
74.45360826743599602651
74.69679462777628486947
74.93998098811655950158
75.11804366617924699767
75.29610634424192028291
75.47416902230460777901
75.70205234604284783018
75.97521322117111708394
76.24837409629938633771
76.50898325836985236492
76.68976539420231119948
76.87054753003477003404
77.05132966586724307945
77.03864703599711560855
77.02596440612698813766
77.01328177625686066676
77.01328177625684645591
77.01328177625686066676
77.01328177625686066676
76.96447271729550720920
76.91566365833416796249
76.86685459937282871579
76.68515211982040113980
76.50344964026800198553
76.32174716071558862041
76.07237415569963445705
75.82300115068366608284
75.57362814566771191949
75.38127236441471268336
75.16374795334189684581
74.94622354226910942998
74.72869913119630780329
74.49601661946616104615
74.26333410773600007815
74.03065159600583911015
73.71489630936018500051
73.39914102271453089088
73.08338573606887678125
73.11863053863532968535
73.15387534120176837860
73.18912014376820707184
73.30458718485600400072
73.40824706986263947783
73.51190695486927495494
73.55854961611294129398
73.62886607141355455042
73.69918252671416780686
73.76949898201478106330
74.07320646296744826031
74.37691394392011545733
74.68062142487278265435
74.70177917707319181773
74.72293692927360098111
74.74409468147401014448
74.49732511937781964662
74.43887530205297764496
74.38042548472813564331
74.32197566740330785251
74.44277896098429891936
74.56358225456528998620
74.68438554814628105305
74.70829907682659154489
74.73221260550687361501
74.75612613418715568514
74.96960815146664458553
75.18309016874613348591
75.39657218602562238630
76.04127829735298860214
76.68598440868035481799
77.33069052000772103383
77.80061533203780754775
78.08222039929651714374
78.36382546655522673973
78.55618124780824018671
78.58109107423656780611
78.60600090066489542551
78.79931847074035999867
79.06585201165961507286
79.33238555257885593619
79.54586756985833062572
79.54586756985834483658
79.54586756985833062572
79.63945846533601979900
79.58437499551808969045
79.52929152570015958190
79.54325051975922633574
79.73199081311555858065
79.92073110647190503641
80.28313074964762563468
80.73477967882908501451
81.18642860801054439435
81.82392350805133673930
82.29301066444497791963
82.76209782083864752167
83.41338140485726171391
84.11771651251561365825
84.73775619505531153663
85.35779587759499520416
85.88424466465698969841
86.26511891755748706601
86.64599317045799864445
86.95782495948151336052
86.94386596542246081754
86.92990697136340827456
86.74228862748495316737
86.48600103863859089870
86.22971344979222863003
85.78757989008651918539
84.35479977565356080049
82.92201966122061662645
81.30704311916269944049
80.22674262942649647812
79.23073756480894758170
78.23473250019142710698
77.23872743557387821056
76.35491570066463395960
75.47110396575538970865
74.58729223084614545769
73.66356826237593224960
72.73984429390571904150
71.81612032543549162256
70.96106560193317136509
70.10601087843087952933
69.25095615492858769358
69.38654798615351637636
69.79055367044935564991
70.19455935474520913431
70.06388898671937681684
69.48594716489002109938
68.90800534306066538193
68.33006352123132387533
67.78550290385516063907
67.62563273264487406777
67.46576256143458749648
67.67159540686783714136
67.87742825230108678625
68.19937193361977278983
68.52131561493845879340
68.84325929625714479698
69.08016125043502597691
69.31706320461293557855
69.28555130571990616772
69.47295870441703868892
69.66036610311414278840
70.29504495561477028787
70.92972380811541199819
71.02615819065482583028
71.12259257319423966237
70.83433650956780525121
70.29333847639620103109
69.75234044322459681098
69.21134241005300680172
68.55423354099599464462
68.50070371274821923180
68.44717388450047224069
68.47868578339347322981
68.09746424793479491200
67.71624271247611659419
67.11610187942729055521
66.51596104637846451624
66.00482788460413985376
65.49369472282981519129
65.52080603101670419619
65.54791733920360741195
65.80553781653300404741
66.31590026340755628098
66.82626271028210851455
67.22786072114971034352
67.62945873201729796165
67.42747770207562041378
67.22549667213395707677
67.34498569362749265110
67.87720814947275016493
68.40943060531799346791
68.81161029154122843465
69.21378997776446340140
69.52696199271321120250
69.38636774770429838100
69.24577350269539977035
69.10517925768650115970
68.73407584353509491848
68.99183732824124604122
69.24959881294739716395
69.61612473366051290213
69.91539492717481607542
70.21466512068910503785
70.51393531420340821114
70.49173545628249826223
70.67744797330614403563
70.86316049032978980904
71.17891577697545812953
71.50678018130236068828
71.83464458562926324703
72.61627524991382642838
73.16686512163603595127
73.71745499335824547416
74.26804486508046920790
74.29090737300614932792
74.31376988093181523709
74.33663238885748114626
74.42675062398197383118
74.26688045277167304903
74.10701028156137226688
73.94714011035108569558
73.13562528267274842619
72.32411045499441115680
71.51259562731607388741
70.68897168195650237976
70.46895018933068399747
70.24892869670487982603
70.25994799664141510220
70.02045513838785950611
69.78096228013430391002
69.44033188681974877454
69.09970149350519363907
68.23885432007816120858
67.37800714665114298896
66.76714837955890402554
66.15628961246666506213
66.25083484290490787316
66.78911235486663144911
67.32738986682835502506
67.78142989647994909319
68.23546992613154316132
68.08590750304935568238
68.08590750304935568238
68.08590750304935568238
68.33641966123943234379
68.58693181942950900520
69.15660297396306077644
69.72627412849658412597
70.81616206314258477050
71.90604999778858541504
72.99593793243458605957
74.08582586708058670411
74.34701537710421348493
74.60820488712782605489
74.86939439715145283571
75.20283443129571310237
75.53627446543997336903
75.86971449958423363569
75.46697371429340250870
75.06423292900257138172
74.66149214371174025473
74.25875135842090912774
73.88502436598886902175
73.51129737355684312661
73.13757038112481723147
73.17581747434573458122
73.21406456756663772012
73.25231166078756928073
73.60058491340176090034
73.94885816601595251996
74.29713141863015835042
74.19191256341130724650
74.08669370819244193171
73.98147485297359082779
74.05621773745099289954
74.13096062192838076044
74.20570350640578283219
74.28044639088318490394
74.33501438697174990011
74.38958238306031489628
74.44415037914887989245
73.87605119357587568629
73.30795200800287148013
72.73985282242986727397
71.98502190455546667636
71.24026123674551058684
70.49550056893556870818
70.21621896714816557505
70.39428164521083886029
70.57234432327351214553
71.15706365799285038065
71.58669614407695291902
72.01632863016105545739
72.44596111624514378491
72.50186984933834821732
72.55777858243155264972
72.61368731552475708213
72.88028914462658747198
73.13559960214291777447
73.39091005965926228782
73.64622051717560680117
73.76015820361908481573
73.87409589006259125199
73.98803357650606926654
73.48079998441008342525
72.97356639231409758395
72.46633280021814016436
72.11418605675737580896
72.01255147148671653667
71.91091688621605726439
71.85500815312286704284
71.47110151921623355520
71.08719488530958585670
70.70328825140296657992
70.33067298908181896877
69.92035261093056419668
69.51003223277929521373
69.23101437563644822148
69.13735803198009932657
69.04370168832375043166
69.11387234335678897423
69.18404299838982751680
68.96855160305493370743
68.75306020772003989805
68.28705665419506942726
68.20037149469438020333
68.11368633519367676854
68.35499907650643081070
68.59631181781917064200
69.06083884484618806709
69.52536587187321970305
70.02759801473035849995
70.32797609060186516672
70.62835416647337183349
70.74337072885833777036
70.85838729124333212894
70.97340385362831227667
71.08842041601329242440
71.48909902876621913492
72.13898019335326239343
72.78886135794030565194
73.05942412850308187444
73.22476804384699278216
73.39011195919090368989
73.55545587453481459761
73.13902648956899099630
72.72259710460315318414
72.30616771963732958284
72.36047336047285227778
72.41477900130837497272
72.46908464214391187852
72.52339028297942036261
72.15862347084420491683
71.79385665870896104934
71.42908984657373139271
70.66669898884389056093
69.90430813111404972915
69.14191727338420889737
68.48474527087321916952
68.36232717970511885142
68.23990908853703274417
68.47605001196440355216
68.71219093539177436014
68.94833185881914516813
68.91559182343080180999
68.88285178804245845186
69.22571271419258209789
69.56857364034270574393
70.33050701946358174155
71.19633002624402706715
72.06215303302448660361
73.07639753356542655638
73.98675240644678297031
74.89710727932813938423
75.80746215220949579816
76.13272484055207200981
76.45798752889466243232
76.78325021723725285483
76.87162691406982162334
76.96000361090241881357
77.04838030773498758208
76.71711531715763499051
76.01024936504182960562
75.30338341292601000987
74.59651746081019041412
74.21669494003023714868
73.83687241925025546152
73.45704989847030219607
73.03852311275601039142
72.61999632704171858677
72.20146954132744099297
71.83328102880903998084
71.64189591091579245585
71.45051079302257335257
71.49601166663933327072
71.78323593134521729553
72.07046019605110132034
72.77732614816692091608
73.19696783557685648702
73.61660952298677784711
74.03625121039671341805
73.92577448121494398947
73.81529775203317456089
73.70482102285140513231
74.06058672244745366697
74.41635242204347377992
74.77211812163950810373
75.12788382123554242753
74.97244064533900598235
74.81699746944246953717
74.66155429354593309199
74.15958291504125554638
73.65761153653659221163
73.15564015803191466603
73.29490040758628310869
73.43416065714063734049
73.57342090669501999400
73.44764739962653266048
73.32187389255807374866
73.19610038548960062599
72.74667834223723161813
72.02051339123757145444
71.29434844023791129075
70.56818348923826533792
70.17642401910600824522
70.29765769297125643789
70.41889136683651884141
70.64492985222079823870
70.74973466373981523247
70.85453947525884643710
70.60533692342471567827
70.36689131627407789438
70.12844570912344011049
70.25421921619189902231
70.37999272326037214498
70.60495158792511460888
70.82991045258987128364
71.33161222500189069251
71.08782843152431496492
70.84404463804672502647
70.60026084456916350973
69.84348390709406828591
69.11859187785421454464
68.39369984861434659251
67.79004149323975525476
67.18638313786514970616
66.99135505382105293393
66.78557002509344897589
66.57978499636587343957
65.89435772842412575301
65.20893046048237806644
64.42431783494436103865
63.63970520940633690543
63.19234706260779432796
63.49047448169885399238
63.78860190078991365681
64.08672931988095911038
64.80979738218377406156
65.50098053625133331934
66.19216369031889257712
66.63045261037498789847
67.06874153043111164152
67.09840017915669818649
67.11812715777266191708
67.13785413638861143681
67.63722335421871889594
68.13659257204884056591
68.86556853614261797247
69.59454450023639537903
69.98626598559070544070
69.73616479522402755720
69.48606360485734967369
69.23596241449067179019
68.56092058091223861993
68.22025549254578891123
67.87959040417933920253
67.79181954982433921941
67.70404869546933923630
67.84805791205920399989
68.00199879875871999957
68.15593968545822178839
67.85200511428227798660
67.54807054310631997396
67.01452922566670622473
66.48098790822707826464
66.37366044785237306769
66.90815566319864160505
67.44265087854492435326
68.12887405865564005580
68.81509723876634154749
69.16694367366508799932
69.35660784164500114457
69.54627200962490007896
69.73593617760478480250
69.69382027463981899018
69.69382027463983320104
69.69382027463983320104
70.15169573251529300251
70.65249701456656339360
71.15329829661786220640
71.65409957866913259750
70.77261182883032120117
69.89112407899150980484
69.00963632915269840851
67.97642061454945405785
66.94320489994620970720
66.29792845195869688268
65.81483427089004578647
65.33174008982139469026
65.29041139999596055077
65.24908271017052641128
65.16563811738015488118
65.08219352458976914022
64.84088078327701509807
64.55664221778845046629
64.27240365229988583451
64.27240365229988583451
65.22847882712505906966
66.18455400195023230481
67.14062917677540553996
68.27699281313904577928
69.41335644950268601860
70.16178081925058052093
70.71910806669632165722
71.27643531414206279351
71.39199707034457276222
71.09612326911067725632
70.80024946787679596127
70.50437566664291466623
70.36637001393137325067
70.63979991865625152059
70.91322982338110136880
70.90242116261741500693
70.44488565007381453142
69.98735013753019984506
69.52981462498658515869
68.89199065090450346815
67.99236943878329952895
67.09274822666208137889
66.38422413684301659487
65.67570004702396602170
65.67570004702396602170
66.08713560446037149632
66.49857116189679118179
66.74385788320577717059
66.98914460451477737024
66.82299576838738630613
66.65684693225998103117
66.67851634371876912155
67.14691260695747132559
67.61530887019615931877
68.34879610958435591783
69.08228334897255251690
70.07756782639988557548
70.74337074873487551940
71.40917367106986546332
72.07497659340485540724
72.03225542592079477799
71.58552857414088066434
71.13880172236096655070
70.85822370670845771201
71.03518120359954934884
71.21213870049064098566
71.38909619738174683334
71.37823544668665931567
71.36737469599157179800
71.35651394529648428033
71.08056221845190236763
70.40924105840893787445
69.73791989836597338126
69.39608029341535200274
69.05424068846474483507
68.98140009974628128475
68.90855951102783194528
69.23972460660523609022
69.65433429497302597611
70.06894398334080165114
70.02601815916497685066
70.06977749448985548497
70.11353682981473411928
70.15729616513959854274
70.20105550046446296619
70.52631818880703917785
70.85158087714964381121
71.57221299869058839249
72.34318339342743797715
73.11415378816428756181
73.88512418290113714647
74.13391269103541958430
74.38270119916973044383
74.63148970730404130336
74.67441553147986610384
74.71734135565569090431
74.76026717983151570479
74.74924787989496621776
74.73822857995844515244
74.72720928002192408712
74.71618998008540302180
73.78996575328126539262
72.86374152647714197428
71.93751729967303276680
70.96095479967303276680
70.56378823268737221497
70.16662166570172587399
70.02263796908644621908
69.97736041769643122734
69.93208286630640202475
70.00922340608447314025
69.54296014348500420965
69.07669688088553527905
68.57769358289772299031
68.07869028490992491243
67.77124435440089200711
67.46379842389187331264
67.79005406723273097214
68.34808978151843916748
68.90612549580416157369
69.46416121008988397989
69.44280099136123851622
69.37932486966764145109
69.31584874797403017510
69.15366648105518265766
69.16490263810969452152
69.17613879516420638538
69.73077875459625829535
70.47299374404030913865
71.21520873348435998196
71.95742372292841082526
72.50808134489368228515
73.27557570155282462565
74.04307005821196696616
74.57878434392625877081
75.19579944264867776837
75.81281454137108255509
75.77069863840611674277
75.77069863840611674277
75.77069863840611674277
75.77069863840611674277
75.42681108338410922443
75.08292352836210170608
74.73903597334009418773
73.83786028567372738962
72.93668459800736059151
72.03550891034097958254
71.13433322267461278443
70.58801493288839878915
70.04169664310217058301
69.49537835331594237687
68.86775925052158697781
68.13680646535568996569
68.06498468187716355260
67.99316289839862292865
68.25849308707786633477
68.52382327575710974088
68.95962259548501549489
69.39542191521289282718
69.83122123494078437034
70.63673365730105047078
71.35033431495540412470
72.06393497260977198948
72.77753563026413985426
72.91944215534448403560
73.24892371043682715026
73.57840526552917026493
73.90788682062151337959
74.23334926659475740962
74.55881171256798722879
74.88427415854121704797
74.71484644769785177232
74.54541873685447228581
74.37599102601109279931
74.42333951085959142802
74.47068799570806163501
74.51803648055656026372
74.65729673011091449553
74.40198627259456998218
74.14667581507825389053
73.89136535756189516633
73.37927270728994244564
72.86718005701798972495
72.35508740674605121512
71.95034754796475340299
71.82550794793569082231
71.70066834790664245247
71.73356693253643356911
71.54574868495024020376
71.35793043736404683841
70.95333599408600377956
70.76945838302393099184
70.58558077196184399327
70.40170316089977120555
70.61239625690839716299
71.03100172786159305360
71.44960719881477473336
71.93741983251156568713
72.42523246620837085175
72.91304509990516180551
73.40085773360195275927
73.78409738613133583840
74.16733703866071891753
74.55057669119010199665
75.19379745068067677494
75.83701821017123734237
76.48023896966181212065
76.61676809587541470137
76.75329722208900307123
76.88982634830259144110
76.99146093357325071338
76.88518314389935426334
76.77890535422547202415
76.44219191681526126558
76.10547847940505050701
75.76876504199483974844
75.43205160458462898987
74.18878596015859727686
72.94552031573257977470
71.70225467130654806169
70.41972475213529492066
69.69795063134640145108
68.97617651055750798150
68.54037719082961643835
67.99195003851173169096
67.44352288619384694357
66.92999027481889129376
66.41645766344394985481
66.39348183401651226632
66.60094165232537477550
66.80840147063425149554
67.06204213312895490162
67.31568279562367251856
68.30054838754936952228
68.95995153350182249596
69.61935467945428968051
70.27875782540675686505
70.37740517297687858900
70.62210737040260255526
70.86680956782834073238
71.22413959784407211373
71.43541477800417283106
71.64668995816427354839
71.85796513832438847658
71.57868353653699955430
70.98131197142349435580
70.38394040630998915731
69.74038799701065727277
69.56098040089304390676
69.38157280477541632990
69.52762765463103278307
69.67368250448664923624
69.54630744961738741949
69.41893239474813981360
69.14550249002326154368
68.92017022735959130841
68.69483796469592107314
68.61556055188785308019
68.53628313907978508723
68.78152334653168509249
69.02676355398357088688
69.59009372476157295750
69.89811343802321630392
70.20613315128485965033
70.05000805136479868906
69.89388295144473772780
69.69793878934322606256
69.50199462724171439731
69.57948036986508100199
69.86371893535363142291
70.14795750084221026555
70.38409842426958107353
70.41348652483173964356
70.44287462539389821359
70.47226272595605678362
70.17713320625827577715
70.07794784866199222506
69.97876249106572288383
70.13488759098578384510
70.44721527226508328567
70.75954295354436851540
71.07187063482366795597
71.11168969700511865994
71.15150875918656936392
71.19132782136800585704
71.02439406068424432306
70.97086423243648312109
70.91733440418872191913
71.07055739880617295512
71.12044671105206816719
71.17033602329796337926
71.22022533554385859134
71.07417048568825634902
70.62744363390834223537
70.18071678212842812172
69.56905959893342128453
69.41285701757419701607
69.25665443621495853677
69.41277953613501949803
69.56890463605509467016
69.61972756085341984544
69.67055048565175923159
69.60796947801409828571
69.43510968560853768849
69.26224989320297709128
69.19272378316897231798
69.12319767313496754468
69.42545510434980826631
69.72771253556464898793
70.33064196870378737003
70.88784554966548512311
71.45377688068302290958
71.56425360986477812730
71.66343896746104746853
71.76262432505734523147
71.86180968265361457270
72.19363089443518788357
72.52545210621674698359
72.85727331799832029446
73.29937331454777904582
73.48283711102527604453
73.66630090750277304323
73.84976470398027004194
73.58825994749929577665
73.32675519101833572222
73.06525043453738987864
73.06525043453737566779
73.06525043453737566779
73.06525043453737566779
73.07654180612287575514
72.84141868710781864138
72.60629556809277573848
72.24383877001416465191
72.13914345664170468808
72.03444814326924472425
71.92975282989678476042
72.28551852949281908423
72.64128422908885340803
72.99704992868488773183
73.24703833501966698805
73.49702674135443203340
73.74701514768921128962
73.78122464972049954213
73.69241733199751820393
73.60361001427455107660
73.51480269655155552755
73.78396720990211576918
74.05313172325267601082
74.32229623660325046330
74.15775134583179806214
73.99320645506034566097
73.82866156428890747065
73.46229186062093674536
73.07486456021436538322
72.68743725980779402107
72.47897226437208928473
72.60887552187341498211
72.73877877937474067949
72.86868203687605216601
72.87959525496580681647
72.89050847305554725608
72.90142169114531611740
72.80077756876204375658
72.70013344637879981747
72.59948932399554166750
72.67479312102814503760
72.87371686987142993530
73.07264061871472904386
73.27156436755802815242
73.30322596836856519076
73.33488756917910222910
73.36654916998963926744
73.22518643320705677979
73.08382369642447429214
72.94246095964189180449
73.04310508202513574361
73.28511194119096217037
73.52711880035681701884
73.76912565952264344560
73.60060526855534135393
73.43208487758802505141
73.26356448662073717060
72.42193463731975100472
71.58030478801877904971
70.73867493871779288384
70.08536483418819784674
69.97142714774470562134
69.85748946130121339593
69.57820785951381026280
69.19065967784798942830
68.80311149618215438295
68.41556331451633354845
67.88665239606791601545
67.46600805749793039467
67.04536371892794477390
67.03524663049110188240
67.36228151421202881011
67.68931639793295573782
68.56584078817687100127
69.44236517842077205387
70.22697780395878908166
71.01159042949680610946
71.25683063694870611471
71.44643418295635228787
71.63603772896399846104
71.93390785485007654643
72.47498967219240739723
73.01607148953470982633
73.55715330687704067714
73.94165376388329491419
74.32615422088954915125
74.71065467789580338831
74.75800316274430201702
74.82938641147526936948
74.90076966020622251108
74.97215290893718986354
75.36326680130264321633
75.75438069366811077998
76.14549458603356413278
76.78510102323340902331
77.42470746043323970298
78.06431389763309880436
78.44643988852531890643
78.82856587941755321935
78.67503224374105741390
78.56981338852220630997
78.46459453330334099519
78.35937567808448989126
78.50733969323601968426
78.63126894450505233181
78.75519819577407076849
78.64892040610018852931
78.31482373749767589288
77.98072706889517746731
77.66983541505105392844
77.16608787781680689477
76.66234034058254565025
76.43001188098401144089
76.21195217623683504371
75.99389247148967285739
76.33773764292105568074
76.68158281435243850410
77.02542798578380711660
77.49090705180636007299
77.70320324745853213244
77.91549944311070419189
78.22659276028522867819
78.76789311840266805120
79.30919347652010742422
79.76557984307611093300
80.19876119487372534422
80.63194254667135396630
80.95613207285589396633
81.00890252140470693121
81.06167296995351989608
81.16835177429747716360
81.24878532903161953982
81.32921888376574770518
81.18821830950886919709
80.92558384066080634511
80.66294937181277191485
80.01096863824589888736
79.26019078315670185475
78.50941292806749061128
77.75863507297827936782
77.30224870642226164819
76.84586233986627235026
76.38947597331025463063
75.94044684709666626077
75.49141772088307789090
75.04238859466948952104
74.45276595316005341374
73.86314331165061730644
73.27352067014118119914
73.12854644333705778081
72.98357221653293436248
72.83859798972881094414
73.08297002764351191217
73.41083443197041447092
73.73869883629731702968
74.06656324062421958843
74.05504689047805300106
74.04353054033185799199
74.03201419018569140462
74.31351754320340319282
74.59502089622111498102
74.87652424923882676921
75.20763495439045698276
75.53874565954208719631
75.86985636469370319901
75.77794459998781917420
75.68603283528194936025
75.59412107057606533544
75.54771017948695543964
75.41780692198562974227
75.28790366448430404489
75.15800040698297834751
75.02412283653362123914
74.89024526608427834162
74.75636769563492123325
74.29452283946017132621
73.83267798328542141917
73.37083312711067151213
73.22804216673040400565
73.08525120635013649917
72.94246024596986899269
72.99947746973280970906
73.02211583542391792889
73.04475420111502614873
73.02189169318936023956
73.13968583300942327696
73.25747997282950052522
73.37527411264957777348
73.80227310050382527606
74.22927208835807277865
74.65627107621233449208
75.15577157571283350990
75.65527207521333252771
76.15477257471381733467
76.18966711565676064311
76.22456165659968974069
76.25945619754261883827
76.48966323848553372500
76.75424913750032374082
77.01883503651509954580
77.05321389458694625318
76.94693610491304980314
76.84065831523915335310
76.73438052556527111392
76.54700544228745684450
76.35963035900962836422
76.17225527573182830565
76.17225527573182830565
76.17225527573182830565
76.17225527573181409480
76.09163488261143015734
76.01101448949104621988
75.93039409637064807157
75.65446120325024992326
75.37852831012986598580
75.10259541700946783749
75.20887320668336428753
75.31515099635726073757
75.42142878603115718761
75.52770657570503942679
75.28808267112860619363
75.04845876655215874962
74.80883486197572551646
74.21952690965407839485
73.63021895733243127324
73.04091100501076994078
73.10984187194662808906
73.17877273888248623734
73.24770360581834438563
73.31663447275420253391
73.45659030891256691120
73.59654614507093128850
73.58449833937794437588
73.63330739833928362259
73.68211645730062286930
73.73092551626197632686
74.23708296175765042335
74.74324040725332451984
75.24939785274901282719
75.65607593494078741969
76.06275401713257622305
76.46943209932435081555
76.25790733979461322178
76.04638258026486141716
75.83485782073510961254
75.84654734691963540172
75.78721190388168338359
75.72787646084370294375
75.66854101780572250391
75.15146655908367279153
74.63439210036160886830
74.11731764163954494506
73.62202094683897257710
73.12672425203837178742
72.63142755723777099774
72.66684947159546936746
72.70227138595313931546
72.73769330031083768517
72.99632950038279943783
73.27887922913505747147
73.56142895788731550510
73.62076440092529594494
73.71642766623141085347
73.81209093153753997285
73.90775419684365488138
74.44961057467753562378
74.99146695251138794447
75.53332333034526868687
75.83530245507039069253
76.13728157979551269818
76.43926070452063470384
76.61190014786529900448
76.78453959120996330512
76.95717903455462760576
77.01742038550307256628
77.05374820777123545668
77.09007603003936992536
76.88319216085128005034
76.63998046939502728492
76.39676877793878873035
76.15355708648255017579
76.16604409998851110686
76.17853111349444361622
76.19101812700040454729
76.09388862747633197614
75.99675912795228782670
75.89962962842821525555
75.81176057841817339522
75.72389152840813153489
75.63602247839807546370
75.71037216645984813113
75.78472185452159237684
75.85907154258333662256
76.06595541177144070843
76.27283928095954479431
76.47972315014763466934
76.54665118317736016706
76.30856954974689188020
76.07048791631642359334
75.83240628288595530648
75.95444560883834128617
76.07648493479074147672
76.19852426074314166726
76.13766739608880129708
76.07681053143446092690
76.01595366678014897843
75.81104617246933230490
75.60613867815854405308
75.40123118384772737954
75.48686996761387035804
75.57250875138002754738
75.65814753514617052588
75.83443119357269779357
76.01071485199919663955
76.18699851042570969639
76.35111126995832364628
76.27714239606046930930
76.20317352216258655062
76.30544183330370344720
76.50583594171692425334
76.70623005013013084863
76.94609673475173394763
77.28019340335423237320
77.61429007195673079877
77.87006157625044977522
77.94596431279711623574
78.02186704934376848541
78.09776978589043494594
78.36851487637946434006
78.63925996686847952333
78.91000505735750891745
79.06027253346499605868
79.21054000957245477821
79.36080748567994191944
79.03180453521395065763
78.70280158474800202839
78.37379863428202497744
78.12194499147094006730
77.87009134865985515717
77.61823770584875603618
77.16354245271175216203
76.70884719957473407703
76.25415194643773020289
75.82071531721582857699
75.24174729554957252731
74.66277927388328805591
74.08381125221703200623
73.83836188351280327424
73.59291251480857454226
73.34746314610436002113
73.20417687924800986821
73.06089061239165971529
72.91760434553532377322
72.73372673447323677465
72.54984912341116398693
72.36597151234909119921
72.46326067592173103549
72.73250088006562918963
73.00174108420952734377
73.24972266443833746052
73.42738778936653432083
73.60505291429473118114
73.78271803922291383060
73.84175605025593824848
73.90079406128896266637
73.95983207232197287340
74.41910226450889354055
74.87837245669579999685
75.33764264888272066401
75.61914600190043245220
75.90064935491814424040
76.18215270793587023945
76.21553391238906272065
76.07696407627099688398
75.93839424015291683645
75.79982440403483678892
75.82302941879322588647
75.84623443355161498403
75.86943944831000408158
75.52569166603763051171
75.18194388376527115270
74.83819610149289758283
74.52221217616232706860
74.20622825083175655436
73.89024432550118604013
73.78069201305474678065
73.67113970060830752118
73.56158738816186826170
73.70015722427993409838
73.70015722427993409838
73.70015722427993409838
73.70015722427994830923
73.58639054608491392173
73.47262386788989374509
73.35885718969487356844
73.65525648711876272046
73.95165578454265187247
74.24805508196652681363
74.09411419526702502480
73.94017330856752323598
73.78623242186800723630
73.52759622179604548364
73.35564518122477295492
73.18369414065350042620
73.01174310008221368662
73.16223950669109399314
73.31273591329997429966
73.46323231990885460618
73.49677675958253075805
73.53032119925620690992
73.56386563892988306179
73.29853545025065386653
73.03320526157139624956
72.76787507289215284345
72.72575916992718703113
72.93728392945693883576
73.14880868898669064038
73.36033344851642823414
73.38253330643735239391
73.40473316435824813198
73.42693302227915808089
73.32171416706029276611
73.21649531184144166218
73.11127645662259055825
73.14531785095810789699
73.35742192335629852096
73.56952599575448914493
73.95504849212603915021
74.27971412384326299616
74.60437975556047263126
74.92904538727771068807
75.25323491346223647724
75.57742443964677647728
75.90161396583131647731
76.49373249032234411970
77.08585101481338597296
77.67796953930439940450
78.42083274635099598981
79.16369595339759257513
79.90655916044417494959
80.37203822646671369512
80.65945461442657915541
80.12283292176844895494
79.58621122911030454361
79.11044640110650050246
78.63468157310268225046
78.06379267794889642573
77.23973922583309104084
76.41568577371727144509
75.59163232160145184935
74.96629463925299319271
74.34095695690453453608
73.71561927455604745774
72.79680814809361777407
71.87799702163118809040
70.95918589516872998502
70.37128873797809092139
69.78339158078748027947
70.01953250421483687660
70.25567342764220768458
70.45954314673690532800
70.66341286583161718227
70.96240665207631082012
71.60651151807388714587
72.25061638407143504992
72.89472125006899716482
73.10160511925710125070
73.30848898844519112572
73.51537285763329521160
73.65727938271362518208
73.79918590779396936341
73.94109243287431354474
74.07497000332367065312
74.20884757377302776149
74.34272514422237065901
74.47660271467172776738
74.55263325432925114455
74.62866379398678873258
74.70469433364431210975
74.10761589273553795465
73.51053745182676379955
72.91345901091798964444
72.38959654085299177950
72.22711317605461545099
72.06462981125625333334
72.05339365420174146948
71.89042953238281086215
71.72746541056386604396
71.51900041512814709677
71.22825933405948717336
70.93751825299082724996
70.64677717192215311570
70.44615432597797166636
70.52481308182119335015
70.60347183766441503394
71.01012849432106577297
71.26209585547698566188
71.51406321663287712909
71.76603057778879701800
71.50195499724503633843
71.23787941670127565885
70.97380383615752919013
70.86145622037820146488
71.04705870788825450290
71.23266119539827911922
71.50053976854127313345
71.59331970278435619548
71.68609963702743925751
71.77887957127052231954
71.58187405511606016262
71.38486853896161221655
71.18786302280716427049
71.14554680215346138539
71.40545495287307176113
71.66536310359268213688
72.07993509074529470126
72.36653819992906733205
72.65314130911281154113
72.93974441829656996106
72.95114744694097907995
72.96255047558538819885
72.97395350422979731775
73.26567402699296849278
73.55739454975615387866
73.84911507251932505369
74.15133944389263831454
74.32559493729706900922
74.49985043070149970390
74.67410592410591618773
74.47920901310732233469
74.28431210210871427080
74.08941519111010620691
74.17003558423050435522
74.25065597735090250353
74.33127637047130065184
74.30852634772125497875
74.28577632497123772737
74.26302630222122047599
74.13505742425235212067
74.23268316620797691030
74.33030890816361591078
74.42793465011925491126
74.84786469471418968169
75.26779473930909603041
75.68772478390403080084
76.29887211115305944986
76.91001943840207388803
77.52116676565108832619
78.11535690649617436065
78.70954704734123197341
79.21595286451574224884
79.80297907481066488344
80.39000528510557330719
80.87823437387814351496
81.36646346265069951187
81.62909793149876236384
81.73083423101041944392
81.83257053052209073485
81.73997140536334882199
81.64737228020460690914
81.55477315504586499628
81.33788478026275470256
80.86259635297930969955
80.38730792569586469654
79.78142825866626708375
79.19898623812194671245
78.70432854124817367847
78.20967084437441485534
77.71501314750065603221
77.51546580955083243225
77.31591847160099462144
77.11637113365118523234
76.91700909218387494093
76.71764705071657886037
76.51828500924926856896
76.03775619314725986442
75.55722737704525115987
75.07669856094322824447
75.18405135243389736388
75.29140414392455227244
75.39875693541520718099
75.48267208042057063722
75.56658722542594830429
75.65050237043132597137
75.73441751543668942759
75.49146161679141187051
75.24850571814613431343
75.00554981950085675635
75.09075274853412906850
75.17595567756738716980
75.26115860660064527110
75.65243813669695782664
76.04371766679324196048
76.43499719688955451602
76.32764440539888539661
76.22029161390823048805
76.11293882241756136864
76.16224978391556987845
76.21156074541357838825
76.26087170691157268720
76.21255692645394219653
76.29479995224534150111
76.37704297803675501655
76.06471529675746978683
75.58494166065349872952
75.10516802454952767221
74.62539438844555661490
74.43303860719254316791
74.24068282593952972093
74.04832704468653048480
74.01360567978196058903
73.97888431487739069325
73.94416294997282079748
73.60809160086654401312
73.27202025176025301789
72.93594890265396202267
72.69750329550333844963
72.79512903745896323926
72.89275477941461645059
73.38495122844095419623
73.82783671596929764291
74.27072220349764108960
74.71360769102599874714
74.85639865140626625362
74.99918961178653376010
75.14198057216680126658
75.43510198957169166079
75.72822340697658205499
76.02134482438148666006
76.40775444668365423695
76.79416406898580760299
77.18057369128796096902
77.41901929843859875291
77.32139355648297396328
77.22376781452733496280
77.22376781452732075195
77.27307877602532926176
77.32238973752333777156
77.32238973752333777156
77.31015658339211427119
77.29792342926089077082
77.28569027512968148130
76.81576546309960917824
76.34584065106952266433
75.87591583903946457212
75.27392525684767576877
74.67193467465588696541
74.06994409246411237291
74.14278791530793455422
74.21563173815175673553
74.28847556099557891685
74.26369364188376209768
74.07230852399052878354
73.88092340609729546941
73.73884924970207066508
73.73884924970207066508
73.73884924970207066508
73.73884924970207066508
74.20877406173214296814
74.67869887376221527120
75.14862368579230178511
75.49743139761369548069
75.84623910943511759797
76.19504682125652550440
75.99423412044382075692
75.79342141963113022030
75.59260871881842547282
75.47241641112611887365
75.51882730221521455860
75.56523819330433866526
75.61164908439344856106
75.11910366805756211761
74.62655825172170409587
74.13401283538583186328
73.58064343056537381926
73.02727402574490156439
72.47390462092444352038
72.22511611279014687170
72.36647884957272935935
72.50784158635531184700
72.67195434588791158603
72.76432285930906118665
72.85669137273021078727
72.86843949303096223957
73.00099090691270475872
73.13354232079444727788
73.26609373467620400788
73.85828838570594712110
74.45048303673570444516
75.04267768776544755838
75.31444202532719600640
75.58620636288893024357
75.85797070045069290245
75.82747760679758641800
75.40683326822760079722
74.98618892965761517644
74.56554459108761534480
74.41726734457327552263
74.26899009805893570046
74.12071285154459587829
73.68150255915816160268
73.24229226677175574878
72.80308197438532147316
72.30111059588065813841
71.47673965432221621086
70.65236871276377428330
70.05820481214826145333
70.04668846200208065511
70.03517211185589985689
70.32591319292455978029
70.59379176606755379453
70.86167033921054780876
71.12954891235354182299
71.19680463955235438789
71.18578533961581911171
71.17476603967929804639
71.33387649203385194596
71.68788385538701390942
72.04189121874016166203
72.39589858209332362549
72.56042999662474812794
72.72496141115615841954
72.88949282568756871115
72.47137668974964697099
71.69626696228206697015
70.92115723481447275844
70.16891001527254445591
69.41666279573061615338
69.15890131102446503064
68.90113982631831390790
68.72165336874749641538
68.51195583646806142042
68.30225830418862642546
67.89766386091056915575
67.49306941763252609690
67.05867964838037664776
67.13616539100374325244
67.21365113362709564626
67.67817816065412728221
68.14270518768114470731
68.96422580623786302567
69.75085188385162382474
70.53747796146539883466
71.32410403907917384458
71.63944939661556077226
71.95479475415194769994
72.27014011168833462762
72.61569654393336747944
73.02029098721141053829
73.42488543048945359715
73.82947987376749665600
74.09106495379161572146
74.35265003381572057606
74.61423511383982543066
74.25698915541026678966
73.89974319698070814866
73.54249723855114950766
73.22014582106450575338
73.17441996888705091351
73.12869411670958186278
73.05976324977373792535
72.56922063310111070678
72.07867801642851190991
71.58813539975591311304
71.15374563050374945306
70.71935586125160000392
70.28496609199945055479
70.02338101197534570019
70.14883721635490587687
70.27429342073446605355
70.63153937916402469455
70.84123691144347390036
71.05093444372290889532
71.26063197600234389029
71.00410039696497221939
70.74756881792760054850
70.49103723889021466675
70.65611740958956943359
71.27854186013902904051
71.90096631068848864743
72.40819990278446027787
72.95270242749819544770
73.49720495221194482838
74.04170747692569420906
74.23701997692569420906
74.43233247692569420906
74.62764497692569420906
75.08769340307581785510
75.54774182922594150114
76.00779025537605093632
76.64414435519341850522
77.28049845501075765242
77.91685255482811101047
78.23068363146347792281
78.08717042824878262763
77.94365722503408733246
77.87081340219027936200
77.76070064672867943045
77.65058789126709370976
77.54047513580550798906
77.17573492496211429170
76.81099471411874901605
76.44625450327538374040
75.64088537575860016204
74.83551624824181658369
74.03014712072504721618
73.71925546688092367731
73.40836381303681434929
73.09747215919270502127
73.10910352853055371725
73.37913495036872291166
73.64916637220690631693
73.84852841367421660834
73.83660184318340213849
73.82467527269260187950
73.81274870220180162050
73.78032234605953476603
73.74789598991725370070
73.71546963377498684622
73.92224721199599457577
74.12902479021698809447
74.33580236843799582402
74.06140034532677418611
73.77669057408742503412
73.49198080284806167128
73.20727103160871251930
73.15587300529293202089
73.10447497897713731163
73.05307695266134260237
73.37047761894882569322
73.68787828523629457322
74.00527895152377766408
74.68492227470777322651
75.36456559789176878894
76.04420892107576435137
77.09338186572037443511
78.14255481036499872971
79.19172775500959460260
80.16281214098160035064
81.14420427508170519104
81.90420247360449934604
82.66420067212727929018
82.93248707322618429316
83.25298621144125377214
83.61927094082990663537
83.84804860625355615866
84.11495588258115674307
84.43039175460465628476
83.11559085302688743013
81.80078995144913278637
80.48598904987137814260
78.75621776407470520098
76.79587339113047050887
74.83552901818622160590
72.98876800331123604337
71.69165626496041454629
70.49988669244686434467
67.30540675378090043068
64.11092681511490809498
63.06656043056227645138
63.42089909271894754283
63.71618131118284367176
63.96603549603690197500
64.41543376354982797238
64.56370469636406994596
64.71197562917832613039
64.86024656199258231482
64.75202145376748319450
64.82496091401547744226
64.89790037426345747917
64.97083983451145172694
64.75467684965924775042
64.56553423791359591632
65.06935499367233433077
66.21155393577089398605
67.35375287786943943047
67.66488789842442486133
67.97602291897941029220
68.30882735099318381344
68.43579893757369347895
68.56277052415420314446
68.34761636946228691158
68.13246221477035646785
68.14174209598787967934
68.15102197720540289083
67.96524703028396174886
67.77947208336250639604
67.90233911174971126457
68.11155953969753795718
68.32077996764536464980
67.89162220925338431243
67.46246445086140397507
66.65700645505333454821
65.85154845924523669964
65.02442105197837918240
64.19729364471152166516
64.07032205813101199965
64.28547621282294244338
64.50063036751485867626
64.71578452220678911999
65.32300123962872362426
66.12527278518962248199
66.92754433075052133972
67.70553585973627264138
68.39717398916137369724
69.08881211858647475310
69.38203211555969573965
69.67525211253291672620
70.34477234692221259138
71.06559217091481173156
71.78641199490739666089
72.50723181889998159022
72.52789582220621866782
72.47707289740787928167
72.42624997260955410638
72.37542704781121472024
71.56472140962372918693
70.75401577143625786448
69.94331013324878654203
69.15688451163646277564
68.69845997850183039191
68.24003544536719800817
68.18002904468445990460
68.15984170618315829415
68.13965436768187089456
68.06816743957729443082
67.99668051147273217794
67.49932081602878497506
67.00196112058483777218
66.57608835324546703305
66.14206057546769557121
65.98705065483278531246
66.19986088485698871864
66.41267111488119212481
66.94742502622409574542
67.48217893756698515517
67.68893176043219739313
67.89568458329739542023
67.85658950406471490169
67.77767536265056946831
67.69876122123642403494
67.69876122123642403494
67.69876122123642403494
68.12463398857579477408
68.55050675591516551322
69.32360174547673636880
70.10485174547673636880
70.60708388833387516570
70.87549774140481417817
71.14391159447572476893
71.09038176622796356696
70.70404934864555457352
70.31771693106313136923
69.93138451348070816493
69.79089999799620613885
69.85979040320299304767
69.92868080840980837820
69.91865707220246406450
70.09645158358131311616
70.27424609496016216781
70.45204060633901121946
70.28261289549564594381
69.97595401699096839820
69.66929513848629085260
69.59645454976784151313
69.78386194846495982347
69.97126934716207813381
70.49147933519384423562
70.71087799209249169508
70.93027664899115336539
71.14967530588981503570
71.15969904209714513854
70.63269043663218837992
70.10568183116723162129
69.39085497811606728646
68.67602812506491716249
68.45841455150463161772
68.24080097794433186209
68.16041857204533016557
68.25451778467888175328
68.34861699731243334099
68.18246822253041727890
68.01631944774841542767
67.67568905443386029219
67.63586999225240958822
67.59605093007095888424
67.89686226120406331574
68.19767359233719616896
69.03551726514260167278
69.91977182903713128326
70.80402639293163247203
71.68828095682616208251
71.38569004506778981067
71.08309913330941753884
70.78050822155103105615
70.30343569126011971093
70.01376321427409266107
69.72409073728806561121
69.43441826030203856135
69.31922740184857900658
69.56973956003864145714
69.82025171822871811855
69.73013348310425385534
69.20992349507247354268
68.68971350704069323001
68.12309262791981723240
67.55647174879894123478
66.74293869094771025630
66.61903782925850237007
66.49513696756932290555
66.37123610588011501932
66.74399767267155425543
66.92935918615805235277
67.11472069964457887181
67.04129494776486808405
66.96786919588517150714
66.52874042736192450320
66.08961165883866328841
65.41260772720102067979
65.16569554847066569891
64.91878336974031071804
64.67187119100995573717
65.10196294391724336492
65.77896687555488597354
66.45597080719252858216
67.15517459675109535056
67.85437838630964790809
68.05691974738758176500
67.65034957337554999413
67.24377939936351822325
67.09599649071770954833
66.94821358207187245171
67.34358301527025503219
67.73895244846863761268
68.37219704478141579784
68.64646019112822727948
68.92072333747503876111
69.19498648382186445360
68.79224569853101911576
67.52498760933305277376
66.25772952013508643176
64.96827157301622435170
63.67881362589734806079
63.24471574187214173435
63.41972939293692945739
63.59474304400171718044
63.76975669506649069262
63.80356530228711164909
63.29422156766352713930
62.78487783303994262951
62.27553409841635101429
61.41101679450921579928
60.54649949060208768969
59.68198218669495247468
58.81746488278781725967
59.53161990203732756299
60.24577492128683786632
60.95992994053635527507
61.67408495978586557840
61.29252714216386266344
60.91096932454186685391
60.52941150691987104437
60.14785368929786812942
59.44524071850033664077
58.74262774770280515213
58.04001477690527366349
57.33740180610774217485
57.34894385455972098953
58.25986194245450633389
59.17078003034928457282
60.08169811824406281175
60.53751202461988611958
60.99332593099571653283
61.44913983737154694609
61.90495374374736314849
62.72060944539455817903
63.53626514704176031501
64.35192084868896245098
65.09080869632667543101
66.29195674098409085673
67.49310478564152049330
68.17030757971974708198
68.84751037379795945981
69.52471316787617183763
69.30253992251159900206
69.44843632003026812072
69.61517506005159816596
69.48873411363861407608
69.34121967615679693608
69.16688443186011170383
68.76290601392616963494
68.18512538291069802199
67.60734475189524061989
67.02956412087978321779
66.13586913897709962384
65.24217415707443024075
64.34847917517173243596
64.21688637592970394508
64.65123254017049703180
65.08557870441126169681
65.51992486865202636181
66.09857847259284824304
66.58820844515817327647
67.00789127878559270357
67.37161640126268480344
67.59351448965895770016
67.85019205524993424206
68.10686962084092499481
68.57201218186763469475
69.03715474289433018384
69.79625627416547217763
70.64911718137420848507
71.50197808858294479251
72.35483899579168109994
73.00502822327719343320
73.65521745076270576646
74.30540667824823231058
74.76312329866142647461
75.22083991907462063864
75.67855653948781480267
75.98204525904557726790
76.38189537237451531837
76.78174548570345336884
77.18159559903239141931
76.42249406776126363638
75.66339253649012164260
74.90429100521897964882
73.69387861059605882019
72.48346621597315220242
71.27305382135021716294
69.87623006905610623107
68.47940631676198108835
67.21311723447621488958
65.88138586705055388393
64.54965449962489287827
63.21792313219923897805
62.84973461968083086049
62.48154610716242984836
62.11335759464401462537
61.59122819542609761356
62.01958544537255590967
62.44794269531901420578
62.87629994526547250189
63.39281213090197297788
63.90932431653847345387
64.42583650217497392987
64.74361352670351266170
65.06139055123205139353
65.24863290575223118140
65.43587526027241096926
66.16174562153381089047
66.88761598279522502253
66.80417139000483928157
66.72072679721445354062
66.32368620223459743102
66.08058649395424311024
65.83748678567388878946
65.13291118885692299045
64.42833559203992876974
63.99315654694710531203
63.55797750185426764347
63.12279845676142286948
63.43801327992306227088
63.75322810308470877771
64.06844292624636238997
64.36530265917437532153
64.12353438536116811974
63.88176611154796091796
63.63999783773475371618
63.91176217529650216420
64.49712251504772098087
65.08248285479892558669
65.64486736512270681487
66.66872776398309952128
67.69258816284349222769
68.35927352754589492179
69.12119965863432469177
70.00034519450561276699
70.29047004349342842033
70.63334486502448328338
71.04479465086174627686
71.30301203952446087442
71.56122942818720389369
71.81944681684993270210
72.71572725303975914812
72.79035551102964518577
72.86498376901951701257
72.41382202753521823979
71.99942161313481392426
71.58502119873439539788
71.17062078433397687149
70.33542535615634960777
69.63942916600834109886
69.05050931280617021457
68.54572086720432366747
68.54572086720432366747
68.54572086720432366747
68.52559636087070771282
68.50211777014816050269
68.47437034474877748380
67.42948830757710254602
66.38460627040541339738
65.33972423323373845960
64.89843804949045136254
64.45715186574715005463
64.01586568200386295757
63.57457949826056875509
62.71602799635464009498
61.85747649444871854030
60.99892499254278988019
60.14037349063687543094
59.50695941253505338864
60.16236337190571958899
60.72413819422343550514
61.21100970689879261499
61.99220120709723147456
62.61818909423161727545
63.24417698136600307635
63.00580023168065935124
62.68107008243468669662
62.35633993318870693656
62.03160978394272717651
62.23543527967103727860
63.22279311011676838916
64.21015094056249949972
65.19750877100821639942
66.17515962278341135061
67.15281047455860630180
67.16247343065013808427
67.10482426706991532228
67.03893950869249351854
66.52577533935549070065
65.92708380846228521932
65.38995883187543256554
66.00532003771486699861
66.62068124355430143169
67.23604244939376428647
67.76478628622339783760
67.58878926308733525730
67.41279223995125846614
67.23679521681519588583
66.88391473864996328302
66.54397689871213117385
66.20403905877428485383
65.85271128207340041172
65.70757078817599960985
65.58316465054966215575
65.47534599794018106422
65.44157843151046449748
65.29598411551255310314
65.15038979951465591967
65.00479548351674452533
65.29716248677513590337
65.65449251679085307387
66.01182254680657024437
66.75556219912445499176
67.49930185144233973915
68.37570409503207713442
69.23942370875170126965
70.10314332247131119402
70.97540538876322102624
71.53958138054571236353
72.10375737232818948996
72.66793336411068082725
73.17153560128956257813
72.97111873420602989881
72.77070186712248300864
72.57028500003896454018
72.61214093049720474937
72.65997627959232829653
72.71517091316361813824
72.26435182259392320248
71.73156562464791363709
71.09222218711269647429
70.24445883934934897752
69.39669549158600148075
68.54893214382266819484
67.82860943925099661556
67.10828673467935345798
66.38796403010771030040
65.66764132553605293197
66.53705534048998515573
67.40646935544388895778
68.27588337039782118154
68.41147959676756329372
68.52447645207570303683
68.62008917579797184771
68.70204293898848391109
68.69856578195752661031
68.69552326955542298492
68.64586334074789419901
68.94667467188101284137
69.24748600301413148372
69.77673300666185696173
70.30598001030958243973
70.61630771636718861828
70.92663542242478058597
70.94739678304131302866
70.98238755449288817090
71.02237700758041683002
71.11891048043544572010
71.23153286543298179367
71.36463204770278423439
69.82367382479236539439
68.38431162705843746608
66.94494942932449532691
65.50558723159055318774
63.55644865576113744510
62.06063433444460741839
60.56482001312808449711
59.06900569181155447041
57.89161943971704005207
57.77250375456362974091
57.65338806941021942976
58.63597610066307197485
59.46739674249241147663
60.07881203833964889327
60.60870529474058798769
61.07236189409140081352
61.15578209035925993931
61.23920228662711906509
61.32262248289498529630
61.40604267916284442208
61.48946287543070354786
62.28851081830727309807
63.08755876118384975371
63.88660670406042640934
64.64451931168400733441
64.67487090453559517300
64.70522249738719722245
63.93637329103799515906
63.16752408468878599024
62.48725197584524693184
61.80697986700170787344
61.12670775815817592047
61.83611768674423814218
62.54552761533030746932
63.25493754391637679646
63.67370644572582705223
64.09247534753527020257
63.48395607775839266651
62.87543680798152223588
61.67979560124153692868
60.52528972975453314120
59.37078385826752224830
58.21627798678052556625
57.86097291449431878618
58.47481220020860348541
59.08865148592288107920
59.70249077163717288386
60.25194132108772038237
60.27893397908474781843
60.30592663708176814907
60.33291929507879558514
59.93876476806422459731
59.54461024104966071491
59.15045571403508972708
58.75630118702052584467
59.73480856036137254250
60.71331593370223345119
61.69182330704308014901
62.67033068038394105770
63.35233607937047395353
63.06519712035651537008
62.77805816134255678662
62.49091920232859820317
62.12004008144947420078
61.74916096057035730382
61.37828183969124040686
61.00740271881211640448
61.85967544608485013669
62.71194817335757676346
63.56422090063030339024
64.41649362790303712245
64.48322639178344672928
64.54995915566385633610
64.61669191954428015379
64.33646605749946445485
64.35274216980897676876
64.36901828211850329353
64.38529439442801560745
65.02711707014898934176
65.81706864399886569572
66.60702021784874204968
67.04748823237198962488
67.55088024896998888380
66.29433746628892265562
65.03779468360785642744
63.78125190092679019926
62.52470911824572397109
61.26816633556465774291
60.96702824124960073959
60.66589014693454373628
60.76127619653401978894
60.85666224613350294703
60.54394417595146649091
60.23122610576941582394
59.20359767740283984949
58.17596924903625676961
56.78012067411806640393
56.53460230469308811507
56.31977373144623300050
56.47646910072498371846
56.71748421045954557940
56.95849932019410744033
57.19951442992866930126
57.44052953966323116219
56.84556554707754827405
56.76210275367800761614
56.67863996027846695824
56.61984778669261686446
57.49714337682011944253
58.37443896694761491517
59.25173455707511749324
60.12903014720261296588
61.56932932513711875799
63.00962850307162455010
64.44992768100613034221
65.73822321708928484441
66.94219901271661399278
68.14617480834394314115
69.62608349709168464869
71.10599218583942615624
72.58590087458713924207
73.59275570666841304046
74.59961053874968683886
75.58179475101727007313
75.61469333564704697892
75.64759192027683809556
75.68049050490661500135
75.71338908953640611799
75.68480623019249264871
75.65214010522799981118
75.61444842257664333829
75.77314631528521715609
75.96069837030444205084
76.18576083632751760888
75.97028981814166570530
73.76222617197122133348
71.55416252580079117251
70.74176407560706536515
69.92936562541333955778
69.11696717521959953956
69.61741758058444418111
70.11786798594926040096
70.61831839131409083166
71.11876879667892126236
71.41429932441842254320
71.65609702893257804135
71.85759511602770999161
72.21705797209465060860
72.52516899158059970887
72.79219854180176696445
72.84956573981875749269
73.81566864766924140895
74.78177155551972532521
73.47405149779964972367
72.16633144007960254385
70.85861138235954115316
69.18270281212107875035
67.50679424188260213668
67.11744797716379196117
66.72810171244498178567
66.54160004490064750371
65.76999510662903958291
64.99839016835744587297
64.07325159440934214672
63.14811302046124552589
61.77404066424603001906
60.57625196645733467449
59.37846326866863932992
58.18067457087994398535
58.43319982340518947694
58.60110225194591748732
58.76900468048664549769
58.93690710902737350807
59.10480953756810151845
58.35262645737255127187
57.60044337717701523616
56.84826029698146498959
56.68118048751320259271
56.92600043510485363640
57.17082038269650468010
57.41564033028814861837
58.10939406014691144264
58.77639010757773974092
59.44338615500856803919
60.11038220243940344290
60.37056722289839427731
60.71537506734190969837
61.06018291178543222486
61.40499075622895475135
61.74979860067247017241
62.03131134590135076223
62.31282409113023135205
62.59433683635911194187
63.32556176579009843408
63.64488693816118569657
63.96421211053227295906
64.28353728290335311613
64.51094668552411803830
64.76511377057281038105
65.01928085562151693466
65.00607349237311893830
65.45590625091416825398
65.97494404923074284852
66.58048814726676312148
67.29613117221843765492
68.33113508777542222106
68.92514744323240449830
69.51915979868941519726
70.11317215414639747451
69.38651663551172532607
68.65986111687703896678
67.93320559824236681834
67.65105445995338584453
67.46960888160076308395
67.24280190865997042238
66.95119294345039406835
66.95119294345039406835
67.06645672144671266324
67.15610632655494782739
67.22782601064152174786
67.28650575216691720470
67.39245569939375002377
67.49840564662058284284
67.60435559384742987277
67.48743573072161439086
68.03705692830257589776
68.58667812588353740466
69.13629932346451312242
69.12206748329073491277
69.11002515698986314874
69.09970316301767923051
68.85035476042870072888
68.63217490816336407988
68.47506078046099275980
68.31794665275862143972
68.34758955178115513718
68.38146715066405079142
68.16012559583661811757
67.90189378187130841980
67.59671072900322030819
67.48400419427831309349
67.37129765955343430051
67.25859112482855550752
67.14588459010366250368
66.96392132497609850361
66.74152177870907109991
66.46352234587527618714
66.62124309183131742884
66.83153741977270101415
67.12594947889064656010
67.56756756756756487903
67.56756756756756487903
67.56756756756756487903
66.63267040625530057696
66.00940563204713384948
65.56421650761272701402
66.65284541579050880955
67.74147432396829060508
68.83010323214607240061
69.91873214032385419614
70.62473445149873896298
71.17384736019032231980
71.61313768714357763656
71.47498498321463955563
71.35985772994052922513
71.26244236178550295335
70.97688033530307905039
70.72939324568496033407
70.51284204226909935187
70.78606030664566617361
71.31225887635496007988
71.91362867030844086003
71.66175626611585869341
71.36790512789117713055
71.02062650998927040291
70.60389216850700222494
70.05246345059411794409
69.36317755320303035660
68.47695282798589744289
67.82105646544137300680
67.16516010289684857071
66.50926374035230992376
66.25749365679261870810
65.55827771093990463669
64.85906176508719056528
64.15984581923446228302
63.93612288808795085515
64.47736917269386935914
64.91036620037860416232
65.26463649575703129813
65.79250389994912495695
66.23916093426549878131
66.62200982082239875126
66.95381218917171395333
66.95381218917171395333
67.05749995939015661861
67.17713969425759046317
67.31671938493627749267
67.48167720119288048863
68.51258732301209875004
69.54349744483130280059
70.57440756665052106200
71.18844043874790372683
71.21726630361992249618
71.24609216849195547638
71.48996744038701933732
71.47784767308979780864
71.46269796396828155594
71.44321976652629757609
71.41724883660367595439
70.57223948939079605225
69.93848247898114323107
69.44556035977363706024
69.44556035977363706024
69.57647602190948532552
69.28094034901531017567
68.98540467612114923668
67.61439730511376922095
66.70195490880040267712
65.78951251248705034413
64.87707011617366958944
65.03976071167302563936
65.17533620792249848819
65.29005393551820191078
65.38838341631451100966
65.38838341631451100966
65.37962957094536875502
65.36941675134802665070
65.35734705546025224976
65.35734705546025224976
64.81761006289306692452
64.15793151642208158592
63.33333333333333570181
64.27927927927927953533
65.22522522522523047428
66.17117117117116720237
67.11711711711711814132
67.15431949806949774029
67.19152187902187733926
67.22872425997425693822
68.04173239005555728909
68.61162120308460998785
69.06753225350784930470
69.44055038567232429614
69.44055038567232429614
69.44055038567232429614
70.13052179135679864430
70.13052179135679864430
70.92270891691337908469
71.91204636737683131287
72.23533616435085491503
72.55862596132486430633
72.88191575829887369764
73.37505159529997911250
73.95784849357400503322
74.65720477150281908507
74.82236844530123676122
75.02882303754925885642
74.36664447006222644632
73.70446590257520824707
73.05655893200248840458
71.54240883030803388465
69.56189389642521803125
68.50309062539182036744
66.45637276097959045273
65.31494991950944495329
64.96067430489046046205
64.60639869027146175995
64.25212307565247726870
64.54112034380257512112
64.78565649377574686696
64.99525890803845129540
65.17691433373281029162
65.05072560892715216596
64.75601258201598398045
64.46129955510480158409
64.49028872217517971421
65.14166821751823022169
65.79304771286126651830
66.44442720820431702577
66.65909191673407008238
66.25136629699110812908
65.84364067724817459748
65.43591505750524106588
64.44904194287477139369
63.47174463286724233058
62.49444732285972037289
61.51715001285219130978
61.46991222665419485338
61.70781166274441886799
61.94571109883464288259
62.18361053492486689720
61.68517573478003157561
61.18674093463520335945
60.68830613449037514329
60.18987133434553982170
60.41395706456624026259
60.63804279478694070349
60.86212852500764114438
61.08621425522834158528
61.86326398641114110433
62.64031371759393351795
63.41736344877672593157
64.17296545647295147319
63.97358543805775354940
63.74353157065559827288
63.47513539201975873993
63.15793990817739711474
63.22499657143038831464
63.29205323468337951454
63.35910989793635650358
63.42616656118934770348
62.40182092796422352876
61.37747529473909224862
60.35312966151397517933
59.32878402828885100462
58.34252347713708530819
58.40627459278159960832
58.47002570842612811930
59.03230617475293939833
59.50808195395255495441
59.83625925028194814104
60.12067957376742555198
60.36954735681720762841
60.27844428077245453323
60.18734120472770143806
60.09623812868295544831
60.00513505263820945856
60.36380240416762887889
60.72246975569706251008
61.08113710722649614127
61.43980445875591556160
61.95797389136706811996
61.72515836186845206157
61.45652505860081049605
61.14311953812188704660
60.77273119573771964497
60.43974790505507144189
59.99370620652828023367
59.54766450800148902545
59.54766450800148902545
59.54766450800148902545
60.79498825781033843896
62.04231200761918074704
63.31325640975169477542
64.44813404845896798179
65.58301168716623408272
66.71788932587350018366
66.96575797301630927905
67.17231517896865966577
67.34709435323603088364
67.49690507403663275454
67.46139711671989402930
67.43032765406775297379
67.88796131637960229455
67.67815076132345097903
67.46834020626728545267
67.25852965121113413716
66.26914175252446170816
65.27975385383777506831
64.63056700673426746562
64.06642188677153626486
63.50227676680880506410
62.93813164684607386334
62.76452053573496669969
62.59090942462385953604
62.41729831351274526696
61.37807687376313481309
60.49386535464844882881
59.60965383553376284453
58.72544231641907686026
58.50867501467238440682
59.43397933698282997739
60.35928365929327554795
61.28458798160372111852
62.44257481564432765708
63.26036059810176936935
64.07814638055920397619
64.84975131883081189699
65.62135625710240560693
66.08431922006538172809
66.54728218302834363840
66.15325133125099910103
66.62483080811217917017
67.09641028497334502845
67.47904746364457650998
67.86168464231582220236
68.24432182098706789475
67.48488737560117556313
66.72545293021526902066
66.66316608962529244309
66.36819673730515489751
66.07322738498501735194
65.20066234727504195234
64.37427815375087902794
63.54789396022673031439
62.72150976670258160084
62.35324377343658852624
62.84197159491088768846
63.33069941638518685068
63.88831764306628713257
64.53487816793732179121
65.18143869280834223900
65.82799921767937689765
65.96304297185571385853
66.09808672603207924112
65.53598287541251465882
64.97387902479295007652
64.78766877578084404377
65.17905421215860428674
65.57043964853633610801
66.21032217451616475046
66.85020470049600760376
67.03196902621768060726
67.21373335193933939991
67.55924932245531522312
67.83587488776447571581
68.11250045307365041936
67.12449240080802326247
66.13648434854239610559
65.65999306697145243561
65.18350178540049455478
64.70701050382953667395
64.47335927501879382362
63.86381444460057821289
63.25426961418236260215
62.64472478376415409684
62.27768377820058276484
61.91064277263701853826
61.54360176707345431169
61.73010343461777438279
61.75285345736779163417
61.77560348011781599098
61.79835350286784034779
63.30106875387310338965
64.80378400487836643151
66.30649925588363657880
67.37601797246117030227
68.44553668903870402573
69.27221535285606535126
70.31675894042066943257
71.36130252798528772473
72.40584611554990601689
72.77288712111348445433
73.13992812667706289176
73.50696913224061290748
73.32046746469629283638
72.65489703168401547373
71.98932659867170968937
71.32375616565943232672
70.44285412196667550688
69.35540791171672481141
68.26796170146675990509
67.61371202564453142259
66.95946234982230294008
67.09866851772636664464
67.02000976188313074999
66.94135100603992327706
66.90730961170440593833
67.05976988491320867070
67.21223015812202561392
67.18086690191907450753
67.14950364571612340114
67.59720915498112958630
67.68282598428172036620
67.76844281358231114609
67.85405964288290192599
68.14622063874070079237
68.72898338645836702199
69.31174613417601904075
69.89450888189367105952
69.68381578588504510208
69.72843314739274944714
69.77305050890046800305
69.77305050890046800305
69.63114398382012382172
69.48923745873977964038
69.53115446307120350866
69.39046037222301777092
69.24976628137483203318
69.47116087049107591156
69.69255545960731978994
70.27250906331902058355
70.85246266703072137716
71.14181451888258322924
71.50078486140554900885
71.85975520392852899931
72.20697742615075753747
71.91184790645297653100
71.61671838675516710282
71.32158886705738609635
71.16836587243994927121
70.97604779858980350582
70.78372972473965774043
70.77402274606913579191
71.17876260485041939319
71.58350246363171720532
71.98824232241301501745
71.70896072062561188432
71.42967911883820875119
71.15039751705080561806
70.80149742459227013569
70.32522227726448704743
69.86069525023746962233
69.78320950761411722851
70.01173629926415742375
70.24026309091419761899
70.46878988256426623593
70.73641175344698694971
70.65099466496754132550
70.56557757648809570128
70.06571365055683031642
69.74923005519076468772
69.38753451762958945892
69.37076267702606457988
69.35119552965525713262
69.32807071912613139375
68.43310596729187977871
67.72341402254016884399
67.01372207778845790926
66.30403013303676118539
66.24099075233563382881
66.17795137163450647222
66.11491199093337911563
66.36256670083065500876
67.12373262434566356660
67.88489854786065791359
68.64606447137566647143
69.30311011916919028408
69.85907182114831925901
70.33561042284472364372
70.96571866415862928079
71.51706337530828250237
72.76870574201169006301
74.12875467670767193340
75.48880361140368222550
76.84885254609967830675
77.64489675393039647133
78.44094096176110042506
78.88564803066320507696
79.14976789536684975701
79.45452158540953746524
79.89102649063342198588
80.32753139585727808480
80.66075612261300875616
79.19465243976054580344
77.72854875690808285071
76.01193570500524288036
74.29532265310238869915
72.53676589636242511006
70.64478567132385933292
68.75280544628530776663
66.86082522124675620034
64.73920629443232144240
62.74587612108454948157
62.44929568386422857884
62.19225930493994525250
61.96735247338121865823
60.69368605306993913473
59.42001963275865961123
58.14635321244738008772
58.33464112494274900200
58.68176273220633731853
59.02888433946993984591
59.37600594673352816244
60.28241689821089721590
61.18882784968826626937
62.09523880116562821740
63.00164975264299727087
64.32658748983465102356
65.54729061483465102356
66.76799373983465102356
67.88904763014078014294
69.01010152044689505146
70.01302285026915228627
70.75085320394190091520
71.48868355761466375498
72.22651391128741238390
72.78384115873315352019
73.34116840617889465648
73.89849565362463579277
73.93061281703676002053
73.56917174170071405115
73.20773066636468229262
72.84628959102863632324
72.02191864947019439569
71.19754770791173825728
70.37317676635331054058
69.64845505948873949364
69.48351839516783456929
69.31858173084692964494
69.41873604267553332647
69.80904071686968848098
70.19934539106384363549
70.61131947671680109124
71.07523405933640958665
71.53914864195603229291
72.00306322457564078832
72.86053604594343369172
73.71800886731122659512
74.57548168867901949852
75.39985263023746142608
76.22422357179590335363
77.04859451335434528119
77.44383466512655900260
77.27928977435510660143
77.11474488358368262197
76.95019999281223022081
75.76206112469952813626
74.57392225658685447343
73.38578338847415238888
72.14570402339478505382
71.25263168238318201020
70.35955934137157896657
69.46648700035999013380
68.57341465934837287932
67.46319986843542437782
66.43049003783994521655
65.39778020724446605527
64.78603832227864245397
64.60342722709903284795
64.42081613191942324192
64.23820503673979942505
63.38337169504811186016
63.26198196833207987311
63.14059224161606209691
63.01920251490003721528
62.65316835526122929423
61.94012717155465708174
61.22708598784808486926
60.51404480414151265677
59.80100362043494044428
59.64912580310142686812
59.49724798576791329197
59.34537016843440682123
58.77252440547124479053
58.74353523840087376584
58.71454607133050984658
58.68555690426013882188
59.88210495517540721266
61.07865300609067560345
62.27520105700594399423
63.55916518848387397611
65.08777375288458699742
66.61638231728530001874
68.39916468305588637122
70.18194704882645851285
71.96472941459704486533
73.41760346362492839489
74.87047751265282613531
76.32335156168072387572
77.67903506540487512666
78.49086197323620694988
79.30268888106756719480
79.24587529024661591848
78.63574672795213871268
78.02561816565764729603
77.41548960336317009023
76.73671297780262534616
76.05793635224209481294
75.37915972668155006886
74.15657843575010588211
72.93399714481864748450
71.71141585388720329775
70.47472196322669901747
68.73271397121871473246
66.99070597921073044745
65.34588853250647844106
63.70107108580224064553
62.05625363909800995543
61.92942734039671393020
61.80260104169541079955
61.67577474299411477432
61.56659384237299548204
61.43864492445527503151
61.31069600653755458097
61.18274708861983413044
61.27475233459166759076
61.36675758056351526193
61.45876282653536293310
61.55076807250719639342
62.30834383008296128992
63.06591958765871197556
63.82349534523447687206
65.09021490995903036492
66.35693447468358385777
66.97430339005748578529
67.59167230543138771282
68.25615266134752801008
68.90298761918344894184
69.54982257701939829531
70.16123562049766348991
70.77264866397592868452
71.45373840756566607979
72.18217663600388789291
72.91061486444212391689
73.63905309288034573001
73.97959796467520732222
74.32014283647009733613
74.66068770826495892834
73.95733486156814251444
73.25398201487132610055
72.55062916817450968665
71.84727632147769327275
71.37605508739211757074
70.90483385330654186873
70.43361261922098037758
69.99781329949307462357
69.62065812707928103009
69.24350295466550164747
68.81899929740322363614
68.43449884039695518823
68.04999838339070095117
67.89313487277138392528
67.73627136215208111025
67.85868945332016721750
68.51586145583115694535
69.17303345834213246235
69.92349774558117303513
70.67396203282021360792
71.14518326690578930993
71.72595681343780427142
72.30673035996981923290
72.88750390650183419439
73.47272043661797624736
74.05793696673411830034
74.64315349685026035331
74.80001700746957737920
74.95688051808889440508
75.11374402870819722011
75.27060753932750003514
74.61343553681652451814
73.95626353430554900115
73.29909153179455927329
72.90642365900730226258
72.51375578622005946272
72.12108791343283087372
71.61886772819914881438
70.97166135853787238830
70.32445498887659596221
69.61416148831710870581
68.72501038702502285105
67.83585928573293699628
67.33505800368163818348
66.83425672163036779239
66.58840881953246082503
66.34256091743455385767
66.63146692667955051093
66.92037293592454716418
67.38053942228108894597
67.48290949418581874397
67.58527956609054854198
68.03818878982623630236
68.49109801356192406274
69.08899342172517776817
69.87720036564334691320
70.66540730956148763653
71.63247185421224116908
72.40922486310810768373
73.18597787200397419838
73.96273088089984071303
73.97243785957036266154
73.98214483824091303177
73.99185181691143498028
74.00155879558197113965
73.92939979838585884409
73.85724080118974654852
73.78508180399364846380
73.60879814556713540696
73.43251448714060813927
73.25623082871408087158
72.88963563453268079684
72.30726153604780392925
71.72488743756289863995
71.33282487483288036856
70.98630571682856782445
70.59028382196645168278
70.76360335899873632570
69.67994321129776835733
70.03806404894240245085
70.39618488658705075522
70.64428172269950323425
70.89237855881196992414
70.94277035502753392393
70.68988277819629217902
70.43699520136505043411
69.88382391648643476856
69.33065263160784752472
69.04305538279507459265
68.75545813398231587144
68.85864537281648267708
69.05438822804832454949
69.22403203591589715415
69.27837115516648225366
70.26861553163467988270
70.08741285750957672462
69.60145601841088591755
69.11549917931216668876
68.62954234021347588168
68.30422084603100074673
68.24309017310454805738
68.18195950017809536803
68.36480934004013931826
68.54765917990216905764
68.67105315979463853182
68.79444713968712221686
68.60032872336645937139
68.31065624638044653238
68.02098376939441948252
67.82541050504194402038
67.62983724068946855823
67.69212408127945934666
68.05916508684302357324
68.42620609240660201067
69.19508037619493734383
69.96395465998327267698
70.46863812251558556454
71.08867780505525502122
71.70871748759493868874
72.32875717013462235627
72.99516480377758398390
73.66157243742054561153
74.32798007106352145001
75.05108917727145012577
75.77419828347937880153
76.49730738968730747729
76.91891913942404812587
77.08267078421836515645
77.24642242901265376531
77.59869088636469314224
77.54912606549194720174
77.49956124461922968294
77.44999642374648374243
77.35980883355888693131
77.26962124337129012019
77.17943365318370751993
76.60283347236189399609
76.02623329154008047226
75.44963311071825273757
75.24400655719199448868
75.00900478173336693999
74.73784888697343831154
74.82349681838174149107
73.92208082262611412716
73.02066482687051518496
71.86789308103790574478
70.71512133520531051545
69.87681499817773556060
69.03850866115017481661
68.10055772319923050873
67.16260678524828620084
66.79655127213395360286
67.09649642502027688806
67.39644157790660017326
68.28936585792766322811
69.23981166041957635571
70.05447949112694061569
70.93973641394069318267
71.71433622140274621870
72.99185194467318638090
74.43352717128651363510
75.87520239789982667844
77.31687762451315393264
78.55366917110298174975
79.79046071769279535602
81.02725226428260896228
82.01168449871484256164
82.56719516451963158943
82.70645541407398582123
82.84571566362835426389
82.54024156783167143203
82.23476747203500281103
82.34074121748250263408
82.26984178063602826114
82.18803473812083382199
82.09259318851979969622
81.74102117049254445647
81.31913474885985237961
80.80349578908655416853
80.09701633620942118341
79.18868561108165238238
76.75855890141603765642
74.90525347668203437479
73.05194805194805951487
72.15007215007214824709
70.72605756816282962518
69.30204298625351100327
67.87802840434419238136
68.45124586829390977982
68.89708167358814705494
69.25375031782353119070
69.36000348995480635494
69.44854780006420469363
69.52346990861830988706
69.58768885880753884976
69.41279531583943196438
69.25976346574232422881
69.10475354510739975922
69.30442734117950465134
69.50410113725160954345
69.70377493332370022472
70.13188440191042616334
70.89017216677714827711
71.64845993164385618002
71.98960429452733933431
72.56961061289405279240
73.14961693126075203963
73.85720030616960229963
74.52335942303295723832
75.18951853989629796615
75.85567765675963869398
76.64388460067777941731
77.43209154459594856235
78.22029848851408928567
78.51282114146833635004
78.80534379442258341442
79.09786644737681626793
79.09786644737681626793
78.76768815109682009279
78.43750985481683812850
78.24319045850242559936
77.81000910670481118814
77.37682775490719677691
77.08569165791371347041
76.80030669911604945810
76.47101636204182284473
75.52688239038491246902
74.69855490058222358130
73.87022741077954890443
74.45743552682610300053
75.21818281118882509872
75.97893009555154719692
76.72023271941803557183
77.82156322384361146760
78.92289372826920157422
80.26526795199937680536
81.44043122192573491702
82.72123484139487459288
84.00203846086402847959
84.53499390181276851308
84.71328113006400428731
84.86928245478384269518
86.01940513453634196139
86.47782966767097434513
86.93625420080560672886
86.24455605418773984638
85.08459250780914828738
83.92462896143055672837
82.78046420170517194492
81.63629944197975873976
81.18821896094368639751
80.54429045797262176620
79.90036195500158555660
78.91874702521590734250
77.93713209543025755011
76.95551716564457933600
75.34714888504984742212
73.73878060445511550824
72.13041232386038359436
71.21374218988350435211
70.33684651879229932092
69.45995084770108007888
69.05132057637058551336
68.93664927528452324168
68.82197797419844675915
68.70730667311238448747
67.89655109333699556373
67.41706050413897344242
66.93756991494092289940
66.70993296855398568823
66.51503605755537762434
66.32013914655678377130
67.01787760565255780421
67.71561606474834604796
68.10590859333510138640
68.49620112192185672484
68.84671918762296627392
69.47194314844475115933
70.09716710926653604474
70.42843209984388863631
70.75969709042122701703
71.27125054253704661278
71.78280399465285199767
71.96309245619131900185
71.71724455409341203449
71.47139665199551927799
71.19280871450926895250
70.50295981006391343726
69.81311090561854371117
69.12326200117318819593
68.74085902723685137516
68.63830933103255915739
68.53575963482825272877
68.15850404350328517467
67.78124845217831762056
67.15602449135653273515
66.53080053053473363889
65.72528810817448174930
64.91977568581424407057
63.83804491658347046723
63.18245051098906372999
62.52685610539465699276
61.87126169980025736095
61.77115903039573652222
61.67105636099122278893
61.57095369158670195020
61.47085102218218821690
61.63151292401587966197
61.79217482584956400160
61.95283672768324834124
62.55772926539516731737
63.41059017260390362480
64.26345107981262572139
65.09251877855479051505
65.92158647729695530870
67.02687252290962760526
68.13215856852229990182
69.46059986406885400356
70.78904115961543652702
71.97325168593121702543
73.36023798730107614574
74.74722428867093526605
76.13421059004080859722
76.98057904244041083075
77.82694749484002727513
78.67331594723964371951
78.77094168919526850914
78.86856743115090750962
78.96619317310654651010
77.83338217153379900992
76.70057116996105150974
75.56776016838830400957
74.43494916681555650939
74.13412121653308872737
73.79031784478168276564
73.39362164660698795160
72.66044171533108908534
71.32989044374743059507
69.99933917216377210480
68.66878790058011361452
67.33823662899645512425
66.18368238054885921429
65.43514423152225845115
64.68660608249567189887
64.15347259805706414681
65.29264571627842883572
66.43181883449977931377
67.57099195272114400268
68.97283909248176314577
69.36524178337347734669
69.70158694699496493286
69.95677526817569003015
70.18006504920882093757
70.63658043682082166015
70.66417425580536360030
70.69176807478990554046
70.71936189377444748061
70.61495794540695669639
70.74529578107700444889
70.87563361674706641224
70.84441795397611940643
71.00738207579505001377
71.17034619761400904281
71.33331031943293965014
71.29926892509743652226
71.53976916559767573744
71.78026940609791495262
72.05481104093365729568
72.45922280563954132049
72.86363457034542534529
73.69696790367875394168
74.40043110714195506716
75.10389431060517040351
75.80735751406837152899
76.20134548280148578669
76.59533345153460004440
76.98932142026771430210
76.68435103394384100284
76.37938064761996770358
76.07441026129612282602
75.23633705606212629391
74.12372221599237320788
73.01110737592262012186
71.89849253585286703583
71.19895125144920200455
70.49940996704552276242
69.79986868264185773114
69.23019752810830595990
68.62143129434207367012
68.01266506057584138034
67.47863227750214321077
66.75932281458653960726
66.04001335167095021461
65.82548245922242813322
65.61095156677392026268
65.91592195309777935108
66.75399515833177588320
67.59206836356577241531
68.45277993449087716726
69.31349150541598191921
69.63125939080487114552
69.94902727619376037183
70.22173929230474698215
70.49445130841573359248
70.80625840375941493221
71.37736899419991232207
71.94847958464038129023
72.70486685492278411402
73.56299042471681559618
74.42111399451087550005
75.27923756430493540392
75.72397190965597246759
76.16870625500700953125
76.61344060035804659492
77.20354733270616520713
77.79365406505428381934
78.38376079740238822069
79.15346523089992558653
79.96822553367536556834
80.78298583645081976101
81.33276592441562513613
81.62324251728364288283
81.91371911015164641867
81.74542398782514851518
81.47539256598695089906
81.20536114414878170464
80.67255906853722535743
80.03364493859628225891
79.39473080865533916040
78.59648967597398439011
77.63023779060443985145
76.66398590523492373450
75.69773401986537919583
74.98561345919809184579
74.27349289853079028489
73.56137233786350293485
73.23381793762871438958
72.90626353739391163344
72.57870913715912308817
72.41093291037935841814
72.24315668359957953726
72.07538045681980065638
72.23601580173601632850
72.39665114665223200063
72.55728649156844767276
72.87724883922507501666
73.23075562655539272328
73.58426241388571042989
73.93776920121604234737
74.18321856992025686850
74.42866793862447138963
74.67411730732870012162
74.85219568895237785000
75.03027407057604136753
75.20835245219970488506
75.47083282323282560355
75.73331319426596053290
75.99579356529908125140
76.12124976967864142807
76.24670597405820160475
76.37216217843777599228
76.65507962312740630750
76.90445262814336047086
77.15382563315932884507
77.39545322438955565758
77.29940292039488269893
77.18857564655488090466
77.05927716040820030230
76.83052082799292747950
76.55601322909461714517
76.22050394155222363679
75.53027482492717581408
74.84004570830211378052
74.14981659167705174696
73.84861177239994844967
73.54740695312284515239
73.24620213384574185511
72.14044521413532606857
71.03468829442492449289
69.92893137471452291720
69.69279499399982569230
68.84455663438433248302
68.13769133470474059777
67.53957454266817705957
67.02690300663682876348
67.06294731069888825914
67.09448607675318498877
66.94682967909677984153
66.45818788610715444065
65.96954609311754325063
65.33346138160065663669
64.69737667008378423361
64.77489261622126548446
65.14750787854242730646
65.52012314086356070675
65.89273840318470831789
66.40461391506022437170
67.36682658501936771245
68.32903925497851105320
69.33956670539529909547
70.35009415581210134860
70.91028444814523368223
71.00758797286812296079
71.10489149759101223935
71.54318041764710756070
72.10432080209265848225
72.66546118653822361466
73.22660157098377453622
73.07414129777495759299
73.38693002583163149666
73.69971875388830540032
74.01250748194496509313
74.22488854854766771041
74.43726961515037032768
74.64965068175307294496
74.43965814815426540463
74.22966561455547207515
74.01967308095666453482
74.10080503396503104341
74.18193698697339755199
74.26306893998177827143
74.22134942860068917980
73.96348932365823714008
73.70562921871578510036
73.44776911377334727149
72.80075578678619763195
72.15374245979904799242
71.50672913281189835288
70.82086321772433734623
70.13499730263679055042
69.44913138754924375462
69.13732429220556241489
68.85477140946849772263
68.57221852673143303036
68.46142792499745155510
68.35063732326347007984
68.24968758815559510822
68.14873785304769171489
68.26392871150116548051
68.67424908965242025261
69.08456946780370344641
69.41879406673417918228
70.25253912602271100241
71.08628418531125703339
71.92002924459978885352
72.89609577583391342159
73.87216230706803798967
74.84822883830216255774
75.85422115882343518933
76.86021347934470782093
77.86620579986598045252
78.82768879123187844016
79.77933091597168413500
80.73097304071148982985
81.32607568281750332062
81.62604880522573580492
81.92602192763396828923
81.66068773391029367303
80.89583307982854876172
80.13097842574681806127
79.23990560453410125774
78.20651131137580591712
77.17311701821749636565
76.13972272505918681418
74.64635799167314189617
73.15299325828709697817
71.65962852490105206016
70.21077312067038178611
68.86492228033424112255
67.51907143999812888069
66.52976008229578042119
65.54044872459341775084
65.18483894074094564530
65.39453647302039485112
65.60423400529982984608
65.93815906820699979107
66.39830229824515583914
66.85844552828329767635
67.37733156820152657929
67.89621760811974127137
68.81589408637195504070
69.75948409330445088017
70.70307410023694671963
71.64666410716944255910
72.48724955020742299894
73.45112942033728131719
74.41500929046716805715
75.37888916059702637540
76.16564213220169676788
76.95239510380636716036
77.73914807541103755284
78.28330230426675484523
78.82745653312247213762
79.44496756948993265723
80.00373579597732032198
80.56250402246470798673
80.65963352198878055788
80.73284949283254263719
80.80606546367631892736
80.68885965026797180144
80.57165383685962467553
80.33115359635938546035
80.09065335585913203431
79.65314759920445908392
78.75906716722508349449
77.86498673524570790505
77.08925306357757278874
76.43189060403065582250
75.77452814448372464540
75.32531223044279045098
74.87609631640182783485
74.42688040236089364043
74.43930321528327453962
74.45172602820564122794
74.46414884112800791627
74.66699343830251223153
74.26033678164586149251
73.85368012498919654263
73.44702346833253159275
72.71993295169531279498
71.99284243505806557550
71.26575191842084677774
70.42031464147237329598
70.40929534153585223066
70.39827604159933116534
70.10575338864508410097
69.88015876872056253433
69.65456414879605517854
69.42896952887153361189
69.20337490894701204525
68.94407141385021020596
68.68476791875340836668
69.03496567748774737083
69.39634478275436890726
69.75772388802101886540
70.63654236942268482835
71.40594910671362072208
72.17535584400454240495
72.94476258129547829867
72.87975134157449019767
73.08663521076258007270
73.29351907995066994772
73.43347491610903432502
73.85340496070395488459
74.27333500529888965502
74.69326504989381021460
75.20139513119461582846
75.70952521249543565318
76.21765529379625547790
76.79859247175498637716
77.37952964971371727643
77.96046682767246238654
78.23236193658156878428
78.50425704549064676030
78.77615215439975315803
78.91943842125608910010
78.79082957920334706614
78.66222073715059082133
77.80542678788854971117
76.66865863018995241873
75.53189047249135512629
74.39512231479275783386
73.93474293523695450858
73.40859507288743657227
72.80150138556106753640
71.98124082609335516736
71.01184198308607165018
68.48456733288696796080
66.56595277455750192530
64.64733821622805010065
62.72872365789858406515
61.04317164787781280211
59.35761963785703443364
57.67206762783626317059
57.04569395557446398470
56.85292819016964926959
56.66016242476484165991
56.46739665936001273394
56.77180031116017033810
57.66144104418989968508
58.42399024392967277208
59.08486621703747943002
59.66313269350680315029
61.00633712573254285871
62.34954155795828967257
63.69274599018402938100
65.19916858231334799711
66.67396951453319786651
68.14877044675304773591
69.52183507946122631438
70.89489971216941910370
71.96985890891342307896
72.48341449388746582372
72.99697007886150856848
73.51052566383555131324
73.51052566383555131324
73.82380712704099323673
74.13708859024643516022
74.45037005345187708372
73.90235066001264385704
73.35433126657338220866
72.80631187313414898199
71.84455897393701206965
70.88280607473987515732
69.92105317554270982328
69.06103657585724420187
68.77953322283951820282
68.49802986982180641462
68.77793012857404164606
69.05783038732629108836
69.42784888120300479386
69.79786737507973271022
69.85460440575101870309
69.78832461666803510525
69.72204482758505150741
70.01714414376870365686
70.42380080042536860674
70.83045745708201934576
71.48762945959300907361
72.29833509778048039607
73.10904073596796592938
73.91974637415543725183
74.30298602668482033096
74.68622567921420341008
75.06946533174357227836
75.86037479983751552481
76.56116603280696608635
77.26195726577640243704
77.91747832635704185122
78.69601620669195085611
79.47455408702684565014
80.42295410107720954329
81.25979677465457484686
82.09663944823195436129
82.67534315193566385460
83.10051321996286333160
83.52568328799009123031
83.38360913159485221513
83.09048771418994761007
82.79736629678504300500
82.56240389077001395890
81.91977166919042474547
81.27713944761083553203
80.26551314730093622529
79.29915701937983385506
78.33280089145875990653
77.36644476353767174714
76.12294543330364149369
74.87944610306961124024
73.63594677283560940850
72.29359282094556249376
70.95123886905552978988
69.60888491716548287513
68.83377518969790287429
68.43476752875081103866
68.03575986780373341389
67.57859319546676601931
67.12142652312982704643
67.00653048106867970546
67.26062851773784245779
67.51472655440701942098
67.79135211971617991367
68.06797768502535461721
68.45188431893198810485
68.76886291980889609476
69.08584152068580408468
69.75981371309238454614
70.47818640988869276498
71.19655910668500098382
71.91493180348132341351
72.25720243375712925626
72.72617626217524389176
73.19515009059333010555
73.66412391901144474105
73.91224606757596404805
74.16036821614048335505
74.40849036470498845119
74.99551657499989687494
75.58254278529480529869
76.16956899558971372244
76.82352323891436185477
77.47747748223898156539
78.13143172556362969772
78.72663765837374683088
79.32184359118386396403
79.91704952399396688634
80.46968374712423610617
80.89561477211218232242
81.32154579710012853866
81.63730017219275225671
81.83163559686315124964
82.02597102153356445342
81.67914272774379469411
80.97088284358363807769
80.26262295942348146127
79.10097520676508509041
77.93932745410670293040
76.77767970144830655954
75.61603194878992439953
74.82757464031632821388
74.03911733184270360653
73.25066002336910742088
72.84852220684771850756
72.44638439032632959425
72.04424657380495489178
71.83748833621217499967
71.63073009861939510756
71.42397186102661521545
72.04731775362533596763
72.67066364622405671980
73.29400953882279168283
74.68082661881533113046
76.06764369880787057809
77.45446077880041002572
78.88867501749128052779
79.96404661812215408645
81.03941821875304185596
82.11478981938392962547
82.84641363774244382512
83.57803745610095802476
83.72600147125247360691
83.78876255737073108776
83.85152364348897435775
84.01817435726681537744
83.89588465931331029424
83.77359496135980521103
83.70492571639186962784
83.32617315252836931450
82.94742058866486900115
82.26319392900467164509
81.53157011064615744544
80.79994629228764324580
79.57555543704445710773
78.35116458180125675881
77.12677372655805640989
76.32448052782868330723
76.10584713230628040037
75.88721373678390591522
75.66858034126150300835
75.45287058001414948194
75.23716081876679595553
75.02145105751942821826
74.76429174218039008792
74.50713242684133774674
74.24997311150231382726
74.38525449678370193851
74.52053588206509004976
74.65581726734646395016
75.46764417517782419509
76.27947108300917022916
77.09129799084051626323
77.76899922824324562498
78.44670046564597498673
79.12440170304870434848
79.84815363305384039450
80.46509230112440036464
81.19263365905234763886
81.92017501698032333479
82.63554547601435729121
83.55147035660490928421
84.46739523719543285551
85.29635351296221301709
85.97356092093886559269
86.65076832891550395743
86.96935280979657534317
87.28793729067763251805
87.60652177155870390379
86.65574213225448829689
85.70496249295025847914
84.75418285364602866139
83.75735252173937794851
83.17711379494892298680
82.48627237830106651018
81.79543096165318161184
81.04373268035098476503
80.09147997749231251419
79.13922727463364026335
78.02449120697659168400
77.06150600710967069062
76.09852080724277811896
75.21167390358091608960
74.32482699991905406023
73.43798009625717782001
73.53252532669542063104
73.77105425374894309698
74.00958318080246556292
74.24811210785597381800
74.48074238080883446855
74.71337265376168090825
74.94600292671454155879
75.48085762452163294256
76.01571232232873853718
76.55056702013581571009
77.33870777857242728714
78.12684853700903886420
78.88437680064977541861
79.74061120951576242533
80.59684561838174943205
81.19250049837552296594
81.78815537836929649984
82.23982656174780458969
82.78421006092602851822
83.40636263141547601663
83.61876273457644970222
83.86656285493096163464
84.15941754262262008979
82.98596327830817642734
81.81250901399371855405
80.63905474967926068075
79.36474208207435765416
78.09042941446942620587
76.86064401202214924069
75.99969986621582052067
72.81941761616613462138
70.05606261231199027861
67.29270760845784593585
64.52935260460368738222
65.33119629980642173450
65.99939937914203369473
66.56480198473370535339
66.91221408786496738230
67.21330457724540963227
67.20193964941269371138
66.59533662634188999618
65.98873360327108628098
65.35923720039338036258
64.72974079751567444418
64.44825041420156708227
64.65274040665481436463
65.53104211439639925629
66.40934382213796993710
67.19421273422079821103
67.97908164630362648495
67.53380241574615183708
67.13471819464939471800
66.73563397355265180977
66.45661611640979060667
66.17759825926692940357
66.54578677178534462655
67.47201099858946804488
68.39823522539359146322
69.42619575170937196162
70.45415627802515246003
71.15586116100010372065
72.00465633066735904322
72.85345150033462857664
73.70224667000189811006
74.68612464199190981162
75.67000261398192151319
76.65388058597194742561
77.19153464318677038136
77.72918870040159333712
78.26684275761641629288
79.02034656050514627168
79.14182934133650348940
79.28200178075734072536
77.57642264188528713476
75.74562959592195454661
74.87026029950003191971
73.99489100307810929280
72.93848750765022259657
72.14011618514811630121
71.34174486264603842756
70.49211199348054890379
69.77457446016084929852
69.05703692684114969325
68.83184266160077413588
68.60664839636038436765
67.81054383286999609481
67.62531865226127081314
67.46479016240037651642
66.81287956052031518084
66.93082716731969128432
67.04877477411906738780
66.39044058441606921406
65.73210639471304261860
65.46482962427171514719
64.98790171332819909367
64.51097380238465461844
64.55329002303835750354
64.48827878331735519168
64.42326754359635287983
64.35825630387535056798
64.68843460015534674312
65.48247751376348446684
66.27652042737160797969
67.02048488473161569345
68.27589751534341644401
69.53131014595518877286
70.78672277656697531256
72.04213540717876185226
73.29754803779056260282
73.99445729431597840176
74.69136655084140841154
75.38827580736682421048
75.66017091627591639735
75.93206602518502279509
76.20396113409412919282
76.47585624300322137969
76.24681548178423895479
76.01777472056525652988
75.78873395934627410497
75.19165551843749994987
74.59457707752872579476
73.99749863661995163966
73.40042019571117748455
72.80334175480240332945
72.20626331389362917434
71.77663082780952663597
71.49465473938184345570
71.21267865095413185372
70.83647257854552492518
70.54331884572133049005
70.25016511289713605493
69.95701138007294161980
69.76960398137580909861
69.59280058675064140061
69.41599719212547370262
69.65730993343819932306
70.24202926815753755818
70.82674860287687579330
71.41146793759619981756
72.28360366027541772382
73.15573938295460720838
74.02787510563381090378
74.92409966758870609738
75.82032422954361550183
76.71654879149849648456
77.41396598238362969369
78.11138317326874869195
78.80880036415388190107
79.13828191924622501574
79.45715947026663172892
79.77603702128703844210
80.10714772643865444479
80.09485183818370046538
80.08255594992873227511
79.92746910129349657836
79.48496586469838121047
79.04246262810329426429
78.39048189453642123681
77.56675592403746577475
76.74302995353849610183
75.91930398303954063977
75.30815665579051199074
74.69700932854149755258
74.08586200129246890356
73.90957834286595584672
73.73329468443944278988
73.55701102601292973304
73.00369605453937538186
72.45038108306584945240
71.89706611159230931207
71.48654210049903667823
71.26656075491713693282
71.04657940933523718741
71.03607556072512352330
71.06788793276868432258
71.09970030481225933272
71.13151267685583434286
71.06650143713483203101
70.52441766712290416308
69.98233389711097629515
69.37332209406932292950
68.99894813053057873731
68.62457416699182033426
68.61499836236887972518
68.60542255774592490525
69.03915158359760084750
69.47288060944924836804
69.71606696978955142185
69.92453196522525615819
70.13299696066096089453
70.29914573544297695662
69.51515028320386591076
68.73115483096475486491
67.94715937872564381905
67.64023645677744411842
68.03346935551562069122
68.42670225425376884232
68.58529731348902203081
68.74389237272427521930
69.01859826784496476648
69.29330416296565431367
69.12470522761172730952
68.73951260276650998549
68.35431997792130687230
68.00384871798067365489
67.65337745804002622663
67.05323662499120018765
67.40324001896351546748
67.75324341293581653645
68.49492264452122469720
69.23660187610663285795
69.27812528700570737783
69.31964869790476768685
69.41419392834299628703
69.50873915878123909806
69.48717355333405976126
69.07384847775460912089
68.66052340217515848053
68.46379201608701237092
68.26706062999885205045
68.48365431949014237034
68.70024800898143269023
69.16651127158090162084
69.53732770160118548119
69.90814413162146934155
69.88728472402864611013
69.60289069904904124542
69.31849667406945059156
69.03410264908983151599
68.69668680457105836012
67.99891965943548655105
67.30115251429992895282
66.99514483929661423645
66.68913716429331373092
66.69809534036087939057
66.70705351642845926108
66.30268661691658849122
65.89831971740473193222
66.13794362198117937623
66.47301435913678346878
66.80808509629241598304
67.21637180429181057661
67.88819312967798680347
68.56001445506416303033
69.41178436656858252718
70.26355427807300202403
71.47567549019422017409
72.72811928296059136301
73.98056307572696255193
75.23300686849333374084
75.80597270335913151484
76.37893853822492928884
76.95190437309071285199
76.92514669066278543141
76.25439820414653979697
75.58364971763029416252
74.91290123111406273892
74.14252584315025274009
73.37215045518642853040
72.60177506722260432070
71.67786202374433912610
70.75394898026607393149
69.83003593678782294774
68.86580031266439050341
67.48758710701923746456
66.10937390137408442570
65.09567280255862442573
64.08197170374315021490
63.74643900770546878221
64.01062982896149833323
64.27482065021754920053
64.53901147147358585698
65.24608778025796596012
65.97957501964616255918
66.71306225903435915825
67.70370881376159388765
68.66794443788501212111
69.63218006200844456544
70.52956790654769747562
71.42695575108697880751
72.73832117714798073393
73.85937506745410985332
74.98042895776023897270
76.10148284806635388122
76.10148284806635388122
76.10148284806635388122
76.10148284806635388122
76.54528540830780514170
76.54633349140851805714
76.54754281806317806058
76.54895369916027902946
76.17657119087279227188
75.08609158116384207915
73.99561197145487767557
73.00236549568650445963
71.96656743794487454124
70.93076938020323041201
70.17178810174145553447
69.41280682327965223521
69.09117850373685598697
69.41374725696253733531
69.73631601018820447280
69.82523335262668240375
69.87500850751341374689
69.91712594626373800111
69.68495028343537001092
69.48373137565080526201
69.30766483133928090865
69.18371364903570963634
69.42208130731182791351
69.66044896558796040154
69.92807083647069532617
70.22875099572749491017
70.52943115498430870502
70.83011131424109407817
70.83011131424109407817
70.85241959686027257703
70.87472787947945107589
71.05767150701481682518
71.56464001966139676369
72.07160853230794828050
72.81331882899206675575
73.40553541105636270458
73.99775199312065865342
74.58996857518495460226
74.58996857518495460226
74.58996857518496881312
74.58996857518496881312
74.57889565335092640908
74.56782273151689821589
74.55674980968287002270
74.54567688784884182951
74.26178352520555847605
73.97789016256227512258
73.69399679991900597997
72.82656811243029437719
71.95913942494156856355
71.09171073745284274992
70.37377576458396788439
69.87625615221662656040
69.37873653984929944727
69.11111466896656452263
68.48025805984909197832
67.84940145073161943401
67.19655947507409621267
66.54371749941658720218
65.90715163606859050560
65.27058577272060801988
64.88453206756267377386
64.49847836240476794956
64.53829742458620444268
64.83762692650191183930
65.13695642841761923592
65.43628593033332663254
65.40174791088152517204
65.14679453092813332660
64.89184115097475569200
64.81574537175399086664
65.10288433076794945009
65.39002328978189382269
65.67716224879586661700
66.27543622836479642046
66.85743409562422812087
67.43943196288364561042
67.63686905770839530305
67.83430615253314499569
67.60587048001852394918
67.37743480750390290268
67.33761574532246640956
67.29779668314101570559
67.59184514232708806958
68.00760469723758205873
68.42336425214807604789
68.66026620632598564953
68.89716816050388104031
68.49977909123980168715
68.10239002197573654485
67.39386593215668597168
66.68534184233763539851
66.42144460798064642404
66.54210814605835366820
66.66277168413606091235
66.91139469515195514759
67.16001770616787780455
67.22002410685061590812
67.28003050753335401168
67.58645139881662089465
67.77116119437548036331
67.95587098993433983196
68.16333080824321655200
68.37079062655209327204
69.21254146830293052517
70.02025091571826465042
70.82796036313358456482
71.63566981054891869007
71.99875240250217700577
72.37312636604093540882
72.74750032957967960101
72.99391482018020838041
73.05561951522190611286
73.11732421026358963445
73.17902890530527315605
73.21323840733654719770
73.24744790936784966107
73.28165741139912370272
72.98228182333993174780
72.68290623528073979287
72.38353064722156204880
72.11819645349787322175
71.37271088760826387443
70.62722532171866873796
69.88173975582907360149
69.12496281835397837767
68.65715605915647756774
68.18934929995899096866
67.90625233632036383824
67.87518322757571809234
67.84411411883107234644
67.59412571249629309023
67.34413730616152804487
67.24299977110051429463
67.45269730337994928959
67.66239483565939849541
67.68248882193118731720
67.70258280820297613900
68.20282816664067127022
68.70307352507839482314
69.36018239413542119109
70.01729126319243334819
70.38542995397185109141
70.35819921155288625414
70.33096846913392141687
70.05170987182097519508
69.77245127450801476243
69.82447853401720294642
69.87650579352640534125
69.77968218176184223012
69.39715854582769338776
69.01463490989355875627
68.82171481996707029793
68.62879473004059605046
68.58879152978457227618
68.54878832952854850191
68.35192161865322191261
68.25647478607403684236
68.16102795349485177212
68.46095055411404928236
68.46998756973002286941
68.47902458534596803474
68.48806160096194162179
68.16581275975575238135
68.14485460899119573241
68.12389645822665329433
68.38863833163165395490
68.40715694160009263669
68.42567555156853131848
68.44419416153698421112
68.30979588183495820886
68.29990976984508677106
68.29002365785521533326
68.17871766756920237640
68.02831659805048047929
67.87791552853175858218
68.01840004401627481911
68.15888455950079105605
68.52365137163602071269
68.88841818377126458017
68.95189430546486164531
69.15935412377373836534
69.36681394208261508538
69.82049702382805378420
70.27418010557349248302
70.32120653066228044281
70.36823295575106840261
70.29074721312770179793
70.29074721312770179793
70.29074721312770179793
70.32984229236039652733
70.36893737159309125673
70.63655924247584039222
70.90418111335858952771
70.94752068759061103265
70.74269667238446857027
70.53787265717832610790
70.18906494535693241232
69.94027643722262155279
69.69148792908831069326
69.44269942095401404458
69.60056756947636813493
69.78935930238574769646
69.97815103529512725800
70.08945702558115442571
69.87396563024626061633
69.65847423491135259610
69.44298283957647299758
68.99896465259152478211
68.88174385122751175459
68.76452304986347030535
68.89546583793760703429
69.04726803360456699465
69.19907022927154116587
69.25085322125138986848
69.30263621323123857110
69.41819796943374853981
69.53375972563625850853
69.61839789745175721691
69.90588131187016074364
70.19336472628855005951
70.80764552632786035247
71.31826644136054937917
71.82888735639322419502
72.33950827142589901086
72.52333180083766706048
72.53373690627606151793
72.54414201171447018623
72.53368770956004141226
72.74215270499576035945
72.95061770043147930664
73.15908269586719825384
73.24703189640895573120
73.33498109695072741943
73.42293029749248489679
73.28572597281217326781
73.14852164813186163883
73.01131732345155000985
72.97777288377787385798
73.06968464848375788279
73.16159641318962769674
73.25350817789552593240
73.66381259337887854599
74.07411700886225958129
74.48442142434561219488
74.72614481543473630154
74.96786820652383198649
75.20959159761295609314
75.61194164703290709895
76.01429169645288652646
76.41664174587285174312
76.78880847656820662905
77.16097520726354730414
77.53314193795890219008
77.70594662718694678460
77.75329511203543120246
77.80064359688391562031
78.03440343940360435226
78.12318905511916966589
78.21197467083472076865
78.26231294403059735032
78.26231294403061156117
78.26231294403061156117
78.16266370933672646970
77.95912484698325783938
77.75558598462981763078
77.55204712227636321131
77.61783659596058271291
77.68362606964478800364
77.74941554332899329438
77.94877758479628937494
78.14813962626361387720
78.34750166773090995775
78.10205229902669543662
77.85660293032245249378
77.61115356161823797265
77.56081528842236139099
77.51047701522648480932
77.46013874203060822765
77.41182396157296352612
77.36350918111533303545
77.31519440065770254478
77.23127925565232487770
76.93052737595307633001
76.62977549625382778231
76.27086460516471788651
75.97774318775981328145
75.68462177035492288724
75.39150035295001828217
75.34531950876419159613
75.29913866457835069923
75.25295782039252401319
75.07308905264547149727
74.89322028489843319221
74.71335151715138067630
74.81097725910700546592
74.90860300106264446640
75.00622874301828346688
75.18979312271753201458
75.37335750241678056227
75.55692188211602910997
75.71122919264249162552
75.86553650316892571936
76.01984381369538823492
76.37875470478448391987
76.74912507515486481680
77.11949544552523150287
77.43709536734679943493
77.73171945974090135678
78.02634355213501748949
77.84867842720683484004
77.49114453453158546381
77.13361064185633608759
76.77607674918110092221
76.48885248447521689741
76.20162821976933287260
75.91440395506344884780
75.44926157719655179790
74.98411919932964053714
74.51897682146274348725
73.65804966914487295071
72.79712251682697399247
71.93619536450910345593
71.12803866074003167341
70.70979461653521980224
70.29155057233040793108
70.34559574544790905293
70.35630134433338866984
70.36700694321885407589
70.37771254210433369281
70.26777023982457137663
70.29040860551567959646
70.31304697120677360545
70.60101953062155644147
71.10323971585523850081
71.60545990108893477100
72.29886128021092872586
73.04260093252881347325
73.78634058484669822064
74.53008023716458296803
74.73594321476667801107
74.94180619236878726497
75.14766916997089651886
75.28024983794176705487
75.41283050591263759088
75.54541117388349391604
75.67799184185436445205
75.92989511099901278612
76.18179838014366112020
76.43370164928830945428
76.37464258398316019338
76.30714650934866938314
75.81449990809164773964
75.26432385175361616803
74.71414779541557038556
74.95677201901806085971
75.36849797422226515664
75.78022392942648366443
75.97854219057197155962
76.31014260470404053649
76.64174301883610951336
76.74783293357518232369
76.85392284831425513403
76.67212331260229518648
76.49032377689036366064
76.18946633317577266098
76.02417349163562221293
75.87954225528801543987
76.05130453629109865687
76.22306681729419608473
76.39482909829729351259
76.24694995409629427741
76.09907080989532346393
75.95119166569432422875
76.13792339799572062020
76.32465513029710280080
76.51138686259849919225
77.06718551880084078221
77.62298417500321079388
78.17878283120556659469
78.85565858930641525149
79.63671001690957496066
80.41776144451273466984
81.39123898049025740420
82.39842539164008883290
83.52434280582941994453
84.65026022001876526701
85.40211881446423092257
86.25984282338457376227
87.11756683230490239112
87.82741169702424599564
88.37989336281785313076
88.93237502861146026589
88.24987317426679567234
87.56737131992213107878
86.88486946557748069608
86.08129050933432324655
85.24433034863797331582
84.40737018794162338509
83.37798391887088200747
82.40450638289334506226
81.31229784387630843412
80.22008930485925759513
79.70055917001232614894
79.07516362069051751860
78.44976807136873730997
77.60513197131467677536
76.91785907018636692101
76.23058616905802864494
76.35728936720033743768
76.48399256534263201956
76.61069576348494081230
76.51198961825969035999
76.44666467748763238887
76.38133973671557441776
76.31601479594351644664
76.42019046544581328817
76.52436613494811012970
76.62854180445043539294
75.93462972641505359661
75.24071764837968601114
74.54680557034430421481
73.80946108299983166035
73.07211659565533068417
72.33477210831085812970
71.84668980490189937882
71.43609324411633565433
71.02549668333074350812
70.84030946591272481783
70.18901936111925010664
69.53772925632574697374
68.88643915153224384085
68.00973970337119567375
67.18067200462904509095
66.35160430588688029729
65.74794595051227474869
65.14428759513768341094
65.03948278361865220631
65.19735093214100629666
65.35521908066337459786
65.34572925375780982904
65.33623942685224506022
65.24926385732334210843
65.16228828779442494579
64.80919799325072006013
64.92221058608248540622
65.03522317891426496317
65.14823577174604452011
65.93210654451040397817
66.66834556785586585193
67.40458459120134193654
68.22184513386233106758
69.03910567652334862032
69.35751267532876340738
69.44095726811914914833
69.52440186090953488929
69.77520442912782527856
70.02600699734614408953
70.08359446631288847129
70.14118193527963285305
70.46488412926117916868
70.87798082448762215790
71.29107751971405093627
71.70417421494049392550
71.13562363987318803993
70.56707306480589636521
69.99852248973860469050
69.34895039535578575851
68.97995631662546145435
68.61096223789516557190
68.47693056517987031384
68.34289889246460347749
68.11111882151973873079
67.87933875057488819493
67.84077377888156945573
67.52539936637904816052
67.21002495387652686532
66.80525604012910889651
66.40048712638167671685
66.38029978788040352811
66.67090153974024246963
66.96150329160010983287
67.27229238196123617399
67.58308147232239093682
67.61329254703102265012
67.64350362173965436341
67.43412870104833700680
67.22475378035701965018
67.11312725789528599307
67.00150073543353812511
67.22267680230646647033
67.72066230998859737156
68.21864781767072827279
68.65405231771518401729
69.08945681775965397264
69.14027974255799335879
68.94823205327578818924
68.75618436399358301969
68.54394933621009045055
68.33171430842659788141
68.31152696992529627096
68.29133963142399466051
68.51073828832267054167
68.53808925593912704244
68.56544022355556933235
68.59279119117202583311
68.28733956945382033155
67.61389979472392042226
66.94046001999402051297
66.32960125290176733870
65.71874248580954258614
65.65789582480385888630
65.83991977787871974215
66.02194373095358059800
66.26481434503412515369
66.50768495911466970938
66.55850788391299488467
66.60933080871133427081
66.40812587861566385072
66.39896863780221281104
66.38981139698874756050
66.63268201106929211619
66.87555262514982246103
67.48641139224207563529
68.09727015933431459871
68.92390783073004456583
69.75054550212578874380
70.02717106743494923649
70.12954113933969324535
70.23191121124443725421
70.27343462214349756323
70.31495803304255787225
70.15636297380730468376
69.99776791457205149527
70.09120071023079390216
70.40580957276245044341
70.72041843529410698466
70.74108243860034406225
70.53524959316709441737
70.32941674773383056163
70.12358390230058091674
69.70197215256382605730
69.37591446349244961311
69.04985677442105895807
68.89805457875409899771
68.74625238308715324820
68.60424611858221055627
68.46223985407729628605
68.52035205970668130249
68.53954709663793209984
68.55874213356918289719
68.35676110362751956018
68.15478007368584201231
68.34989497468309593842
68.77150672441985079786
69.19311847415659144644
69.86536357038161781929
70.53760866660664419214
71.11429970216629214974
71.67843902466816530250
72.24257834717002424441
72.80671766967186897546
73.28709218711382789024
73.76746670455580101589
74.24784122199775993067
74.82861476852977489216
75.40938831506178985364
75.99016186159380481513
76.34534078820129820997
76.34534078820129820997
76.34534078820129820997
75.90512784798835355105
75.21428156128715158957
74.52343527458594962809
73.83258898788473345576
73.47327368058901697623
73.11395837329328628584
72.75464306599755559546
72.37475140216146485272
71.99485973832535989914
71.61496807448926915640
71.17359455026124237520
70.52638818059998015997
69.87918181093871794474
69.45757006120196308530
69.34709333202019365672
69.23661660283843843899
69.56635281386959945849
69.89608902490078889969
70.22582523593194991918
70.55556144696313936038
70.56631839164661812447
70.44113757491133753774
70.31595675817604274016
70.28532117187900496447
70.25468558558196718877
70.49321451263548965471
70.73174343968899790980
71.17610521217577002062
71.54180822881932044766
71.90751124546287087469
71.96207924155143587086
71.98374865301022396125
72.00541806446901205163
72.02708747592780014202
72.26553308307845213676
72.50397869022908992065
72.74242429737972770454
73.11680766594913905010
73.47926446402775013667
73.84172126210637543409
74.20417806018497230980
74.00717254403052436373
73.81016702787607641767
73.61316151172161426075
73.46359908863944099267
73.31403666555725351373
73.16447424247508024564
73.04781040402266967249
73.14796471585127335402
73.24811902767986282470
73.13149714381660260187
72.63873010693191645260
72.14596307004723030332
71.65319603316254415404
71.32137482138097084317
70.98955360959941174315
70.65773239781785264313
70.61620898691877812325
70.91843335829207717325
71.22065772966539043409
71.55409776380965070075
72.04825067080777500905
72.54240357780589931735
73.03655648480402362566
73.33958972633848816258
73.64262296787293848865
73.94565620940741723643
74.78487557445174616078
75.62409493949607508512
76.46331430454040400946
76.96777495966662741012
77.47223561479287923248
77.97669626991910263314
78.36875883264912090453
78.41707361310676560606
78.46538839356439609674
78.42978802901666313119
78.23347479161506612400
78.03716155421346911680
77.59528190672489245117
77.12770377441898972393
76.66012564211308699669
76.19254750980718426945
75.44150073836948422468
74.69045396693178417991
73.93940719549408413513
73.56191812694837039999
73.18442905840265666484
72.80693998985692871884
72.54184901370743432381
72.13952686989662765882
71.73720472608584941554
71.41879772728042041763
71.32642921385928502787
71.23406070043812121639
71.38725859710396548508
71.80370675702005200947
72.22015491693613853386
72.63660307685222505825
73.40145773093396996956
74.16631238501571488086
74.93116703909743137046
75.63593597556754843936
76.34070491203766550825
77.04547384850778257714
77.77950239968728851636
78.65076211852809251468
79.52202183736889651300
80.18150329651874130832
80.61494627028429249549
81.04838924404984368266
81.32432213717024183097
81.33700476704036930187
81.34968739691049677276
81.04160132240107827784
80.50853642236933183085
79.97547152233759959472
79.46471490492504585745
78.82622575753791238640
78.18773661015077891534
77.54924746276363123343
77.02006853678517472872
76.49088961080673243487
75.96171068482827593016
75.22184681977944364917
74.48198295473061136818
73.74211908968177908719
73.30243455176709233001
72.86275001385240557283
72.42306547593770460480
72.30414964240257802430
72.31527657848945978003
72.32640351457634153576
72.31522216804404479262
72.13227854050866483249
71.94933491297328487235
71.76639128543789070136
71.41409874170959426465
71.06180619798126940623
70.70951365425295875866
70.77968430928601151209
71.06357767192929486555
71.34747103457256400816
71.48869515072685487667
71.43666789121766669268
71.38464063170846429784
71.33261337219927611386
71.14028376198727698920
70.94795415177529207540
70.75562454156330716160
70.73505721235440546479
70.94052836852978316529
71.14599952470516086578
71.38224976095540341703
71.26332107059815257344
71.14439238024087330814
71.02546368988362246455
70.69281229191611259921
70.40891892927284345660
70.12502556662956010314
70.03438357964977001302
70.25016248395327522758
70.46594138825676623128
70.69197987364104562857
70.80754162984355559729
70.92310338604607977686
71.03866514224858974558
70.92818841306683452785
70.67165683402944864611
70.41512525499206276436
70.51377260256218448831
70.75847479998792266542
71.00317699741366084254
71.24787919483938480880
71.12336702712724445519
70.99885485941510410157
70.87434269170296374796
70.44340963270754230052
69.91261698234967525423
69.38182433199179399708
68.96150841081569637936
68.54119248963959876164
68.51972994607064038064
68.49826740250166778878
68.62285970878832586095
68.65335280244141813455
68.68384589609452461900
68.56828413989200043943
68.45272238368949047072
68.26666433357785024327
68.40106261327987624554
68.53546089298190224781
68.85591722279555426667
69.17637355260920628552
69.59668947378531811410
69.91170321983969415669
70.22671696589405598843
70.54173071194843203102
70.45789108039566883690
70.08579538521647123162
69.71369969003725941548
69.43570320749159918705
69.56207362445780972848
69.68844404142402026991
69.81481445839023081135
70.01168116926555740065
70.21940863083597150762
70.42713609240638561459
70.44880550386517370498
70.41668834045304947722
70.38457117704092524946
70.45775618875052259682
70.70774459508530185303
70.95773300142008110925
71.20772140775486036546
71.74596587771608824369
72.05488594464875973244
72.36380601158144543206
72.26835917900226036181
71.98412061351369573003
71.69988204802513109826
71.41564348253655225562
71.12054416635291431703
71.10968341565782679936
71.09882266496273928169
71.14174848913856408217
70.90996841819369933546
70.67818834724884879961
70.26960488167881635491
69.55110991502732531444
68.83261494837584848483
68.11411998172437165522
67.62494941810143700422
67.78635544392639644684
67.94776146975137010031
68.29795922848572331532
68.64815698722006231947
68.71580186321733663135
68.78344673921462515409
68.56685304972333483420
68.21706944917752934998
67.86728584863175228747
67.79220814320663635044
67.71713043778152041341
67.98102767213849517702
68.55483640757691432555
69.12864514301534768492
69.59715170333204525832
70.06565826364874283172
69.88358823451753210065
69.60103535178046740839
69.31848246904340271612
69.03592958630633802386
69.03592958630633802386
69.16748154865685194181
69.29903351100735164891
69.56377538441236652034
69.70680616209294555574
69.84983693977353880200
69.99286771745411783741
69.79692355535260617216
69.18250825529452185947
68.56809295523643754677
68.05897983030006059835
67.54986670536371207163
67.27060810805076584984
67.09183236434367358925
66.91305662063656711780
66.65469683618243834644
66.39633705172832378594
66.00642530492370951833
65.61651355811906682902
65.38897461928154086763
65.28314677616842232055
65.17731893305531798433
65.07149108994221364810
65.30001788159225384334
65.94701581119888089688
66.59401374080550795043
67.06237419100799002081
67.53073464121047209119
67.76924056378953764579
67.46064389463185761997
67.15204722547417759415
66.92303459706351986824
66.69402196865286214233
66.88473687710970239095
67.07545178556654263957
67.10379388605629458198
67.13213598654604652438
66.95249440742470881105
66.77285282830337109772
66.25885661441887464207
65.74486040053439239728
65.13027990372643216688
64.69433688632261691964
64.25839386891880167241
64.15012728904036976019
64.58896330089869763924
65.02779931275702551829
65.46663532461533918649
65.93446050354403098481
65.98255814560522480861
66.03065578766643284325
65.85735884061138278867
65.68406189355633273408
65.71874862611237233523
65.75343535866842614723
66.06870010687697458707
66.38396485508553723776
66.79981388621754945234
67.21566291734959008863
67.80846944537270815090
68.07359953587044287815
68.33872962636817760540
67.99477707653328195647
67.65082452669838630754
67.27788280979311252850
66.90494109288783874945
66.52391197162960168043
66.36427743948760848980
66.20464290734561529916
66.04500837520362210853
66.22783799262656145856
66.13008959439697775906
66.03234119616739405956
65.59212864837289203024
65.15191610057839000092
64.53474605589278212392
63.91757601120717424692
63.70098232171589103245
64.09347127255725240502
64.48596022339859246131
64.87844917423994672845
65.72715571914902454864
66.58394966841106565880
67.44074361767312097982
68.15758173077678350182
68.87441984388044602383
69.24879380741920442688
69.41769661478258512943
69.58659942214595162113
70.09796637907422223179
70.78109561700560448116
71.46422485493700094139
72.14735409286836897991
72.42990697560543367217
72.36226209960815936029
72.29461722361088504840
72.22697234761361073652
71.55622386109737931292
70.88547537458113367848
70.21472688806490225488
69.68393423770703520859
69.61700620467730971086
69.55007817164758421313
69.68862129479323641590
69.76215317821788630681
69.83568506164253619772
69.73745466406410287163
69.63922426648565533469
69.39630757634878932549
69.15339088621189489459
69.26067195480936788954
69.62205106007600363682
69.98343016534263938411
70.49169528706050869005
70.93368061969539439815
71.37566595233028010625
71.81765128496516581436
71.79577200027192418474
71.44108828356490903388
71.08640456685787967217
70.79673208987185262231
70.78607747002868677555
70.77542285018552092879
70.76476823034235508203
70.89879990305763612923
70.91889388932942495103
70.93898787560121377282
70.70498382520383984229
70.26886183545980202325
69.83273984571576420421
69.46289764505470998301
69.09305544439365576181
69.15933523347663935965
69.22561502255962295749
69.62469924365638007657
69.87205549998870424133
70.11941175632102840609
70.08775015551049136775
69.84643741419773732559
69.60512467288501170515
69.36381193157225766299
69.23643687670301005710
69.42604042271065623027
69.61564396871830240343
70.00736545407261246510
70.46601497245663381364
70.92466449084068358388
71.38331400922470493242
71.20037038168931076143
71.01742675415394501215
70.83448312661855084116
70.80326746384760383535
70.85628928338678633736
70.90931110292595462852
71.17198406296733992349
71.12495763787855196369
71.07793121278976400390
71.03090478770097604411
70.66689976173529430525
69.57363936435599782726
68.48037896697670134927
67.32019053656767937355
66.16000210615867160868
65.50049092033168562921
65.04645089068009156108
64.59241086102849749295
64.13837083137691763568
63.83980701962236281588
63.45700572555767848826
63.07420443149300126606
62.69140313742831693844
62.51332439338207791479
62.33524564933584599657
62.15716690528961407836
62.39298506328778159968
63.35805859269953543844
64.32313212211130348805
65.28820565152307153767
66.32716040806684532072
66.86543792002855468581
67.40371543199027826176
67.44570871306530079892
67.48770199414033754692
67.37421905731832794118
67.26073612049631833543
67.18149663176123453923
67.10225714302615074303
67.12799448940279489761
67.15373183577943905220
67.67393632109811107966
67.78024390437238366758
67.88655148764665625549
67.61385962944643779338
67.34116777124619090955
66.99459468591395250314
66.14972545192873099040
65.30485621794350947766
64.95627121484497479287
64.60768621174642589722
64.25910120864790542328
64.49271247949320695625
64.69208030225158267967
64.89144812500997261395
65.08132612086279777941
65.27120411671560873401
64.96661497362640602660
64.66202583053720331918
64.52479466287590526008
64.76656293668909825101
65.00833121050231966365
65.56850648312095586334
66.62697790439256095851
67.68544932566419447539
68.50895834092077052446
69.33246735617737499524
70.15597637143395104431
70.39728911274670508647
70.35026268765790291582
70.30323626256912916688
70.26569966438589176505
70.44331722089459901781
70.62093477740329205972
70.79855233391199931248
70.80881191499277349521
70.62260166598066746246
70.43639141696856142971
69.93177416915102639905
69.26478411336640306217
68.59779405758177972530
68.16576640781221385623
67.73373875804261956546
67.80940461760678772407
67.88507047717095588268
68.24907550313665183239
68.45397007674777967168
68.65886465035892172182
68.64860506927813332823
68.63834548819735914549
68.47021775859420245069
68.30209002899107417761
68.33043212948082612002
68.73138949229172567357
69.13234685510261101626
69.69567702588061308688
70.25900719665860094665
70.78879292776291265454
71.31857865886721015158
71.34067088063777362095
71.09133715575815415377
70.84200343087852047574
70.75178015835345490814
71.00056866648773734596
71.24935717462204820549
71.49814568275634485417
71.90480233941300980405
72.36932936644002722915
72.83385639346705886510
72.92576815817294288991
72.55315289585179527876
72.18053763353064766761
71.80792237120951426732
71.68900653767437347597
71.57009070413924689547
71.45117487060412031497
71.60368498371917667100
72.01231525504967123652
72.42094552638016580204
72.49056401705129815127
72.38991989466804000131
72.28927577228478185134
72.18863164990153791223
72.09671988519565388742
72.00480812048976986262
71.91289635578388583781
72.33101249172179336711
72.74912862765972931811
73.16724476359763684741
73.36520591042322791964
73.31317865091403973565
73.26115139140483734081
73.20912413189563494598
72.73284898456785185772
72.25657383724009719117
71.78029868991231410291
71.47428615563890730300
71.34670909909674207938
71.21913204255460527747
71.02495225796471345348
70.53220866162027391510
70.03946506527580595503
69.50122059531459228765
68.96297612535337862028
68.56396846440628678465
68.16496080345919494903
68.01594154884688236962
68.18688464189121134496
68.35782773493554032029
68.69689855758299756872
69.03596938023045481714
69.22476111313983437867
69.41355284604921394020
69.42390910122733771459
69.47618236073689956811
69.52845562024646142163
69.87929269151055677867
70.52328349559886078168
71.16727429968716478470
71.81126510377546878772
72.17606326269128658168
72.54086142160707595394
72.90565958052289374791
72.95049539178205577628
72.75908096679100367510
72.56766654179992315221
72.37625211680885684018
72.59411704055614222852
72.81198196430341340601
73.02984688805068458350
72.92651320567914297044
72.82317952330760135737
72.71984584093604553345
72.32335842574029527441
71.69740210358143883695
71.07144578142258239950
70.58544529542209033934
70.47496856624033512162
70.36449183705856569304
70.25401510787679626446
70.37978861494526938714
70.40025994689202093468
70.42073127883875827138
70.18220235178523580544
69.84319057112585937830
69.50417879046648295116
69.44444861159450965715
69.38471843272252215229
69.68697586393736287391
69.98923329515221780639
70.52095963333016470642
70.88572644546539436305
71.25049325760062401969
71.23973631291715946645
71.09368146306152880243
70.94762661320592656011
70.80157176335029589609
70.76081908861642943975
70.96530662133443456696
71.16979415405242548331
71.47476454037629878258
71.56498781290137856104
71.65521108542645833950
71.74543435795152390710
71.52545301236962416169
71.30547166678773862714
71.08549032120583888172
71.03246850166667059057
71.13117464689192104288
71.22988079211717149519
71.46388484251454542573
71.56721852488610124965
71.67055220725765707357
71.77388588962919868663
71.38233352877453796737
70.99078116791989145895
70.59922880706523073968
70.42242356000936354121
70.45334714439638901240
70.48427072878341448359
70.46341132119059125216
70.25376018068838845920
70.04410904018618566624
69.83445789968398287328
69.47307879441734712600
69.03695680467330930696
68.60083481492927148793
68.29538319321108019722
67.98993157149287469565
68.09829365689952851426
68.45630157808051308166
68.81430949926149764906
69.06395533503581418699
69.31360117081011651408
69.35551817514155459321
69.46182575841582718112
69.56813334169009976904
69.86323265787373770763
70.15833197405740406793
70.65828091544210565189
71.15822985682680723585
71.73292168268892510241
72.01731570766852996712
72.30170973264812062098
72.58610375762773969655
72.22809583644675512915
71.87008791526577056175
71.51207999408480020520
71.26243415831048366726
71.37079624371713748587
71.47915832912380551534
71.52312983558761061431
71.31540237401719650734
71.10767491244678240037
70.89994745087636829339
70.39999850949163828773
69.90004956810693670377
69.40010062672223511981
69.19044948622003232686
69.29777694659472331296
69.40510440696942850991
69.74102010637984960795
69.84732905952660075855
69.95363801267333769829
70.05994696582008884889
69.80824799778585543208
69.58648197994320128146
69.36471596210053291998
69.39464891229209797530
69.74848318918350287277
70.10231746607492198109
70.54352359757955071018
70.75317473808175350314
70.96282587858397050695
71.17247701908617329991
71.06514955871146810296
70.78656162122521777746
70.50797368373898166283
70.45899249251641549563
70.68859923878008544307
70.91820598504375539051
71.14781273130742533795
71.34748652737951601921
71.24929019756555703680
71.15109386775156963267
70.72899621123775659726
70.28779007973312786817
69.84658394822848492822
69.63693280772628213526
69.42728166722406513145
69.49106043144672639755
69.55483919566940187451
69.78987843700360826915
70.00345513476884207194
70.21703183253407587472
70.15202059281308777372
69.91252773455954638848
69.67303487630599079239
69.43354201805243519630
69.49191928568497189644
69.87946746735079273094
70.26701564901662777629
70.67367230567327851531
70.88332344617549551913
71.09297458667769831209
71.30262572717990110505
71.23884696295723983894
71.03306193422965009177
70.82727690550206034459
70.64295442034342897841
70.82061954527162583872
70.99828467019982269903
71.35043141366055863273
71.49310066014953690683
71.63576990663850097008
71.77843915312749345503
71.59193748558317338393
71.35340855852965091799
71.11487963147612845205
71.07335622057706814303
71.42389537240802610540
71.77443452423895564607
72.12497367606991360844
72.56920431194814113951
73.01343494782636867058
73.45766558370459620164
73.36710392026796512255
73.27654225683133404345
73.18598059339470296436
73.30489642692984375572
73.63276083125673210361
73.96062523558364887322
74.28848963991055143197
74.49297717262855655918
74.69746470534656168638
74.90195223806456681359
74.26701906125401819736
73.63208588444346958113
72.99715270763292096490
72.41053431128001705019
72.19375811558816735669
71.97698191989630345233
71.93301041343249835336
72.00059624744167763311
72.06818208145087112371
72.13576791546006461431
71.83971588317673706570
71.54366385089339530623
71.24761181861005354676
71.12696391744481161368
71.11572776039031396067
71.10449160333580209681
71.54061359307982570499
72.02201313421389272662
72.50341267534793132654
72.98481221648199834817
72.85784062990148868266
72.73086904332096480630
72.60389745674045514079
72.36536852968694688570
72.47058738490579798963
72.57580624012464909356
72.83571439084425946930
73.24358655671537121634
73.65145872258651138509
74.05933088845762313213
74.25814207552413392932
74.45695326259064472652
74.65576444965716973456
74.77297026306550264962
74.89017607647384977554
75.00738188988219690145
75.44875541411020947180
75.89012893833820783129
76.33150246256622040164
76.52475383822971366499
76.37425743162084756932
76.22376102501196726280
75.79925736774968925147
75.22678969533589565799
74.65432202292211627537
74.08185435050830847103
73.79509396290002598562
73.50833357529174350020
73.22157318768347522564
72.81501552242326624764
72.40845785716307148050
72.00190019190287671336
71.50970374287652475687
70.86249737321526254163
70.21529100355400032640
69.81620678245725741817
69.70679503834651313809
69.59738329423578306887
69.76197880077846491531
69.92657430732113255090
69.98685885036428544481
70.04714339340745254958
69.92136988633899363776
69.84713681996190359769
69.77290375358481355761
69.85479578712778447880
70.20201800935001301696
70.54924023157224155511
70.89646245379447009327
71.39869459665160889017
71.80901497480286366226
72.21933535295411843435
72.33998325411937457829
72.12640655635411235380
71.91282985858887855102
71.69925316082364474823
71.84669607935090596129
71.99413899787815296349
72.14158191640541417655
72.23748439424127809616
72.49112505673599571310
72.74476571923068490833
72.73307619304614490829
72.26854916601912748320
71.80402213899211005810
71.33949511196507842214
70.96687984964393081100
70.70154565592025619480
70.43621146219658157861
70.50510186740336848743
70.80578202666016807143
71.10646218591696765543
71.15043369238080117611
70.81762926036702765487
70.48482482835325413362
70.15202039633948061237
69.66147777966688181550
69.32594508362919327737
68.99041238759150473925
69.10771719239630783704
69.48033245471745544819
69.85294771703858884848
70.22556297935975067048
70.49089717308341107582
70.50092090929075538952
70.51094464549808549236
70.28917862765544555259
70.02759354763132648714
69.76600846760723584339
69.88119932606069539816
69.99639018451418337463
70.40671056266543814672
70.81703094081669291882
71.07234139833303743217
71.03252233615157251734
70.99270327397013602422
70.69757375427234080689
70.32495849195120740660
69.95234322963005979545
69.57972796730891218431
69.46242316250410908651
69.71773362002045359986
69.97304407753679811321
70.26817359723457911969
70.41084284372355739379
70.55351209021253566789
70.69618133670151394199
70.54372106349269699876
70.14276370068181165607
69.74180633787091210252
69.63597849475780776629
69.73692822986569694876
69.83787796497360034209
70.01631344270484191838
70.23646843181718679716
70.45662342092951746508
70.67677841004184813301
70.52431813683304540064
70.19980051657309161328
69.87528289631313782593
69.70322554926200098180
69.73503792130557599194
69.76685029334913679122
69.79866266539271180136
70.07897212703838363268
70.42429282840502935414
70.76961352977168928646
70.90815665291734148923
70.65497829070869784118
70.40179992850003998228
70.10690205491030724261
69.81200418132057450293
69.55414407637812246321
69.29628397143567042349
69.21048121354436943875
69.59201605756726394247
69.97355090159017265705
70.15121602651836951736
70.04690506301885477569
69.94259409951934003402
69.83828313601983950321
69.66896093279933666054
69.35198233192241445977
69.03500373104552068071
69.10974661552290854161
69.55710476232145822451
70.00446290912000790740
70.45182105591854337945
70.86214143406979815154
71.23891737254739098262
71.61569331102496960284
71.52513164758832431289
71.26362689110737846931
71.00212213462641841488
71.02259346657315575158
71.25884370282338409197
71.49509393907362664322
71.73134417532386919447
72.11525080923050268211
72.33540579834283335003
72.55556078745516401796
72.40310051424634707473
71.99278013609509230264
71.58245975794383753055
71.17213937979258275845
70.79536344131498992738
70.72972252339239673802
70.66408160546978933780
70.76938378059152512378
70.64935369304959067449
70.52932360550764201435
70.19351461366218813964
69.42884812153668860901
68.66418162941118907838
67.89951513728567533690
67.29860028995446441513
67.14609017683940805910
66.99358006372435170306
67.09893005555173317589
67.20428004737914307043
67.43077689611862979291
67.65727374485813072624
67.57263557304264622871
67.27086356782803022725
66.96909156261340001492
66.89265182006245424873
66.81621207751150848253
67.18623057138825060974
67.98510656554502418203
68.78398255970182617602
69.53735768024183983016
70.29073280078186769515
70.59570318710572678356
70.64536311591325556947
70.69502304472078435538
70.74468297352831314129
70.67319604542373667755
70.64227246103669699551
70.61134887664968573517
70.79755912566179176793
71.15389912696497276556
71.51023912826815376320
71.86657912957134897169
71.77646089444687049763
71.39673542189962063276
71.01700994935235655703
70.68278535042188082116
70.50936692644850722900
70.33594850247514784769
70.41784053601813297973
70.91003698504447072537
71.40223343407080847101
71.89442988309716042750
72.34606298840594718058
72.95192399457020826503
73.55778500073445513863
73.99351625460761283648
74.55153720643428982839
75.10955815826096682031
75.85519745396607049770
76.89044398709395977676
77.92569052022184905582
78.90175705145598783474
79.71701740773299604825
80.53227776401000426176
81.09681008034883120672
81.25103798120429132723
81.40526588205972302603
81.19101825624082380273
80.97677063042192457942
80.60829510374756523561
80.32084109638874735992
80.03338708902992948424
79.62364338371759231450
79.21389967840526935561
78.56989496797081073964
77.92589025753636633453
77.28188554710192192942
76.54085825720198954514
75.79983096730205716085
75.05880367740213898742
74.84309391615477125015
74.62738415490741772373
74.41167439366006419732
74.54028323571282044213
74.66889207776556247609
74.79750091981831872090
74.84508824255553349758
74.89267556529276248511
74.94026288802999147265
74.98785021076722046018
75.21805725171013534691
75.44826429265306444449
75.67847133359599354208
75.91691694074663132596
76.15536254789728332071
76.39380815504792110460
76.11921866633355193699
75.84462917761918276938
75.57003968890481360177
75.31960712356467979589
75.06917455822453177916
74.81874199288441218414
74.56830942754427837826
73.76884240555892802149
72.96937538357357766472
72.16990836158822730795
71.93970132064531242122
71.70949427970238332364
71.47928723875945422606
71.39704421296804071062
71.20214730196943264673
71.00725039097082458284
71.05079908712286851369
70.82546682445919827842
70.60013456179552804315
70.37480229913185780788
70.06366727857685816616
69.75253225802188694615
69.44139723746690151529
69.67929667355711842447
70.11276937399981079579
70.54624207444251737797
70.27447773688076892995
69.42511771392916841705
68.57575769097758211501
67.72639766802598160211
66.98969153028157563767
67.14898807673245073602
67.30828462318331162351
67.73646212844991509883
68.16463963371649015244
68.55774812315992505773
69.03665937049467515862
69.51557061782943947037
69.80524309481546652023
70.09491557180147935924
70.18901478443504515781
69.95640375143528899571
69.72379271843553283361
70.06877737082564294724
70.41376202321573885001
71.07480230139597665584
71.73584257957621446167
71.50088017356117120471
70.94986214175600025555
70.39884410995082930640
69.84782607814564414639
69.33187706216361334555
68.77954006294915245689
68.22720306373469156824
67.86410483486898215233
67.86410483486898215233
67.86410483486898215233
68.19081508050228990214
68.51752532613559765196
68.55456309478286414105
68.59160086343015905186
68.31258300628729784876
67.96013939726474006875
67.60769578824216807789
67.57130780500975220093
67.53491982177733632398
67.88309261097960245479
68.23126540018186858561
68.61582617261655059337
68.61582617261655059337
68.61582617261655059337
68.25272794375084117746
67.88962971488510333984
67.51753401970590573455
67.14543832452670812927
67.06301510633352336299
66.98059188814032438586
67.30253556945899617858
67.69790500265739296992
68.09327443585577555041
68.33178035843484110501
68.57028628101390665961
68.42423143115829020644
68.04870767433958178572
67.67318391752085915414
67.68222093313681853033
67.69125794875277790652
68.24359494796723879517
68.79593194718169968382
69.35726641270963455099
69.86130138063955996586
70.36533634856949959158
70.86937131649943921730
70.77392448392025414705
70.67847765134106907681
70.58303081876188400656
70.64444749680200175135
70.82308497620616094537
71.00172245561032013939
71.40982884197757130096
71.55031335746207332704
71.69079787294658956398
71.83128238843112001177
71.42846692031713473625
71.13491718646889694355
70.84136745262065915085
70.60511721637041659960
70.51630989864744947226
70.42750258092446813407
70.53381016419874072199
70.21593192119848936272
69.89805367819822379261
69.58017543519795822249
69.14507639083367962485
68.85817233155110272946
68.57126827226852583408
68.55198608386868386333
68.53270389546885610343
68.61161803688298732595
68.69053217829713275933
68.66018058544554492073
68.28741901865411989547
67.91465745186269487021
67.39445296654403705361
66.87424848122536502615
66.62724680710289248964
66.80443095925497232201
66.98161511140705215439
67.46089938023226295627
67.94018364905748796900
68.27127293280099706863
68.60236221654452037910
69.15567718801806051943
69.70899215949160065975
70.16411080115116760680
70.81053515103295126210
71.45695950091476333910
72.44579382473636997020
73.61321527709677070561
74.78063672945715723017
75.94805818181755796559
76.77209626243555362635
77.59613434305356349796
78.42017242367157336957
78.03226954910711299362
77.64436667454265261767
77.25646379997819224172
76.63898927080252576616
75.79928905389682824989
74.95958883699114494448
74.11988862008544742821
73.53459494313008804056
72.93899351804662956056
72.34339209296317108056
71.17597064060277034514
70.00854918824236960972
68.84112773588198308516
67.74388684406775951174
66.94923686401192242101
66.15458688395607111943
66.26977774240954488505
66.38496860086301865067
66.88720074372017165842
67.61900454118851655494
68.35080833865686145145
68.97946421436483888101
69.60812009007278788886
69.79106371760818205985
69.90717455349360420769
70.02328538937902635553
70.53262912400262507617
71.14164092704427844183
71.75065273008593180748
72.35966453312758517313
72.66608542441085205610
72.87283824727606429406
73.07959107014127653201
73.28634389300648876997
72.49697279113537717876
71.70760168926427979841
70.91823058739318241805
70.23200740728246671551
69.91872594407702479202
69.60544448087158286853
69.36930355744421206055
69.19234606055312042372
69.01538856366201457604
68.73876299835285408335
68.46213743304367937981
67.64313661404285937806
66.82413579504203937631
66.10480304445928823043
65.38547029387655129540
65.10123172838797245277
65.42607580323205240802
65.75091987807613236328
66.25419943065145389482
66.75747898322678963723
66.88781681889683738973
67.01815465456689935309
66.90451197744846467685
66.73168587379377925117
66.55885977013907961464
66.27828175448655656510
65.99770373883404772641
66.25950097687319839679
66.52129821491233485631
67.07369720481131025736
67.62609619471029986926
67.74340099951510296705
67.86070580431992027570
67.29270359158086023399
66.54626590111055861598
65.79982821064024278712
65.05339052016994116912
65.04308277204182786591
65.27675553670219699143
65.51042830136256611695
65.66334519103303080101
65.92401399270130468722
66.18468279436959278428
66.44535159603788088134
65.91349920989480892786
65.09104507189188382199
64.26859093388897292698
63.44613679588605492654
63.48670013960360591909
64.21257050086501294572
64.93844086212641286693
65.63381812973472051453
66.32919539734302816214
66.28844272260916170580
66.24769004787526682776
65.82064854191649772019
65.47436291094761884324
65.12807727997875417714
64.78179164900987530018
64.57429146037334533048
65.15931245954816120047
65.74433345872296285961
66.55676386051854365178
67.36919426231412444395
67.31860718238924334855
67.26802010246436225316
66.44505374377737894065
65.65258047874348790174
64.86010721370962528454
64.06763394867573424563
64.17096763104729006955
64.66059014464372012299
65.15021265824017859813
65.57725416419896191655
66.00429567015773102412
66.29255173378416543528
66.58080779741061405730
66.35995073610069994174
65.91168427217000669316
65.46341780823931344457
65.67311534051876265039
65.88281287279818343450
66.86488968383972064657
67.84696649488124364780
68.21168587433231778050
68.57640525378337770235
68.04531768582901918307
67.51423011787464645295
67.16339304661055109591
66.87513698298411668475
66.58688091935766806273
66.64946192699532900861
66.71204293463300416533
67.28373706720702784878
67.85543119978105153223
68.27390233773762417968
68.03440947948408279444
67.79491662123052719835
67.01248007744077028747
66.23004353365101337658
66.06496442145171954508
65.89988530925242571357
66.44157688127872063433
66.98326845330502976594
67.34470952864106152447
67.42713274683424629075
67.50955596502743105702
67.24114211195652046626
66.97272825888559566465
65.93434709511990376996
64.89596593135421187526
64.01080776220598522741
63.12564959305773015785
63.01045873460426349766
63.43821156168699815225
63.86596438876973280685
64.29371721585246746145
64.68443227428792852152
64.36837664849778661846
64.05232102270764471541
63.73626539691750281236
63.19177423880376665011
62.64728308069004469871
62.10279192257631564189
61.55830076446257947964
62.63940309571233200359
63.72050542696209163296
64.80160775821184415690
65.74677232804282311918
65.92196958717903498837
66.09716684631524685756
66.27236410545145872675
65.55290031485205304307
64.87047429289989963763
64.18804827094777465391
63.50562224899563545932
63.66773698808626846812
64.33730511664333562294
65.00687324520042409404
65.57013242061076141454
66.13339159602109873504
65.84102459276272156785
65.54865758950433018981
64.57669080203206135593
63.74066177597855897830
62.90463274992505660066
62.06860372387154711760
61.86556654550821576777
62.55719041688052328709
63.24881428825283080641
63.94043815962513122031
64.72633477335379836859
64.66769062603970041891
64.60904647872560246924
64.24607007219202614579
63.98940261880520097293
63.73273516541836869465
63.47606771203153641636
63.08300744734816589698
63.36954696687870125515
63.65608648640924371875
63.94262600593977907693
64.63167306178162618835
64.68772826993330227197
64.74378347808497835558
64.79983868623665443920
64.69208677423884523705
64.49006211988465508966
64.28803746553046494228
64.21228333314255110054
64.44086145997411563258
64.66943958680565174291
64.89801771363721627495
65.34174999516069703986
65.92187508798070894045
66.50200018080073505189
66.86697111892881650874
67.23194205705689796559
67.19440545887368898548
67.15686886069045158365
67.09161580311860006987
67.02636274554677697779
67.12491680812442496062
67.22347087070207294346
67.73583864040458024647
68.12193588814082545468
68.50803313587707066290
68.67236436577067593134
68.83669559566425277808
68.78587267086592760279
68.43572024415188082003
68.08556781743783403726
67.95056954541571769823
67.81557127339360135920
68.06179453683016333798
68.30801780026672531676
68.58195752309191561835
68.53657207354601155203
68.49118662400010748570
68.44580117445420341937
67.98660201778344003287
67.53640032742615062489
67.08619863706886121690
66.85776296455426859211
66.62932729203963333475
66.80712180341848238641
67.28424581671303883468
67.76136983000760949380
68.07076958198776139852
68.38016933396791330324
68.30834755048938689015
67.95342285847449659286
67.59849816645959208472
67.56289864681576773364
67.52729912717195759342
67.93166602668382836328
68.33603292619568492228
68.73140235939408171362
68.94981429570135844642
69.16822623200863517923
69.38663816831592612289
69.19881992072973275754
69.03109565941534242484
68.86337139810093788128
68.86337139810093788128
68.89386449175404436573
68.92435758540713663933
69.23795358759660700798
69.55154958978607737663
69.56039142700196009628
69.56923326421784281592
69.13810868227804462549
68.65450229403109005943
68.17089590578413549338
67.86424701442830098586
67.55759812307245226748
67.75632958874838607244
67.95506105442433408825
68.13369853382849328227
68.31233601323262405458
68.29224202696083523279
68.24165494703595413739
68.19106786711107304200
68.08129736064991277544
67.97152685418873829803
68.16651051270116568048
68.36149417121360727378
68.83637808847828409853
69.36374381205008887719
69.89110953562189365584
70.23813375603668873737
70.58515797645145539718
70.42680183983445374452
69.74791900199259941928
69.06903616415073088319
68.39015332630887655796
67.91000195414295603769
68.08695945103406188537
68.26391694792516773305
68.50005787135252433018
68.74705954547499686669
68.99406121959744098149
69.24106289371991351800
69.20816430909012240136
68.83313998318790538633
68.45811565728567416045
68.26343283454049526426
68.31497327523187834686
68.36651371592326142945
68.93858085783949718461
69.51064799975573293978
69.99079937192165346005
70.47095074408757398032
70.29399324719648234350
70.04814534509857537614
69.80229744300066840879
69.54558879020768813461
69.28888013741469364959
69.21393581034337216806
69.13899148327206489739
69.40617289747318352511
69.52084419855924579679
69.63551549964530806847
69.50396353729480836137
69.37241157494430865427
68.88931739387565755806
68.40622321280699225099
68.01504480148867060052
68.23576163370462666080
68.45647846592059693194
68.74608570334336832275
69.03569294076613971356
69.28154084286403247006
69.52738874496193943742
69.59147232133815919042
69.61263007353858256465
69.63378782573897751718
69.80745569105445724745
70.15792695099509046486
70.50839821093572368227
70.85886947087635689968
71.56088294953515571706
72.20615939752266854157
72.85143584551016715523
72.88481704996337384728
72.51911403331982342024
72.15341101667627299321
71.78770800003272256617
71.88737606845079142204
71.98704413686886027790
72.08671220528692913376
72.22930609788082279010
72.59749461039922380223
72.96568312291763902522
73.15706824081087233935
73.21522725220073368746
73.27338626359059503557
73.33154527498044217282
73.11782256737021157278
72.90409985975998097274
72.69037715214973616185
72.87573866563624847004
73.06110017912276077823
73.24646169260927308642
73.01021145635903053517
71.80623566073170138679
70.60225986510437223842
69.39828406947702887919
67.96871365392516395332
67.28446951561662103813
66.60022537730807812295
66.04920734550290717380
65.49818931369772201379
65.01242433946438836756
64.85527811490243266235
64.69813189034047695714
64.11538130758989950664
63.53263072483931495071
62.94988014208873039479
62.36712955933815294429
62.14473027720435283072
62.89005655444766063056
63.63538283169096132497
64.38070910893426912480
65.50679080492838579630
65.88754622367918045711
66.26830164242997511792
66.10943325606206144585
65.95056486969414777377
65.72644342575439679877
65.50232198181463161291
65.15481821719052391018
65.23291881075502374188
65.31101940431955199529
65.38911999788405182699
65.67732816652700478244
65.60518503455315908468
65.53304190257932759778
65.46089877060549611087
65.16277135151443644645
64.48388851367258212122
63.80500567583071358513
63.12612283798885925989
63.35024428192861734033
63.57436572586837542076
63.79848716980814060662
64.08994738548869918304
64.50478992185361448719
64.91963245821852979134
65.33447499458344509549
65.06044963612865217328
64.57631670259542033818
64.09218376906218850309
63.60805083552894956256
63.65284299988633165412
63.92361945136093481779
64.19439590283553798145
64.65463806101723776010
65.11488021919892332789
65.21174190071771192834
65.30860358223651473963
64.86775625105842379980
64.35957014813953946941
63.85138404522066224445
63.34319794230177791405
63.27585917056097031264
63.89738829363989225385
64.51891741671880708964
65.30780451522562657374
66.09669161373244605784
66.35665361434865872070
66.61661561496485717271
66.45143269661738827381
66.09678407156280854906
65.74213544650825724602
65.38748682145370594299
65.32884267413959378246
65.80790753952236116220
66.28697240490512854194
66.92610129589814960127
67.56523018689117066060
67.76351174670611499096
67.96179330652104511046
67.71236935707096904480
67.29558743219297411997
66.87880550731497919514
66.76903500085381892859
66.65926449439265866204
66.97463890689519416810
67.29001331939771546331
67.90748784857336772802
68.52496237774906262530
68.84643242918426153665
68.59259955275010156583
68.33876667631594159502
67.92486977427154215547
67.51097287222714271593
67.17529512286652959574
66.83961737350591647555
66.95164513341032375138
67.06367289331473102720
67.56389941098311169299
67.75711451023465770049
67.95032960948620370800
67.86452685159488851241
67.77872409370357331682
67.39082121913912715172
67.00291834457465256492
66.81058873436265344026
67.19356205202004161947
67.57653536967741558783
68.11861913968934345576
68.66070290970125711283
69.12456752702939866140
68.73942545239742685226
68.35428337776545504312
67.96914130313348323398
67.19580047073752382403
66.69557395306915736910
66.19534743540076249246
65.97413877487525724064
65.75293011434972356710
65.88793875490273421747
66.02294739545571644612
65.96238277165625163434
65.90181814785677261170
65.45179463642433859150
64.84266067263735067172
64.23352670885037696280
63.67446685474571665964
63.96441369260116971418
64.25436053045662276872
64.54430736831209003412
65.18361652799276839687
65.54981137294586801545
65.91600621789896763403
66.28220106285206725261
66.16035498098884204410
65.68229159804707251169
65.20422821510533140099
64.72616483216357607944
64.03960926470027459345
63.74251258486994231589
63.44541590503960293290
63.14831922520927065534
63.31565759395962089684
63.48299596270997824377
63.65033433146032848526
63.22731737452345157635
62.45493809576136357009
61.68255881699927556383
60.91017953823718755757
60.13780025947509955131
60.33653542058306840090
60.53527058169103725049
60.73400574279901320551
61.38819550574275751842
62.25087745320804089033
63.11355940067332426224
63.97624134813860763416
64.72336153940136682650
64.95597257240112298859
65.18858360540087915069
64.67407444713785480417
64.74992061456207181891
64.82576678198628883365
64.90161294941049163754
65.35872747996401699311
65.81584201051755655953
65.78988302801737120262
65.76392404551718584571
65.16334274213217270244
64.10730683691139120128
63.05127093169062391098
61.99523502646984240982
61.27146796366133685297
60.66326265705534837025
60.05505735044935988753
59.44685204384336429939
60.13340761130665867995
60.81996317876995306051
61.50651874623324744107
62.19307431369654182163
62.25417197685639791871
62.31526964001625401579
62.37636730317611011287
62.14161994529810328913
62.48149490830490293547
62.82136987131170968723
63.16124483431851643900
64.01149571081171529841
64.52947774489261689723
65.04745977897354691777
65.56544181305444851660
65.70721100860353658391
65.30133952134607966400
64.89546803408865116580
64.48959654683120845675
63.60968702161243015780
62.97396703756774627436
62.33824705352307660178
61.70252706947839982377
62.07051522249010133692
62.43850337550180995549
62.80649152851351146865
63.17447968152521298180
62.76584941019471841628
62.35721913886421674533
61.94858886753371507439
61.53995859620322050887
61.16997702621848986837
60.79999545623376633330
60.43001388624903569280
60.06003231626431215773
60.89073595882141631819
61.72143960137852047865
62.55214324393562463911
63.21462091040822883770
63.16923546086232477137
63.12385001131642781047
63.07846456177052374414
62.78033714267946407972
62.74845223444422970260
62.71656732620900243091
62.68468241797376094837
63.18353596182934239778
64.01995364287097345368
64.85637132391261161501
65.48789443134310772621
66.11941753877363225911
66.02429347162365047552
65.92916940447365448108
65.21597355224783143512
64.67100367610652256189
64.12603379996519947781
63.58106392382386928830
63.81849706265197141875
64.30867217102523625272
64.79884727939850108669
65.31937398062333954840
65.83990068184820643182
65.82968893098224327787
65.81947718011629433477
65.29666074997898306265
64.97873889345282805152
64.66081703692665882954
64.34289518040050381842
64.48722513021216684592
65.24962686509967113579
66.01202859998717542567
67.06103344405843813547
68.11003828812968663442
68.37664011723151702427
68.64324194633334741411
68.47891071643975635652
68.28422789369457746034
68.08954507094938435330
67.56901836972453168073
67.04849166849967900816
67.04056964654617445376
67.03264762459266989936
67.59679274455540110011
68.16093786451813230087
68.26283117814303125215
68.05439678571033823573
67.84596239327764521931
67.35092489166119378297
66.85588739004474234662
66.05225321927061088445
65.24861904849647942228
64.87591793671776940755
64.50321682493905939282
64.71837097963097562570
65.25936901280257984581
65.80036704597416985507
66.25072309216598398507
66.70107913835781232592
66.57936804263337648990
66.45765694690895486474
65.94173713558819827085
65.73614503032504785551
65.53055292506188322932
65.32496081979873281398
65.43609168750853655183
65.85581922437603452636
66.27554676124353250088
66.57514596408773854819
66.87474516693191617378
66.58648910330548176262
66.29823303967904735146
65.18510436507496308423
64.16261767745065469626
63.14013098982635341372
62.11764430220205213118
61.09515761457775084864
61.16136524169638732928
61.22757286881503091536
61.29378049593367450143
61.64889413229731474075
61.68728479568797951060
61.72567545907864428045
61.76406612246930905030
61.38145957875772751322
60.99885303504615308157
60.61624649133457864991
60.23363994762300421826
60.50680428328733739818
60.77996861895167057810
61.05313295461600375802
61.32629729028034404337
62.42916638380363991701
62.83754987818032589075
63.24593337255700475907
63.65431686693369073282
63.30735824100847253249
62.96039961508324722672
62.61344098915802192096
61.90321371643074854774
61.73411198482901340867
61.56501025322728537503
61.39590852162555734139
61.22680679002382220233
61.93703406275109557555
62.64726133547836894877
63.35748860820564232199
63.74542787482138095356
63.30366238357815689142
62.86189689233492572384
62.42013140109170166170
61.46082312631170907480
60.96795096258861690330
60.47507879886553183724
59.98220663514244677117
60.64455341757350481657
61.30690020000454865112
61.96924698243559959110
62.63159376486665053108
62.92267051598438598603
62.50351999437484806776
62.08436947276531014950
61.66521895115577223123
61.03302162778197725856
60.40082430440818939132
59.76862698103439441866
59.13642965766059944599
60.03517330864112011568
60.93391695962164789080
61.83266061060216856049
62.73140426158269633561
62.68458778218194993315
62.63777130278119642526
62.59095482338044291737
62.26930312246722110103
62.32600978578162909116
62.39144055114440590160
62.46777644406763130291
62.55799159024964239961
63.52610696514468457963
64.49422234003971254879
65.46233771493474762337
66.43045308982979690882
65.56842792202638747767
64.70640275422297804653
63.84437758641957572081
62.98235241861618050052
62.48760906672998061140
61.99286571484376651142
61.49812236295755951687
61.47531964903762258245
62.20198627723494411157
62.82484338711837068558
63.36465288235066850575
63.31061207720375705321
63.24440445008511346714
63.17819682296646988107
63.11198919584782629499
63.11198919584782629499
63.35681262813545799872
63.60163606042308970245
63.84645949271072140618
64.54099510920045190687
65.13663430827631373177
65.73227350735217555666
66.19636074407752346360
66.66044798080287137054
66.51844171629795710032
66.37643545179301440839
65.98475961417990731661
66.11945789004198559269
66.25415616590404965791
66.38885444176611372313
66.86344556326145038838
67.27182905763814346756
67.68021255201480812502
67.55785759430068537768
67.43550263658656263033
66.86343549467034108602
66.29136835275409112000
65.86706634527129722301
65.57431630013900303311
65.28156625500669463236
64.98881620987440044246
65.13082247437931471268
65.52249831199243601532
65.91417414960554310710
66.21350365152125050372
66.51283315343695790034
66.47226980971940690779
66.43170646600185591524
66.51515105879224165619
67.12933410367344322367
67.74351714855463058029
68.71346589303186647157
69.68341463750908815200
70.50559824755288218512
71.44194097003071419749
72.37828369250854620987
73.31462641498639243309
73.88200230151122127609
74.44937818803603590823
75.01675407456086475122
75.82757519788199829236
76.63839632120311762264
77.44921744452423695293
77.99175216674646549109
78.41027895246074308488
78.82880573817502067868
78.86092290158715911730
78.53727436540324902126
78.21362582921935313607
77.08451929722735940231
75.84125365280132768930
74.59798800837531018715
73.35472236394927847414
72.62579297898344066198
71.89686359401761706067
71.16793420905179345937
70.28790592298705064422
69.42742487085840252803
68.56694381872975441183
67.97474916769999708777
67.03101229795211679630
66.08727542820420808312
65.52994818075848115768
64.97262093331272581054
64.95115838974376742954
65.73515384198287847539
66.51914929422200373210
67.30314474646111477796
68.10860274226918420482
68.33393500493286865094
68.55926726759653888621
68.37316397282380364686
68.18706067805106840751
67.98141014934225268007
67.77575962063343695263
67.81539581323362142484
68.20657422455195728617
68.59775263587030735835
69.27690360660342605570
69.95605457733654475305
70.09934084419288069512
69.90522242787223206051
69.71110401155156921504
69.51698559523090636958
69.30140463534128514311
68.81999105100533142831
68.33857746666936350266
68.26859943976981526248
68.19862141287026702230
68.01178299073772848260
67.82494456860518994290
67.39281942516365120355
67.26673640916608576390
67.12264153374030684063
66.60195045050775775053
65.99447752006976486427
65.27655496591579264987
66.15217763092201153086
67.02780029592824462270
67.90342296093446350369
68.47795176967227348541
69.43914621396838526834
70.40034065826449705128
71.31103129906306037356
72.22172193986160948498
73.30239133736269252495
74.12589216086351484591
74.94939298436435137774
74.65599118804827583062
74.40772812962697457806
74.28140649048205546023
74.17192773655645510189
74.07613382687154057749
73.73327290072143114230
73.39041197457132170712
73.04755104842118385022
72.69201707230158149287
72.33648309618196492465
71.98094912006234835644
71.66013650884733010571
71.85152162674054920899
72.04290674463378252312
72.41109525715219774611
72.72997280817260445929
73.04885035919301117247
73.36772791021341788564
73.19840570699291504297
73.02908350377239798945
72.85976130055189514678
71.77284840073517102610
70.68593550091844690542
69.59902260110169436302
68.73178477743900316455
67.86454695377629775521
67.61151557538630640920
67.35848419699630085233
66.59325508949804373060
65.90901095118948660456
65.22476681288094368938
64.58983363607039507315
63.95490045925985356234
63.81282630286462165259
64.08328604114613824549
64.35374577942765483840
64.45805674292716958007
65.47995840302289138890
66.50186006311862740858
67.52376172321436342827
67.91293616393531351605
68.30211060465624939297
68.07707860010449962829
67.99919518292628595191
67.92131176574810069724
67.76244337938018702516
67.60357499301227335309
67.81581002079575171138
68.02804504857925849137
67.74742105594744145947
67.46679706331562442756
66.63668356416081905991
65.97271883978804396520
65.30875411541526887049
64.64478939104247956493
63.47090539494177363622
62.92974861821584653399
62.38859184148991232632
61.84743506476398522409
62.23891448413164795284
62.63039390349931778701
63.02187332286698762118
63.41335274223465034993
62.98370523601847281725
62.55405772980229528457
62.12441022358612485732
60.89416213568768654341
60.21340355431222235438
59.53264497293676527079
58.85188639156129397634
58.17112781018582978732
59.14114208719183096719
60.11115636419783214706
61.08117064120382622150
62.05118491820982740137
62.12402550692827674084
62.19686609564673318573
62.26970668436518252520
62.14056624314196142222
62.46144931335118144489
62.78233238356040146755
63.10321545376961438478
63.97119832041491349628
64.96317873943810639048
66.10777153061870592410
67.44312978699609573141
69.02128045362391617346
68.73766785942400758813
68.45405526522412742452
68.17044267102423305005
67.88683007682432446472
66.99742121070114819759
66.10801234457798614130
65.21860347845479566331
64.11303174239623103858
63.00746000633766641386
61.90188827027910178913
60.79631653422053005897
60.38545030534461233174
60.77904515924991102338
61.11641217688303129307
61.40879692549840029869
61.71897720615032767455
61.79112033812416626688
61.86326347009800485921
61.93540660207183634611
61.35093975728194948260
61.00034675425968799800
60.64975375123743361883
60.29916074821517923965
60.95577072497891180092
61.61238070174263725676
62.26899067850637692345
62.92560065527010237929
63.46623860805438965826
63.40461020968842831280
63.34298181132245986191
63.28135341295649141102
62.46349632623711300994
61.64563923951773460885
60.82778215279836331320
60.00992506607898491211
60.74127792732011243970
61.47263078856124707272
62.20398364980237460031
62.93533651104350212790
63.01007939552090419966
63.08482227999829916598
63.15956516447569413231
62.51600797317638580353
61.98842280585651565161
61.46083763853664549970
60.93325247121677534778
60.40566730389691940672
61.40554834369115155823
62.40542938348539792059
63.40531042327963007210
64.49710723282419166935
64.69630407117196568834
64.89550090951975391818
64.15119212949667826251
63.40688334947361681770
62.66257456945055537290
61.91826578942749392809
61.17395700940442537785
61.84523066009235492402
62.51650431078027736476
63.18777796146819980549
63.98356377986827681070
64.77934959826833960506
64.74955427229423321478
64.71975894632012682450
64.16974684023355735007
63.52781896439667264076
62.88589108855978793144
62.24396321272291032756
62.88617814065212030528
63.52839306858133738842
64.17060799651054026072
65.32157343319028086626
66.47253886986999305009
66.92622195161544595976
67.37990503336088465858
68.12521832799637877542
68.74601945491973253866
69.36682058184308630189
70.12027022204190984667
70.87371986224071918059
72.14738628255199159867
72.67748917465434033147
73.20759206675666064257
73.73769495885899516452
73.95709361575765683483
74.17649227265631850514
74.39589092955498017545
74.10653907770311832337
73.37503834620238762909
72.64353761470165693481
71.91203688320092624053
70.46944123363115863867
69.02684558406137682596
67.58424993449159501324
66.00900577164634341898
64.43376160880112024643
63.80202306432671832681
63.91384804806126851418
64.02567303179580449068
63.81817284315927452099
63.58073970433117239054
63.34330656550307026009
63.10587342667496102422
62.95086350604003655462
63.23800246505399513808
63.52514142406795372153
64.41017579124518022127
65.71467486360140242141
67.01917393595761041070
68.32367300831381839998
69.79223836681509851587
71.26080372531637863176
71.78586346544682328386
71.97057326100566854166
72.15528305656452801031
72.65931802449446763603
72.24741826743455419546
71.83551851037464075489
71.42361875331474152517
70.92929577806162910747
70.95751106698619992130
70.98572635591075652428
70.41604623667205942184
69.46723879958678082858
68.51843136250153065703
67.56962392541626627462
66.45675020218592976562
65.17879658028464007202
63.90084295838336458928
62.96323928105366007912
62.02563560372395556897
61.74901003841478086542
62.38831919809545922817
63.02762835777613048549
63.66693751745680884824
63.98240289197730135129
63.77533000232013904451
63.56825711266297673774
63.36118422300582153639
63.11191443684997182118
62.86264465069412210596
62.61337486453827239075
62.36410507838242267553
62.99528786442885319730
63.62647065047528371906
64.25765343652170713540
65.09558904543335700055
65.27254654232444863737
65.44950403921555448505
65.62646153610664612188
65.19433639266510738253
65.08605503438374739744
64.97777367610238741236
64.86949231782102742727
64.94804938167220598189
65.44793065986864633032
65.94781193806507246791
66.81640760621382924000
67.68500327436260022296
67.83822626898005125895
67.99144926359750229494
67.76707750452290213161
67.33595292258310394118
66.90482834064330575075
65.66986811108229460388
64.43490788152126924615
63.80903029229290268631
63.18315270306452902105
62.55727511383615535578
62.85377708819048336863
62.96344064041226573636
63.07310419263405520951
62.80687414324837902768
62.17192970391036510591
61.53698526457235118414
60.90204082523433726237
60.56020122028371588385
60.59595636902513149380
60.63171151776654710375
60.66746666650796271369
62.03630446651833096894
63.40514226652870632961
64.77398006653908169028
65.68699661478140683357
66.60001316302373197686
66.59065014768337675832
66.58128713234302153978
65.61941488054944215946
65.03343623036333553955
64.44745758017722891964
63.86147892999112229973
64.33335950808560710357
64.51213525179269936416
64.69091099549979162475
64.86968673920688388534
64.68866224400662190419
63.97839074515862023418
63.26811924631061856417
62.55784774746262399958
61.93045465312812325465
61.30306155879361540428
60.67566846445910755392
60.04827537012459970356
61.21692520013917260258
62.38557503015373839617
63.55422486016830418976
64.64174273717449636933
64.67140138590011133601
64.70106003462569788098
64.73071868335131284766
64.13104571627081895713
63.89117298809768641377
63.65130025992456097583
63.41142753175142843247
63.82088769946631146013
64.60329071443572956923
65.38569372940517610004
65.93966107185997316265
66.49362841431478443610
66.20406206887371070025
65.91449572343265117524
64.81414361075658803202
63.79492345108891981909
62.77570329142122318444
61.75648313175354786608
60.73726297208586544230
61.34677680579243030934
61.95629063949900228181
62.56580447320556714885
63.34979992544467819471
63.48446248179577366955
63.61912503814687624981
63.14482664181240778589
62.89896391799254615762
62.65310119417267742392
62.40723847035282290108
62.33634464095173655096
63.07623657878563960821
63.81612851661954266547
64.55602045445343151187
65.55542283202160547262
66.55482520958975101166
66.55482520958975101166
66.40465759359298658637
66.23303746102524769412
65.82026762371472727864
65.33870281351912012724
64.76958076510612727361
65.06097183130745520430
65.07375192843619515770
65.08653202556494932196
65.09931212269368927537
64.83214198875239731024
64.66914575577644086479
64.50614952280048441935
64.34315328982452797391
63.84713260541155221972
63.22710674989534851420
62.42993064994593765960
62.42993064994593765960
62.42993064994593765960
62.84987352922900072372
63.17649576867139415981
63.43779356022530180326
63.65158266240577944473
63.21152685044914676382
62.77147103849249987206
62.33141522653586008573
61.89135941457922740483
61.17516384544248353450
60.45896827630573966417
59.74277270716898158298
59.35979655019901457536
59.03573980199366388888
59.40746043712198343201
59.72961832089985989569
60.01150646920551423591
59.06155306546006045210
58.11159966171460666828
57.16164625796915288447
56.21169285422369910066
55.80215298431284054459
55.95835860771528302848
56.11456423111772551238
56.27076985452016799627
56.55171287288059289722
57.25325178490557931354
57.95479069693056572987
58.65632960895555925163
59.35786852098054566795
59.03240450945989437059
58.66044563915058773773
58.23126232725524431544
58.86801591916957221429
59.62054289143195973111
60.35856067766061983093
61.09657846388927282533
61.83459625011791871430
61.74971150061811187015
62.36148734594838316525
62.97326319127864735492
63.58503903660891865002
63.30637479867689165758
62.96578517453774992418
62.54004814436382275744
61.99267196271161139975
61.99267196271161139975
62.76631524009994222979
63.36803778917975193963
63.84941582844360397075
64.24327058784129462765
65.21927327617274272598
66.19527596450420503515
67.17127865283565313348
68.01756503083936422627
68.20044098805161070231
68.42903593456691169195
68.72294372294372521992
68.72294372294372521992
68.72294372294372521992
68.41072145293999540172
68.16788190960376425664
67.97361027493477081407
67.32826796292579274450
66.68292565091682888578
66.03758333890785081621
65.39224102689885853579
64.94199620272712536462
64.37919017251246600608
63.65558241937933559029
63.65558241937933559029
63.65558241937933559029
63.65558241937933559029
62.86880670108693180964
62.25687003130396135475
61.76732069547757930650
60.88094180649019193652
60.28713916688756313533
59.54488586738427358114
59.23295598247796078795
58.81704946926956978359
58.23478035077780390338
57.36137667304015508307
57.36137667304015508307
57.36137667304015508307
57.36137667304015508307
59.49454351530287254946
60.91665474347801279009
61.93244847788883333806
62.89547525353511048252
63.85850202918138762698
64.82152880482765056058
65.78455558047393481047
65.78455558047393481047
65.78455558047393481047
65.78455558047393481047
65.78455558047393481047
65.78455558047393481047
66.22199421605557745352
66.56222426595240904135
66.83440830586987146944
67.05710433852961216417
66.96009368394720695505
66.84152510612425146519
66.69331438384556065557
67.06342156688576494616
66.16355072481839272314
65.26367988275102050011
64.36380904068364827708
63.54376643660975076955
62.90595552233006060305
63.13709910053184160006
63.32621657360602540621
63.48381446783451309557
62.11155086311148210143
60.73928725838845110729
59.36702365366542011316
57.99476004894238911902
57.59646323044302818062
58.17139544421100083582
58.66967002947658471612
59.10566029158398038135
59.59578816752787133737
60.29341623210829936852
60.99104429668872739967
61.68867236126915543082
61.78148919510131520383
61.41093583541752565225
61.04038247573372899524
60.66982911604993233823
61.53543944468843562845
62.40104977332692470782
63.26666010196542799804
64.13227043060392418283
65.00716064045994357912
65.08026254939446175740
65.15336445832897993569
64.95676981937930349886
64.76017518042962706204
64.35608035284343486637
63.95198552525722845985
64.19261157145743368346
65.03804884840587874351
65.88348612535435222526
66.81904163742728997022
67.75459714950024192603
68.22901959174446062661
68.70344203398869353805
69.27162385217050655228
69.83980567035231956652
70.39870760731662358012
71.16999061088361600014
71.94127361445060842016
72.98225316590179545528
73.93351611926968303123
74.88477907263754218548
75.83604202600542976143
76.08046757165635654019
76.32489311730729752981
76.56931866295823851942
76.87545910549852123950
77.18159954803881817043
77.48773999057911510135
77.83335300932780853600
78.08520665213887923528
78.33706029494997835627
78.68351262310962113133
78.81758388466656128912
78.95165514622350144691
79.27127952735659732753
79.68062050657297845646
80.08996148578938800711
80.06492275922562384949
80.10200056659240885892
80.13907837395919386836
80.17615618132597887779
80.06140085667891526100
79.94664553203185164421
79.67522645439612460905
79.36433480055201528103
79.05344314670790595301
77.60447686810493905796
76.06091190415341429798
74.51734694020188953800
72.97378197625036477802
71.24466389272268429522
70.47421283783626222430
69.70376178294984015338
69.36769043384354915815
68.61130316356116054521
67.85491589327877193227
67.09852862299639753019
66.34214135271400891725
65.09475316797488630982
64.00402873622444133161
62.91330430447398924798
61.82257987272353716435
62.43307411216251523456
63.04356835160147909392
63.65406259104045005870
64.11419454582180321722
64.57432650060314927032
64.07579142674326533324
63.57725635288337429074
62.89182908494163370960
62.62671773817599074619
62.36160639141034778277
62.09649504464470481935
61.59183665955153941240
61.57817918891510089452
61.56452171827866237663
61.55086424764222385875
62.52782200119764866031
62.94163570832251508591
63.35544941544738151151
63.76926312257224083169
62.94398415122159917701
62.11870517987095752233
61.29342620852031586764
60.46814723716966710754
59.79787514500151246466
59.12760305283335071636
58.45733096066519607348
57.78705886849704143060
57.35633381465640923125
58.09525007081404623932
58.83416632697167614197
59.57308258312930604461
59.32138361509507973324
58.21874941357057764435
57.11611521204608266089
56.01348101052158767743
56.30030177213021858051
56.36605490855482258894
56.43180804497943370279
56.49756118140404481665
56.59519963272801845733
57.59356896282219651084
58.59193829291638166978
59.59030762301055972330
60.58867695310473777681
60.03022093388641877709
59.39198548335121330410
58.65555996350289547081
57.79639685701319962163
58.01874625260213491629
58.16196831618380969076
58.30519037976549157065
58.44841244334718055597
58.91318741628743538286
59.37796238922771152602
60.58363969889163058724
61.78931700855554964846
61.68484031273553824803
61.23080778771769416835
60.67587914602922438689
59.98221834391863183100
59.98221834391863183100
59.98221834391863183100
61.34965593087278534767
62.44360600043611242427
63.33865605735156378842
64.73530700476725030512
65.08101143516157094382
65.42671586555589158252
65.77242029595022643207
66.11812472634456128162
66.22234500808964696716
66.34972535244475011496
66.50895078288863260241
66.50895078288863260241
66.50895078288863260241
65.96369433039792795626
65.52748916840536708150
65.17059403586600296876
64.19900186168111133611
63.38998638519861117402
62.40118969172002039159
61.16519382487176414998
61.22754366567183836878
61.31067678673860399385
62.76329337093412874538
64.21590995512967481318
64.96284345360321310636
64.96284345360321310636
64.96284345360321310636
64.96284345360321310636
64.96284345360321310636
65.52304357745836682625
64.58446133466820526792
63.64587909187805081501
62.70729684908788925668
61.74484719260838261334
61.02300995024874907813
61.26021937098133918198
61.44998690756741410723
61.60525125568329229964
60.82133193680719074337
59.86320832484749132618
58.66555380989787238377
58.66555380989787238377
58.66555380989787238377
58.66555380989787238377
58.66555380989787238377
58.66555380989787238377
59.05061160363595007539
59.56402199528671559392
60.28279654359779726747
61.36095836606441622507
63.15789473684210264537
63.15789473684210264537
63.15789473684210264537
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
73.71007371007371489213
73.71007371007371489213
73.71007371007371489213
73.71007371007371489213
74.15793238578048374166
74.45650483625166771162
74.66977087230250731409
74.66977087230250731409
74.66977087230250731409
74.66977087230250731409
74.66977087230250731409
74.66977087230250731409
74.66977087230250731409
74.66977087230250731409
74.66977087230250731409
74.66977087230250731409
72.88611602742318495984
71.10246118254384839474
69.31880633766452604050
67.53515149278517526454
65.57676532304337513324
63.61837915330155368565
61.65999298355974644892
61.65999298355974644892
61.65999298355974644892
61.65999298355974644892
61.65999298355974644892
61.65999298355974644892
60.69709817716154987011
61.75937740650113028096
62.60920078997279603072
63.30451083099506348617
63.96529818454171589792
64.62608553808836120425
65.28687289163501361600
64.30466182539034036836
63.23007129510051527177
62.15548076481069728061
61.08089023452086507859
60.19249672057969746675
60.19249672057969746675
60.19249672057969746675
60.19249672057969746675
60.91657916095631009057
62.12032792350259313707
62.07017078536859600035
60.92347403646991210735
59.77677728757123531977
58.76580827377149773838
57.75483925997176726241
56.74387024617202968102
57.27084542775079256671
57.89363427870751621640
58.64098089985559170145
59.55440454792545068585
60.69618410801277264000
60.69618410801277264000
60.69618410801277264000
60.69618410801277264000
59.42341491694664767920
57.72638932885848817023
55.35055350553504638356
55.35055350553505348898
55.35055350553505348898
55.35055350553505348898
55.35055350553505348898`