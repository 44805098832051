/*
# Copyright ©2020-2021. Biorithm Pte Ltd. All Rights Reserved. 
# This software is the property of Biorithm Pte Ltd. 
# It must not be copied, printed, distributed, or reproduced in whole or in part
# or otherwise disclosed without prior written consent from Biorithm.
# This document may follow best coding practices for JavaScript as suggested in 
# ES2021
#
# Filename: Login.js
# Original Author: PHAM DUY HOAN
# Date created: 15 JUL 2021
# Purpose: Login Component to Display Login Page
# Revision History Raises (if any issues):
*/
import React, { useState } from 'react'

import Amplify, { API } from 'aws-amplify';
import awsconfig from '../../aws-exports';

import SecondStep from './SecondStep.js'

import './Login.css'

Amplify.configure(awsconfig)

const formStyle = {
  margin: 'auto',
  padding: '10px',
  border: '1px solid #c9c9c9',
  borderRadius: '5px',
  background: '#f5f5f5',
  width: '220px',
  display: 'block'
};

const labelStyle = {
  margin: '10px 0 5px 0',
  fontFamily: 'Arial, Helvetica, sans-serif',
  fontSize: '15px',
};

const inputStyle = {
  margin: '5px 0 10px 0',
  padding: '5px',
  border: '1px solid #bfbfbf',
  borderRadius: '3px',
  boxSizing: 'border-box',
  width: '100%'
};

const submitStyle = {
  margin: '10px 0 0 0',
  padding: '7px 10px',
  border: '1px solid #efffff',
  borderRadius: '3px',
  background: '#3085d6',
  width: '100%',
  fontSize: '15px',
  color: 'white',
  display: 'block'
};

const Field = React.forwardRef(({ label, type }, ref) => {
  return (
    <div>
      <label style={labelStyle} >{label}</label>
      <input ref={ref} type={type} style={inputStyle} />
    </div>
  );
});

const Login = () => {
  // const usernameRef = React.useRef();
  // const passwordRef = React.useRef();
  const userToken = React.useRef()
  const measurementCaptureRef = React.useRef(0)
  const [isGetS3Token, setIsGetS3Token] = useState(false)
  const [s3Token, setS3Token] = useState(false)
  const [liveTraceUrl, setLiveTraceUrl] = useState('')

  async function handleSubmit(e) {
    e.preventDefault();
    // let userToken = await axios.get(`https://cors-anywhere.herokuapp.com/https://biorithm-test.oth.io/idp2/users/auth`, {
    //   auth: {
    //     username: usernameRef.current.value,
    //     password: passwordRef.current.value
    //   }
    // })
    // .then(res => {
    //   console.log('get userToken resp = ', res)
    //   return res.data.token
    // })
    // .catch(err => {
    //   console.log(err)
    // });
    const data = {
      userToken: userToken.current.value,
      measurementCapture: measurementCaptureRef.current.value
    };
    const postContent = {
      headers: { 'Content-Type': 'application/json' },
      body: data
    };
    let resp = await API.post('S3STS', '/s3-sts', postContent);
    console.log('S3STS resp = ', resp)
    if (resp.token !== undefined) {
      console.log('isGetS3TokenRef = ',)
      setIsGetS3Token(true)
      setS3Token(resp.token)
      setLiveTraceUrl('http://localhost:3000/trace/' + measurementCaptureRef.current.value)
    } else {
      setS3Token({})
      setIsGetS3Token(false)
    }
  };

  return (
    <div>
      <div className="step">1st Step</div>
      <form style={formStyle} onSubmit={handleSubmit} >
        {/* <Field ref={usernameRef} label="Username:" type="text" />
          <Field ref={passwordRef} label="Password:" type="password" /> */}
        <Field ref={userToken} label="UserToken:" type="text" />
        <Field ref={measurementCaptureRef} label="measurementCaptureRef:" type="measurementCaptureRef" />
        <div>
          <button style={submitStyle} type="submit">Send 1st API to get S3 Token</button>
        </div>
      </form>
      <SecondStep isGetS3Token={isGetS3Token} s3Token={s3Token} measCapture={measurementCaptureRef.current.value}/>
      <h3> Live Trace URL</h3>
      <a href={liveTraceUrl}>Live Trace URL</a>
    </div>


  );
};

export default Login