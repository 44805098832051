/*
# Copyright ©2020-2021. Biorithm Pte Ltd. All Rights Reserved. 
# This software is the property of Biorithm Pte Ltd. 
# It must not be copied, printed, distributed, or reproduced in whole or in part
# or otherwise disclosed without prior written consent from Biorithm.
# This document may follow best coding practices for JavaScript as suggested in 
# ES2021
#
# Filename: STG001A_rawONON_fSmoothedHR.js
# Original Author: PHAM DUY HOAN
# Date created: 15 JUL 2021
# Purpose: Fetal Heart Rate From STG001A
# Revision History Raises (if any issues):
*/
export const fHR = `132.45097657011422143114
132.39249931776538460326
132.35351448286618847305
132.66431926846570377165
133.04960515176267676907
133.49810715507160807647
133.87351259294450755988
134.24891803081743546500
134.62098613416307557600
134.99305423750868726529
135.36512234085432737629
135.44087073498837980878
135.36443683502506019067
135.36443683502506019067
135.36443683502506019067
135.44156558862442807367
135.59512824218705873136
135.67156214215037834947
135.67156214215037834947
135.67156214215037834947
135.67156214215037834947
135.67156214215037834947
135.74869089574971781076
135.67156214215037834947
135.59443338855103888818
135.51799948858771927007
135.44225109445366683758
135.51868499441698645569
135.59511889438030607380
135.59511889438030607380
135.51799014078096661251
135.59511889438030607380
135.67224764797964553509
135.74868154794296515320
135.82442994207701758569
135.52277081688848170415
135.14736537901558222075
134.69886337570662249163
134.25036137239766276252
133.72473061548936357212
133.19909985858106438172
132.75059785527213307432
132.37852975192649296332
132.15923150631243743192
132.01367957338277392410
131.94122420588917066198
131.79694626887129516035
131.72449090137766347652
131.58021296435981639661
131.43593502734194089498
131.29165709032406539336
131.21983452079979315386
131.14801195127557775777
131.07618938175130551826
131.07618938175130551826
131.00436681222706170047
131.00436681222706170047
131.07618938175130551826
131.14801195127557775777
131.21983452079979315386
131.36411245781766865548
131.65523327295596800468
131.79951120997384350630
131.94378914699171900793
131.94378914699171900793
131.73018695970529279293
131.37723913951106169407
130.95551174409945360821
130.46132898119418541683
129.82030334016855022128
129.19031006009689122038
128.56031678002523221949
128.07460143697142029851
127.73066571167973393131
127.52607561929596613481
127.39026510212951848189
127.39026510212951848189
127.52841717993675274556
127.80237977491046308387
128.14631550020212102936
128.49025122549377897485
128.83418695078543692034
129.17812267607709486583
129.52205840136875281132
129.73018360949396310389
129.80015673981191071107
129.94071146245056525004
130.15431364973699146503
130.58721408263741636802
131.09451927744260046893
131.67689654143424604626
132.33502219955988721267
133.06958175764884799719
133.80414131573780878171
134.46811928150609105614
134.98907665230865404737
135.36786453109652939020
135.67224764797964553509
135.97939182145466929796
136.21078760079564062835
136.36574948017334918404
136.36574948017334918404
136.21356718607597713344
136.06138489197860508284
135.83207384428192199266
135.60276279658523890248
135.37136701724421072868
135.06422284376918696580
134.68200660110775856992
134.38034747591922268839
134.08402776670760658817
133.64343012047814340804
133.06105285648655467412
132.47867559249493751850
131.82054993436926793038
131.23817267037765077475
130.87264709333462064933
130.58152627819634972184
130.50907091070271803801
130.50907091070271803801
130.65085052846487201350
130.79263014622702598899
130.93440976398915154277
131.07618938175130551826
131.07618938175130551826
131.59253542211482113089
132.03705889295406450401
132.55340493331760853835
133.14618487364441534737
133.73896481397122215640
134.33174475429805738713
134.92452469462489261787
135.59443338855103888818
135.82582916789201021857
136.13577213838544821556
136.44571510887885779084
136.67922417940900459143
136.91273324993909454861
137.14624232046924134920
137.37975139099933130638
137.22618873743670064869
136.84397249477524383110
136.38320906096134876861
135.92244562714745370613
135.46168219333355864364
134.92651399761490438323
134.31759948921188652093
133.63558841537283683465
133.26352031202719672365
133.04227267200201367814
132.82102503197683063263
132.59977739195167600883
132.45162631736252478731
132.37788000467816118544
132.30478343924212936145
132.30478343924212936145
132.30478343924212936145
132.32566817222388522168
132.35351448286618847305
131.97210440982613022243
131.49323224953803901371
131.01436008924994780500
130.72705880597087002570
130.43975752269182066811
130.15245623941274288882
130.19278857788171421817
130.29423585717489686431
130.43601547493705083980
130.50659706725778619330
130.50659706725775777159
130.43601547493705083980
130.36543388261634390801
130.29485229029560855452
130.22427069797487320102
129.94314544730485749824
129.66202019663484179546
129.18045718067449456612
128.63706751594591537469
128.09367785121733618325
127.42007759255483279048
126.74647733389234360857
125.94480986555819868045
125.28248803013192969047
124.62016619470566070049
124.29324915317411637261
124.09874035273149672776
123.84046254912593099107
123.71239533945430366657
123.71239533945430366657
123.97067314305985519240
124.22895094666542092909
124.61963699135989713795
124.94655403289144146584
125.34051677277098235663
125.79824851581346933926
126.39179077602238976397
126.85726582655968286417
127.19253028316305176304
127.66594681757366913644
128.21870785897547762033
128.77146890037727189338
129.25718424343108381436
129.88717752350274281525
130.38136028640798258493
130.73126511229534685299
131.08116993818268269933
131.29292268626278428201
131.29292268626278428201
131.36474525578705652151
131.58211975824096384713
131.72896263636135927300
131.87580551448178312057
132.16692632962008246977
132.60752397584954564991
133.12387001621306126253
133.64021605657654845345
134.08473952741582024828
134.38371106532542853529
134.76249894411327545640
135.21911994867951989363
135.67574095324570748744
135.98288512672075967203
136.29282809721416924731
136.68204219610569793986
137.07125629499719821069
137.46047039388872690324
137.69612183921759651639
137.85394015876812545685
137.97788855718312106546
138.14315308840312468419
138.24884792626727403331
138.24884792626727403331
138.24884792626727403331
138.24884792626727403331
138.24884792626727403331
  nan
  nan
  nan
  nan
  nan
140.84507042253520125996
140.51599315519283095455
140.84714009088327202335
141.18037876484493153839
141.38032196922191019439
141.51361743880656263173
141.80319635561409086222
141.85030865950358247574
141.93335410196968382479
142.01562341880526219029
141.93257797633916084123
141.76569993081483289643
141.59882188529047652992
141.43194383976617700682
141.09499391052565897553
140.92811586500130260902
140.84507042253520125996
140.92811586500130260902
141.01116130746743237978
141.09420674993356215055
141.17725219239969192131
141.22470673095176607603
141.28797944902120775623
142.08564570545948413383
142.88331196189778893313
143.10793011104425431768
143.10793011104422589597
142.99720285442273848275
142.88647559780125106954
142.22395735901068292151
141.72706867991774970506
140.64647285600500481451
138.88995778191201679874
137.12723552562752615813
135.46419730598552177980
133.88420452880964717224
132.30421175163377256467
131.11752636170871255672
129.93084097178368097047
128.83319432619182975941
128.34327271364983857893
128.20629892130210691903
128.13810470417172382440
128.06991048704134072977
128.13869006225868929505
128.56409324994658049945
129.28581614684605938237
130.15206701309691084134
131.08651209647817381665
131.87013671653897972647
132.57935657469499801664
133.21483012016665270494
133.86148094787969853314
134.08272858790485315694
134.00765651871844852394
134.00765651871847694565
134.00765651871844852394
134.15847698203890558943
134.46013610722744147097
134.91267029869970883738
135.37343373251360389986
135.91346829472558965790
136.45350285693757541594
136.92272207615678780712
137.55419467024620416851
138.18566726433562052989
138.65920193759669132305
139.13711187812344860504
139.45864150171942696943
139.70089999691731463827
139.94315849211523072881
140.02390784612038032719
139.94240382525535437708
139.86089980439032842696
139.94240382525538279879
139.94240382525535437708
140.02467314209096116429
140.27382050445089589630
140.52296786681080220660
140.68674120451143494392
140.44671560178048252965
139.97318092851941173649
139.94264736290068640301
139.90193594207573823951
139.15503026559258614725
140.71087305366287978359
140.05566524358576430132
140.27816930428483033211
141.26583446384765352377
142.56484505078392999167
142.50988072734762113214
142.46865748477040369835
143.19571478765709571235
142.02883269660441101223
142.60486956296384164489
142.68950057176544987669
142.43559608499106161617
142.18169159821667335564
142.01481355269234541083
141.84793550716801746603
141.59878814480811115573
141.10738228725026033317
140.53134542089082970051
139.87603742613327995059
139.23281272630481453234
138.51245927287698123109
137.79871736195198650421
137.00922705689293934483
136.22693399948303749625
135.68689943727105173821
135.07246011315433520394
134.53729191743568094353
134.01166116052738175313
133.49070378972481876190
132.90315972391988452728
132.39136405224900272515
131.95464044976452555602
131.51791684728004838689
131.15559800670033041570
130.65272444348195790553
130.15854168057671813585
129.60100376799869081879
128.98703251094937627386
128.43898720587083062128
128.02446988825843732229
127.74576308781252009794
127.67518149549178474444
127.74515462580973235163
127.95327983393494264419
128.22724242890865298250
128.70065896331925614504
129.10814954575911883694
129.31273963814291505514
129.31273963814294347685
129.10461443001770476258
128.89648922189249447001
128.48550779825285417246
128.14449950493116148209
127.73700892249129879019
127.32951834005140767658
126.99140026020145910479
126.92320604307106179931
126.92320604307107601016
126.99198561828841036458
127.33299391161011726581
127.67400220493179574532
128.08149278737167264808
128.48898336981153533998
128.89647395225142645359
129.17279337750699141907
129.31094545531425410445
129.38031795790416822456
129.45029108822211583174
129.52026421854009186063
129.73201696662019344330
129.94376971470029502598
130.29980039979827211027
130.65895452001433341138
131.08808177054837074138
131.51720902108240807138
131.87636314129844095078
132.23551726151453067359
132.45289176396843799921
132.67026626642234532483
132.67026626642234532483
132.38169373852701937722
131.95256648799301046893
131.52343923745897313893
131.09431198692493580893
130.66518473639092690064
130.30603061617483717782
130.01745808827951123021
129.87318015126163572859
130.01620074622729816838
130.37223143132527525268
130.80135868185931258267
131.15738936695728966697
131.51342005205526675127
131.79947760683529622838
132.08805013473062217599
132.30542463718455792332
132.67419971388977728566
132.97051942310133654246
133.34592486097426444758
133.79442686428319575498
134.09074657349481185520
134.53924857680374316260
134.91465401467664264601
135.44502133192725068511
135.75216550540227444799
136.05930967887729821086
136.21427155825494992314
136.36923343763265847883
136.67637761110768224171
136.90988668163780062059
137.14339575216791899948
137.30121407171844793993
137.45903239126897688038
137.61685071081950582084
137.77466903037003476129
138.01249247024827582209
138.25031591012648846117
138.57184553372246682557
138.89337515731847361167
139.13563365251638970221
139.29638812684922299923
139.45714260118211313966
139.53714772150982526000
139.61789707551497485838
139.69864642952012445676
139.69864642952012445676
139.69864642952012445676
139.69864642952012445676
139.78015045038517882858
139.78015045038517882858
139.86089980439032842696
139.86089980439032842696
139.86089980439032842696
139.86089980439032842696
139.86089980439032842696
139.94316912122593521417
140.10848388052764335043
140.35530266069437743681
140.60212144086113994490
140.84894022102787403128
141.01271355872847834689
141.09498287556408513410
141.17725219239969192131
141.26029763486579327036
141.17725219239969192131
141.01193743309798378505
140.68436929892607167858
140.11897772487591851132
140.01525019663887405841
139.87694682565614812120
139.23381865021079306644
138.47996321814392217675
138.04725946876592956869
137.72424814183537478129
137.30337272095999878729
137.09675688178978703036
136.99202537919643418718
137.14912819758029627337
137.38695163745850891246
137.62477507733674997326
137.70478019766446209360
137.86553467199732381232
138.26194059165905514419
138.73547526492012593735
139.13188118458185726922
139.29263565891471898794
139.45488903378492295815
139.61714240865512692835
139.77939578352530247685
139.86089980439032842696
139.94240382525538279879
140.02390784612038032719
140.10541186698543469902
140.26918520468603901463
140.35145452152164580184
140.43372383835725258905
140.51599315519283095455
140.59826247202840932005
140.68053178886401610725
140.76280110569962289446
140.92811586500130260902
141.01116130746743237978
141.01116130746743237978
141.01116130746743237978
140.92889199063185401428
140.76511865293122127696
140.60134531523061696134
140.43757197752998422402
140.27225721822827608776
140.02543843806151357967
139.86166510036090926405
139.69789176266027652673
139.69789176266027652673
139.69789176266027652673
139.69789176266027652673
139.69789176266027652673
139.61638774179525057662
139.61638774179525057662
139.61638774179525057662
139.78016107949588331394
140.02930844185578962424
140.35995982508075030637
140.69061120830571098850
141.19133447524683333540
141.52198585847179401753
141.85263724169675469966
142.18328862492171538179
142.35016667044604332659
142.26633406738784515255
142.18250146432961855680
142.09866886127139196105
141.76171893203087392976
141.59484088650657440667
141.26418950328161372454
140.93353812005665304241
140.60288673683169236028
140.35606795666495827390
140.10924917649822418753
139.86243039633146167944
139.69865705863082894211
139.53488372093022462650
139.53488372093022462650
139.53488372093022462650
138.90063424947146586419
138.51659328510191926398
138.25938612616931777666
138.00217896723674471104
137.74497180830417164543
137.30999902944265045335
136.99930418739873516643
136.76628305586575606867
136.61272040230312541098
136.30277743180968741399
135.91356333291815872144
135.44860083989260601811
134.98363834686705331478
134.75841312164180862965
134.53318789641659236622
134.45743950228256835544
134.30661903896208286824
134.30661903896208286824
134.38305293892540248635
134.69019711240042624922
134.99734128587545001210
135.61178060999219496807
136.38697440844180164277
137.09419539666527043664
137.79498443321011791340
138.34495300643450832467
138.81848767969557911783
139.13706047357896977701
139.29487879312952713917
139.29487879312949871746
139.05485319039854630319
138.73332356680256793879
138.49329796407161552452
138.17472517018819644363
137.77831925052646511176
137.30478457726542274031
137.06913313193655312716
136.91131481238602418671
136.60137184189258618972
136.29142887139914819272
135.98148590090573861744
135.75009012156473886535
135.67296136796539940406
135.82792324734307953804
135.90575637312139178903
135.90575637312139178903
136.05931902668405086843
136.05931902668405086843
136.05931902668405086843
136.05931902668405086843
135.98288512672075967203
135.90505200094239057762
135.82721887516407832663
135.90576606631651657153
136.14141751164538618468
136.53063161053688645552
136.91984570942841514807
137.30905980831994384062
137.69827390721144411145
137.93392535254031372460
138.16957679786918333775
138.24884792626727403331
138.40960240060013575203
138.57035687493299747075
138.73111134926588761118
138.97336984446377528002
139.13412431879663699874
139.45865213083013145479
139.78317994286362591083
140.10770775489712036688
140.27148109259775310420
140.43525443029835741982
140.35450507629317939973
140.11224658109529173089
139.95149210676243001217
139.70696941505661925476
139.46244672335083691905
139.21792403164505458335
139.13565471480944779614
138.89113202310366546044
139.05338539797386943064
139.29564389317178552119
139.53790238836967319003
139.78242508007545552573
139.94467845494565949593
140.10693182981586346614
140.02542780895083751602
140.02542780895083751602
139.86317443408063354582
139.46309761933218851482
138.68080456192231508794
137.81624218767686329556
137.03394913026696144698
136.25165607285708802010
135.62593910549864517634
135.08097149214540877438
134.53600387879211552900
134.22885970531709176612
134.30393177450349639912
134.30393177450349639912
134.22952701259873720119
134.00827937257355415568
133.71195966336199489888
133.27136201713250329703
132.75956634546162149491
132.31896869923215831477
131.87837105300269513464
131.51284547595963658750
131.22172466082139408172
131.00624869836207153639
130.79077273590277741278
130.71957471046135879078
130.57901998782267583010
130.36726723974257424743
130.15551449166247266476
129.94376174358237108208
129.73200899550226949941
129.73200899550226949941
129.73200899550226949941
129.59145427286358653873
129.59145427286358653873
129.52208177027367241863
129.45270926768375829852
129.38333676509387260012
129.45330989541182020730
129.45330989541179178559
129.45330989541179178559
129.52328302572976781448
129.52328302572976781448
129.52328302572976781448
129.52328302572976781448
129.52328302572976781448
129.38393739282190608719
129.10523059237596044113
128.82652379193004321678
128.48258806663838527129
128.20862547166467493298
128.00403537928087871478
127.79944528689708249658
127.66247149454935083668
127.52549770220163338763
127.45730348507125029300
127.52608306028858464742
127.73067315267238086562
127.93526324505617708382
128.20922584002988742213
128.55316156532154536762
128.82948099057713875482
129.10580041583273214201
129.38211984108832552920
129.59204706631692260999
129.80197429154549126906
130.08309954221550697184
130.43913022731348405614
130.87203066021390895912
131.30493109311433386210
131.81223628791954638473
132.31954148272473048564
132.83133715439561228777
133.49531512016386614050
134.16522381409004083253
134.61372581739897213993
134.91538494258753644317
135.14061016781275270660
135.21568223699915733960
135.29075430618559039431
135.29075430618559039431
135.13857201208821834371
135.13857201208821834371
135.21570076568758622670
135.29282951928689726628
135.44639217284958476739
136.44217386159368743392
136.28721198221603572165
136.60578477609942638082
136.37647372840271486893
136.14716268070603177875
136.70734193806839584795
137.26752119543073149543
137.03821014773401998355
136.88602785363667635465
137.27881317289256912773
137.44258651059320186505
138.23207681565224902442
138.46138786334893211460
137.90120860598659646712
137.65668591428081413142
138.20165352763407895509
138.72629717700141327441
138.69919855835348698747
138.27002418551714413297
137.62626262626264406208
138.04713804713807689950
139.10422049956932255554
139.21188630490956938957
139.53488372093022462650
139.53488372093022462650
138.89186582359874932990
137.43762654308673631931
136.71050690283075823572
138.21455936841843481488
139.15373647038626359063
138.28287563008379379426
138.00869859201989697794
137.80306581347190331144
137.56741436814306212000
137.79672541583977363189
138.34169302919303845556
137.67395798645799231963
137.25074563542875694111
137.55512875231184466429
137.55512875231187308600
137.55512875231187308600
137.55512875231187308600
137.55512875231184466429
137.23947218665529135251
136.84668686739939857944
136.13294495647437543084
135.97938230291171635145
136.53956156027408042064
137.09974081763641606813
137.20489859677928734527
137.34510896896975395975
137.54140349003642995740
137.66480949579539583283
137.91050974149567309723
138.03280398143695606450
137.01311116389825883743
136.05512134923901612638
136.05512134923901612638
136.05512134923901612638
136.11682435211849906409
136.11682435211849906409
136.49386666464465633908
136.99431498292977948950
136.63070800167574248007
136.18035190615836427241
136.20653540008379422943
135.92178990364476476316
135.61740678676162019656
135.61740678676162019656
135.38175534143277900512
134.54801777170200693945
134.25431476418319221011
134.63653100684462060599
134.71436413262296127868
135.25439869483494703672
135.55878181171806318162
135.55878181171806318162
135.25439869483494703672
135.54810170235373334435
136.78941252658412963683
137.35480410063428280409
137.04765992715925904122
137.37218773919272507555
137.93236699655508914475
137.70305594885837763286
137.77812801804481068757
138.39816770058450856595
138.07059956641256803778
137.74303143224068435302
138.20799392526623705635
137.88346611323274260030
137.96651155569884394936
138.83904730322404930121
139.14619147669907306408
139.39071416840485539979
139.63523686011063773549
138.23157255751016236900
136.90791337523739912285
136.59513317630921847012
135.87477972288138516888
134.63346889865098887640
133.95754964847074575118
132.51570894910213382900
131.07386824973349348511
131.56378986227548466559
131.10221082235989342735
129.62975279909971959569
130.18479149322581633896
131.26078755815450449518
131.85887368255640694770
132.74053512456268322239
132.81050825488063082958
132.59690606759426145800
133.99293019089111567155
135.01688621084230135239
134.01334551340727330171
133.85109213853706933151
134.16674870419362264329
133.78796082540577572217
134.22086125830617220345
134.80323852229781778078
134.95680117586044843847
135.48243193276874762887
135.40997656527514436675
134.49623387521666018074
134.25841043533841911994
134.71503143990460671375
134.71503143990463513546
134.56555460881344288282
134.88121117447002461631
134.35558041756169700420
134.42803578505532868803
135.02612190945725956226
134.94828878367894731127
134.79332690430123875558
135.09498602948980305882
134.94814315136937921125
134.10685582880449828735
134.86813803104166709090
135.08938567106685013641
134.78500255418373399152
134.11509386025755929950
133.52231391993075249047
133.07381191662179276136
133.07381191662179276136
132.24436469711321251452
130.65363527536743504243
130.28810969832440491700
129.17203826975296010460
128.42149241822460226103
128.00339337520685489835
127.44101639517123203404
127.29673845815335653242
127.98190774064408969934
128.33317848183591536326
127.88798042677073851792
128.46557651212486916847
129.33197064015610067145
129.88943603084643996226
131.00436681222706170047
131.87386482204271942464
131.30674635099413194439
130.82787419070604073568
131.90468942681332009670
132.55077856847768202897
132.88050680407519621440
133.11602697235915115925
133.21759502938567720776
133.36574610397480000756
133.87305129877998410848
133.87305129877998410848
133.21492564065434294207
132.77040216981507114724
132.40162709310985178490
132.47737548724387579568
132.62819595056436128289
132.40694831053917823738
132.18570067051402361358
132.11572754019607600640
132.04575440987809997750
131.69280658968384045693
131.40923127207949505646
130.68113248363587786116
129.54554311275239797396
128.78202184521452977606
128.92886472333495362363
129.43173828655335455551
129.43173828655335455551
129.50111078914324025391
129.50111078914324025391
129.50111078914324025391
130.12035411966323295019
130.73959745018319722476
129.89571559364310360252
129.53339675306341405303
129.67395147570209701371
129.88570422378219859638
130.09745697186230017905
130.09745697186230017905
129.88570422378219859638
130.02998216080007409801
130.72702113921974387267
130.87004173418540631246
131.01306232915106875225
130.59496328613334981128
131.11130932649683700220
131.83940811494045419749
131.97996283757913715817
131.76448687511987145626
132.21298887842880276366
132.73394624923136575489
132.95324449484542128630
133.66246435300143957647
133.36349281509183128946
133.36349281509185971117
133.95103688089679394579
134.23960940879214831511
133.86420397091922041000
133.63694960763547214810
133.71135436954020292433
133.78575913144496212226
133.93391020603411334378
133.93391020603411334378
133.78308974271362785657
133.93124081730275065638
133.57208669708668935527
133.06478150228150525436
133.29203586556528193796
133.51929022884905862156
133.59436229803549167627
133.51861390390143924378
133.44420914199668004585
133.51928121118308467885
134.10165847517470183448
134.60896366997988593539
134.38170930669610925179
133.93320730338714952268
133.63688759417556184417
133.86619864187224493435
134.24498652066014869888
134.52914798206276714154
134.52914798206276714154
134.63014584090817038486
134.78964019718438294149
134.98638971799087471481
135.37208108261836514430
135.75777244724582715207
136.14346381187331758156
136.37277485957002909345
136.52495715366737272234
136.60139105363069234045
136.60139105363069234045
136.37208000593398082856
135.98638864130651882078
135.60069727667902839130
135.21500591205153796182
134.91062279516842181692
134.68336843188464513332
134.45611406860086844972
134.00761206529193714232
133.71129235608032104210
133.34251727937510167976
132.97374220266988231742
132.60496712596469137679
132.31126411844587664746
132.01756111092709033983
131.72385810340830403220
131.72385810340830403220
131.79695466884433585619
132.09065767636312216382
132.23620960929281409335
132.45550785490684120305
132.60105978783650471087
132.74661172076616821869
132.89216365369583172651
133.03900653181625557409
133.18715760640537837389
133.18715760640537837389
133.41038075018093422841
133.55985758127212648105
133.78308072504765391386
134.15848616292055339727
134.53389160079348130239
134.83555072598198876221
135.13993384286513332881
135.60069727667902839130
135.98638864130651882078
136.53135625465975522275
137.07632386801304846813
137.46910918726894124120
137.86189450652483401427
138.09540357705495239315
138.09540357705498081486
137.78546060656151439616
137.39976924193405238839
136.85480162858078756472
136.46201630932489479164
136.06923099006894517515
135.67644567081305240208
135.44293660028296244491
135.21362555258625093302
135.13787715845219850053
135.21362555258625093302
135.36580784668359456191
135.44293660028296244491
135.59789847966061415718
135.75286035903829429117
135.90782223841597442515
136.21496641189099818803
136.52211058536602195090
136.67567323892868103030
136.75280199252802049159
136.83063511830636116429
136.90918230945879940919
137.06700062900932834964
137.22481894855985729009
137.46264238843809835089
137.86271920318654338189
138.34062914371327224217
138.90080840107563631136
139.38315453265965970786
139.86999891555737463023
140.27757217005697043533
140.68514542455659466214
141.01271355872847834689
141.26186092108844150061
141.34413023792401986611
141.34413023792399144440
141.34413023792401986611
141.51259384978385469367
141.68105746164368952122
141.84952107350352434878
141.84952107350352434878
141.68264302797919640398
141.68264302797919640398
141.68264302797919640398
141.68264302797916798227
141.43113397365323180566
141.01585158162666289172
140.60056918960006555608
140.35375040943333146970
140.18997707173269873238
140.02620373403209441676
139.86243039633146167944
139.69865705863082894211
139.45413436692507502812
139.29337989259218488769
138.97480709870879422851
138.65623430482537514763
138.49547983049251342891
138.33472535615965171019
138.17397088182678999146
137.93394527909580915548
137.93394527909583757719
138.01395039942352127582
138.41402721417196630682
138.97941878822209105238
139.47082464577994187493
139.87839790027953768003
140.28597115477913348514
140.85664814073504658154
141.52438318347009271747
142.19211822620513885340
142.69759989406998101913
143.30160357561624095979
143.82177465410427430470
144.42577833565053424536
145.02978201719676576431
145.46115366400118773527
145.63619100530064542909
145.98969779263097734656
146.25353456561407483605
146.16473513393046346209
146.07593570224679524472
145.98713627056318387076
145.81039537271942663210
145.81039537271942663210
145.89919480440306642777
145.89919480440303800606
146.07941828151467689167
146.34844119030992715125
146.61746409910517741082
146.88648700790042767039
147.24345138285582379467
147.42192082888664117490
147.42192082888664117490
147.33225081453946359034
147.06322790574418490905
146.70626353078881720648
146.26220328069408083138
145.73188060881062710905
145.03148605321092645681
144.25476691485135916082
143.32319509913321553540
141.26427840607018993069
139.08603593656556540736
137.20386014134629704131
135.47936181358710427958
133.84112590761662886507
132.58843784782166608238
131.50262783355103124450
130.66134051098617874231
130.94739806576617979772
131.51467739410747981310
132.02198258891266391402
132.53377826058354571614
132.97050186306802288527
133.19174950309320593078
133.26484606852923775477
133.33794263396526957877
133.41103919940132982447
133.41103919940132982447
133.33663443749657062654
133.33663443749657062654
133.48745490081702769203
133.63827536413748475752
133.93724690204709304453
134.23621843995670133154
134.68875263142891185453
135.14128682290117922093
135.67645501861983348135
135.90576606631651657153
135.98219996627983618964
135.90645157214578375715
135.83070317801175974637
135.75495478387770731388
135.60139213031504823448
135.44782947675238915508
135.29144915982163865920
135.29144915982161023749
135.36857791342094969878
135.67572208689597346165
135.98286626037099722453
136.36855762499848765401
136.67850059549192565100
137.06771469438342592184
137.46412061404515725371
137.94203055457194295741
138.41994049509870023940
138.90228662668272363589
139.55151080379107497720
140.12218778974698807360
140.69286477570290117001
141.18427063326072357086
141.51492201648568425298
141.76406937884559056329
141.92938413814732712126
142.09626218367162664435
142.18089319247326329787
142.26552420127489995139
142.35015521007653660490
142.69358539072078428944
143.03701557136506039569
143.38044575200933650194
143.72061266057249895312
143.89068454428866061789
143.97612541920321405087
143.97612541920324247258
143.97612541920324247258
143.63269523855893794462
143.28926505791466183837
142.77895683174608620902
142.19197114301618967147
141.77275189694006485297
141.43580196769954682168
141.18429291337361064507
140.93278385904764604675
140.76590581352331810194
140.59902776799896173543
140.59902776799896173543
140.68053178886401610725
140.51827841399381213705
140.27375572228802980135
140.02923303058224746565
139.78471033887643670823
139.54018764717068279424
139.37641430947002163521
139.13189161776423929950
138.98117386489479940792
138.99655469422910414323
138.99655469422910414323
138.88988120045883079001
138.67751286882449335280
138.46514453719009907218
138.57381656501010525062
138.79015439817035826309
138.98969175383894025799
139.13934477059041228131
139.30159814546058782980
139.62612595749408228585
140.02992489792563901574
140.43372383835725258905
140.43372383835725258905
140.35221981749219821722
140.27071579662720068882
140.18921177576214631699
140.02695840089194234679
139.70243058885844789074
139.37790277682498185641
139.05337496479148740036
138.89112158992128343016
138.81037223591610541007
138.64961776158324369135
138.48886328725035355092
138.48886328725035355092
138.64961776158324369135
138.81037223591610541007
138.97112671024899555050
139.05187606425414514888
139.13262541825932316897
139.37488391345721083781
139.61714240865512692835
140.02854314588523720886
139.94779379188005918877
139.78703931754719747005
139.46846652366377838916
139.07206060400204705729
138.75348781011865639812
138.35341099537023978883
137.95333418062182317954
137.30411000351344341652
136.91132468425755064345
136.52211058536602195090
136.13989434270456513332
135.83551122582144898843
135.52836705234642522555
135.37340517296874509157
135.29557204719043284058
135.21773892141209216788
135.29486767501143162917
135.37130157497475124728
135.52212203829520831277
135.59719410748164136749
135.52212203829520831277
135.29486767501143162917
134.99048455812831548428
134.61169667934044014146
134.15916248786817277505
133.78375704999527329164
133.63293658667481622615
133.55786451748838317144
133.63293658667481622615
133.78375704999527329164
133.93457751331573035714
134.08405434440692260978
134.15780065709128621165
134.23154696977564981353
134.15714220787089061560
134.23221427705732367031
134.53935845053234743318
134.84930142102578543017
135.08069720036678518227
135.46638856499424719004
135.92300956956046320556
136.30179744834833854839
136.52702267357355481181
136.52702267357355481181
135.85110342339331168660
135.16909234955423357860
135.09125922377592132761
134.93487890684514241002
135.01342609799758065492
135.49133603852433793691
135.96055525774357874980
136.34624662237104075757
137.25998931242952494358
138.10127663499437744576
138.49768255465613719934
138.89408847431784010951
139.13411407704882094549
139.05261005618376657367
139.21486343105397054387
139.78554041700988364028
140.28157216304163057430
140.77760390907337750832
141.19288630109994642226
141.60816869312651533619
141.93882007635147601832
142.26947145957643670044
142.51861882193634301075
142.51861882193634301075
142.43398781313473477894
142.01870542110813744330
141.52267367507641893098
141.02664192904467199696
140.53523607148684959611
140.12383533425671089390
139.79318395103177863348
139.54403658867181547976
139.16745684609213640215
139.10621903361911222419
139.14968506256514046981
139.21488410598419704911
139.53689616588985700218
139.86089980439032842696
140.18691588785046064913
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
132.74336283185840557053
132.74336283185840557053
132.94001966568339412333
132.89085545722713277428
132.86135693215337028050
132.84169124877087142522
132.82764433206907028762
132.81710914454276917240
132.59973464208883342508
132.38236013963492609946
132.09123932449665517197
131.87386482204271942464
131.72831288911305591682
131.72831288911305591682
131.72831288911305591682
131.80205920179741951870
132.09318001693571886790
132.60952605729920605881
133.20230599762606971126
133.79508593795287652028
134.31604330875543951151
134.54071194831217894716
134.84027013438779363241
135.14165749459868948179
135.59373853491501904500
135.74660633484162985951
135.74660633484162985951
135.74660633484162985951
  nan
  nan
  nan
136.36363636363637397153
136.67496886674967981889
136.77874636778744843468
136.52071135955100089632
136.12239868405336551405
135.85685690038826578530
135.66718419777035364859
135.52492967080689822978
135.22054655392378208489
134.76392554935756606938
134.30730454479135005386
133.85880254148241874645
133.27642527749077316912
132.69404801349918443520
132.11167074950756727958
131.38994785260808839666
130.74329702489501414675
130.10227138386937895120
129.66937095096895404822
129.38331339618892457111
129.45389498850965992460
129.52447658083036685639
129.59505817315110220989
129.80498539837969929067
130.08611064904971499345
130.43601547493705083980
130.57779509269920481529
130.71957471046135879078
130.79077273590280583448
131.07934526379813178210
131.36791779169345772971
131.65649031958881209903
131.87386482204271942464
132.16498563718101877384
132.53051121422404889927
132.89603679126707902469
133.33663443749657062654
133.71203987536947010994
134.16457406684170905464
134.61710825831394799934
135.06964244978621536575
135.37130157497475124728
135.52212203829520831277
135.59719410748164136749
135.30087439827002526727
134.99921527308151780744
134.76990422538480629555
134.54059317768809478366
134.31128212999141169348
134.15909983589403964288
134.08335144175998721039
134.08335144175998721039
134.23019431988041105797
134.15644800719604745609
133.93322486342049160157
133.71000171964493574706
133.56052488855377191612
133.41104805746257966348
133.26157122637141583255
133.18649915718498277784
133.26090391908974197577
133.48412706286529783029
133.78309860077487769559
134.08207013868448598259
134.30729536390970224602
134.53252058913494693115
134.68199742022611076209
134.83281788354656782758
135.13720100042968397247
135.52289236505717440195
135.83283533555061239895
136.14277830604405039594
136.61199752526323436541
137.08121674448247517830
137.62618435783574000197
138.17615293106013041324
138.57255885072186174511
138.73183509944766456101
138.97186070217861697529
139.16574381690659834021
139.21188630490956938957
139.27648578811368906827
139.14847759558938378177
138.89127043665675387274
138.89127043665678229445
138.89127043665678229445
138.76207147024851451533
138.78320770668858585850
138.79830501843147771979
138.72962288191095581169
138.89037735624381753041
139.21490516827731198646
139.54555655150227266859
139.95695728873238294909
140.36835802596249322960
140.77975876319260351011
141.19115950042274221232
141.68256535798056461317
142.01321674120552529530
142.18009478672985324010
142.01321674120552529530
141.84633869568119735050
141.59719133332126261848
141.43031328779693467368
141.26343524227260672887
141.01428787991267199686
140.84740983438834405206
140.84740983438834405206
141.44565952671706554611
142.31293212784106572144
143.17192058303623980464
143.85896970704857267265
144.45721939937732258841
145.04979694238153342667
145.64804663471028334243
146.07941828151467689167
146.07941828151467689167
145.54909560963119474764
145.10932640051214548294
145.02052696882850568727
145.38099990932641958352
145.38099990932641958352
144.86579565946379943853
144.18527665029949957898
143.50475764113514287601
143.08553839505907490093
142.91546651134285639273
142.48409486453849126519
141.77688128603776362979
141.51808211419512417706
141.68654572605495900461
141.93569308841489373663
142.10100784771660187289
142.10100784771657345118
141.60960199015877947204
141.52810301922005464803
141.30659637956625829247
140.99648708405092634166
140.53132314077794262630
139.98173259212731522894
139.21788190705888155208
138.24884792626727403331
  nan
  nan
  nan
  nan
126.58227848101266488356
126.58227848101266488356
126.76106700994064624410
127.12208230873753223023
127.22958074060704802832
127.39217198469390268656
127.50830858761308661542
127.59541103980248522021
127.93641933312417791058
128.34740075676381820813
128.76191807437621150711
129.32919740271751152250
129.96467094818913778909
130.75517342030596523728
131.54567589242276426376
132.33617836453962013366
132.98852875884915647475
133.57090602284080205209
134.08270169451168385422
134.23085276910080665402
134.52982430701041494103
134.75913535470709803121
135.22409784773265073454
135.68906034075820343787
136.15402283378378456291
136.53971419841124657069
136.76902524610795808258
136.84409731529439113729
136.99765996885702179497
136.92053121525771075540
136.60775101632947325925
136.29497081740129260652
135.90575671850979233568
135.67224764797964553509
135.51868499441698645569
135.59443338855103888818
135.36512234085432737629
135.28868844089103617989
135.21225454092771656178
135.13582064096439694367
135.13582064096439694367
135.05938674100110574727
135.13582064096439694367
135.13582064096439694367
135.21156903509842095445
135.13582064096439694367
135.06007224683034451118
135.06007224683034451118
135.06007224683034451118
135.21363490039300359058
135.29076365399234305187
135.52215943333334280396
135.91137353222484307480
136.37633602525039577813
136.84129851827597690317
137.47277111236539326455
137.86198521125692195710
138.09763665658579157025
138.33328810191466118340
138.73336491666307779269
139.39484265793709028003
140.22967869613916036542
140.97741885920191862169
141.09034785510928600161
141.56393117658294045214
140.94398118588219404046
140.42502905402682245040
139.78781579999113660051
138.59164355118733169547
137.32446501504796287918
136.38528791308016252515
136.91022054772184901594
137.19288745360285020070
137.81570758314944669110
138.35195811939999543938
138.53067848160659991663
138.61963062535326685065
138.41546446841698525532
138.25554743234366128490
138.04232471757919142874
137.87301188331724688396
137.61904263192437269936
137.19576054626952554827
136.98630136986301408797
135.15981735159817844760
133.33333333333334280724
133.33333333333334280724
133.18584070796458718178
133.18584070796458718178
133.18584070796458718178
133.18584070796458718178
133.18584070796458718178
133.13667649950835425443
133.03834808259585997803
132.74336283185840557053
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
143.54066985645931708859
143.54066985645931708859
143.54066985645931708859
143.54066985645931708859
143.54066985645931708859
143.42674868990658865187
143.34537642808319901633
143.28434723171565678967
143.19890635680110335670
143.11346548188652150202
143.19972790367526727096
143.37308620060338171243
143.54644449753146773219
143.80524366937410718492
143.94068664254996292584
144.12127727345117023106
144.23519844000392708949
143.89985303423597429173
143.33556989953032712037
142.65515893130549329726
141.97474796308068789585
141.29433699485585407274
141.32506513305705198036
141.43194383976614858511
141.26187195604998692033
141.34491739851608826939
141.26264808168050990389
140.85884914124892475229
140.69507580354832043668
140.53130246584768769935
140.36598770654597956309
140.03533632332101888096
139.70468494009605819883
139.44686365121489757257
139.26143122279182762213
139.44346122133558196765
139.35117624532969671236
139.25889126932378303536
139.07345884090071308492
138.79914386635104506240
138.70685889034513138540
138.57033639143730852084
138.41106014271150570494
138.25178389398573131075
137.93612732832917799897
137.54334200907328522590
136.92330232653358734751
136.46253889271969228503
135.84249921017999440664
135.38173577636609934416
134.92097234255220428167
134.61102937205879470639
134.45746671849610720528
134.38103281853278758717
134.45610488771922064188
134.60692535103970612909
134.68199742022611076209
134.75706948941251539509
134.87542635524698653171
134.83214155859894844980
134.77154284329171218815
134.68064477033087200653
134.73114369975357362819
135.55765828773601811008
136.58616859340929750033
137.61467889908257689058
136.99479295809570089659
136.62286139350356961586
136.27390915826345008099
136.02465756166336063870
135.91346725834733888405
135.52777589371984845457
135.14208452909235802508
134.78885676195093878960
134.73114369975357362819
132.95550914403602860148
131.90150584788025867056
130.84750255172448873964
129.87670416713299914591
129.00690364138691279550
128.32450941249240372599
128.62597701194999899599
129.14065023943857113409
130.24715093100149942984
131.03765340311832687803
131.67867904414396207358
132.10780629467797098187
132.53693354521200831186
132.97365714769645705928
133.26222967559181142860
133.26222967559181142860
133.33663443749657062654
133.04031472828495452632
132.89347185016455910045
132.96721816284892270232
133.11536923743804550213
133.19044130662445013513
133.41366445040000598965
133.78906988827293389477
134.01632425155668215666
134.31264396076829825688
134.53389160079348130239
134.60829636269824050032
134.46014528810911770051
134.38507321892268464580
134.31000114973625159109
134.15781885563890796220
134.15781885563890796220
134.31000114973625159109
134.53725551302002827470
134.76450987630380495830
135.06348141421341324531
135.21430187753387031080
135.36512234085432737629
135.36512234085432737629
135.21294004675698374740
135.13650614679366412929
135.06007224683034451118
135.06007224683037293289
135.13650614679366412929
135.21294004675698374740
135.21294004675698374740
135.28868844089100775818
135.21361637170460312518
135.06279590838414605969
135.06279590838414605969
135.06279590838414605969
134.96510870460448927588
134.83485909956496584527
134.77480389245096148443
134.68472108177991231059
134.93677096618273480999
135.44087073498837980878
135.74660633484162985951
  nan
  nan
  nan
  nan
133.33333333333334280724
133.03834808259585997803
132.74509549118724294203
132.30991332144719763164
132.04880401960318181409
131.97049457773948688555
131.91455926212256599683
131.94506314290350701413
131.79822026478308316655
131.79822026478308316655
131.79822026478308316655
132.01559476723701891387
132.23296926969092623949
132.37852120262061816902
132.52407313555028167684
132.59716970098631350083
132.67026626642234532483
132.38169373852701937722
132.02566305342904229292
131.73709052553368792360
131.73709052553368792360
131.88524160012281072341
132.10846474389836657792
132.33168788767392243244
132.55491103144947828696
132.99163463393392703438
133.49450819715229954454
133.85682703773201751574
133.93057335041640953932
133.93057335041640953932
133.85550128122997648461
133.70602445013878423197
133.55654761904762040103
133.40839654445846917952
133.40839654445846917952
133.40839654445846917952
133.40839654445846917952
133.26024546986934637971
132.96654246235056007208
132.67542164721226072288
132.31310280663257117340
132.02453027873721680407
131.59162984583679190109
131.22931100525710235161
130.86699216467738438041
130.57841963678203001109
130.50659706725778619330
130.57841963678203001109
130.72144023174769245088
130.93691619420701499621
131.22297374898704447332
131.43844971144631017523
131.80076855202602814643
132.08934107992138251575
132.30671558237528984137
132.37917094986892152519
132.45162631736252478731
132.45162631736252478731
132.45162631736252478731
132.52472288279858503302
132.59912764470334423095
132.74727871929246703075
132.89542979388158983056
133.11667743390677287607
133.41299714311836055458
133.78506524646400066558
134.31069600337227143427
134.84106332062287947338
135.22327956328430786925
135.60549580594576468684
135.98771204860719308272
136.36992829126864990030
136.67707246474367366318
136.90846824408470183698
137.06484856101548075458
137.22266688056600969503
137.54123967444940035421
137.85981246833279101338
138.17838526221621009427
138.33620358176673903472
138.41475077291914885791
138.18335499357817752752
137.64818679785952326711
136.67142863316308876165
135.74939195659305823938
134.97419815814345156468
134.19900435969384488999
133.51081872289273633214
132.54275009427362874703
131.81465130583001155173
131.17362566480437635619
130.96369843957580769711
130.60766775447783061281
130.24534891389811264162
130.09850603577768879404
130.09850603577768879404
130.68088329976933437138
131.64037786755392289706
132.81657170429821235302
133.92398596582518166542
134.58307755700215579964
135.27446004838657245273
136.18380814683865764891
136.78718706880167133022
136.78718706880167133022
136.37766150416828736525
135.96440421017703670259
135.65305878280355500465
135.66642271880900239012
135.60001177084976120568
135.29006880035632320869
135.21294004675698374740
135.21294004675695532569
135.13786797757055069269
134.91264275234533442926
134.39168538154277143803
133.87072801074020844680
133.42620453990093665197
132.98168106906166485714
132.46072369825910186591
132.16440398904751418740
131.87070098152872787978
131.65140273591467234837
131.65140273591467234837
131.65140273591467234837
131.87070098152872787978
132.23947605823391882041
132.60825113493913818274
132.90457084415072586125
133.12386908976475297095
133.34316733537878008065
133.56246558099283561205
133.78176382660689114346
133.78176382660689114346
133.63228699551569889081
133.48281016442450663817
133.40773809523810200517
133.55721492632926583610
133.78244015155451052124
133.78244015155451052124
133.78244015155451052124
133.49131933641621117204
133.20019852127791182284
132.90907770613964089534
132.54355212909661076992
132.10295448286711916808
131.51541041706218493346
131.08250998416176003047
130.72019114358204205928
130.64899311814065185899
130.50721350037846946179
130.36543388261631548630
130.22365426485418993252
130.08187464709203595703
130.01129305477130060353
130.01129305477130060353
130.08249108021271922553
130.37106360810807359485
130.44262557067639818342
130.53804152076747868705
130.67162385089500276081
132.22053365668554647527
134.02428546043736901083
135.06282435801423957855
137.68909114552349137739
139.88060892812495694670
140.20637393495280775824
140.42355060617137496592
140.42355060617137496592
140.98532494758910615928
141.50943396226415416095
141.50943396226415416095
141.50943396226415416095
141.50943396226415416095
141.50943396226415416095
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
142.18009478672985324010
142.18009478672985324010
142.18009478672985324010
142.18009478672985324010
138.65761496093250570993
138.10051043057600850261
137.82195816539774568810
137.65482680629079936807
136.61606812291742585330
136.36924595828705264466
135.85126265451154381481
135.38967861124385194671
135.01761050789821183571
134.48724319064763221832
133.88377930796099235522
133.28031542527440933554
132.60439617509416621033
132.23232807174849767762
132.23232807174849767762
132.60439617509416621033
132.97646427843977789962
133.27543581634935776492
133.42227869446978161250
133.56912157259020546007
133.78841981820423256977
133.93526269632462799564
134.00900900900899159751
133.71003747109941173221
133.41106593318983186691
133.11209439528022357990
133.03834808259585997803
132.96460176991149637615
132.89085545722713277428
132.81710914454276917240
132.67026626642234532483
132.67026626642234532483
132.81841734101146812463
133.04164048478702397915
133.34061202269663226616
133.56383516647218812068
133.78705831024768713178
133.93520938483683835329
134.23152909404842603180
134.60693453192135393692
134.68200660110773014821
134.68200660110775856992
134.60625820697370613743
134.60625820697370613743
134.45678137588254230650
134.53185344506894693950
134.38237661397778310857
134.08071748878921880532
133.93124065769805497439
133.78176382660689114346
133.55854068283133528894
133.18976560612611592660
132.75686517322566260191
132.60738834213452719268
132.60738834213449877097
132.60738834213449877097
132.60738834213449877097
132.60738834213452719268
132.75553941672364999249
133.12431449342884093312
133.70669175742045808875
133.85616858851165034139
134.08139381373683818310
134.30661903896208286824
134.45609587005327512088
134.38234955736888309730
134.23419848277976029749
134.01097533900423286468
133.78775219522867701016
133.71268012604224395545
133.63693173190821994467
133.56118333777413909047
133.63693173190821994467
133.78508280649734274448
133.85882911918170634635
133.93257543186606994823
134.00632174455046197181
133.93191698264570277388
133.70869383887011849765
133.59148610412833590999
133.33421126562720360198
133.21533923303837809726
132.63562908811078955296
132.63562908811081797467
132.75467670715843837570
132.87372432620605877673
133.11076604554867230945
133.34716303496767864090
133.51601802740984226148
133.71840766587550319855
134.38238563164372862957
134.76117351043160397239
135.22193694424549903488
135.68270037805942251907
136.14346381187331758156
136.68843142522655398352
137.23339903857981880719
137.70261825779905962008
138.17615293106013041324
138.57255885072186174511
138.89408847431784010951
139.29788741474942526111
139.78473179764711176176
140.19613253487725046398
140.60753327210736074449
141.01893400933749944670
141.34958539256243170712
141.76486778458900062105
142.09864615575054358487
142.35015521007653660490
142.69032211863964221266
143.03375229928394674062
143.29008660478882575262
143.46015848850504426082
143.63023037222120592560
143.71567124713578778028
143.88737454383908698219
144.05907784054238618410
144.05907784054235776239
144.14701930670250362709
144.41085607968562953829
144.76095527445744437500
145.11105446922931605513
145.46115366400118773527
145.72499043698428522475
145.98882720996738271424
146.25266398295053704715
146.34146341463414842110
146.34146341463414842110
146.34146341463414842110
146.34146341463414842110
146.34146341463414842110
146.34146341463414842110
146.34146341463414842110
146.34146341463414842110
146.52168689174578730672
146.88572038282694620648
147.24975387390810510624
147.61378736498932084942
147.88546087380359495000
148.15713438261789747230
148.15713438261789747230
148.15713438261789747230
147.88811147382264721273
147.43527855105782009559
146.98244562829302140017
146.52961270552819428303
146.16913976503025196507
145.63362948323285195329
145.19808941354642684018
144.76254934386003014879
144.00052631383067591742
143.07774880946845996732
142.15497130510624401722
141.15292267234590894986
139.91736496905548392533
138.78041070710122539822
137.73941134771621364052
136.47518884455564602831
135.62624873342167575174
134.86496653118450694819
133.95940639192949106473
132.99133776331041190133
131.91284247992979317132
130.77725310904628486242
129.65148618032611693707
128.74894239538147644453
127.84639861043680753028
127.08287734289896775408
126.53011630149717348104
126.25379687624158009385
126.45838696862537631205
126.93585068138321503284
127.20292092085617241537
127.40237486029309366131
127.60182879973001490725
127.66831108884821333049
127.82345860425326122822
127.66955917752862603720
127.59095394870880113558
127.43170179681411013917
127.66752613388568704522
128.06059553421900432113
128.37369564276039568540
128.60553131763404621779
128.55548092857083020135
128.89648922189252289172
129.10461443001770476258
128.96764063767000152438
128.76129434273235574437
128.48497491747676235718
128.27862862253911657717
128.21043440540873348255
128.14224018827835038792
127.93589389334070460791
127.72776868521549431534
127.86474247756322597525
128.07108877250087175526
128.48918781551861911794
129.05156479555421356054
129.68703834102586824883
129.97667603716030271244
130.05461228530927542124
130.13321751412908611201
130.13321751412911453372
130.21318680592108307792
130.13181763398799262177
130.13181763398802104348
130.04827870206108286766
130.38516421828575175823
130.95244354662702335190
131.45094329975097480201
131.87634648743886600641
132.08994867472523537799
132.16114670016668242170
132.01686876314880692007
131.87259082613093141845
131.72703889320126791063
131.58148696027160440281
131.43593502734194089498
131.36347965984830921116
131.50903159277797271898
131.65330952979584822060
131.72513209932009203840
131.72513209932009203840
131.65330952979581979889
131.58148696027160440281
131.43846636530591354131
131.29544577034025110152
130.93629165012418980041
130.44210888721895003073
130.02038149180734194488
129.67047666591997767682
129.47992093073324326724
129.22584661715094966894
128.98405941884200842651
127.58846092179423692414
126.61443494776828799786
126.07801181720287786447
125.64707020979069795885
125.32250026496758721350
125.71201263215384358318
125.99023575157259813295
126.26709730826705424533
126.86063956847596045918
127.26128059411701087811
127.66532462593769992054
128.07281520837759103415
128.34445099910936960441
128.48142479145712968602
128.61839858380483292422
128.55020436667444982959
128.41439384950800217666
128.34619963237761908204
128.27742005716027051676
128.27742005716027051676
128.48734728238883917584
128.69727450761743625662
128.97839975828745195940
129.39649880130519932209
129.95403671388325506086
130.44395832642524624134
130.79386315231261050940
130.93441787495129347008
130.65571107450534782402
130.17414805854497217297
129.62138701714317789992
129.06862597574138362688
128.37756007569714711281
127.68649417565291059873
127.13373313425111632569
126.65217011829074067464
126.58397590116035758001
126.78683211667480179585
127.05846790740659457697
127.46944933104623487452
127.94691304380407359531
128.42437675656191231610
129.04896036748795040694
129.67354397841398849778
130.16346559095597967826
130.44217239140192532432
130.65209961663049398339
130.58212648631254637621
130.51215335599459876903
130.30402814786938847647
129.81410653532736887428
129.32418492278540611551
128.83426331024341493503
128.48736229266711461605
128.27743506743848911356
128.48918781551861911794
128.77276313312296451841
129.26694589602820428809
129.75686750857019546856
130.24678912111218664904
130.66488816412993401173
131.08298720714768137441
131.29291443237627845519
131.29291443237625003349
131.22109186285200621569
131.07681392583413071407
131.07681392583413071407
131.29611217144815782376
131.36793474097243006327
131.36793474097243006327
131.43913276641384868526
131.36793474097243006327
131.15618199289230005888
130.87505674222228435610
130.52210892202805325724
129.94986285621973820525
129.45994124367774702478
128.90718020227595275173
128.28793687175596005545
127.67396561470667393223
127.13517251125838924963
126.53661967899506635149
125.93806684673172924249
125.33951401446840634435
124.87793497455280089525
124.55101793302125656737
124.29058318060789645187
124.09607438016527680702
123.96694214876032447137
124.16145094920294411622
124.48836799073447423325
124.81528503226601856113
125.14220207379756288901
125.40263682621093721536
125.46693503271961844803
125.46693503271961844803
125.46693503271961844803
125.33672443878570845754
125.13893962865913067617
124.94115481853253868394
124.80874657744361400091
124.80874657744361400091
125.07136541246646288528
125.46532815234598956522
125.92690719226159501432
126.39238224279888811452
126.86180284365724446616
127.33122344451561502865
127.80463997892624661290
128.14275805877622360640
128.41439384950800217666
128.61898394189179839486
128.82533023682944417487
128.89410981204679274015
128.89410981204679274015
128.89410981204679274015
128.89410981204679274015
129.10403703727536139922
129.31396426250395848001
129.52389148773255556080
129.52389148773255556080
129.45511191251517857381
129.52448441510512111563
129.73441164033368977471
129.80438477065166580360
129.73380317833090202839
129.66322158601019509661
129.59263999368948816482
129.73319471632814270379
130.15492211173975078964
130.65779567495815172151
131.24533974076308595613
131.90346539888872712254
132.48584266288034427816
133.22040222096933348439
134.03209053265763373020
134.85103037780356771691
135.52694962798381084212
136.06211782370246510254
136.37206079419587467783
136.84128001341511549072
137.38624762676835189268
137.77903294602427308746
138.09468951168082639924
138.17323670283329306585
138.09540357705498081486
137.86189450652483401427
137.47620314189737200650
136.78175869745291493018
135.79619343787021534808
134.59887543020735733990
133.19521112760688197341
131.81616107534813409075
130.66053703836817589945
129.58204175498755716944
128.72334506574034662663
128.15109899993203157464
127.86997374926203008272
127.80060124667213017347
127.93757503901986183337
128.20921082975164040363
128.55314655504329834912
128.75773664742709456732
128.91461695876165549635
128.94115898407744680298
128.94115898407744680298
128.94115898407744680298
128.84945288378764871595
128.84945288378764871595
128.66365870657716641290
128.75615537669705190638
128.83532529825265555701
128.96467586973727748045
129.17460309496587456124
129.38453032019447164203
129.59265552831965351288
129.73200116122751524017
129.73200116122751524017
129.66262865863762954177
129.52447658083036685639
129.38513094792250512910
129.17520372269393647002
129.03464900005525350934
129.03464900005525350934
129.10523059237596044113
129.38635584304597614391
129.66748109371599184669
130.01738591960335611475
130.44278910729121889744
130.86819229497911010185
131.15176761258345550232
131.36536979986988171731
131.50839039483554415710
131.58021296435978797490
131.65203553388403179270
131.79631347090190729432
131.79631347090190729432
131.79631347090190729432
131.86876883839553897815
132.01432077132523090768
132.15987270425489441550
132.45357571177368072313
132.74727871929246703075
133.19578072260139833816
133.72141147950972595027
133.94467164681537951765
134.45724536488276612545
134.88628015102321455743
135.31531493716369141111
135.66318135645866505001
136.01104777575366711062
136.09919492272436514213
136.09919492272433672042
136.05512134923901612638
136.05512134923901612638
136.05512134923901612638
136.21008322861666783865
136.28791635439500851135
136.36574948017334918404
136.28862072657400972275
136.21149197297464183976
135.90983284778610595822
135.46133084447717465082
134.94037347367458323788
134.26445422349434011267
133.58853497331409698745
132.98507109062748554607
132.60966565275458606266
132.23426021488165815754
132.23426021488165815754
132.38110309300208200511
132.67480610052086831274
132.89410434613489542244
132.96655971362855552798
132.89410434613489542244
132.74855241320523191462
132.67545584776920009062
132.60170953508483648875
132.60170953508480806704
132.52730477318007729082
132.52730477318004886911
132.67414765130047271668
132.89344589691449982638
133.11274414252858377949
133.33399178255370998158
133.48214285714286120310
133.55654761904762040103
133.63095238095237959897
133.78042921204354342990
133.92990604313473568254
134.07938287422592793519
134.30460809945111577690
134.37968016863754883161
134.37968016863754883161
134.45475223782398188632
134.52982430701038651932
134.60557270114443895181
134.83488374884115046370
135.06419479653783355388
135.29559057587880488427
135.60273474935385706885
135.98495099201531388644
136.29209516549033764932
136.52140621318704916121
136.67496886674967981889
136.44565781905299672871
136.21634677135631363853
135.90920259788126145395
135.67780681854026170186
135.44641103919929037147
135.21501525985831904109
135.29284838563663129207
135.37068151141494354306
135.67782568488999572764
135.98496985836504791223
136.19294441212247193107
136.36924595828705264466
137.65624724528834121884
138.71863038314316440847
139.47248581521003529815
139.96972270427946227755
140.71557803788354590324
141.95867026055705650833
141.35272362565035564330
140.85995667829806166083
139.81146388668599911398
139.38697136779146035224
138.67948383630059083771
138.89333380745799217948
139.15205022353649155775
139.32452783425546272156
139.43319986207549732171
139.86995737430470398976
140.63086002224591197773
140.75637058510568522252
140.85050350725049383982
141.09732228741722792620
141.26263704671893606246
141.51178440907887079447
141.76093177143877710478
141.76093177143877710478
141.67709916838057893074
141.51178440907887079447
141.34646964977716265821
141.34646964977716265821
141.34646964977716265821
141.34646964977716265821
141.34646964977716265821
141.43030225283536083225
141.59876586469522408152
141.84791322705513039182
142.09706058941503670212
142.18089319247326329787
141.93407441230652921149
141.60342302908156852936
141.35427566672166221906
141.02362428349670153693
140.60834189147010420129
140.38380576357585027836
140.08442425971688294339
139.66529015431427751537
138.19003147180222867973
136.71477278929017984410
135.10910767339410654131
133.75080349557975978314
132.39249931776538460326
132.64851133623307077869
132.83137706370999353567
132.89412159741289087833
133.11536923743807392384
133.56387124074700523124
134.08950199765530442164
134.38847353556488428694
134.53662461015403550846
134.31537697012885246295
134.02167396261006615532
133.73055314747176680612
133.29382954498728963699
132.55926998689830043077
131.47126224995440679777
130.46920086653710768587
129.61795994644023721776
128.85443867890239744156
128.09744682688742045684
127.41227754439668728992
126.79830628734740116670
126.52198686209180777951
126.72833315702943934866
127.14285047464181843679
127.55736779225421173578
128.10541309733275738836
128.71938435438204351158
129.33335561143135805651
129.94732686848067260144
130.22364629373626598863
130.29361942405421359581
130.29361942405421359581
130.43664001901987603560
130.72521254691523040492
131.08753138749491995441
131.37610391539027432373
131.51912451035593676352
131.58970610267667211701
131.38158089455146182445
130.90001787859108617340
130.34247996601305885633
129.63939012050531118803
129.06714405469702455775
128.56864430157307310765
128.35504211428667531436
128.35504211428667531436
128.70494694017403958242
129.25770798157583385546
129.74342332462964577644
130.22913866768345769742
130.58208648787774563971
131.00748967556558000069
131.36664379578166972351
131.87843946745255152564
132.31903711368204312748
132.91181705400884993651
133.73810606486580354613
134.48584788457029048914
135.08393400897219294166
135.53243601228115267077
135.90784145015402373247
136.06002374425142420478
136.28933479194810729496
136.36646354554744675625
136.28791635439500851135
136.28791635439500851135
136.36574948017334918404
136.67852967910155825848
136.99130987802976733292
137.06843863162907837250
136.99130987802976733292
136.53877568655749996651
135.71983584141159440151
134.90089599626566041479
134.08195615111975484979
133.48668589752722368758
132.37039085215985778632
131.70484993358041947431
130.72543718946181456886
130.06077085196324105709
129.96666206220228900747
129.87255327244130853614
129.59265030546984576176
129.31649661441488774472
128.84276253714361359926
128.42779378903830433956
127.81902755527207204977
127.28477292951744459515
126.74138326478886540372
126.19799360006030042314
125.99340350767648999408
125.92578720764043964664
125.79225922017425887134
125.72688265113660577299
125.66204862624033467000
125.66204862624033467000
125.53183803230641046866
125.40162743837248626733
125.07196290500165503090
124.74229837163080958362
124.41809461605450337629
124.28896238464955104064
124.22466417814084138627
124.16036597163216015360
124.16036597163216015360
124.16036597163216015360
124.22519999652843125659
124.42133654243309592857
124.81202258712758634829
125.13893962865913067617
125.46585667019067500405
125.79277371172220512108
126.19005454512910091580
126.65552959566639401601
127.12495019652476457850
127.46306827637474157200
127.73470406710652014226
128.00633985783832713423
128.27797564857010570449
128.54961143930188427476
128.61780565643226736938
128.68658523164961593466
129.11198841933750713906
129.46493623953176665964
129.67486346476033531872
129.74423596735024943882
129.67545639213290087355
129.60667681691558072998
129.53848259978516921365
129.33389250740137299545
128.77151552736575013114
128.28159391482378737237
128.00288711437784172631
127.79654081944019594630
127.72834660230981285167
127.66015238517942975704
127.66015238517942975704
127.72776868521549431534
127.72776868521549431534
127.72776868521549431534
127.59195816804904666242
127.39081388054681553967
127.18966959304458441693
126.98852530554233908333
126.92090900550627452503
126.85329270547022417759
126.78567640543414540844
126.65101440705009849808
126.51748641958391772278
126.51748641958393193363
126.45156046761317725213
126.45156046761317725213
126.38451476926518068922
126.31746907091719833716
126.25042337256920177424
126.25042337256920177424
126.25042337256920177424
126.25042337256920177424
126.31634932453994224488
126.31634932453994224488
126.31634932453994224488
126.31634932453994224488
126.38339502288793880780
126.45044072123593537071
126.51692301035410537224
126.58340529947228958463
126.58340529947228958463
126.51747934750154911399
126.12679330280705869427
125.79987626127551436639
125.40591352139597347559
125.01195078151644679565
124.68503373998490246777
124.61855145086671825538
124.81969573836896358898
125.08676597784193518237
125.61211402092048672330
126.07369306083607796154
126.53527210075168341064
126.99685114066727464888
127.45843018058288009797
127.65957446808511122072
127.65957446808511122072
127.52491246970106431036
127.52491246970106431036
127.39025047131700318914
127.39025047131700318914
127.39025047131700318914
127.39025047131700318914
127.25558847293294206793
126.98851823345995626369
126.98851823345995626369
126.92090193342389170539
127.12375814893835013208
127.19195236606873322671
127.26014658319911632134
127.32834080032949941597
127.59997659106127798623
127.89668573945557739080
128.11575361586272947534
128.31517552547595073520
128.34268735556290152999
128.38854040570780057351
128.48024650599757023883
128.75536480686696449993
  nan
  nan
  nan
  nan
126.58227848101266488356
126.05264551665695194060
125.70176367777129655678
125.65707589640379637785
126.05757561074005934643
126.32457542029756325519
126.75111390705309588611
127.00164526952983123920
127.27328106026160980946
127.60854551686497870833
128.00918654250600070554
128.34445099910936960441
128.41264521623975269904
128.48083943337013579367
128.27449313843249001366
128.13751934608475835375
128.06873977086743821019
128.20689184867467247386
128.34504392648193515925
128.48319600428919784463
128.62134808209643210830
128.75950015990369479368
128.82769437703407788831
128.89588859416448940465
128.96466816938180954821
128.89529566679189542810
128.82592316420198130800
128.68777108639474704432
128.48142479145710126431
128.27507849651945548430
128.20688427938907238968
128.13869006225868929505
128.13869006225868929505
128.34861728748728637584
128.55854451271585503491
128.83725131316180068097
128.97422510550953234088
129.11119889785726400078
129.24817269020496723897
129.31636690733537875531
129.11177681495158253711
128.69725949733918923812
128.28274217972682436084
127.93584116215049562015
127.79886736980276396025
127.73008779458541539498
127.66130821936809525141
127.79946029717532951508
128.14339602246698746058
128.48733174775864540607
128.83126747305030335156
129.10758689830589673875
129.31393319324354251876
129.31393319324354251876
129.31393319324354251876
129.24456069065362839865
129.10521505774576667136
128.96586942483790494407
128.82652379193004321678
128.75655066161209560960
128.75655066161209560960
128.96467586973727748045
129.17280107786251619473
129.38272830309108485380
129.59265552831965351288
129.80258275354827901538
129.94192838645614074267
129.94192838645611232096
129.87255588386619820085
129.73321025095836489527
129.59386461805050316798
129.38393739282190608719
129.17401016759330900641
129.03345544495465446744
129.10403703727536139922
129.38761235487970679969
129.59753958010830388048
129.66691208269821800059
129.73628458528810369899
129.66750501007078355542
129.73687751266066925382
129.73687751266066925382
129.73687751266066925382
129.59385691769500681403
129.66505494313645385773
129.87680769121652701870
130.08856043929665702308
130.36726723974257424743
130.50782196238125720811
130.64837668501994016879
130.57779509269920481529
130.64899311814062343728
130.64899311814062343728
130.57779509269920481529
130.43601547493705083980
130.43601547493705083980
130.57903606990271327959
130.72205666486840414109
130.93565885215480193438
131.15303335460870925999
131.29731129162658476162
131.51278725408587888523
131.72638944137230510023
131.79758746681369530052
131.24004955423566798345
130.68251164165761224467
130.19259002911562106419
129.97521552666171373858
130.27153523587330141709
130.56785494508488909560
130.93663002179010845794
131.37722766801954321636
132.15406382621165448654
132.85715367171937373314
133.49262721719105684315
133.85815279423405854686
133.78308072504765391386
133.70800865586122085915
133.48478551208569342634
132.97298984041481162421
132.61067099983509365302
132.25151687961903235191
131.68423755127773233653
130.96880714834736636476
130.25337674541694354957
129.46975212535616606147
128.76666227984841839316
128.55673505461984973408
128.34680782939125265329
128.07048840413568768781
128.00229418700527617148
127.93409996987489307685
127.86590575274450998222
127.79828945270844542392
127.79828945270844542392
127.59369936032464920572
127.45672556797691754582
127.52491978510730064045
127.80123921036289402764
128.21933825338064139032
128.63743729639838875300
129.12315263945220067399
129.68069055203025641276
130.38378039753797565936
131.01925394300963034766
131.58653327135093036304
132.16891053534254751867
132.53443611238557764409
132.97503375861504082422
133.41563140484450400436
133.93658877564706699559
134.31199421351999490071
134.76452840499220542370
135.21706259646447279010
135.76203020981773761378
136.38206989235743549216
136.84703238538298819549
137.31199487840854089882
137.62477507733674997326
137.93755527626495904769
138.25321184192151235948
138.56886840757806567126
138.64961776158324369135
138.81187113645344766155
138.97262561078630938027
139.05263073111399307891
139.13263585144170519925
139.05336472304364292540
138.81554128316537344290
138.49988471750884855282
138.02635004424774933796
137.54844010372102047768
137.07490543045992126281
136.68212011120402848974
136.28933479194813571667
135.97939182145466929796
135.74799604211369796758
135.22236528520539877718
134.55838731943717334616
133.81728060006958003214
133.15330263430132617941
132.56052269397451937039
132.03956532317192795745
131.52321928280844076653
131.00687324244492515390
130.86259530542704965228
130.86259530542702123057
130.86259530542702123057
130.72204058278836669160
130.30394153977061932892
129.54694968775561392249
128.86178040526490917728
128.30901936386311490423
127.89092032084536754155
127.54401930326903880086
127.19711828569273848188
126.92315569071902814358
126.85553939068296358528
127.05499333011988483122
127.18740157120882372510
127.18740157120880951425
127.25559578833919260887
127.39256958068692426878
127.46076379781732157426
127.66535389020110358160
127.86994398258489979980
128.14390657755859592726
128.62546959351897157831
129.25005320444500966914
129.52876000489095531520
129.73868723011955239599
130.01739403056546962034
130.22732125579403827942
130.29669375838398082124
130.29669375838398082124
130.29669375838398082124
130.08309157109755460624
130.01250997877684767445
129.94192838645614074267
129.94192838645614074267
130.01312641189753094295
130.22487915997766094733
130.43663190805773410830
130.50782993349918115200
130.79388748827921062912
131.22678792117963553210
131.58594204139569683321
131.87451456929105120253
131.94633713881529502032
132.01815970833956725983
132.16243764535741433974
132.30671558237528984137
132.30671558237528984137
132.23296926969092623949
132.23296926969092623949
132.08741733676123430996
132.08741733676123430996
132.15987270425489441550
132.15987270425489441550
132.30671558237528984137
132.45355846049568526723
132.52730477318007729082
132.82627631108965715612
133.27079978192892895095
133.71532325276817232407
134.24095400967647151447
134.61302211302211162547
134.83824733824732788889
135.06347256347254415232
135.36513168866108003385
135.51869434222373911325
135.67225699578639819265
135.74869089574971781076
135.67156214215037834947
135.59581374801632591698
135.44499328469586885149
135.36924489056184484070
135.14199052727803973539
134.76320264849016439257
134.31066845701792544787
133.78971108621533403493
133.19693114588852722591
132.61455388189691007028
132.10724868709172596937
131.45489829278216120656
130.80824746506908695665
130.23600139926080032637
129.66872207091952873270
129.17880045837753755222
128.69308511532372563124
128.34914939003206768575
128.07282996477647429856
127.79886736980276396025
127.59427727741896774205
127.38968718503517152385
127.25387666786872387092
127.18626036783265931263
127.05273238036649274818
126.78790187189191840389
126.58675758438968728115
126.45322959692350650585
126.58904011408996836963
126.79363020647375037697
126.99822029885756080603
127.20281039124134281337
127.54092847109131980687
128.01034907194969036937
128.34846715179963894116
128.75595773423953005477
128.96408294236474034733
129.10342857527260207462
129.38455382594261777740
129.80995701363048056010
130.23536020131837176450
130.80760626712668681648
131.23300945481457802089
131.58904013991252668347
131.87509769469255616059
132.16115524947258563770
132.30543318649046113933
132.30543318649046113933
132.30543318649046113933
132.15859030837003729175
132.15859030837003729175
132.23168687380609753745
132.24212924029694704586
132.49498368604000120285
132.92020437557786749494
133.25885547181968604491
133.51170991756274020190
133.76456436330582278060
134.01741880904884851589
134.18673432286502134048
134.15446396107830651090
134.15446396107830651090
134.00364349775782102370
133.92857142857141639070
133.78042035398226516918
133.55917271395713896709
133.55917271395711054538
133.63424478314351517838
133.86149914642729186198
134.24371538908877710128
134.62593163175020549716
134.85318599503398218076
135.15215753294356204606
135.44847724215517814628
135.52354931134158277928
135.59929770547563521177
135.44711541137826316117
135.21571963203729183078
134.98432385269632050040
135.06145260629563153998
135.21501525985829061938
135.21501525985831904109
135.21501525985831904109
135.13926686572426660859
135.13926686572426660859
135.13926686572426660859
135.21570076568758622670
135.13857201208821834371
135.06144325848890730413
135.29075430618559039431
135.59789847966061415718
135.90504265313563792006
136.21218682661069010464
136.36574948017334918404
136.44287823377266022362
136.52000698737202810662
136.52000698737202810662
136.36644433380936902722
135.98422809114791220964
135.60201184848648381376
135.07164453123590419636
134.47355840683397332214
133.59189696482769704744
132.50388922788380341444
131.42539394450318468444
130.28980457361967637553
129.30503566605665355382
128.45379474595981150742
127.75070490045209226082
127.26078328791010108034
127.19141078532018696023
127.12453025921091409600
127.12551129111329828447
127.66883206769978187367
128.02795007387661030407
128.48653104093216370529
129.03922079774866915614
129.50175548785043133648
129.78165845482186568915
129.87496190537527240849
129.87435790096958498907
129.87435790096958498907
130.01250997877684767445
129.87316434586898594716
129.66323712064041728809
129.45330989541182020730
129.10640887783549146661
128.89648165260689438583
128.82650852228897520035
128.96706324492765816103
128.89769074233771561921
128.82891116712039547565
128.76013159190304691037
128.69135201668569834510
128.75954623381608143973
128.75954623381608143973
128.69017373122616731962
128.61959213890543196612
128.75893777181329369341
128.96706297993850398598
129.17518818806371427854
129.38331339618892457111
129.55162556634044790371
130.20562107012136721096
130.77533507369159337941
131.18579692536710012973
131.51271984511569712595
131.83964276486429412216
132.08375955033446302878
132.32787633580460351368
132.37981214781132166536
132.24359483559391037488
132.49870699990881917074
133.01588893301996563423
133.61660979805802185183
134.21733066309604964772
134.63878789361629628729
134.97444847363777853388
134.97444847363777853388
134.62246620297929666776
134.32614649376773741096
133.72806036936580653673
133.12997424496387566251
132.53188812056197320999
132.23556841135038553148
132.16116364944562633355
132.30931472403474913335
132.53056236405993217886
132.75181000408508680266
132.82426537157871848649
132.82426537157871848649
132.75244280205447466869
132.60816486503659916707
132.31704404989832823958
131.95151847285526969245
131.58599289581223956702
131.22046731876918101989
131.07618938175130551826
130.93316878678564307847
130.86197076134419603477
130.79077273590280583448
130.86259530542702123057
131.00687324244489673220
131.15115117946277223382
131.07995315402135361182
131.00875512857993498983
131.00875512857993498983
131.00875512857993498983
131.07995315402135361182
131.22550508695101711965
131.37234796507144096722
131.66866767428302864573
132.10539127676750581486
132.46771011734719536435
132.83002895792691333554
133.11860148582223928315
133.33597598827617503048
133.33597598827617503048
132.97365714769648548099
132.39127988370483990366
132.02575430666180977823
132.10015906856656897617
132.17456383047135659581
132.32271490506045097391
132.32271490506045097391
132.32271490506045097391
132.68503374564016894510
133.19233894044535304602
133.55786451748838317144
133.55786451748838317144
133.48345975558362397351
133.33530868099450117370
133.33530868099450117370
133.33530868099450117370
133.26156236831013757183
133.11341129372101477202
132.96526021913189197221
132.89085545722713277428
132.89085545722713277428
133.03900653181625557409
133.11275284450061917596
133.18649915718498277784
133.24980434651737937202
133.33408584672810093252
133.25054691480116275670
133.16626541459046961791
133.42366567199070459537
133.50946232248946898835
133.68029298659368464541
134.02504627252343993860
134.24024128031322788956
134.54190040550173534939
134.99040240881072350021
135.44293660028296244491
135.59649925384559310260
135.82581030154230461449
136.05512134923901612638
136.13225010283835558766
136.36575917336847396655
136.59926824389859234543
136.72107708804958292603
136.78065054211845108512
135.98320237624923834119
134.90095129399813345117
133.91280900150800903248
132.92466670901788461379
131.72801732728672163830
130.62629864614413577328
130.92873638696048033125
131.37878783634823776083
131.67249084386705249017
132.17979603867226501279
132.61651964115671376021
132.97883848173643173141
133.41556208422088047882
133.85615973045037208067
134.07938287422592793519
134.07938287422592793519
134.15445494341233256819
134.22952701259873720119
134.30459908178517025590
134.60625820697370613743
134.83351257025748282103
135.06282361795416591121
135.21500591205153796182
135.36718820614891001242
135.51937050024628206302
135.67155279434359727020
135.67155279434362569191
135.29614735647072620850
134.77518998566813479556
134.10528129174196010354
133.51250135141515329451
132.70796866300821648110
131.83406347201139396930
130.96015828101457145749
129.96679959338331400431
129.13018176040941398242
128.43911586036517746834
127.95340051731136554736
127.46768517425756783723
126.73599083624138472715
126.07366900081511573717
125.41134716538883253634
125.14872833036599786283
124.95259178446131897999
124.82128926345291120015
124.62183532401598995420
124.35700481554141560991
124.61528261914696713575
124.93948637472328755393
125.26369013029959376126
125.59335466367043920854
126.05882971420771809790
126.45947073984874009511
126.93288727425937167936
127.47168037770765636196
127.75006149562285884258
128.03333505021544169722
128.42991802664511169496
128.75773651635722671926
128.08720760315048892153
127.28592555186841650539
126.33909248304718175859
125.39225941422594701180
125.41841004184099972463
125.79193744621501593883
126.13667898320261429035
126.39523513594332371213
126.79927916776402696541
127.26869976862239752791
127.60396422522576642677
128.00800825704646968006
128.41205228886718714421
128.54902608121491880411
128.68717815902218148949
128.82533023682941575316
128.89470273941932987327
128.89470273941932987327
128.96348231463667843855
128.96348231463667843855
128.96348231463667843855
128.82650852228894677864
128.62016222735132942034
128.27915393402963672997
127.87166335158974561637
127.60002756085796704610
127.32839177012617426499
127.05675597939438148387
126.78512018866260291361
126.65045819027854179240
126.51579619189448067118
126.51579619189448067118
126.38449367088608710219
126.25208542979714820831
126.25208542979714820831
126.38674742818122354038
126.52140942656527045074
126.65607142494931736110
126.79073342333337848231
126.92539542171743960353
127.25784223022807850612
127.73125876463871009037
128.07226705796040278074
128.20924085030810601893
128.34621464265586610054
128.48318843500359776044
128.48318843500356933873
128.28204414750135242684
128.01497390802836662260
127.54155737361774924921
127.00276427016946456661
126.40421143790614166846
126.06833238519743645156
125.62049364825251984712
125.00505492836505538889
124.65780859181393225299
124.39846352455708711204
124.13911845730028460366
124.22628721601715540146
124.57353355256829274822
124.78451735478243733724
125.07628319390920523801
125.33671794632257956437
125.86649120336855389723
126.39626446041452823010
126.92603771746053098468
127.33008174928122002711
127.53122603678346536071
127.73237032428571069431
127.79998662432177525261
128.00113091182399216450
127.93293669469360906987
127.86474247756322597525
127.79654826043284288062
127.72776868521549431534
127.86474247756322597525
127.73008047917917906489
127.59541848079511794367
127.46075648241105682246
127.25961219490882569971
127.05846790740659457697
126.92380590902253345575
126.74075986672173144143
126.40499237669752119473
126.40499237669750698387
126.67546733071678488614
127.03764838502583245372
127.58096916161234446463
128.40662693563896823434
129.33601224019852793390
129.57434860763117967508
129.68190285776421433184
130.09288428140388305110
130.30100948952906492195
130.51093671475766200274
130.79451203236203582492
130.93878996937988290483
130.93878996937991132654
131.15616447183381865216
131.29918506679950951366
131.29918506679948109195
131.29918506679948109195
131.22860347447877416016
130.94502815687442875969
130.58899747177645167540
130.23296678667844616939
129.87381266646238486828
129.59023734885803946781
129.45089171595017774052
129.31154608304231601323
129.31154608304231601323
129.38151921336026362042
129.52207393599894658109
129.66262865863762954177
129.73321025095836489527
129.87376497359701943424
130.08369219882561651502
130.29361942405421359581
130.36420101637492052760
130.43478260869565588109
130.29422788605700134212
130.15367316341831838145
129.87496636297237273538
129.59625956252642708932
129.31755276208050986497
128.90303544446811656599
128.55613442689181624701
128.14161710927942294802
127.80060881595773025765
127.45960052263603756728
127.25674430712157914058
127.05388809160714913560
126.91807757444070148267
126.98569387447676604097
126.98569387447676604097
127.05331017451283059927
127.18797217289687750963
127.32263417128093863084
127.45729616966499975206
127.72893196039677832232
128.00289455537048866063
128.27921398062608204782
128.62611499820238236680
129.04421404122012972948
129.39111505879645847017
129.66743448405205185736
129.94375390930764524455
130.08309954221550697184
130.15307267253345457902
130.15307267253345457902
130.08249108021271922553
130.01129305477130060353
130.01129305477130060353
130.08187464709203595703
130.08187464709203595703
130.08187464709203595703
130.01190151677405992814
129.87255588386622662256
129.59623645861063323537
129.31752965816468758931
129.24694806584395223581
129.31814609128539927951
129.45992570904755325500
129.60170532680970723050
129.74226004944836176946
129.95218727467695885025
130.22850669993255223744
130.43663190805776253001
130.43663190805773410830
130.29485229029560855452
130.15307267253345457902
130.08187464709203595703
130.22489524205769839682
130.36791583702336083661
130.58151802430978705161
130.86757557908981652872
131.15363313386981758413
131.43969068864981863953
131.51027228097058241474
131.51027228097058241474
131.36725168600491997495
131.43970705349852323707
131.58525898642818674489
131.65835555186424699059
131.73145211730030723629
131.87829499542070266216
132.39009066709158446429
132.97763473289651869891
133.56517879870145293353
134.01368080201041266264
134.46621499348265160734
134.91874918495489055204
135.44911650220547016943
135.90573750677168618495
136.37069999979723888828
136.75991409868876758082
137.22913331790797997201
137.70266799116905076517
138.18057793169580804715
138.57698385135756780073
138.81480729123578043982
139.05263073111402150062
139.16745684609210798044
138.98709305232009114661
138.98709305232009114661
138.89480807631417746961
138.80166062389702119617
138.89480807631417746961
139.08024050473724742005
139.26567293316034579220
139.38082830249661014932
139.78090511724502675861
139.86165447125020477870
139.94240382525535437708
139.86165447125020477870
139.61939597605231710986
139.53789195518726273804
139.45638793432223678792
139.45638793432226520963
139.62170269362394492418
139.95235407684890560631
140.19917285701563969269
140.52674099118758022087
141.01814684874540262172
141.43342924077194311394
141.76408062399690379607
142.01322798635683852808
142.09706058941503670212
142.09706058941503670212
142.18089319247326329787
142.26472579553148989362
142.18089319247326329787
142.08428609752044735615
142.08428609752044735615
141.98847740831109831561
141.98847740831109831561
141.98847740831106989390
141.98847740831106989390
141.89266871910169243165
141.98847740831109831561
142.09706058941503670212
142.26713247313125521032
142.43559608499106161617
142.43559608499106161617
142.35176348193286344213
142.26793087887466526809
142.26793087887466526809
142.18409827581643867234
142.01563466395660384478
141.76173017718218716254
141.67709916838057893074
141.67709916838057893074
141.84556278024041375829
142.01402639210024858585
142.26793087887466526809
142.60809778743777087584
142.94826469600093332701
143.28843160456403893477
143.54476591006897479019
143.80110021557391064562
143.97280351227720984753
144.14450680898048062772
144.31786510590859506920
144.40496098104793532002
144.49205685618727557085
144.57915273132664424338
144.66624860646601291592
144.75334448160535316674
144.84044035674469341757
144.92753623188406209010
144.92753623188406209010
144.84044035674469341757
144.75334448160535316674
144.72845994585125595222
144.69528056484580247343
142.56712291092676991866
140.43896525700779420731
138.60580445645567237989
136.87273974815212795875
135.35680073221308816755
133.84086171627407679807
134.02575791896850887497
134.16443007098934003807
134.46074978020095613829
134.83281788354656782758
135.21503412620802464517
135.52217829968304840804
135.83212127017648640503
136.22133536906801509758
136.69055458828722748876
137.15977380750643987994
137.53557749685555222641
137.93565122380894649723
138.12552119459812161040
138.41032615078191270186
138.67554190134833902448
138.88888888888888573092
138.88888888888888573092
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
126.05042016806723381706
126.05042016806723381706
126.05042016806721960620
125.52955066324049937521
125.21702896034446439444
124.67272738565291945179
122.97404560016221353180
121.70003426104419474996
120.09467513626219670186
112.12666757632504754838
111.61587973716191868334
111.10509189799878981830
111.29642819066678782747
111.48776448333478583663
112.29243946616838911723
113.09711444900197818697
112.86459956804959858800
112.63208468709721898904
113.30368329843793162581
113.97528190977865847344
114.74462840976497091106
115.19594022276810107996
114.54502098014880573373
114.23266433306613976129
113.74019172191047744036
115.94807215669307254302
116.32399837510395457230
116.88788770272023498364
117.82770324874736900256
121.97529610874306627011
126.12288896873876353766
127.34325842961084163107
128.07548010613407996061
127.85059254991219290787
127.81875620294101736363
127.79601595510446543358
128.26888238176903200838
129.22038303422661442710
130.10006111715995302802
130.10006111715995302802
130.76996981108612772005
131.65163125309240399474
132.36085111124845070663
133.07007096940446899680
133.74599021958471212201
133.46241490198036672155
133.25066215390023671716
132.56549287140953197195
131.21041489499262411300
130.54050620106647784269
130.24938538592817849349
129.54016552777216020331
128.37432466504992589762
128.30495216246001177751
128.23557965987009765740
127.72738932565025038457
127.21919899143041732259
126.40381911500577416518
125.58843923858111679692
125.79478553351876257693
125.26943749044021103600
124.60711565501394204603
124.88824090568395774881
126.08160052239452397771
127.06320739102497441309
127.66683451936953019867
128.27046164771405756255
128.27046164771405756255
129.21390873381034225531
130.15735581990662694807
130.08615779446520832607
130.01495976902378970408
131.13227265635890717022
131.06290015376902147182
130.99352765117910735171
131.06350078149705495889
130.92172116373490098340
130.90991464252175546790
130.98910331482611013598
131.09996745605218393393
129.31274733849841140909
129.45149234367823964931
129.59023734885803946781
130.45603821465891769549
130.45603821465891769549
130.33885654575311718872
131.24014817726785508967
131.88392791406411674870
131.77398277633449197310
131.98573552441456513407
132.56301384953775368558
132.63741861144251288351
133.21979587543410161743
133.21979587543410161743
132.48523631734514083291
132.79801651627332148564
133.70357665552836579081
134.06910223257142433795
134.06910223257139591624
134.06910223257139591624
133.99403016338499128324
133.71771073812939789605
134.15061117102982279903
133.53617184691307784306
132.86293240700535989163
132.68532590341101240483
132.43667679837892592332
132.06370314083082462275
131.44208037825060841897
133.33333333333334280724
131.04434907010016786444
128.75536480686696449993
129.12695316128792910604
129.73995171307120699566
130.10775084414117941378
130.84465036712811070174
131.37100716926164523102
131.99508581855849342901
132.94611976315465540210
133.22724501382467110489
133.36902463158682508038
134.04494388176706820559
134.19049581469673171341
133.96727267092120428060
133.81779583983001202796
133.43900796104213668514
132.76909926711596199311
132.76909926711596199311
132.41919444122859772506
131.32154779563674651399
131.54477093941230236851
131.76799408318782980132
131.47687326804955887383
131.33003038992913502625
131.47430832694701052787
131.26255557886690894520
131.40070765667417163058
132.04173329769980682613
131.89225646660861457349
131.30987920261702583957
131.16809958485487186408
131.02660091006697484772
130.83793601034980724762
131.14345424690253594235
132.06709956709957509702
132.18614718614719549805
131.89935064935065156533
132.57575757575759212159
133.92857142857141639070
133.92857142857141639070
133.92857142857141639070
133.92857142857141639070
133.92857142857141639070
  nan
  nan
  nan
  nan
  nan
136.36363636363637397153
136.36363636363637397153
134.19913419913419261320
133.11688311688311614489
131.80996218970901168177
130.93868157159295151359
130.01620246148394244301
129.32434312890219985093
127.96926515248529199198
126.61418717606841255474
126.61418717606841255474
126.61418717606841255474
127.68329425783370822955
127.29260821313923202069
127.16454100346760469620
127.16454100346760469620
127.03433040953366628401
127.30140064900666629910
127.02508122375107291191
126.48169155902249372048
125.28017623616824494093
126.01187057418442805101
126.74356491220061116110
127.55894478862526852936
128.37432466504989747591
129.08354452320594418779
129.79276438136196247797
130.40272892837066365246
131.21601499104889398950
131.59714902374142297958
132.64090965463671523139
133.34586588860386768829
133.81165919282511822530
133.81165919282511822530
134.54225280013105248145
134.04836856151271717863
133.69559410535674714993
134.02909938764170760805
134.25841043533841911994
134.64410179996588112772
135.17926999568453538814
135.63589100025075140366
135.44297523316328124565
136.08697987728700695698
136.98858637906019453112
137.14482388291614256559
137.40521972267606543028
137.30049013447279548927
136.98630136986301408797
  nan
  nan
  nan
  nan
  nan
  nan
133.33333333333334280724
133.33333333333334280724
133.13667649950835425443
133.03834808259585997803
131.22406689582876992972
130.01454610465069094971
130.74605882329132100494
131.29469336227180065180
130.06012546103721660984
128.82555755980266098959
127.11358958677274699767
125.40162161374283300574
124.32883076822471934975
123.25603992270659148289
121.92431400591843271286
120.59258808913027394283
120.85086589273583967952
120.78761958632013318038
121.27551966438413444394
122.35201409194239374756
123.88638397737611285265
125.21960957530760083500
125.41574612121226550698
126.01937324955680708172
126.49278978396742445511
127.35770355871727588237
128.22261733346709888792
128.78499431350272175223
129.34737129353834461654
129.75486187597820730844
130.16235245841807000033
130.96688514682500681374
131.46975871004337932391
131.90265914294383264860
132.79218058041047356710
133.39564446309708500849
133.39564446309708500849
133.75167514819509051449
134.63333659020133836748
135.18837528432743511075
136.04507310364209615727
136.19454993473328840992
135.88740576125823622533
135.88740576125823622533
136.49086964394484766672
137.33215696650972859061
137.56780841183856978205
137.32554991664068211321
137.08329142144276602266
137.54825391446834714770
137.62400230860237115849
137.39260652926139982810
136.71668727908115670289
135.80294458902267251688
135.64512626947214357642
135.48730794992161463597
135.32948963037108569551
134.71505030625434073954
134.48982508102909605441
134.79976805152253405140
135.55423449285524384322
135.27065917525089844275
134.88844293258944162517
134.50622668992798480758
134.04960568536176879206
134.04960568536176879206
133.34038582720575050189
132.09599777333107795130
131.93961745640029903370
132.82127889840654688669
132.82127889840654688669
132.89635096759297994140
133.04582779868417219404
133.00475557944858451265
133.89561909800914918378
135.14282802399395677639
134.83762593908335247761
134.83762593908332405590
135.44639217284958476739
136.21777490210453720465
137.63221687855144637069
138.14315668041123785770
137.74373162281631266524
137.45842801024849677560
137.16801640085932945112
137.01445374729667037172
137.49236368782342765371
137.33598337089264873612
136.85807343036589145413
136.15085244214242266025
135.99867014804507903136
135.77141578476130234776
135.62059532144081686056
135.62059532144081686056
135.22051850669242867298
135.29835163247074092396
134.91956375368283715943
134.77008692259167332850
134.77008692259170175021
134.84515899177807796150
134.19850816406503213329
133.40103687303152923960
132.30339022743967802853
131.20574358184779839576
130.98644533623377128606
131.28810446142233558930
131.44028675551967921820
131.14396704630809153969
131.56937023399595432238
131.36478014161218652589
131.22896962444571045125
132.48443458958809060277
133.31817215931886266844
132.23967687593824393844
132.08749458184090030954
132.38381429105248798805
132.31135892355888472594
132.86889683613691204300
133.72013775623378251112
133.18010319402179675308
132.56566386990505179710
133.34250002809716306729
133.19302319700597081464
132.26671020772269571353
131.70917229514463997475
131.56615170017897753496
131.12942809769450036583
131.20450016688090499883
131.35397699797209725148
130.92107656507164392679
130.48817613217124744551
130.90269344978361232279
131.31721076739597720007
131.67952960797569517126
131.46960238274712651219
130.74787948584764762927
130.74787948584764762927
131.33025674983923636319
131.47203636760141876039
131.54323439304283738238
132.13077845884777161700
132.35600368407298788043
132.35600368407298788043
132.35600368407295945872
132.58531473176967097061
131.86359183487019208769
131.58246658420017638491
131.72674452121805188654
131.35467641787244019724
130.91015294703316840241
131.26310076722742792299
131.61604858742168744357
131.01796246301978499105
130.74632667228797799908
130.47469088155619942881
129.98050811865095965913
130.12735099677138350671
130.34664924238541061641
130.17060665295176136169
129.93588320037355288150
129.60727036676405532489
130.36351833820188517166
131.61268556005398977504
132.45614035087720594674
132.01754385964912330564
131.57894736842104066454
131.57894736842104066454
131.57894736842104066454
131.57894736842104066454
131.92982456140350677742
132.28070175438597289030
132.75062656641605940422
133.22055137844614591813
133.63095238095237959897
133.63095238095237959897
133.63095238095237959897
133.33647996643588840016
133.10153136144327845614
133.70759196750384489860
134.19460495451681936174
134.19460495451684778345
134.15026603352595202523
133.45884871839169250052
132.94028573204099075156
131.98062791859848630338
131.98062791859848630338
131.45499716169018711298
130.09991918527330767574
129.04922432573954438340
129.27647868902332106700
130.08101137743022945870
130.15098450774820548759
130.82458476641070888036
130.68030682939283337873
130.53602889237495787711
130.94007292419567534125
131.34411695601639280540
131.19193466191904917650
130.59915472159221394577
130.74093433935436792126
131.39905999748000908767
131.91540603784352470029
132.82096617709856900547
134.00765156702360059171
134.07702406961348629011
133.42467367530392152730
133.28289405754179597352
134.18845419679681185698
134.57766829568831212782
134.42684783236785506233
133.88681327015586930429
133.27237394603912434832
133.77524750925749685848
134.42759790356709004300
134.93490309837227414391
134.39486853616028838587
133.20818314623528522134
132.56153231852221097142
132.63660438770864402613
132.78608121879980785707
132.35318078589938295409
131.77080352190776579846
131.62265244731861457694
131.47450137272949177714
131.68442859795808885792
132.55833378895493979144
132.71051608305228342033
132.26991843682282024020
132.41169805458497421569
132.99407531857656294960
133.21729846235211880412
133.07174652942245529630
133.21476712438811773609
132.69380975358555474486
132.54162745948821111597
132.98222510571767429610
133.05404767524191811390
132.68527259853669875156
132.24467495230723557142
132.17285238278299175363
132.46655539030177806126
132.76025839782056436889
132.68518632863415973588
132.31641125192894037355
132.31641125192894037355
132.10280906464254258026
131.96102944688038860477
131.88983142143894156106
131.45310781895449281365
131.01638421647004406623
130.57966061398553847539
130.79895885959956558509
131.01825710521362111649
130.47486744048507034677
129.93147777575649115533
130.00267580119788135562
129.72396900075196413127
129.44526220030604690692
129.44526220030604690692
129.08294335972632893572
128.58006979650792800385
129.05348633091855958810
129.25294027035548083404
128.76722492730166891306
129.18895232271327699891
129.61067971812488508476
129.47012499548620212408
129.32957027284751916341
129.32957027284751916341
129.32957027284751916341
129.60353286782122950171
129.67114916785726563830
129.18122755531527445783
129.40052580092932998923
129.90339936414773092110
129.90339936414773092110
128.69014292695487711171
128.48379663201723133170
128.34564455420996864632
128.13751934608475835375
128.06873977086743821019
128.06873977086743821019
128.06873977086743821019
128.06873977086743821019
128.27508606580505556849
128.48143236074270134850
128.52090438297335595053
128.44296813482432639830
128.52157336364416551078
128.60017859246400462325
128.75806668138656618794
128.99592406210106787512
129.07589335389303641932
129.23652732262294762222
129.38630923338203615458
129.80440827639978351726
130.08553352706979922004
130.51093671475766200274
130.86696739985563908704
131.15302495463566856415
131.33629568099368611911
131.48654785970998659650
131.69690090991284137090
131.87003443433428628850
132.15859030837003729175
132.15859030837003729175
132.15859030837003729175
  nan
  nan
  nan
  nan
  nan
  nan
136.98630136986301408797
136.98630136986301408797
137.19576054626952554827
137.14339575216789057777
137.23765238155084489335
137.30049013447279548927
137.34537424370276426089
137.37903732562523373417
137.53685564517576267463
137.77467908505400373542
138.01470468778495614970
138.33327748166834680887
138.57330308439929922315
138.81332868713028005914
139.05335428986123247341
139.29337989259218488769
139.37338501291989700803
139.21410876419409419213
139.13335941018894459376
139.05261005618376657367
138.97186070217861697529
138.89111134817341053349
138.89111134817343895520
138.89111134817343895520
139.13563403987922129090
139.53943298031077802079
139.78395567201658877821
140.19535640924672748042
140.60675714647683776093
141.01815788370694804144
141.34880926693190872356
141.93579495566177683941
142.27274488490229487070
142.43962293042662281550
142.52266837289275258627
142.27352101053281785425
142.02437364817288312224
141.53296779061508914310
140.88374361350673780180
139.90035200511510993238
138.92609406491288837060
138.19903676202622477831
137.55581206219775936006
137.07790212167100207807
136.59999218114421637438
136.36434073581534676123
135.47481929834873426444
134.45500577853519530436
133.30946479680656580058
132.02031277236042683398
130.59985822690589429840
129.17940368145133334110
127.62981690459182004815
125.82826479816166909131
124.83840100341983259113
123.87300094163423125337
123.17118387344312679943
122.65878379145911480919
122.46588255689121638170
122.40104853199494527871
122.46534673850364072223
122.91182086851691224183
123.35829499853016955058
123.98843060371240198947
124.62376538278533644188
125.40550396940196264950
125.93085201248049997957
126.32813284588740998515
126.52426939179207465713
126.59019534376282933863
126.65612129573356980927
126.72204724770432449077
126.92319153520655561351
126.92319153520656982437
126.99138575233693870814
127.05957996946733601362
127.32891847409995023099
127.53006276160219556459
127.73120704910442668734
127.93235133660665781008
127.93235133660665781008
127.93235133660665781008
128.07050341441390628461
128.20865549222116896999
128.34680757002843165537
128.55315386496604901367
128.75950015990369479368
128.96584645484131215198
129.10282024718907223360
129.17159982240639237716
129.10222731981650667876
129.10222731981650667876
129.17220045013445428594
129.31275517277310882491
129.45330989541182020730
129.59386461805050316798
129.66323712064041728809
129.80258275354825059367
130.08370800421829471816
130.36728332182264011863
131.02540897994828128503
132.24442563984263188104
132.50294607286363657295
132.42228139592563707083
132.66428453658863872988
132.82631838545967184473
132.82631838545967184473
132.90912451973809993433
132.56744276212711497465
131.67339355365871256254
132.10611125134326471198
132.84067080943219707478
133.43345074975906072723
134.02623069008586753625
134.31735150522416688545
134.39044807066017028774
134.39044807066019870945
134.24229699607107590964
133.94332545816149604434
133.56792002028856813922
133.34066565700479145562
132.96526021913189197221
132.89151390644752837034
132.89151390644752837034
132.89151390644752837034
132.89151390644752837034
132.89151390644752837034
132.89151390644752837034
132.74336283185840557053
132.67026626642234532483
132.52471433349268181701
132.30733983103877449139
132.08996532858486716577
131.73081120836877744296
131.30168395783476853467
130.59859411232704928807
129.89550426681933004147
129.26551098674764261887
128.70797307416958688009
128.22225773111577495911
127.80415868809802759642
127.52783926284243420923
127.32149296790478842922
127.38910926794085298752
127.52491978510730064045
127.58295917329454027822
127.66034502421086926915
127.76868521549371848778
128.09883928739395742014
128.42899335929419635249
128.87110443970314577200
129.31321552011209519151
129.53425884460367001338
129.40444317164755716476
129.47096984285931853265
129.52086484626812534771
129.59083797658610137660
129.66081110690404898378
129.66081110690404898378
129.73139269922478433728
129.80136582954270352275
129.80136582954270352275
129.66202019663484179546
129.59204706631692260999
129.52207393599894658109
129.45210080568097055220
129.52268239800173432741
129.52268239800173432741
129.73628458528810369899
130.08923240548236321956
130.44218022567662274014
130.72575554328099656232
131.08178622837897364661
131.43781691347692230920
131.72638944137230510023
132.01496196926763104784
132.16051390219729455566
132.16051390219729455566
132.01749330723163211587
131.87447271226596967608
131.65899674980664713075
131.44352078734738142884
131.15494825945199863781
130.86637573155667269020
130.79327916612064086621
130.86573453361427255004
131.08121049607353825195
131.36978302396889262127
131.58525898642818674489
131.65645701186963378859
131.83098192660875724869
132.06368181292759800272
132.04470011383912719793
131.25400769319074356645
131.02316299396215981687
131.14115709425715294856
131.37610569924981973600
131.84189900347107027301
131.79807373096272726798
131.93311503109069349193
132.03439600618665394904
132.67542164721228914459
132.96654246235056007208
132.96654246235056007208
132.89279614966619647021
132.81904983698183286833
133.03834808259585997803
133.18649915718498277784
133.18649915718498277784
133.03965627906458735197
132.89281340094416350439
132.67351515533013639470
132.52796322240044446517
132.45486665696444106288
132.32937141748314502365
132.06283808230179488419
131.92673313239043864087
132.04368763708811229662
132.27863624208077908406
132.62951343506324519694
132.98039062804571130982
133.21533923303837809726
133.13667649950835425443
133.08048883270120654743
132.89279614966619647021
132.60422362177087052260
132.24190478119112412969
131.66965871538283749942
131.30733987480314794993
131.01621905966484860073
130.79884455721094127512
130.58147005475700552779
130.50964748523279013170
130.43906589291205477821
130.36848430059131942471
130.36848430059131942471
130.08977750014537377865
129.73987267425803793230
129.25415733120422601132
128.76844198815041409034
128.62666237038826011485
128.76844198815041409034
128.91022160591256806583
129.19134685658258376861
129.54125168246991961496
129.89115650835731230472
130.37687185141112422571
130.84233296335605700733
131.00436681222706170047
131.00436681222706170047
131.23521151145564545004
131.46605621068425762132
132.05089713395312855937
132.63573805722199949741
133.22057898049087043546
133.43867381408620076400
133.76837845990840492050
134.24916601480515510048
134.93117708864423320847
135.61318816248331131646
136.07395159629720637895
136.30120595958095464084
136.45202642290144012804
136.45202642290144012804
136.29984412880406807744
136.11201059019285253271
135.86156587204456513973
135.51094326663698552693
134.98500935852558768602
134.73114369975357362819
134.52914798206276714154
134.52914798206276714154
128.20512820512820439944
127.93235133660665781008
127.66111558033664152845
127.52549770220163338763
127.44412697532064271400
127.38987982406662524681
127.35113185888519637956
127.45788140216558304019
127.45788140216556882933
127.52607561929596613481
127.80003821426964805141
128.07400080924338681143
128.20981132640980604265
128.41440141879360226085
128.68836401376731259916
128.75714358898466116443
128.75714358898466116443
128.82592316420198130800
128.82592316420200972971
128.82592316420198130800
129.03404837232722002227
129.24397559755578868135
129.38453032019447164203
129.59445754542304030110
129.80258275354827901538
129.80258275354825059367
129.73321025095833647356
129.66383774836845077516
129.66383774836845077516
129.59325615604771542166
129.66445418148913404366
130.10117778397361121279
130.60848297877882373541
131.25513380649186956362
131.82737987230018461560
132.39962593810844282416
132.83252637100889614885
133.26542680390934947354
133.55654761904762040103
133.55654761904762040103
133.55654761904762040103
133.70736808236807746653
133.93259330759332215166
134.15781853281850999338
134.38304375804375467851
134.60826898326899936364
134.83349420849418720536
134.98431467181467269256
135.13513513513512975805
135.28869778869778883745
135.36513168866110845556
135.36513168866110845556
135.21431122534062296836
134.91533968743101468135
134.54327158408540299206
134.02692554372188737943
133.43938147791698156652
132.98684728644471420012
132.61144184857181471671
132.31247031066220642970
132.01876730314342012207
131.73019477524809417446
131.44413722046806469734
131.30235760270591072185
131.23177601038520379007
130.87262189016911406725
130.72706995723945055943
130.65397339180339031373
130.72642875929702199755
130.94190472175631612117
131.30105884197240584399
131.80836403677758994490
132.31566923158277404582
132.82297442638795814673
133.11667743390677287607
133.25354702326916367383
133.43603980908571315922
133.69152970922883127969
133.92857142857141639070
134.85009829619920651567
136.25167636928890146919
137.17320323691669159416
138.09473010454451014084
137.87304435760819387724
137.72525385965067812322
137.61968921825243228341
137.54051573720374790355
137.46196854605130965865
137.14339575216791899948
136.83345278167448100248
136.72184905061615722843
136.67777366407503336632
136.67777366407503336632
136.47115782490479318767
136.26454198573458143073
136.05792614656436967380
135.85131030739412949515
136.05606051214434160102
136.09999991950033404464
136.05582572141798891607
136.05582572141798891607
136.21078760079566905006
136.44429667132578742894
136.83708199058168020201
137.22986730983757297508
137.54552387549415470858
137.78117532082302432173
137.93613720020067603400
137.93613720020067603400
137.85830407442236378301
137.54836110392892578602
137.00339349057566096235
136.45842587722239613868
135.91345826386913131500
135.57983116924086175459
135.34541946913714127732
135.01724308899196103084
134.68064477033087200653
134.52914798206276714154
134.52914798206276714154
134.52914798206276714154
  nan
  nan
  nan
  nan
  nan
  nan
135.74660633484162985951
135.74660633484162985951
135.54278260160614877350
135.44087073498837980878
135.37972361501772411430
135.23796100952523602245
135.13670200560201806184
134.98568568347320706380
134.75843132018940195849
134.53117695690565369659
134.38035649358516820939
134.22953603026473956561
134.07871556694425407841
134.07871556694425407841
134.15446396107830651090
134.30528442439876357639
134.45610488771922064188
134.60692535103970612909
134.75774581436013477287
134.83281788354656782758
134.96199594854300585212
135.03413727628972651473
135.01393770452065723475
134.18778403993906067626
133.36163037535743569606
132.53547671077583913757
131.70932304619421415737
131.00436681222706170047
131.10013023825939626477
131.41915925184139268822
131.58468469934351219308
131.72896263636138769471
131.80078520588563151250
131.94506314290350701413
132.16243764535744276145
132.45355846049568526723
132.74726146801449999657
132.82166622991925919450
132.96981730450841041602
133.26613701371996967282
133.71066048455924146765
134.00698019377082914616
134.15513126835998036768
134.15513126835995194597
134.00698019377082914616
133.64145461672779902074
133.60043792932157202813
133.34532576500666323227
133.04702420331855705626
132.45956067501262509722
131.97219323895524212276
131.67979557813649194031
131.58233349088845898223
131.69792682662074412292
131.69792682662074412292
131.43656782531130033931
131.69792682662074412292
131.92877152584932787249
132.04368763708811229662
132.04368763708811229662
131.69892609715321896147
131.39412672598265885426
131.17641288943227095842
131.15490712978163401203
130.93753262732769826471
130.79325469030982276308
130.79325469030982276308
130.79325469030982276308
131.30505036198073298692
131.96317602010640257504
132.62130167823204374145
133.13764771859555935407
133.73042765892236616310
134.40033635284851243341
134.84485982368778422824
135.13856283120659895758
134.91533968743101468135
134.76451922411058603757
134.61369876079010055037
134.46287829746964348487
134.23562393418586680127
133.85683605539799145845
133.63161083017274677331
133.55720606826798757538
133.63095238095237959897
133.55654761904762040103
133.40839654445846917952
133.86915997827236424200
134.56994901481724014047
135.18438833893395667474
135.71955653465261093515
136.17617753921882695067
136.40140276444407163581
136.55087959553523546674
136.55087959553526388845
135.86886852169618578046
134.80255390810827975656
133.75521415109110989761
132.78714552247203073421
131.62130465974979642851
130.68685957636853345321
130.13796123269793270083
129.70372763283782546750
129.21275509773141720871
129.32767120897020163284
129.90231780131588834593
130.59495849395659661241
131.72971026700625429839
132.98350965910350396371
133.34211390510878914029
133.59825979511253990495
133.56514398739014382045
133.42212339242445295895
133.13355086452912701134
132.77123202394940904014
132.40891318336969106895
132.04338760632666094352
131.52704156596314533090
131.01069552559962971827
130.71957471046135879078
130.79077273590280583448
131.00624869836207153639
131.36856753894178950759
131.88036321061269973143
132.39670925097618692234
132.54098718799406242397
132.75836169044799817129
132.90263962746587367292
133.04691756448372075283
133.04691756448374917454
132.90007468636332532697
132.82500261717689227225
132.74925422304283983976
133.04557393225445593998
133.26879707602998337279
133.49004471605513799659
133.71129235608032104210
133.85813523420074488968
134.07938287422592793519
134.07938287422592793519
134.00431080503949488048
133.92923873585306182576
133.85416666666665719276
133.92923873585306182576
133.85483397394830262783
134.00431080503949488048
133.92990604313473568254
133.78175496854561288274
133.76078118854192666731
133.73281614853701171342
133.69366509253012509362
133.48479437014697168706
133.53528138241816236587
133.03834808259585997803
132.74336283185840557053
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
127.11864406779660896518
127.11864406779660896518
127.29895420122609550617
127.38910926794085298752
127.44320230796969894982
127.47926433465561046887
127.50502292514553914771
127.59253608514336519875
127.72834660230981285167
127.86415711947627471545
128.00113091182399216450
128.13810470417172382440
128.41442412942731721159
128.52225979533335475935
128.83805342419688599875
129.07591080491141610764
129.47580203449697933138
129.95777620068164992517
130.36114513804653824991
130.76451407541139815294
131.00863086088156705955
131.07992042432400126017
131.22170004208615523567
131.36347965984830921116
131.36347965984830921116
131.29165709032406539336
131.36411245781766865548
131.43656782531130033931
131.58211975824099226884
131.65457512573459553096
131.79885306275247103258
132.08997387789074196007
132.38109469302901288756
132.67221550816731223676
132.81905838628773608434
132.96590126440815993192
133.03964757709252353379
133.03964757709252353379
133.03964757709252353379
132.89280469897209968622
132.74596182085167583864
132.59911894273128041277
132.59911894273125199106
132.45227606461085656520
132.23297781899682945550
132.08869988197895395388
132.08869988197892553217
132.08869988197895395388
132.08869988197895395388
132.08869988197895395388
131.86940163636489842247
131.65392567390563272056
131.65392567390560429885
131.58272764846418567686
131.36725168600489155324
131.15177572354559742962
131.00794506857067744932
130.76168682576002311180
130.84449296003845120140
131.09075120284910553892
131.09075120284910553892
131.25420331138133178683
131.25420331138130336512
131.01428648254861286659
130.59494748074070002986
130.38502025551210294907
130.09896270073207347195
129.95468476371422639204
129.88286219418995415253
129.73984159922429171274
129.66926000690355635925
129.66926000690358478096
129.73745422403396787558
129.66867464881659088860
129.46054944069138059604
129.11064461480404474969
128.82951936413400062520
128.61959213890543196612
128.48024650599757023883
128.61959213890543196612
128.82771734703064225869
129.03584255515585255125
129.31454935560179819731
129.59325615604771542166
129.59325615604771542166
129.59325615604771542166
129.59325615604771542166
129.31693673079212203447
129.04061730553652864728
128.90127167262866691999
128.76071694999001238102
128.76071694999001238102
128.90006258289784568660
129.03940821580573583560
129.17875384871359756289
129.38510014365124334290
129.52207393599894658109
129.45329436078162643753
129.45329436078162643753
129.38271276846089108403
129.38271276846089108403
129.38271276846089108403
129.45329436078162643753
129.66504710886169959849
129.79500172343239228212
130.05998064314977114009
130.20990780626246419160
130.29485229029560855452
130.43642643035084915937
130.71957471046135879078
131.00436681222706170047
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
129.87012987012985831825
129.31274733849841140909
129.31274733849841140909
129.31274733849841140909
129.31274733849841140909
129.68675909523082623309
129.87376497359701943424
129.87303795290358721104
129.54003761990324505859
129.32087955214689145578
128.95384030475517533887
128.46372205440462721526
128.09613336664168059542
127.28765330914977482735
126.54347145816655029193
125.86987119950407532087
125.53460674290069221115
125.26753650342770640691
124.93787197005687517049
124.85428045830767018742
124.74282510930873968391
124.89800170141840851556
125.21182017486589188593
125.52563864831338946715
125.63112017146656285149
125.73660169461977886840
126.05042016806723381706
126.22845748468880344717
126.43290276803112703874
126.65443094766825993247
126.85557523517049105521
127.12491373980311948344
127.46303181965308226609
127.80114989950304504873
128.13926797935300783138
128.34385807173680404958
128.27624177170073949128
128.14043125453429183835
127.93928696703206071561
127.60402251042869181674
127.06976788467406436212
126.60034728381569379962
126.06609265806105213414
125.53183803230641046866
125.46479233395842811660
125.39774663561043155369
125.53127462307659811813
125.66368286416552280116
125.99334739753636824844
126.25817790601095680358
126.58784243938178804001
126.91750697275261927643
126.91750697275263348729
126.91750697275263348729
126.98512327278869804559
127.11978527117275916680
127.39374786614645529426
127.87946320920025300438
128.36517855225406492536
128.63914114722774684196
128.84199736274223369037
129.04485357825666369536
129.04485357825666369536
128.91019157987258836329
128.56918328655092409463
128.01642224514910139987
127.39717891462912291445
126.85838581118085244270
126.45774478553981623463
126.18840628090720201726
126.18840628090720201726
126.39126249642163202225
126.59411871193607623809
126.79697492745052045393
127.13509300730048323658
127.60451360815885379907
128.00515463379986158543
128.34327271364986700064
128.48024650599757023883
128.48024650599757023883
128.61839858380483292422
128.75655066161209560960
128.82592316420198130800
128.75714358898466116443
128.75714358898466116443
128.68836401376728417745
128.61958443854996403388
128.55139022141958093925
128.34504392648193515925
128.13869763154428937924
127.93235133660665781008
127.79654081944021015715
127.66073030227374829337
127.52491978510730064045
127.45672556797691754582
127.45672556797691754582
127.52491978510730064045
127.59311400223768373507
127.59311400223768373507
127.66073030227374829337
127.79654081944019594630
127.93235133660665781008
127.93235133660665781008
127.86473503657059325178
127.72892451940414559886
127.59311400223768373507
127.52549770220163338763
127.39083570381757226642
127.25502518665112461349
127.11921466948467696056
127.11921466948467696056
127.18683096952074151886
127.25444726955680607716
127.32206356959287063546
127.38967986962893519376
127.50502292514553914771
127.56941940137035373937
127.65957446808511122072
127.23226208492833677610
126.91043122492476413754
126.58860036492119149898
126.26676950491761886042
126.05131030750312959299
126.22919926755203334778
126.35626281044412166921
126.45156046761317725213
126.51748641958391772278
126.58421016980059903290
126.67317517008949323554
126.79772617049393090838
126.85158809286426162544
126.76256943455348391581
126.94287956798298466765
127.30504094709351647907
128.02327695944717333987
127.93235133660665781008
127.98690671031097565447
128.11498305973697142690
128.20646616646982351995
128.34445099910936960441
128.62077042436499141331
128.82889563249017328417
128.96704771029743596955
129.17339400523508174956
129.31154608304231601323
129.38091858563223013334
129.52026421854009186063
129.59023734885803946781
129.59023734885803946781
129.66081894117877482131
129.73079207149669400678
129.80076520181467003567
129.87073833213264606457
130.08249108021271922553
130.29609326749914544052
130.50969545478551481210
130.72329764207194102710
130.86631823703760346689
131.07992042432400126017
131.29352261161039905346
131.65267673182646035457
131.72449930135070417236
131.72449930135070417236
131.58147870638504173257
131.50965613686076949307
131.58211150435440117690
131.65456687184803286073
131.72702223934166454455
131.65392567390563272056
131.72638104139923598268
131.79883640889286766651
131.99370123749250183209
132.49724140461188426343
132.92246209414972213381
133.43503581221710874161
133.94760953028446692770
134.46018324835185353550
134.88540393788971982758
135.31062462742758611967
135.28868844089100775818
135.36443683502506019067
135.36443683502506019067
135.28800293506174057256
135.21156903509842095445
135.13513513513512975805
135.13513513513512975805
135.13513513513512975805
135.13513513513512975805
135.13513513513512975805
135.13513513513512975805
135.13513513513512975805
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
135.13513513513512975805
135.13513513513512975805
135.13513513513512975805
135.13513513513512975805
135.13513513513512975805
135.13513513513512975805
135.13513513513512975805
135.13513513513512975805
  nan
132.74336283185840557053
132.74336283185840557053
132.74336283185840557053
132.74336283185840557053
132.74336283185840557053
132.74336283185840557053
132.74336283185840557053
132.74336283185840557053
132.74336283185840557053
  nan
139.53488372093022462650
139.86089980439032842696
139.96957183221036302712
140.02390784612038032719
140.05650945446637933856
140.05650945446637933856
140.05650945446637933856
140.05650945446637933856
140.18691588785046064913
140.18691588785046064913
140.18691588785046064913
140.18691588785046064913
  nan
  nan
  nan
  nan
  nan
  nan
  nan
144.92753623188406209010
144.23410304417168958935
144.00295864826756542243
143.71650470048638226217
143.68133773168096922745
143.54397191925795596035
143.74162396391881202362
144.06660389525825394230
144.24334479310201118096
144.59344398787385443939
144.94354318264572611952
145.20234235448833715054
145.37570065141645159201
145.54740394811972237221
145.46030807298041054310
145.37150864129674232572
145.28270920961313095177
145.19390977792949115610
145.01716888008573391744
145.01716888008570549573
145.01716888008570549573
145.10426475522507416827
145.10426475522507416827
144.84296499213684228380
144.49622435413408538807
144.06485270732969183882
143.80605353548702396438
143.54725436364438451164
143.37389606671629849188
143.28680019157695824106
143.28680019157695824106
143.37306261336567558828
143.54476591006897479019
143.80110021557391064562
143.97280351227720984753
144.14450680898048062772
144.23076923076922639666
144.23076923076922639666
144.23076923076925481837
144.23076923076925481837
144.23076923076922639666
144.23076923076922639666
144.23076923076922639666
144.23076923076922639666
144.23076923076922639666
  nan
  nan
145.63106796116505847749
145.27930209652456028380
144.69975801650281255206
144.23910422666281760939
143.82730233867624747290
143.44099094260755578034
143.16505423112994321855
142.95810169752172669178
142.44289744765907812507
142.01563466395660384478
141.84556278024041375829
141.51178440907887079447
141.26263704671893606246
141.09732228741722792620
140.76975415324534424144
140.36218089874569159292
140.19686613944401187837
140.03155138014227532040
139.86467333461797579730
140.19845170577949033941
140.70393337364436092685
141.29651091664854334340
142.40830620947832585443
143.60010662263579206410
144.54964854059537060493
145.49919045855494914576
146.27590959691451644176
146.71144966660094155486
146.79854554174028180569
146.71144966660094155486
146.26738941650620517976
145.73706674462275145743
144.95283958596485263115
144.16861242730698222658
143.47493873141354470135
143.06543520523922552456
142.75508503413172434193
142.45994819480421256230
143.02555905453269247118
143.44363867547386348633
144.26796547525401592793
144.95759835350150979139
145.29452557722117944650
144.80704903320551579782
144.48206467052841617260
144.24993298290192456079
143.64969288686350523676
143.44965021109265990162
143.34914567833820342457
143.54644449753146773219
143.80524366937410718492
144.06404284121674663766
144.32284201305941451210
144.58164118490205396483
145.01718125458847907794
145.12952582941977652808
145.16319076234239560108
145.21032166843409072499
145.28101802757157656742
145.39884529280075753377
145.63449982325909104475
146.34146341463414842110
  nan
144.92753623188406209010
144.23410304417168958935
144.00295864826756542243
144.23410304417168958935
144.09541640662922645788
143.77619613664600706215
143.54818165808654839566
143.54724268288313737685
143.37388438595502293538
143.54724268288313737685
143.72060097981125181832
143.54724268288313737685
143.37717079916694729036
143.37717079916694729036
143.37717079916694729036
143.12326631239255902983
142.86936182561817076930
142.52593164497389466305
142.26713247313125521032
142.18169159821667335564
142.35176348193286344213
142.52183536564905352861
142.77816967115398938404
143.11833657971709499179
143.28680019157692981935
143.37143120037856647286
143.37143120037856647286
143.44490096369796106046
143.42893948157106365215
143.66008387747521624078
143.77621171099431762741
143.89233954451344743575
144.09937888198754762925
144.40993788819875476293
145.10341916420429697610
145.35102801429027863378
145.63566060014386494004
145.99087514973180645939
146.58876102527585771895
147.18664690081996582194
147.27187609092098341534
147.27187609092098341534
147.54534411254695669413
147.78612025212950698005
147.96794443280896302895
148.27098473394138977710
148.14905135833780036592
147.78325123152708897578
  nan
  nan
  nan
  nan
  nan
138.24884792626727403331
139.21788190705888155208
140.65881122352570287148
141.90187540793553466756
142.78979300927525741827
143.26333883459022899842
143.60158585267234343519
143.50853047823116526160
143.91610373273076106670
144.08141849203246920297
143.82751400525808094244
143.31230975539546079744
142.54299131454752114223
141.86247230538319286097
141.18195329621886457971
140.94264050180112235466
140.75370934831346403371
140.56477819482577729104
140.37584704133811897009
140.28093796423397066064
140.37496004061750909386
140.18952761219443914342
139.91266076053969413806
139.70468415625566649396
139.63579390888497755441
139.54394024572403054663
139.41534511729872747310
139.05791379098965876437
138.46219491380779231804
138.24884792626727403331
138.24884792626727403331
  nan
140.18691588785046064913
140.18691588785046064913
140.18691588785046064913
140.18691588785046064913
140.31854679478740877130
140.40630073274536471217
140.46898211700104752708
140.43372383835725258905
140.43372383835725258905
140.59903859765893230360
140.93281696882050368913
141.26659533998204665295
141.60354526922256468424
142.11385349539116873530
142.71210318771991865106
143.75309513738216082857
144.26254931588670160636
144.85135417287253289942
145.24762918773285491625
145.44386152682233159794
145.54244715172376345436
145.44290900870734617456
145.44290900870734617456
145.03093993385255089379
145.10595593726662855261
145.10595593726662855261
145.10595593726662855261
145.19305181240599722514
145.28014768754530905426
145.28014768754533747597
145.28014768754533747597
145.10511034624585136044
144.93007300494639366661
144.66877324185816178215
144.40747347876992989768
144.33317879975362529876
144.23411922773189530744
144.23411922773189530744
144.00073610599926610121
144.00073610599926610121
144.00073610599926610121
144.11575266838426045979
144.34689706428835620500
144.73431513073455789709
145.02487868056920206072
145.11197455570857073326
145.19907043084793940579
145.37410777214739709962
145.90961805394482553311
146.44512833574222554489
146.66192720772195912105
146.59577782077363394819
146.35781855939919182674
146.47507384761271964635
146.59232913582619062254
146.24392945170856705772
145.64604357616451579815
144.81701330471631194996
144.53703181220583928734
144.32704569282293505239
143.89567404601854150314
143.38046979615594977986
142.86526554629330121315
142.52831561705281160357
142.01800739088420755252
141.68105746164368952122
141.51259384978385469367
141.51259384978385469367
141.68266573350004478016
141.93657022027440461898
142.19047470704882130121
142.38267531912219965307
142.19550579032150494641
142.00833626152078181804
141.45194268106627077941
141.07048168545307476052
140.59137397565180549464
140.01911881343335153360
139.44686365121489757257
139.45786615992932411245
139.45786615992932411245
139.45786615992932411245
139.69789176266027652673
139.61638774179525057662
139.53488372093022462650
139.53488372093022462650
139.53488372093022462650
139.53488372093022462650
139.61638774179525057662
139.61638774179525057662
139.37636213906429816234
139.13633653633334574806
138.89631093360239333379
138.73555645926950319335
138.65480710526435359498
138.49405263093149187625
138.25179413573357578571
138.33329815659860173582
138.82014253949631665819
139.39081952545222975459
139.96149651140814285100
140.53753337776757348365
141.03356512379932041767
141.60960199015877947204
142.18563885651821010470
142.51941722767975306851
142.51941722767975306851
142.43558462462155489447
142.43558462462155489447
142.35095361581994666267
142.18249000396008341340
142.09785899515844675989
142.09785899515844675989
142.09785899515847518160
142.26632260701831000915
142.43478621887814483671
142.43478621887814483671
142.51941722767978149022
142.68788083953961631778
142.77251184834125297130
142.68788083953961631778
142.60324983073797966426
142.56697939839440891774
142.51861882193634301075
142.58632362897765233356
142.51861882193634301075
142.40577747686751308720
142.18009478672985324010
141.84476437449700370053
142.18328840970349347117
142.63574889195544415088
142.86197913308140527988
142.72560226381111192495
142.52290754688661422733
142.37812560622629121099
142.18649370826489075625
142.10344826579876098549
141.93498465393892615793
141.68108016716453789741
141.42717568039012121517
141.34334307733192304113
141.34334307733192304113
141.26029763486582169207
141.26029763486582169207
141.34334307733192304113
141.42717568039012121517
141.42717568039012121517
141.34413023792401986611
141.17881547862231172985
141.09577003615618195909
141.09577003615618195909
141.09577003615618195909
140.93045527685447382282
140.68130791449453909081
140.43448913432780500443
140.27071579662717226711
140.18921177576214631699
140.10694245892653952978
139.94316912122593521417
139.61864130919244075812
139.37638281399455308929
139.13412431879663699874
138.50840735143822257669
138.36176787008221822362
138.16624856160754575285
137.76211509635896845793
137.31892294021614020494
137.00894794486578120996
136.38899795416500637657
134.52914798206276714154
  nan
  nan
  nan
  nan
134.52914798206276714154
135.44639217284958476739
135.75214023644517169487
135.90501426824297936946
136.24694719441089318934
136.47490247852286415764
136.45900731925334525840
136.52491907557953254582
136.83206324905458473040
136.83206324905458473040
136.67850059549192565100
136.52493794192926657161
136.44639075077682832671
136.44639075077682832671
136.76204731643338163849
136.99987075631162269929
137.23769419618986376008
137.55335076184644549357
138.18482335593583343325
138.62049310176453786880
138.89285839041477288447
139.14849428868120639891
139.21339563862926524962
139.32156455520942017756
139.53790238836967319003
140.18691588785046064913
  nan
  nan
  nan
  nan
136.98630136986301408797
136.98630136986301408797
136.98630136986301408797
136.83063511830636116429
136.73723536737236372574
136.67496886674970824060
136.71944493862301328591
136.83134918368045873649
136.83134918368045873649
136.75351605790211806379
136.67568293212380581281
136.67568293212380581281
136.75351605790211806379
136.90989637483289698139
136.89898137554288837237
136.77969845495294975990
136.73837787197095394731
136.83206324905458473040
136.98820554419398831669
137.30049013447279548927
137.61467889908257689058
  nan
  nan
  nan
  nan
  nan
  nan
139.53488372093022462650
139.53488372093022462650
139.53488372093022462650
139.53488372093022462650
139.53488372093022462650
139.53488372093022462650
139.69789176266027652673
139.79569658769830198253
139.92610302108235487140
140.18814036140332746072
140.18814036140332746072
140.18814036140332746072
140.18814036140332746072
139.43048488418565966640
138.54242297358388213979
137.65436106298210461318
137.26674747066550708041
137.00471013034444922596
137.75549743566440952236
138.29177408232152401979
138.69398156731435278743
139.41433502074215766697
140.13468847416999096822
140.85504192759779584776
141.26261518209742007457
141.26261518209742007457
141.17956973963129030381
141.09652429716516053304
141.09652429716516053304
141.09652429716518895475
141.01347885469905918399
140.84816409539735104772
140.52059596122543894126
140.35984148689257722253
139.87749535530855382603
139.55296754327503094828
139.14539428877543514318
138.81782615460355145842
138.57330346289774070101
138.41105008802753673081
138.41105008802753673081
138.25323176847700779035
138.25323176847700779035
138.01758032314813817720
137.78192887781926856405
137.46627231216274367398
137.38626719183503155364
137.17160694939875043019
136.29367465553724514393
135.77182207889015330693
135.24996950224308989164
134.81706906934263656694
134.46920265004766292805
134.12133623075266086744
133.50415387393897503898
133.55720606826798757538
133.85617760617759586239
134.15783673136616016563
134.53662461015403550846
134.91541248894191085128
135.14266685222568753488
135.21773892141209216788
135.40190570610363351989
135.64746141902566023418
135.74992667580377769809
135.75075676104430044688
135.54646356018028541257
135.13787715845219850053
134.52914798206276714154
  nan
  nan
  nan
  nan
  nan
  nan
  nan
136.98630136986301408797
137.30049013447279548927
137.82995638594482556982
138.09468951168085482095
138.25352938712245531860
138.35942263741688407208
138.34362625010979286344
138.25250783123135533970
138.33105502238382200630
138.25250783123135533970
138.09323158250558094551
137.93395533377977812961
137.77467908505400373542
137.85542843905918175551
138.34683429661697573465
138.83367867951469065702
139.07370428224564307129
139.55605041382966646779
139.95984935426125161939
140.19987495699220403367
140.43990055972315644794
140.43990055972315644794
140.02849982249304616744
139.62092656799342194063
139.30235377411003128145
138.58861186318500813286
137.87486995226004182769
137.32490137903562299471
136.77493280581126100515
136.30139813255016179028
135.98574156689360847849
135.75009012156473886535
135.82863731271720553195
136.06003309205817686234
136.13646699202149648045
136.21290089198478767685
136.36686742867760813169
136.27951440014811623769
136.19136725317744662789
136.16265573476761119309
135.85131030739412949515
135.64469446822388931650
135.54278260160614877350
135.44087073498837980878
136.02572973298006786536
136.74741269765368656408
137.28859518138062867365
137.56938124882432816776
137.80289031935444654664
138.03639938988456492552
138.26779516922556467762
138.57773813971897425290
138.33771253698802183862
138.01618291339204347423
137.77615731066109105996
137.45758451677767197907
137.37903732562523373417
137.06909435513179573718
136.76195018165680039601
136.37625881702928154482
135.99056745240181953704
135.60487608777435752927
135.29493311728091953228
135.13997123790323939829
134.98500935852555926431
135.21640513786655901640
135.31458473155279875755
135.44549085646784192249
135.62875943134883982566
135.90366229367035089126
136.15983801318211021680
136.36645385235232197374
136.98630136986301408797
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
140.18691588785046064913
139.21788190705888155208
139.10821756766890189283
139.05338539797389785235
138.63996859235172109948
138.36435738860359379032
138.16749224306923338190
138.16749224306923338190
137.80002981610238066423
137.61966602233033540870
137.61966602233033540870
137.61966602233036383041
138.07689666775712566960
138.53412731318388750879
139.08538003499421620290
139.22307201660123610054
139.54460164019724288664
139.70241995974777182710
139.81863868415615570484
139.97359698336740052582
139.93093320247075439511
139.86693753112587046417
139.54089323398940791776
139.21788190705888155208
138.24884792626727403331
  nan
  nan
  nan
  nan
  nan
  nan
  nan
139.53488372093022462650
139.21188630490956938957
139.10422049956935097725
139.05038759689924177110
139.01808785529718193175
139.10422049956935097725
139.07345884090071308492
139.07345884090071308492
138.99655469422910414323
139.01808785529715351004
139.05038759689921334939
139.10422049956935097725
139.21188630490956938957
141.21477937267411562061
143.54066985645931708859
143.77070298122927738405
143.88571954361427174263
144.09408288126823549646
144.23299177303752571788
144.33221241001561452322
144.40662788774915270551
144.66792765083738458998
144.92922741392561647444
145.19306418690871396393
145.63712443700345033903
145.90353534919441358397
146.16994626138537682891
146.61836066804346501158
147.15913505696838115000
147.51960799746632346796
147.88008093796423736421
147.96975095231147179220
147.96975095231144337049
148.24411442904542468568
148.61176148786893236320
148.79740505222540036812
148.89068863431492673044
149.07633219867136631365
149.45136970242174356827
149.81901676124527966749
149.81901676124527966749
149.63520674727570280993
149.63520674727570280993
149.92073974219957221976
150.30339280342406027557
150.68604586464851990968
150.87930498647909871579
151.06869892587303638720
151.53518646132607727850
152.09781305230904990822
152.38334604723291931805
152.57858228307827630488
152.67669845262304306743
152.67669845262304306743
152.67669845262301464572
152.67669845262301464572
152.67669845262304306743
152.97106236491566733093
153.36558118346329138149
153.66606195269406498483
154.17213482718801742521
154.77632387122667978474
155.48494065127204066812
156.38681655314792351419
157.39423112439231999815
158.11781015300468311580
158.74123427536204644639
159.37127459794271544524
159.79572281526020560705
160.22017103257766734714
160.43237712713670362064
160.53791579650516041511
160.53791579650516041511
160.22128196593945403947
160.00907587138038934427
159.68905539006959770632
159.36903490875880606836
159.15455309681647122488
159.26236748356822658934
159.47684929551058985453
159.80031105514254363698
160.22475927246003379878
160.33029794182849059325
160.33029794182849059325
160.33029794182849059325
160.22475927246003379878
159.80031105514254363698
159.37586283782505347517
158.84243467281794437440
158.31465220364043489099
157.89909369300215757903
157.48787566520380210022
157.07665763740544662141
156.66543960960711956432
156.35864931781549103107
156.05185902602380565440
155.78859076658363846946
155.57534542314337500102
155.44041450777203294820
155.44041450777203294820
155.44041450777203294820
155.44041450777203294820
153.09709167599658030667
150.75376884422109924344
150.75376884422109924344
150.75376884422109924344
151.13639262301353483053
151.13639262301353483053
151.13639262301353483053
151.13639262301353483053
151.26393388261101335956
151.51901640180597041763
152.28426395939087001352
152.28426395939087001352
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
133.33333333333334280724
133.63095238095237959897
133.73015873015870624840
134.08140283140281212582
134.41444353209058704124
134.63647066588242751095
134.88320862270441580222
135.30391353564976952839
136.16061135496443057491
137.10821917167601213805
138.05582698838759370119
139.10651882855313488108
140.25413506568344246261
141.40175130281372162244
142.56018025250483560740
143.48295775686705155749
144.16347676603137983875
144.76748044757761135770
145.46115414347104888293
145.90092335259012656934
146.16733426478108981428
146.34407516262484705294
146.25613369646470118823
146.08109635516524349441
145.90605901386575737888
145.55595981909391412046
145.28954890690295087552
145.28954890690295087552
145.02313799471198763058
144.84639709686823039192
144.67303879994014437216
144.58677637815139860322
144.50051395636265283429
144.67387225329073885405
144.67387225329076727576
144.23410304417168958935
144.06074474724357514788
143.88738645031548912812
143.88738645031548912812
143.88738645031548912812
143.88738645031548912812
143.62858727847284967538
143.36978810663021022265
143.19971622291402013616
143.02964433919783004967
142.69269440995731201838
142.35574448071682240879
142.18567259700063232231
142.01560071328444223582
141.76409165895847763750
141.51258260463254146089
141.18193122140758077876
140.85127983818262009663
140.68750650048201578102
140.44298380877620502361
140.03158307154609474310
139.54017721398827234225
139.13637827355665876894
138.73257933312510203905
138.41400653924171137987
138.09543374535832072070
137.93467927102543058027
137.77540302229965618608
137.53757958242141512528
137.61758470274912724562
137.77686095147490163981
138.01688655420588247580
138.33545934808927313497
138.49327766763980207543
138.41400653924171137987
138.33545934808927313497
138.25762622231093246228
137.94196965665435072879
137.39700204330108590511
136.47496536673105538284
135.55292869016105328228
135.01289412794906752424
134.62720276332160551647
134.29018867667284098388
133.94461406217891408232
133.46080960188740505146
133.19372500684357873979
133.33991813771564238778
134.08405434440692260978
134.08405434440692260978
134.08405434440695103149
133.93391014219224643966
134.03311644883731901245
134.36604786523719212710
134.36604786523719212710
134.63649537080030427205
134.53676741793333349051
135.14831340619164734562
135.63974408375170810359
136.24580468981230296777
136.86291087321626491757
136.25685026715567005340
135.76959744485193937180
135.25373928249740629326
134.79439029323782506253
134.11847104305758193732
133.44255179287736723381
133.02525399705464792532
132.26034984671665029055
132.04575314939330610287
132.77634675669924035901
133.74188896899781298089
135.07586908056705965464
136.40984919213627790668
137.87183749622991513206
138.14901186701328583695
138.34699356043000761929
138.49547983049251342891
138.89555664524095845991
139.29563345998937506920
139.87167032634880570185
140.44770719270826475622
140.85910792993837503673
141.10592671010513754482
141.35274549027187163119
141.59956427043863413928
141.76487902974031385384
141.93019378904205041181
141.76173017718218716254
141.59326656532238075670
141.50943396226415416095
141.42638851979802439018
141.26107376049631625392
141.09575900119460811766
140.93044424189289998139
140.76512948259119184513
140.51831070242442933704
140.27149192225769525066
140.02467314209096116429
139.86089980439032842696
139.77939578352530247685
139.69789176266027652673
139.61638774179525057662
139.53488372093022462650
139.53488372093022462650
139.61638774179525057662
139.61638774179525057662
139.61638774179525057662
139.61638774179525057662
139.61638774179525057662
139.69789176266027652673
139.77939578352530247685
139.77939578352530247685
139.69789176266027652673
139.61714240865512692835
139.62889364975868033980
139.64456197123007541450
139.01318240688198102362
138.27313081471385203258
137.89037380008653599361
137.38065732987308820157
136.87094085965961198781
136.61609886169839001013
136.88822370579319454009
137.08259859443234063292
137.44006167892169401057
137.79752476341107580993
137.85394015876812545685
138.09176359864636651764
138.41033639252975717682
138.65036199526070959109
138.81111646959357130982
139.05337496479148740036
139.29563345998937506920
139.45638793432223678792
139.61714240865512692835
139.61714240865512692835
139.45638793432223678792
139.21636233159128437364
138.97633672886033195937
138.49842678833357467738
138.02051684780681739539
137.54698217454574660223
137.15057625488401527036
136.83491968922743353687
136.59926824389859234543
136.44288792696781342784
136.28650761003703451024
136.28650761003703451024
136.28650761003703451024
136.36363636363637397153
136.36363636363637397153
136.13432531593969088135
135.39321859657206914562
134.86758783966379837693
134.63827679196708686504
134.40896574427040377486
134.33183699067103589186
134.17827433710840523418
134.10114558350903735118
134.25332787760640940178
135.15081491390478163339
135.57827557183830435861
135.75313017013425564983
136.16265573476761119309
136.26549407290005433424
136.47024427765026644011
136.57308261578273800296
136.67592095391518114411
136.57119136571191120311
136.81085658864839160742
137.07061062617844982015
137.30843406605669088094
137.38626719183503155364
137.46410031761337222633
137.54193344339165605561
137.69831376032246339491
137.77686095147490163981
137.69758982307681094426
137.53831357435103655007
137.30049013447279548927
137.30049013447279548927
137.37903732562523373417
137.53685564517576267463
137.77688124790671508890
138.01690685063766750318
138.25693245336864833916
138.33620358176673903472
138.57402702164495167381
138.81185046152319273460
139.13338008511919952070
139.37563858031708718954
139.45714260118211313966
139.70396138134884722604
139.95078016151558131241
140.44218601907340371326
140.85358675630354241548
141.26498749353368111770
141.51413485589358742800
141.76328221825349373830
142.01242958061342847031
142.09626218367162664435
142.26472579553148989362
142.26472579553148989362
142.26472579553148989362
142.52106010103639732733
142.77739440654130476105
143.20876605334569831030
143.64013770015012028125
143.64013770015012028125
143.55550669134851204944
143.55550669134848362773
143.14410595411837334723
142.63862428625353118150
142.41117500846559096317
141.87452618301566076298
141.12321782738575848271
140.85899858754976321507
140.41863318782304759225
139.53790238836967319003
140.18691588785046064913
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
142.85714285714286120310
142.85714285714286120310
142.63146016700520135601
142.02032409721650196843
141.65364245534328802023
140.98048186263318370948
140.49965286784023987821
140.13903112174554621561
139.48372312698796804398
138.82841513223047513748
138.25773814627456204107
137.93620852267855525497
137.38116982855245851169
137.14766075802234013281
136.91415168749222175393
136.68064261696210337504
136.44713354643198499616
136.21362447590186661728
135.98011540537174823839
135.67017243487833866311
135.59373853491501904500
135.36648417163121393969
135.13922980834746567780
134.91197544506368899420
134.75979315096631694360
134.75979315096631694360
134.75979315096631694360
134.75979315096631694360
134.75979315096631694360
134.83486522015272157660
134.90993728933915463131
134.98500935852555926431
134.98500935852558768602
134.83282706442821563542
134.90995581802755509671
135.06351847159021417610
134.98777007745616174361
134.98777007745616174361
134.98777007745616174361
134.98777007745616174361
134.98777007745616174361
134.98777007745616174361
134.68338696057304559872
134.45407591287636250854
134.52982430701041494103
134.60557270114443895181
134.68132109527849138431
134.75706948941251539509
134.83281788354656782758
134.90856627768062026007
135.13582064096439694367
135.52151200559188737316
135.91072610448338764400
136.37994532370260003518
136.92991389692699044645
137.47988247015138085771
138.02985104337577126898
138.57981961660016168025
138.81332868713028005914
138.57981961660016168025
138.19060551770866140942
137.72138629848942059652
136.94619250003981392183
136.17099870159020724714
136.09024934758502922705
135.59815872282143800476
135.26207202677792906798
136.44716144716144867743
137.39881732839481287556
138.35047320962812023026
139.27639913555404405088
140.20232506147999629320
140.09565156770972293998
139.92325689930675025607
139.79396089800451363772
139.21792403164505458335
138.97340133993927224765
138.64887352790577779160
138.64887352790577779160
138.72962288191092738998
138.97188137710884348053
139.13413475197904745073
139.37865744368482978643
139.70318525571832424248
139.78393460972347384086
140.00323270736006975312
140.18895667710526709016
139.76419295192741287792
139.02414135975928388689
138.38692810572362645871
137.64002242924047436645
136.89311675275732227419
136.26173718840922788331
136.01422444464546401832
135.82858988682261269787
135.59719410748164136749
135.52076020751832174938
135.36719755395566266998
135.29006880035632320869
135.68647472001805454056
136.16000939327912533372
136.78572636063756817748
137.41144332799598259953
137.88066254721519499071
138.19060551770863298771
138.50054848820207098470
138.65551036757975111868
138.49475589324686097825
138.33400141891399925953
138.17324694458113754081
137.85467415069774688163
137.61685071081950582084
137.53830351966706757594
137.61757464806515827149
137.77539296761568721195
137.77539296761568721195
137.93614744194854893067
138.01615256227626105101
138.25397600215450211181
138.49179944203271475089
138.72962288191092738998
138.80962800223863951032
138.88963312256635163067
138.96963824289406375101
138.88888888888888573092
138.88888888888888573092
138.72961264016311133673
138.57033639143730852084
138.41106014271150570494
138.25178389398573131075
138.09250764525995691656
138.25476102013016088677
138.41701439500033643526
138.49776374900551445535
138.57703487740360515090
138.65630600580169584646
138.73557713419975812030
138.97560273693071053458
139.13487898565651335048
138.97262561078630938027
138.49471567025955209829
137.86899870290110925453
137.63334725757226806309
137.39769581224339844994
137.16204436691452883679
136.84347157303113817761
136.76346645270342605727
136.76346645270342605727
137.15987237236515738914
137.78558933972357181119
138.18199525938533156477
138.41764670471417275621
138.57402702164495167381
138.65257421279738991871
138.81332868713028005914
138.89407804113545807922
138.89407804113545807922
138.97558206200045560763
139.05708608286550997946
139.21784055719837169818
139.53937018079435006257
140.11004716675026315897
140.44069854997519541939
140.77134993320015610152
140.85285395406521047335
140.77134993320015610152
140.60909655832998055303
140.76255553510137019657
140.85849547630985512114
140.59417561422586118169
140.19769582109987027252
139.53689616588985700218
139.21188630490956938957
138.88888888888888573092
  nan
  nan
  nan
  nan
  nan
  nan
139.53488372093022462650
139.53488372093022462650
139.31955211024978780188
139.05187606425414514888
138.89127043665675387274
138.67850518039440999019
138.43676177888991674081
138.25545422776156101463
138.01542862503060860035
137.77540302229965618608
137.61612677357385337018
137.53685564517576267463
137.53685564517576267463
137.61612677357385337018
137.77394509312438231063
138.01176853300262337143
138.17104478172839776562
138.33032103045420058152
138.57034663318515299579
138.97414557361673814739
139.21640406881462581623
139.37715854314751595666
139.53791301748037767538
139.61866237148552727376
139.69941172549070529385
139.81520070214810402831
139.86192019901776006918
139.66529015431427751537
139.92979376919703327076
140.32470381746378507160
140.71961386573056529414
141.01579640193068598819
141.50943396226415416095
141.50943396226415416095
141.50943396226415416095
141.34334307733192304113
141.28797944902115091281
141.17725219239969192131
140.84507042253520125996
140.84507042253520125996
140.84507042253520125996
  nan
  nan
  nan
  nan
  nan
  nan
175.78259373925007480466
176.21564352928965035971
176.47547340331340137709
176.64869331932922591477
176.77242183076910464479
176.86521821434897105974
177.25682222437404789162
177.91735910875365789252
178.12404066210501696332
178.22558195588780449725
178.36773976718367862304
178.58097648412754665515
178.93637101236731723475
175.94454866013006721914
172.60051441721941500873
167.91510611735330371630
167.60299625468164208542
167.60195007637099706699
167.60125262416391933584
168.00886588564699763992
168.31457583175932768427
168.28246868733967289700
168.23965916144689458633
168.55425766040292501202
169.02615540883698486141
169.50228542407316467688
170.45454545454546746441
170.45454545454546746441
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
143.54066985645931708859
143.54066985645931708859
143.54066985645931708859
143.71319470003680862646
143.81670960618330923353
143.88571954361430016434
143.83642673116358423613
143.83642673116358423613
143.64205886405935075345
143.64205886405935075345
143.74064448896075418816
143.74064448896078260987
143.74064448896078260987
143.64205886405937917516
143.64205886405937917516
143.71564765989808165614
144.05907784054235776239
144.23243613747044378215
144.23267426699916882171
144.23299177303752571788
144.23343628149117989778
144.40662788774915270551
144.69528056484577405172
144.92753623188406209010
142.55722605986727558047
140.18691588785046064913
140.18691588785046064913
140.18691588785046064913
140.18691588785046064913
140.18691588785046064913
140.00148345942736227698
139.86240913811008113043
139.62015064291216503989
139.37789214771424894934
139.21563877284407340085
139.05338539797386943064
139.05338539797386943064
139.05338539797386943064
139.21563877284407340085
139.54320690701598550731
140.11924377337544456168
140.86698393643817439624
141.53471897917322053218
142.37749136320775278364
142.88297303107259494936
143.38845469893743711509
143.72223307009898007891
143.72223307009898007891
143.38845469893743711509
142.88297303107259494936
142.21523798833754881343
141.13464216442477550117
140.38690200136204566661
139.63916183829928741034
138.90530665238932783723
138.33991507833917466996
137.93983826359075806067
137.61830863999477969628
137.45903239126897688038
137.69685583114721794118
137.77686095147493006152
137.69758982307683936597
137.77613701422927761087
137.77613701422927761087
137.77613701422927761087
137.69758982307683936597
137.61904263192437269936
137.61904263192437269936
137.69979198592955071945
137.85906823465535353535
138.01834448338109950782
138.17616280293162844828
138.33398112248218581044
138.49179944203271475089
138.81037223591610541007
138.89112158992128343016
138.89112158992128343016
138.73184534119548061426
138.41618877553892730248
138.18053733021008611104
137.94488588488121649789
137.55567178598968780534
137.00570321276529739407
136.37998624540688297202
135.67919720886203549526
135.29350584423454506577
135.13994319067188598638
135.06281443707251810338
134.98568568347320706380
135.13924833703586614320
135.29281099059852522259
135.67850235522598723037
136.13926578903988229285
136.60422828206543499618
136.99344238095696368873
137.30622257988517276317
137.61900277881338183761
137.93465934446993514939
138.41256928499669243138
138.73409890859267079577
139.13789784902425594737
139.46242566105775040342
139.78695347309124485946
140.19452672759084066456
140.68593258514866306541
141.09733332237880176763
141.34648068473870807793
141.76569993081483289643
142.10264986005535092772
142.26952790557967887253
142.43640595110400681733
142.52023855416223341308
142.60486956296384164489
142.77494144668003173138
143.11837162732427941592
143.29172992425242227910
143.46508822118050829886
143.72142252668541573257
143.97775683219032316629
144.23409113769525902171
144.40579443439855822362
144.84556364351760748832
145.11197455570857073326
145.19991602186871659796
145.19991602186868817626
145.19991602186871659796
145.19991602186868817626
145.02821272516541739606
144.85650942846208977244
144.33129934442848707477
143.80608926039485595538
143.37471761359046240614
143.03128743294618629989
142.85958413624288709798
142.51941722767975306851
142.35095361581994666267
142.18249000396008341340
142.01402639210024858585
141.84556278024041375829
141.67868473471608581349
141.67868473471608581349
141.67868473471608581349
141.84714834657592064104
142.10105283335030890157
142.35495732012472558381
142.52342093198456041137
142.60725353504275858540
142.77413158056708653021
142.77413158056708653021
142.68950057176547829840
142.66555167385439517602
142.51969864342026994564
142.31550440081247188573
141.65105257356992751738
140.86480320147740030734
140.46616742170152747349
140.06753164192565463964
139.66889586214978180578
139.75523253309987126158
139.91092366016206938184
139.91092366016206938184
139.97359698336737210411
140.31854679478740877130
140.35145452152164580184
140.40630073274539313388
140.51599315519283095455
140.84507042253520125996
  nan
138.88888888888888573092
138.88888888888888573092
138.67554190134833902448
138.72887864823348991195
138.76088069636458044442
138.99855322827889381188
139.16831932250337899859
139.29564389317178552119
139.45789726804196106968
139.62015064291216503989
139.70015576323987716023
139.70015576323987716023
139.78090511724502675861
139.94621987654676331658
140.19536723890666962689
140.44451460126660435890
140.69366196362653909091
141.02744033478808205473
141.52347208081982898875
142.01950382685157592277
142.60648951558141561691
143.03375229928394674062
143.37718247992819442516
143.72061266057249895312
144.06404284121674663766
144.32284201305941451210
144.58164118490205396483
144.84044035674469341757
144.58410605123978598385
144.24067587059550987760
143.81341308689303559731
143.38615030319053289531
142.95888751948803019332
142.53162473578555591303
142.10436195208308163274
141.67709916838057893074
141.59326656532238075670
141.50943396226415416095
141.50943396226415416095
141.67789757412398898850
141.84636118598382381606
142.01482479784365864361
142.09865740090188523936
142.09865740090188523936
142.09865740090185681765
142.09865740090185681765
142.01561195843578389031
141.68183358727421250478
141.26655119524764359085
140.77051944921589665682
140.27911359165807425597
139.87154033715845002916
139.38469595426073510680
138.97712269976113930170
138.73260000805532854429
138.65109598719033101588
138.65109598719033101588
138.65109598719033101588
138.73110110751804313622
138.73110110751804313622
138.81037223591610541007
138.73110110751804313622
138.57182485879224032033
138.33179925606128790605
138.09177365333033549177
137.85395021345209443098
137.61612677357385337018
137.38047532824498375703
137.06769512931680310430
136.83418605878665630371
136.60067698825656634654
136.36716791772641954594
136.13365884719632958877
135.97869696781862103308
135.82373508844096932080
135.74660633484162985951
135.82373508844096932080
135.82373508844096932080
135.82373508844096932080
135.90086384204033720380
136.13437291257045558268
136.36788198310057396156
136.36788198310057396156
136.36788198310057396156
136.21431932953791488217
136.06213703544054283157
135.90995474134319920267
135.75639208778051170157
135.44644911728710212628
135.21294004675695532569
135.36790192613466388138
135.44503067973397492096
135.52146457969729453907
135.59721297383134697156
135.67296136796539940406
135.67296136796539940406
135.67296136796539940406
135.67296136796539940406
135.51799948858771927007
135.44087073498837980878
135.51799948858774769178
135.67156214215037834947
135.74799604211369796758
135.74799604211369796758
135.67224764797964553509
135.52006535388230190620
135.36788305978492985560
135.29144915982161023749
135.13788650625895115809
134.98432385269632050040
134.75706948941251539509
134.91063214297517447449
135.29632350760266490397
135.68201487223015533345
135.83419716632749896235
135.83419716632749896235
135.75844877219344652985
135.60762830887298946436
135.53322354696823026643
135.22884043008511412154
134.84314906545762369205
134.45745770083016168428
134.23020333754635657897
134.07938287422592793519
134.00431080503949488048
133.85615973045037208067
133.78241341776600847879
133.56116577774082543328
133.26484606852923775477
132.96852635931765007626
132.74727871929246703075
132.52603107926728398525
132.37788000467816118544
132.37788000467816118544
132.30478343924212936145
132.30478343924212936145
132.16050550222425385982
131.94502953976495973620
131.72955357730566561258
131.58527564028779011096
131.43972370735809818143
131.43972370735812660314
131.33702847864213936191
131.20010150702080409246
131.23924844597954120218
131.44036490530055516501
131.77555900416885492632
132.16115510013972311754
132.74336283185840557053
  nan
132.74336283185840557053
132.74336283185840557053
132.74336283185840557053
132.74336283185840557053
132.74336283185840557053
132.74336283185840557053
132.16621777606772525360
131.59504318637141295767
131.13332714173887438847
130.67161109710633581926
130.09696450476059226276
129.96586122523152084796
129.79293316546517189636
129.66323712064041728809
129.52268239800173432741
129.55301633663250981954
129.40605529128865214261
129.20030982780727413228
129.03285481722659255865
128.94035814710670706518
122.38553957512074532588
115.83072100313479779743
  nan
  nan
  nan
  nan
  nan
  nan
116.26364269234973392031
  nan
130.81450541104993590125
130.71957471046135879078
130.66261629010821820884
130.62464400987278168031
130.59752095256175152826
130.50659706725778619330
130.36481744949563221780
130.01491262360826794975
129.73620582316232230369
129.66562423084161537190
129.55574446257674026128
129.40923810489024958770
129.31512931512929753808
129.40923810489024958770
129.50334689465123005903
129.87495596191246249873
130.24656502917369493844
130.34067381893467540976
130.35411793175765637898
130.43539904181636757130
130.57717865957852154679
130.57717865957852154679
130.57717865957852154679
130.57717865957852154679
130.50659706725778619330
130.29666984202918911251
130.08674261680062045343
129.80561736613060475065
129.52449211546058904787
129.31456489023199196708
129.10463766500339488630
129.03405607268268795451
128.89471043977482622722
128.89471043977482622722
128.89471043977482622722
128.82593086455747766195
128.68895707220974600204
128.55198327986201434214
128.55198327986201434214
128.48201014954406673496
128.69193737477263539404
128.97306262544265109682
129.54530869125096614880
129.73676304641136880491
130.08296114279906419142
130.56763847774186615425
131.01743887881389127870
131.12939588732260176585
131.12939588732257334414
131.01547904661632060197
130.43576890168876047937
130.34149572976227204890
130.27415774981480467432
130.22365426485418993252
130.08187464709203595703
130.15245623941274288882
130.22365426485418993252
130.22365426485418993252
130.22365426485418993252
130.29423585717489686431
130.36481744949563221780
130.43539904181636757130
130.50598063413707450309
130.50598063413707450309
130.43478260869565588109
130.43478260869565588109
130.43478260869565588109
130.29422788605700134212
130.29422788605700134212
130.22364629373623756692
130.15306470141553063513
130.29608529638119307492
130.43910589134688393642
130.58212648631254637621
130.65332451175396499821
130.72329764207194102710
130.65271604975120567360
130.65271604975120567360
130.58274291943325806642
130.36914073214686027313
130.15553854486046247985
130.01251794989477161835
129.94131992445335299635
130.08309954221550697184
130.29670172950190476513
130.43848134726403031891
130.72205666486840414109
130.93565885215480193438
131.14926103944119972766
131.14926103944119972766
131.14926103944119972766
131.14926103944119972766
130.93565885215480193438
130.92584342928734031375
130.81699277276504744805
130.62629864614413577328
130.43560451952325252023
130.43560451952325252023
130.43560451952325252023
130.34067381893467540976
130.43478260869565588109
130.68104085150625337519
130.86573453361427255004
131.15430706150959849765
131.36978302396889262127
131.65835555186424699059
131.94692807975957293820
132.08994867472523537799
132.32640096987233846448
132.35436941345605532661
132.39352523447325893358
132.30606583512695806348
131.73395072556692753096
130.87569622449888129267
130.29855116870820097574
130.29855116870820097574
130.55463040865078028219
130.62958647591349858885
130.68312652395829331908
130.72328155999187515590
130.79386315231261050940
130.79386315231261050940
130.65330842967392754872
130.36973311206958214825
130.08615779446520832607
129.80503254379519262329
129.52390729312520534222
129.24278204245518963944
129.03285481722659255865
128.96348231463667843855
128.96348231463667843855
128.96348231463667843855
128.89410981204679274015
128.89410981204679274015
128.96348231463667843855
129.10282794754454016584
129.31275517277310882491
129.52268239800173432741
129.66323712064041728809
129.80379184327910024876
130.01371906850766890784
130.22364629373623756692
129.94968369876255565032
129.47221998600474535124
128.79287215991774928625
128.11352433383081006468
127.56547902875226441211
127.21857801117594988227
126.87167699359962114158
126.52477597602330661175
126.59239227605937117005
126.79353656356160229279
127.19417758920262429001
127.45900809767721284516
127.52549038679538284669
127.39082838841132172547
127.25616639002726060426
127.12150439164321369390
126.92036010414098257115
126.71921581663873723755
126.44987731200612302018
126.35433920403605156935
126.31634932453995645574
126.26316349324540055932
126.18338474630360224182
126.05042016806721960620
126.05042016806723381706
126.05042016806723381706
128.20512820512820439944
128.75773651635722671926
128.94193928676688187807
128.75773651635722671926
128.86825817860304255191
128.75773651635722671926
128.67879247189594593692
128.61958443854996403388
128.55139022141958093925
128.34504392648193515925
128.07108133150822482094
127.86822511599379481595
127.46073453355391791320
127.32492401638747026027
127.25672979925707295479
127.18853558212668986016
127.18853558212668986016
127.18853558212668986016
127.25615188216275441846
127.39081388054681553967
127.59195816804904666242
127.65957446808511122072
127.65957446808511122072
127.65957446808511122072
127.79654826043284288062
127.93352205278057454052
128.07049584512830620042
128.20746963747603786032
128.34444342982376952023
128.48141722217150118013
128.61839101451923284003
128.82473730945687862004
128.89410981204679274015
128.96348231463667843855
129.03285481722659255865
129.10222731981650667876
129.17159982240639237716
129.10282024718907223360
129.03404067197172366832
128.96466816938180954821
128.82651609157454686283
128.68836401376728417745
128.48201771882966681915
128.20805512385595648084
127.86704683053426379047
127.53178237393089489160
127.26244386929826646337
126.99080807856648789311
126.85499756140004024019
126.78680334426965714556
126.85499756140004024019
127.05958765378383645839
127.33122344451561502865
127.73526747633633249279
128.14275805877622360640
128.55373948241586390395
129.11127739499391964273
129.60119900753591082321
129.95110383342327509126
130.02047633601318921137
130.08984883860307490977
130.08984883860307490977
129.95169676079581222439
129.74357155267063035353
129.32184415725902226768
128.90011676184741418183
128.41440141879360226085
128.28640950325180369873
128.11575361586270105363
127.98783137766187678608
127.93350717079530909359
127.84296682601767258802
127.66188613646241378774
127.38910926794085298752
127.12092647454889515757
127.12016567229812835649
127.11978527117274495595
127.22774311055522389324
127.39064062631739204789
127.50699599471893463942
127.59426252102009868850
127.87058194627567786483
128.05462529845610220036
128.21062217023268203775
128.42901779071991086312
128.62137862137862498457
128.76012876012876517962
129.03762903762901714799
129.87012987012985831825
  nan
  nan
  nan
  nan
  nan
  nan
  nan
125.52301255230125320850
125.52301255230125320850
125.87610119520506657409
126.18673691335294506644
126.48130442429938113946
126.67768276493033852148
126.81795300823816319280
126.92315569071902814358
127.25842014732239704244
127.66246417914311450659
127.93409996987489307685
128.13869006225868929505
128.20688427938907238968
128.27507849651945548430
128.34327271364983857893
128.34327271364986700064
128.36300764343863534123
128.29761478286727083287
128.20606477806731504643
128.06873977086743821019
127.79711873653452869348
127.52549770220163338763
127.38910926794085298752
127.38910926794085298752
127.55231305537832042774
127.84531835074631089810
127.97532498733497163812
128.07282996477647429856
128.14044626481253885686
128.20806256484860341516
128.20806256484860341516
128.20806256484860341516
128.07225204768215576223
127.86590575274450998222
127.72893196039677832232
127.66015238517942975704
128.00705340275575849773
128.05669325056584284539
128.45315155636734516520
128.84960986216884748501
129.32334393944012163047
129.63913756830368129158
129.79567904527252153457
129.79567904527252153457
129.47715698762002034528
129.31815338980854335205
129.10822616457994627126
128.89829893935137761218
128.82771734703064225869
128.89829893935137761218
129.17942419002139331496
129.45813099046731053932
129.73683779091325618538
130.01554459135917340973
130.22547181658777049051
130.36481744949563221780
130.36481744949563221780
130.29423585717489686431
130.15245623941274288882
130.08187464709203595703
129.94131992445335299635
129.73139269922478433728
129.52146547399618725649
129.38211984108832552920
129.31214671077037792202
129.45392632853253189751
129.38395319821455586862
129.38395319821455586862
129.66752851581890126909
130.02047633601318921137
130.37342415620742031024
130.58335138143598896932
130.65332451175396499821
130.44157176367386341553
130.44157176367386341553
130.51215335599459876903
130.22857803839025336856
129.87563021819596542628
129.52268239800173432741
129.31275517277313724662
129.17340953986527551933
129.10403703727536139922
129.03466453468547570083
128.75595773423953005477
128.41494944091783736440
127.94552884005946680190
127.41127421430482513642
126.74895237887855614645
126.21469775312391448097
125.74527715226554391847
125.27585655140717335598
124.87521552576615135877
124.67743071563955936654
124.74280728467721246489
124.93894383058189134772
125.26314758615819755505
125.52576642118104643941
125.79059692965562078371
126.19123795529665699178
126.59187898093767898899
127.13067208438594946074
127.74464334143526400567
128.36388667195524249109
128.98313000247520676567
129.68016898089487654033
130.31016226096656396294
130.73188965637814362708
131.15361705178975171293
131.50964773688775721894
131.72512369934702292085
131.86940163636489842247
132.01367957338277392410
131.72762201860274444698
131.44156446382274339157
131.22796227653634559829
130.94438695893200019782
130.45020419602676042814
129.82021091595510142724
129.19021763588341400464
128.49317865746374422997
128.14924293217208628448
128.14924293217208628448
128.14924293217208628448
128.07987042958217216437
128.07987042958217216437
128.07987042958217216437
128.01282473123418981231
127.94634244211599138907
127.81281445464982482463
127.33535074189200031469
126.85788702913416159390
126.51976894928421302211
126.25043044465158459388
126.11690245718540381858
126.05042016806723381706
126.05042016806723381706
125.91911764705882603721
125.72298110115416136523
125.52684455524948248240
125.26640980283613657775
125.07027325693145769492
124.87413671102679302294
124.67800016512211414010
124.48186361921744946812
124.41702959432117836513
124.41702959432117836513
124.48186361921744946812
124.61099585062240180378
124.67582987551867290676
124.80604046945259710810
125.13570500282344255538
125.46536953619428800266
125.79503406956513344994
126.05765290458796812345
126.39009971309860702604
126.72254652160924592863
127.12318754725028213670
127.39025778672325373009
127.45787408675931828839
127.52549038679538284669
127.45844468844740049462
127.45844468844738628377
127.32378269006332516255
127.18912069167927825220
126.98626447616483403635
126.98626447616483403635
126.98626447616483403635
126.98626447616483403635
126.98626447616483403635
126.91978218704664982397
126.85329989792847982244
126.65551508780188783021
126.45773027767531004883
126.12528346916467114625
125.92413918166244002350
125.72299489416019468990
125.65651260504202468837
125.72299489416019468990
125.78947718327837890229
126.12192399178901780488
126.38675450026359214917
126.65158500873818070431
126.78511299620433305790
126.91864098367051383320
127.11978527117274495595
127.25444726955680607716
127.45730348507123608215
127.59427727741896774205
127.79886736980276396025
128.00345746218656017845
128.20804755457035639665
128.34385807173680404958
128.41205228886718714421
128.54902608121491880411
128.61780565643226736938
128.61780565643226736938
128.82773288166083602846
129.10885813233085173124
129.15935717882570088477
129.31839534110864065042
129.54104876830473358496
128.48133383162269183231
127.52364930000135245791
127.08903203483050958766
126.00207551309135567408
124.80120215064593480747
124.83433512553828848013
124.78390550772279254943
124.81091731925747012610
125.00381855382536855359
125.13295078523032088924
124.93516597510372889701
124.87033195020747200488
124.80549792531121511274
124.80549792531121511274
124.87033195020747200488
124.93570851924513931408
125.06701104025353288307
125.26479585038011066445
125.46258066050670265668
125.72519949552955154104
125.92133604143421621302
126.11912085156079399439
126.45156766007144710784
126.71863789954441870123
126.91978218704664982397
127.05444418543071094518
127.18910618381477206640
127.27579585564363640060
127.48002513690637726995
127.65957446808511122072
127.48002513690636305910
127.21183275357005015849
126.94364037023375146873
126.50037795888623293195
126.05711554753871439516
125.83200301229457807040
125.59887040435280880502
125.07352236127427147494
124.61959001016407455609
124.29538625458776834876
124.16408373357936056891
124.36186854370595256114
124.55965335383253034252
124.82227218885537922688
125.01678098929798466088
125.27505879290355039757
125.72899114401374731642
126.11967718870822352528
126.45212399721886242787
126.58678599560292354909
126.72144799398697045945
126.78849369233496702236
126.92090193342389170539
127.05331017451283059927
127.05331017451283059927
126.98682788539464638689
126.78568359789241526414
126.51861335841942945990
126.35561891418147695276
126.22844805524562161736
126.40799738642435556812
126.40799738642435556812
126.40799738642435556812
126.40799738642436977898
126.23292735841316414280
126.14575859969629334500
125.90798277128679671932
125.72965089997967425006
125.46258066050668844582
125.33017241941776376279
125.19776417832883907977
125.13183822635809860913
125.19721479539575170747
125.26259136443340480582
125.26259136443340480582
125.26259136443340480582
125.32851731640414527646
125.59558755587713108071
125.93085201248049997957
126.26611646908386887844
126.60138092568723777731
126.93664538229060667618
127.40212043282789977638
127.86759548336519287659
128.13693398799782130482
128.00227198961374597275
127.79941577409931596776
127.59655955858487175192
127.59655955858487175192
127.59655955858487175192
127.59655955858487175192
127.59655955858487175192
127.52836534145448865729
127.66302733983854977851
127.79768933822259668887
127.93235133660665781008
127.86415711947627471545
127.79596290234587740997
127.72776868521549431534
127.65957446808511122072
127.59195816804904666242
127.52434186801298210412
127.45672556797691754582
127.45672556797691754582
127.45672556797691754582
127.45672556797691754582
127.45672556797691754582
127.45672556797691754582
127.52434186801298210412
127.52434186801298210412
127.59195816804904666242
127.72893196039677832232
127.86590575274450998222
127.93409996987489307685
128.00229418700527617148
127.86763218862122926112
127.89735472012637274020
128.02713982884799293060
128.10065290100058632561
127.04093796431854457296
125.98122302763654545288
125.34019738661088183562
124.80377425604550012395
124.59192107030321494676
124.57348885872431765165
124.56032299331081958371
124.42238138457906870826
124.42238138457906870826
124.29640506812657463342
123.97752751710615370939
123.71924971350060218356
123.33499559344252816118
123.08095206731837834013
122.82690854119424272994
122.82690854119424272994
122.82690854119424272994
122.82690854119424272994
122.82690854119424272994
122.70093222474173444425
122.70093222474173444425
122.70093222474173444425
122.70093222474173444425
122.63768591832604215597
122.70145492148898824780
122.89120024110442841447
123.21007779212484933851
123.52895534314524184083
123.84783289416566276486
124.29801296619447725789
124.74819303822329175091
125.33678738771752136927
125.59722214013089569562
125.85765689254425581112
125.98895941355266359096
126.12026193456105715995
126.25156445556946493980
126.25156445556946493980
126.25156445556946493980
126.25156445556946493980
126.45270874307169606254
126.58623673053787683784
126.65271901965604683937
126.71920130877423105176
126.71920130877423105176
126.65327535680349058111
126.65327535680347637026
126.38620511733050477687
126.11913487785751897263
125.91968093842061193754
125.78727269733167304366
125.65486445624274836064
125.58893850427199367914
125.65486445624274836064
125.65486445624274836064
125.65486445624274836064
125.65486445624274836064
125.65486445624274836064
125.85431839567966960658
126.05377233511659085252
126.25322627455351209846
126.38675426201967866291
126.52028224948585943821
126.52028224948585943821
126.65269049057478412124
126.65269049057476991038
126.32302595720392446310
125.99336142383309322668
125.79390748439617198073
125.59445354495925073479
125.52740784661125417188
125.59333379858199464252
125.33071496355915996901
125.20050436962523576767
125.33071496355915996901
125.59333379858199464252
125.59333379858199464252
125.59333379858200885337
125.72799579696606997459
125.92914008446828688648
126.12527663037296576931
126.32141317627764465215
126.38733912824838512279
126.25493088715946043976
126.25493088715946043976
126.45438482659638168570
126.45438482659636747485
126.38676852656031712741
126.52029651402648369185
126.65382450149266446715
126.78735248895883103160
126.98680642839575227754
127.18626036783265931263
127.32207088499912117641
127.39026510212951848189
127.52607561929596613481
127.66188613646241378774
127.72950243649847834604
127.79711873653454290434
127.79711873653452869348
127.79711873653452869348
127.72892451940414559886
127.66073030227374829337
127.59253608514336519875
127.52434186801298210412
127.52434186801298210412
127.59253608514336519875
127.72834660230981285167
127.86415711947627471545
127.93235133660665781008
128.00054555373702669385
128.20689184867467247386
128.41323814361231825387
128.61958443854996403388
128.75773651635722671926
128.82651609157454686283
128.82651609157454686283
128.69070557440812763161
128.55489505724165155698
128.34854876230400577697
128.21039668449677151330
128.07224460668950882791
127.93409252888226035338
127.79711873653454290434
127.79711873653454290434
127.93292925370099055726
128.06873977086743821019
128.13693398799782130482
128.13693398799782130482
128.20571356321516987009
128.27449313843249001366
128.41146693078025009527
128.48024650599757023883
128.54902608121491880411
128.54902608121491880411
128.41321556404847115118
128.27740504688202349826
128.07281495449825570176
128.00403537928087871478
127.79944528689709670743
127.59485519451330048923
127.45788140216558304019
127.45788140216558304019
127.52549770220163338763
127.59311400223769794593
127.59311400223768373507
127.39025778672325373009
127.19080384728633248415
126.99134990784941123820
126.72427966837642543396
126.38901521177305653509
126.12194497230007073085
125.85487473282709913747
125.72134674536093257302
125.72134674536093257302
125.98841698483391837726
126.46183351924452153980
126.66128745868144278575
126.72721341065219746724
126.79313936262295214874
127.06020960209593795298
127.26135388959815486487
127.26135388959815486487
127.06020960209592374213
126.65271901965606105023
126.51919103218988027493
126.51919103218988027493
126.58567332130806448731
126.58567332130806448731
126.58567332130806448731
126.72033531969211139767
126.85386330715829217297
126.92034559627646217450
126.98682788539464638689
127.05331017451283059927
127.18797217289687750963
126.98682788539464638689
126.98682788539464638689
127.12380167774237804679
127.12453847916320626155
127.21491514552162982454
127.34144247842341712840
127.53123347777611229503
127.48845314767311265314
128.20746963747603786032
128.75536480686696449993
  nan
  nan
  nan
  nan
125.52301255230125320850
125.52301255230125320850
125.34867503486749740205
125.13183822635809860913
124.89885901083853525506
124.74353953382551196682
124.78017674327900010667
124.74282062547285931942
124.54831182503025388542
124.35380302458763424056
124.22467079318266769405
124.22467079318266769405
124.41917959362528733891
124.67961434603864745441
124.81091686704705523425
125.00705341295173411709
125.33397045448326423411
125.66088749601480856199
125.92132224842816867749
126.18394108345103177271
126.24986703542177224335
126.24986703542177224335
126.18394108345101756186
126.11801513148027709121
126.05169179583278094015
125.90036809173115273097
125.82502414662171474902
125.45140467058568845005
125.15376495354182395658
124.85612523649800209569
124.85612523649800209569
124.93146918160742586679
125.07133805491488942607
125.13726400688565831842
125.07188743784799100922
125.20101966925295755573
125.33015190065790989138
125.65706894218945421926
125.78947718327837890229
125.99062147078061002503
126.39811205322048692778
126.80560263566036383054
127.34439573910863430228
127.88318884255691898488
128.21563565106754367662
128.35029764945159058698
128.48495964783566591905
128.48495964783566591905
128.27861335289802013904
128.00465075792430980073
127.59716017548446131968
126.86546583746826399874
126.27687148797403438039
125.50161091428120130331
124.85028655475015568754
124.38870751483455023845
123.92712847491895900021
123.53316573503943232026
123.27273098262605799391
123.33649998578900408575
123.46351529536764246586
123.71342782598193821286
123.83940414243443228770
123.77563513927150040672
123.90476737067645274237
124.16520212308981285787
124.55916486296936795952
124.95312760284889463946
125.34709034272842131941
125.80482208577089409118
126.12902584134721450937
126.58675758438968728115
126.85158809286426162544
126.98511608033044240074
126.96604065354954116174
126.94060675117503933507
126.90499928785072825121
126.85158809286426162544
127.66656925774401543094
127.93819029207691073680
128.20981132640980604265
128.34504392648193515925
128.20795003480256468720
128.11655410701632717974
128.05127130145473302036
127.86764719889947627962
127.39423066448884469537
127.05896620788547579650
126.85610999237103158066
126.74132935298329982743
126.58828850046633363036
126.37403130694258379663
126.05264551665696615146
125.87610119520506657409
126.05264551665695194060
126.58227848101266488356
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
126.58227848101266488356
126.31634932453994224488
126.22770627238237750589
126.18338474630360224182
126.26316349324540055932
126.31634932453994224488
126.35433920403603735849
126.38283161365812645727
126.38283161365812645727
126.44931390277631066965
126.51579619189448067118
126.58227848101266488356
126.58227848101265067271
126.73617790773730007459
126.89007733446193526561
126.94137714337016120680
127.01319687584164341843
127.12092647454889515757
127.30047580572762910833
127.65957446808511122072
127.30047580572762910833
126.94137714337016120680
126.76106700994064624410
126.98569387447676604097
126.90501079578393728298
126.85122207665538951460
126.81280156299213501825
126.71750388862653835531
126.71750388862653835531
126.71750388862653835531
126.58397590116035758001
126.38283161365812645727
126.31634932453994224488
126.24986703542177224335
126.05208222529518025112
126.05208222529518025112
125.98559993617701024959
125.91911764705882603721
125.91911764705882603721
125.91911764705882603721
125.91911764705882603721
125.91911764705882603721
126.31975867269986224528
126.31975867269986224528
126.45328666016602880973
126.72262516479864302710
126.99196366943127145532
127.26130217406389988355
127.59942025391384845534
127.93753833376382544884
128.07569041157108813422
128.48318099401095082612
128.75714358898463274272
128.89529566679189542810
128.82710144966151233348
128.75890723253112923885
128.55431714014733302065
128.34972704776353680245
128.14338075282589102244
128.07400825023597690233
128.07400825023600532404
128.07400825023597690233
128.28035454517362268234
128.48670084011126846235
128.68213752187054410570
128.83734954148931706186
128.53212641066303945081
128.07036180286792159677
127.91247371394536003208
127.75458562502277004569
127.91521959375269545944
128.07585356248262087320
128.16079246803064961568
128.09259825090026652106
128.29205219033718776700
128.55912242981020199295
128.49092821267979047661
128.53175678518715585597
127.83007534855939013596
127.12839391193162441596
126.66662930413652077277
126.36140617331024316172
126.05874273505358473813
125.75351960422733554879
125.44829647340107214859
125.39225941422594701180
125.26204882029202281046
125.13183822635809860913
124.87356042275254708329
124.61528261914696713575
124.48615038774201480010
124.35593979380809059876
124.22572919987416639742
124.16089517497790950529
124.16089517497789529443
124.16089517497790950529
124.22466417814085559712
124.28843318130380168895
124.35273138781249713247
124.41756541270875402461
124.48239943760502512760
124.54723346250128201973
124.87680257288100449387
124.87680257288100449387
124.94217914191865759221
125.00755571095632490142
124.69642468745969665633
124.38529366396309683296
124.51882165142926339740
124.65234963889542996185
124.14831738083091750013
123.64428512276640503842
123.84373906220332628436
124.04319300164024753030
124.54722525970475999202
124.79510997678566752711
124.27413192732748825620
124.00930141885291391191
124.51333367691742637362
124.95253191008566773235
124.62286737671483649592
124.69048367675090105422
125.02293048526153995681
125.08617679167724645595
125.14942309809293874423
125.55006412373397495230
125.95070514937499694952
126.16061619916715130785
126.44049759889003325952
126.19668222505102050945
125.83095916429249427893
126.62234884457105010824
128.20512820512820439944
128.20512820512820439944
127.93235133660665781008
127.84142571376612806944
127.26182242339990580149
126.91406044918016959855
126.85876345448558311091
126.81926560113230095794
126.14904363580859580907
125.30558884498537963736
124.97314203647474073477
124.64069522796410183219
124.57531865892644873384
124.50994208988879563549
124.44345980077062563396
124.13791427502175679365
124.58465167443495147381
125.21008403361345528992
125.26260504201681555969
125.35014005602242548321
125.52521008403361690853
126.05042016806723381706
  nan
  nan
  nan
  nan
  nan
  nan
125.00000000000000000000
125.52521008403361690853
125.70028011204482254470
125.78781512605041825736
125.84033613445379273799
125.84033613445379273799
125.84033613445379273799
124.62979262851386863531
123.55930514498290051506
123.12703347088115890529
122.69476179677941729551
122.78340484893698203450
122.87204790109454677349
123.17604105808104009157
123.40403592582092073826
124.20690153846913972302
125.00976715111735870778
124.94652084470166641950
125.07458805437329374399
124.81196921935045907048
124.35803686824024794078
124.10188932725665722501
124.23319184826505079400
124.36449436927345857384
123.86046211120893190127
123.61257739412803857704
124.14683201988266603166
124.68108664563729348629
124.93723418662091262377
125.19338172760451755039
124.80593166561250484392
124.41848160362050634831
125.46130696513330349262
126.50413232664608642608
126.36715853429835476618
126.23018474195062310628
126.36148726295903088612
126.49278978396742445511
127.08138413346168249518
127.66997848295591211354
127.43564043372302307944
127.12318970141249963035
127.01591274807796594359
126.85499731807617251889
127.12318970141249963035
127.65957446808511122072
127.65957446808511122072
127.11864406779660896518
127.11864406779660896518
128.41388498260675987694
129.06150544001184243825
127.94056237670083930880
127.19326700116016581887
127.41642383054684728450
127.58379145258686548914
127.65140775262293004744
127.71902405265898039488
126.90364417623433723747
126.41792883318052531649
126.87566057622301229912
127.00372778589463962362
126.46947316014001216899
125.80715132471372896816
125.28180328163519163809
125.41877707398292329799
125.95303169973755075262
126.02064799977361531091
125.86379141862747133018
126.09420202159374468920
126.41677686574652739182
127.15677355131896320017
128.39010136060633726629
128.20746963747603786032
127.65957446808511122072
  nan
  nan
  nan
  nan
  nan
121.45748987854250344753
123.22874493927125172377
123.81916329284750588613
124.11437246963562586188
124.29149797570849500516
124.40958164642374583764
124.49392712550606177047
124.55718623481780582551
124.74385245901639507338
124.48770491803279014675
125.23825076956117641203
125.98879662108954846644
126.05417319012721577565
126.11954975916486887400
126.38438026763944321829
126.64921077611401756258
126.64921077611401756258
126.64921077611401756258
126.29930595022665329452
125.94940112433930323732
125.75489232389668359247
125.56038352345407815847
125.33777487426229413359
125.04096334200657736346
125.45899207598162661270
126.61990110292882150134
126.72994842327655362624
126.62083767586793214832
127.35936413973290370905
128.20746963747603786032
128.20746963747603786032
128.20746963747603786032
128.20746963747603786032
128.02483791434573845436
127.65957446808511122072
126.85499731807617251889
126.05042016806723381706
126.05042016806723381706
126.05042016806723381706
126.05042016806723381706
126.05042016806723381706
126.05042016806723381706
126.05042016806723381706
  nan
  nan
  nan
  nan
  nan
  nan
  nan
125.00000000000000000000
125.00000000000000000000
124.48559670781894226366
124.22839506172840629006
124.38271604938272218988
124.31342039927899634222
124.26392350634776562401
124.68837987656490895461
125.02082668507554785720
125.02082668507554785720
125.21372791964346049554
125.21372791964346049554
125.02082668507554785720
125.55059994212155061177
126.08037319916752494464
125.85477301789357795769
125.55397277619499618595
125.66476733143399258097
125.83095916429249427893
126.61233125368146090750
127.39370334307044174693
127.67000749868493869599
127.94631165429943564504
127.67350501964207865058
126.88639079505692563998
126.32416634892467754980
126.62806615616746341857
126.89970194689925619969
127.03436394528330311005
126.82801765034567154089
126.35460111593503995664
126.22219287484611527361
126.67612522595631219247
127.13005757706649490046
127.13005757706649490046
127.13005757706650911132
127.06244127703044455302
126.99482497699436578387
127.53821464172294497530
128.08160430645153837759
128.14865000479952072965
128.37241736534048186513
128.30859279175274423324
128.21923838872990586424
128.49438696896322653629
128.90356715373081897269
128.62487588791509551811
128.07223703740390874373
128.20746963747603786032
127.56597570998083313043
127.13831309165068716993
127.05887957584525338461
126.99930443899118870377
126.52026822318913445997
126.52026822318913445997
126.52026822318913445997
126.65267646427804493214
126.85046127440463692437
126.81214944677719813626
126.76106700994063203325
126.76106700994064624410
126.85122207665540372545
127.21340313096443708218
127.48387808498370077359
127.75435303900296446500
127.93390237018168420491
127.81743689841239586258
127.73008779458541539498
127.73008779458541539498
127.66247149454935083668
127.52549770220161917678
127.45730348507123608215
127.35047138220595286384
127.29895420122609550617
127.33501622791200702522
127.38910926794085298752
127.47926433465561046887
127.65957446808511122072
127.65957446808511122072
127.11864406779660896518
127.11864406779660896518
127.11864406779660896518
126.98455267110063005020
126.79772617049395933009
126.67317517008950744639
126.58421016980061324375
126.51748641958393193363
126.45044072123593537071
126.38339502288793880780
126.31634932453994224488
126.31634932453994224488
126.38283161365812645727
126.44931390277631066965
126.51579619189448067118
126.58227848101266488356
126.58227848101266488356
126.58227848101266488356
126.64932417936066144648
126.71636987770864379854
126.78341557605665457231
126.85046127440463692437
126.91750697275261927643
126.98455267110063005020
126.85214443001170536718
126.65435961988511337495
126.38952911141053903066
126.05986457803970779423
125.73020004466886234695
125.20922199521066886518
124.75149025216819609341
124.23051220271001682249
123.84625808265194280011
123.52738053163153608693
123.20850298061111516290
122.95445945448699376357
123.01929347938325065570
123.27544102036685558232
123.59964477594317600051
123.98709483793517449612
124.43727490996398898915
124.88745498199280348217
125.33763505402161797520
125.78781512605041825736
125.72298110115414715438
125.40410355013375465205
124.95392347810494015903
124.56647341611292745256
124.37033687020826278058
124.30441091823750809908
124.23848496626676762844
124.17255901429601294694
124.30276960822993714828
124.68702372828799695981
125.07127784834607098219
125.37420044494818682779
125.52301255230123899764
125.52301255230125320850
125.52301255230125320850
125.52301255230126741935
124.66561492557788426438
123.80821729885451532027
123.11947648923968756662
123.58960736692976922768
123.97628840400406602384
124.06046163680836968979
124.34474142698820742226
124.62443355874125927585
125.07836590985145619470
125.53229826096163890270
126.05764630404017623277
126.20873863318877283746
126.32302631333669751257
126.69136601583807077986
126.85160247778077291514
126.94137714337016120680
127.12092647454889515757
127.65957446808511122072
  nan
  nan
  nan
  nan
  nan
125.00000000000000000000
124.74066390041494400975
124.65421853388659201300
124.61099585062241601463
124.58506224066391609995
124.56777316735822580540
124.40906130394374429216
124.35379640954580793277
124.28896238464955104064
124.35379640954582214363
124.41863043444207903576
124.54884102837600323710
124.54884102837598902624
124.48454282186730779358
124.54831182503025388542
124.48454282186730779358
124.35647561219566625823
124.22734338079069971172
124.09821114938574737607
123.90370234894312773122
123.83940414243443228770
123.77563513927148619587
123.71186613610855431489
123.71186613610855431489
123.77563513927150040672
123.90476737067645274237
124.09927617111907238723
124.29378497156169203208
124.48829377200431167694
124.81249752758060367341
125.00539876214850210090
125.19829999671641473924
125.19829999671641473924
125.06916776531144819273
125.00379119627379509438
124.93841462723614199604
124.87303805819848889769
124.74173553719008111784
124.87303805819848889769
124.87303805819848889769
125.00217028960344123334
125.06646849611213667686
125.07596399555673372106
125.08862466148285363943
125.10634959377941299863
125.13293699222428756457
124.87033195020747200488
125.00108508828279241243
125.13183822635809860913
125.26150627615062660425
125.20920502092049275689
125.26150627615061239339
125.26150627615061239339
125.26150627615061239339
125.31380753138076045161
125.26150627615061239339
125.17433751743374159560
125.26150627615062660425
125.52301255230125320850
126.58227848101266488356
126.05264551665695194060
125.87610119520506657409
125.91968093842061193754
125.94582878434994199779
125.96326068163615730100
126.05169179583279515100
126.05153284236209287883
125.98505055324392287730
126.05097650521466334794
126.11690245718540381858
126.18338474630358803097
126.24986703542177224335
126.31634932453994224488
126.31634932453994224488
126.38283161365812645727
126.44931390277631066965
126.51579619189448067118
126.58227848101266488356
126.64932417936066144648
126.78398617774470835684
126.91864817612876947805
127.12150439164321369390
127.32436060715764369888
127.52721682267210212558
127.79885261340388069584
128.07048840413565926610
128.13810470417172382440
128.13810470417172382440
128.13810470417172382440
128.00229418700527617148
127.86648366983882851855
127.73067315267238086562
127.52608306028858464742
127.38910926794085298752
127.38910926794085298752
127.32149296790478842922
127.25387666786872387092
127.25387666786872387092
126.98904615939414952663
126.53131441635167675486
126.07358267330920398308
125.74113586479856508049
125.40868905628792617790
125.14385854781335183361
124.81419401444250638633
124.54936350596793204204
124.48452948107166093905
124.61259669074330247440
124.67636569390624856624
124.54723346250128201973
124.54723346250128201973
124.61261003153893511808
124.74282062547285931942
124.94060543559945131165
125.07081602953337551298
125.20102662346728550347
125.59498936334682639426
125.92190640487837072214
125.98728297391602382049
125.98728297391602382049
126.11969121500494850352
126.18673691335294506644
125.74026278333967354683
125.29378865332641623809
124.64786058387622347254
124.06897821277402726992
123.43884260759179483102
122.80870700240956239213
122.10688993421847214904
121.52318187472349109157
121.64813189471549037535
121.96439543079483769361
122.28065896687417080102
122.59692250295351811928
123.09684751420184056769
123.59677252545014880525
123.90790354894676283948
124.03387986539925691432
124.09712617181496341345
123.96905896214332187810
123.77774544605598805447
123.58643192996864001998
123.33238840384450440979
123.07834487772035458875
122.95236856126786051391
122.82639224481533801736
122.82639224481533801736
122.89016124797828410919
123.14630878896190324667
123.40245632994549396244
123.65649985606964378348
123.91054338219379360453
124.22942093321420031771
124.54829848423460703088
124.80657628784017276757
125.06701104025353288307
125.26479585038011066445
125.46258066050670265668
125.60275964046051910827
125.78966494706561718431
126.23292735841316414280
126.58603470304592519824
126.67393597234026003662
126.58603470304592519824
126.32232162287714061222
126.05860854270837023705
125.98209482979305562367
125.92470954510659453263
125.59226273659595563004
125.32743222812138128575
125.19612970711295929505
125.06591911317904930456
125.00108508828279241243
124.93625106338652130944
124.87087449434885400024
124.80549792531121511274
124.67636569390624856624
124.54723346250128201973
124.48255252857289576696
124.78268834002757614599
125.23672357820690592689
125.69075881638620728609
125.91679859938801655517
126.06959836595268598103
126.49012960939117533599
127.12092647454889515757
127.12092647454889515757
126.46709239148580650181
125.93295191253983489332
125.53066333753537264784
125.53066333753537264784
125.53066333753537264784
125.53066333753537264784
125.53066333753537264784
125.53066333753537264784
126.05190373379372203999
126.31634932453994224488
126.58227848101266488356
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
127.11864406779660896518
127.11864406779660896518
126.76256943455348391581
126.45268021399041913355
126.26674668165257742203
126.23069226272168918968
126.20493910634249346003
126.18562423905808600466
125.98617029962116475872
125.78671636018424351278
125.65541383917583573293
125.59003727013818263458
125.39552846969556298973
125.07132471411925678240
124.55892463213524479215
124.04652455015123280191
123.60005042013797549316
123.15357629012470397356
123.15357629012470397356
123.15357629012470397356
123.34808509056732361842
123.73877113526179982728
124.31765350636399602990
124.96358157581417458459
125.60950964526436735014
126.25543771471456011568
126.58788452322518480742
126.85271503169975915171
126.98512327278868383473
126.98512327278869804559
126.98512327278868383473
126.98512327278868383473
126.91807757444070148267
126.71862363500378023673
126.45155339553079443249
126.18672288705622008820
125.85980584552467576032
125.53288880399313143243
125.20597176246158710455
124.81200902258206042461
124.48509198105051609673
124.29058318060789645187
124.09607438016527680702
123.96694214876032447137
123.96694214876032447137
123.96694214876032447137
124.03124035526901991489
124.09553856177771535840
124.15983676828641080192
124.22413497479510624544
124.28843318130378747810
124.41756541270875402461
124.54669764411372057111
124.67582987551867290676
124.74066390041494400975
124.80549792531120090189
125.00163447121587978472
125.07596399555673372106
125.30012004801922387287
125.37483612691939072192
125.44955220581957178183
125.52426828471973863088
125.45017225626686752094
125.37607622781398220013
125.15192017535149204832
125.06809612853631108464
124.87195958263163220181
124.74174898869770800047
124.54724018825510256647
124.35273138781249713247
124.35273138781249713247
124.41756541270875402461
124.54777600664267822594
124.67798660057660242728
124.87412314648126709926
125.27140397988816289399
125.73298301980376834308
126.12694575968330923388
126.38956459470614390739
126.58734940483273589962
126.71975764592166058264
126.78568359789241526414
126.78568359789241526414
126.65102159950835414293
126.65102159950835414293
126.71863789954441870123
126.92149411505886291707
126.92149411505886291707
126.92149411505886291707
126.92149411505886291707
126.92149411505886291707
126.85501182594067870468
126.78739552590462835724
126.78739552590461414638
126.58486228601553591488
126.58529292018268108677
126.58589580801667295873
126.71976471800404340229
126.94287956798298466765
127.38910926794085298752
125.81325830842271784604
123.96694214876032447137
124.13840403278351232075
124.22413497479510624544
124.48391049029632426937
124.48348004205190875382
124.48317257902019150606
124.48294198174640712296
124.54724018825510256647
124.61153839476378379914
124.61153839476378379914
124.61153839476378379914
124.61153839476378379914
124.67637241966005490212
124.61207421315137366946
124.48400700347973213411
124.16774346740038481585
124.03967625772875749135
123.91160904805711595600
123.78354183838547442065
123.78354183838547442065
123.84891840742314172985
124.04342720786576137471
124.17149441753738869920
124.29644443752940219383
124.23319813111368148384
124.16995182469798919556
124.10670551828226848556
123.78518140826102467145
123.71980483922335736224
123.65442827018570426389
123.71926229508196115603
124.10671235707397386250
124.49416241906598656897
124.88161248105799927544
125.26906254305001198190
125.79004059250820546367
126.05487110098277980796
126.45551212662380180518
126.92493272748215815682
127.26305080733212093946
127.60116888718209793296
127.80231317468434326656
128.00345746218656017845
127.93641176383857782639
127.80288377637239705109
127.40224275073137505387
126.93282214987300449138
126.52877811805228702724
126.25714232732050845698
126.32533654445089155161
126.39353076158127464623
126.36656680166250055208
126.91223961624288563144
127.37011507411833122205
127.82799053199379102352
128.13321366282005442372
128.28711308954470382560
128.20917684139567427337
128.05396482177687289550
127.93704972752934168057
127.59311400223768373507
125.00000000000000000000
125.00000000000000000000
125.00000000000000000000
126.43678160919540687246
125.69178373776074408852
125.24478501489996062901
125.30598126862494723355
125.40797502483326297806
125.40797502483326297806
125.70155088887811700715
125.87769640730502374026
125.33208313799032396219
125.95718080950148021202
126.05921121456219680113
125.71049970359520386864
125.83503952894055544220
125.92844439794959043866
125.53775835325510001894
125.14707230856060959923
124.94928749843403181785
124.75150268830745403648
125.07841972983898415350
125.40533677137052848138
125.01465072667605227252
124.62396468198156185281
125.28628651740783084279
125.94860835283409983276
125.88377432793782872977
126.14860483641240307406
126.21565053476041384783
125.88873349322886951995
125.95250249639180140093
126.14540373095971403927
125.67598313010134347678
125.40434733936955069566
125.66696617439239958003
125.45958361819178605856
125.18307354325764890746
125.42629982215711947902
125.79113924050633954721
125.79113924050633954721
125.79113924050633954721
124.75781451821235634725
123.72448979591837314729
123.97959183673469851783
124.14965986394558683514
124.34585310513924127918
124.49299803603449277034
124.49299803603449277034
124.49299803603449277034
124.81187558705489948352
125.13075313807530619670
124.93785190350740776921
125.20268241198198211350
125.40213635141890335944
125.14385854781335183361
124.95095731324545340613
125.08225983425384697512
125.21356235526225475496
125.47839286373684331011
125.93612460677931608188
125.60646007340847063460
125.27679554003762518732
125.73452728308009795910
126.19225902612258494173
126.66972273888040945167
127.14718645163823396160
127.14718645163823396160
127.14718645163823396160
127.47685098500906519803
127.80651551837991064531
127.90478286846324351700
128.03580600190767313507
127.66894122826325030928
127.01137095043982583320
126.90409783308302849036
126.85046127440463692437
126.76106700994064624410
126.58227848101266488356
126.58227848101266488356
125.88196158761839171802
125.53180314092126934611
124.37403839871687694085
123.21627365651249874645
123.06281484357734257173
122.96050896828724319221
122.96050896828724319221
122.96050896828724319221
122.65634520177870570024
122.20009955201592788399
122.28305956528953402085
122.44897959183673208372
122.44897959183673208372
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
  nan
120.96774193548387188457
120.96774193548387188457
120.96774193548387188457
120.96774193548387188457
120.96774193548387188457
120.96774193548387188457
120.96774193548387188457
123.01804264625478424477
123.51178853170574711839
124.00553441715669578116
124.00553441715669578116
124.00553441715669578116
124.00553441715669578116
124.00553441715669578116
124.00553441715669578116
122.44897959183673208372
122.44897959183673208372
  nan
  nan
128.75536480686696449993
128.75536480686696449993
128.75536480686696449993
128.75536480686696449993
125.60217219935185539725
124.55110799684682376665
125.32822461465639207745
125.79449458534213590610
125.90549058948599281393
126.47296629583604499203
125.96980249244963090405
125.59242963990980967992
126.38072779178858695559
127.16902594366736423126
126.64367790058882690118
126.11832985751027536026
125.57953675406200488851
125.04074365061373441677
125.56172170007191368768
126.08269974953009295859
125.87810965714629674039
125.80933008192894817512
126.40287234213788281068
126.92822038521642014075
127.26066719372704483249
127.06776595915916061585
126.61003421611667363322
126.54298851776869128116
126.47594281942068050739
126.01046776888340161804
125.54499271834610851784
125.14771188493921272311
124.75043105153230271753
124.87849826120393004203
125.00656547087557157738
124.87415722978664689435
124.74174898869770800047
125.20722403923500110068
125.67269908977229420088
125.41865556364814437984
125.16461203752400876965
125.29482263145793297099
124.91684289117202411035
124.40865255695217683751
124.47457850892291730815
124.20524000429028887993
122.88548133159045505636
122.28524123555203573233
122.26185004179707505045
121.71832295671305246287
121.88357124226207872653
122.04881952781111920103
121.87374949979992777571
121.69867947178870792868
122.25212665711445936267
122.80557384244021079667
123.49900250324662920320
124.01907399885143945539
123.59390393082422576754
123.16873386279701207968
123.50118067130765098227
123.83362747981828988486
124.20852750647785001092
124.58342753313739592613
124.65162175026777902076
124.71981596739817632624
124.66099797379757774252
124.60217998019696494794
124.46751798181290382672
124.33285598342885691636
124.65977302496040124424
124.85428182540300667824
124.51901736879963777938
123.99243010646698337496
125.21265424489304507460
126.31858410257284219824
126.26584522688487766118
126.18673691335294506644
126.05488972413303372377
126.32082831004893819227
127.11864406779660896518
127.11864406779660896518
  nan
127.11864406779660896518
127.11864406779660896518
126.06807676145119501143
125.54279310827847382370
125.75069018282530919350
125.88928823252319944004
126.06491049470511711661
125.67564914188336899770
125.15467109242518972678
124.44702681876842120801
124.13334528499120779088
124.46026232652275211876
124.46026232652273790791
123.69771848025158078599
122.86812893563242710115
122.74317891564041360652
122.61822889564842853360
122.37628170465529819921
122.13433451366219628653
121.87171567863933319131
121.60909684361649851780
121.60909684361649851780
121.60909684361649851780
121.73404686360849780158
  nan
  nan
124.48132780082987380865
124.48132780082987380865
124.48132780082987380865
124.48132780082987380865
124.48132780082987380865
124.48132780082987380865
124.48132780082987380865
129.49917977419667636241
134.51703174756343628360
134.51703174756343628360
132.13777381067257010727
130.71021904853805040148
129.84568463249857472874
129.22816004961325120348
128.76501661244924434868
127.01353271637064779043
125.13183822635809860913
125.00377101668645707377
124.81086978211855864629
124.74603575722228754330
124.55152695677966789845
124.35701815633706246444
124.16250935589444281959
123.65431902167459554676
123.33805548559524822849
123.32109339447217166708
123.29847727297476467356
123.06190716740373147786
122.83564842206456546592
122.45855051316597439381
121.78664296764424079811
121.95121951219512368425
122.11713953874232174712
121.95222712369236717223
122.05157761732122878584
122.20145129312288645451
122.23681247865343379999
122.32606337783815320108
122.45101339783016669571
122.57596341782215176863
122.70193973427466005433
123.01562126805188768230
123.20536658766734205983
123.33238189724599465080
123.52212721686143481747
123.84365132688270705330
124.16517543690396507827
124.48669954692522310324
124.74497735053078883993
124.93948615097340848479
125.00378435748208971745
125.46536339739769516655
125.92694243731330061564
125.98464670660072783903
126.06158573231732589193
126.16930036832052053342
126.33087232232533381193
126.60015891233337015365
127.65957446808511122072
127.65957446808511122072
  nan
  nan
  nan
  nan
  nan
  nan
  nan
124.48132780082987380865
124.48132780082987380865
124.48132780082987380865
124.48132780082987380865
124.37845067041595825685
124.22484057925611011797
124.11511908557049821411
124.09659696846921406177
124.03229876196053282911
123.96800055545182317474
123.83993334578019585024
123.77563513927148619587
123.97014393971412005158
124.03391294287705193256
124.09768194603999802439
124.03391294287705193256
123.90689763329839934158
123.71715231368295917491
123.65390600726725267577
123.52689069768860008480
123.20536658766734205983
123.14159758450439596800
123.20589579101309141151
123.33396300068471873601
123.52527651677206677050
123.77932004289621659154
124.03546758387982151817
124.29161512486342644479
124.74554747597362336364
125.13623352066809957250
125.39885235569094845687
125.59498890159562733970
125.65982292649189844269
125.72465695138815533483
125.72465695138815533483
125.72465695138815533483
125.59224871029923065180
125.45984046921029175792
125.32743222812138128575
125.26150627615062660425
125.32688284518827970260
125.32688284518829391345
125.32688284518829391345
125.07073530420467477597
124.68648118414661496445
124.17829084992678190247
123.79403672986870788009
123.47251261984743564426
123.15098850982617761929
122.89484096884257269267
122.63869342785895355519
122.63869342785895355519
122.70142343789575534174
122.95133596851005108874
123.14108128812549125541
123.26809659770414384639
123.52424413868774877301
123.84576824870900679798
124.10191578969262593546
124.35806333067623086208
124.61421087165983578871
124.74227808133147732406
124.74227808133147732406
124.87141031273642965971
124.67850907816853123222
124.42023127456297970639
124.29109904315801315988
124.09819780859011473240
123.96906557718514818589
123.90476737067645274237
123.90476737067645274237
123.77563513927150040672
123.77563513927148619587
123.77563513927148619587
123.71186613610855431489
123.77563513927150040672
123.77563513927148619587
123.83993334578019585024
123.96906557718514818589
124.09819780859011473240
124.22626501826175626775
124.35433222793338359224
124.35433222793338359224
124.29056322477043750041
124.22679422160749140858
124.09872701193586408408
123.90582577736796565659
123.40179351930343898402
122.84162754419969587616
122.97183813813363428835
123.23011594173918581419
123.62080198643367623390
124.01148803112815244276
124.40217407582264286248
124.79286012051713328219
125.49467718870823773614
126.38450769718279786957
126.58396163661971911552
126.85103187609270491976
127.05388809160714913560
127.25674430712157914058
127.39140630550565447265
127.45845200385363682472
127.52549770220163338763
127.45845200385363682472
127.39140630550565447265
127.19026201800340913906
126.92092351337078071083
126.71806729785635070584
126.65045099782028614754
126.51692301035410537224
126.05919126731163260047
125.48030889620943639784
125.02637654509925368984
124.76594179268589357434
124.58244488191712662228
124.24913928206785840302
123.67523832492210544842
123.08144286413585177797
122.95632711102886958088
123.45888091044574252919
123.96694214876032447137
  nan
  nan
125.52301255230125320850
125.52301255230125320850
125.17578430181079340855
125.00217017656557061400
124.89800170141843693727
124.82855605132033360860
124.77895201553597814836
124.77895201553597814836
124.63013990818292597851
124.63013990818292597851
124.77895201553597814836
124.82855605132033360860
125.17871449801744176966
125.52887294471457835243
125.96842565587571982633
126.13274257186442639522
126.50950203857395592877
126.73744939793041908160
126.81279334303985706356
126.71749669616828271046
126.65101440705009849808
126.45322959692350650585
126.18839908844893216155
125.92356857997437202812
125.79004059250820546367
125.65651260504202468837
125.52521008403361690853
125.39390756302520912868
125.26260504201681555969
125.26260504201681555969
125.32798161105446865804
125.39335818009212175639
125.32743222812138128575
125.32743222812138128575
125.39280879715903438409
125.45818536619668748244
125.58948788720509526229
125.72079040821350304213
125.72079040821350304213
125.78671636018424351278
125.91912460127316819580
126.05265258873933476025
126.25210652817625600619
126.45156046761317725213
126.58508845507935802743
126.71861644254552459188
126.85102468363444927490
126.91750697275263348729
126.91750697275261927643
126.85046127440463692437
126.85046127440463692437
126.91807757444070148267
126.98569387447676604097
127.12150439164321369390
127.46251268496490638427
127.66536890047935060011
127.86822511599379481595
128.07108133150822482094
128.13869763154428937924
128.00403563316024246888
127.86937363477618134766
127.73356311760973369474
127.45960052263603756728
127.32379000546958991436
127.25559578833919260887
127.11994830022648272916
127.35577263729805963521
127.74549640109431436485
128.13522016489054067279
128.44832027343187519364
128.44832027343187519364
128.37169661817702603912
128.29442084670725421347
128.14744874934342533379
127.94110245440577955378
127.60009416108408686341
127.25908586776239417304
126.91807757444070148267
126.78454958697452070737
126.71806729785635070584
126.58453931039016993054
126.45101132292400336610
126.24986703542177224335
126.18338474630358803097
126.18338474630358803097
126.31804674468764915218
126.35627911277627788422
126.40725560356111145666
126.47862269065990403760
125.36492419921512464498
125.19355020700209024653
124.90792688664704712664
124.07075108946423824818
120.48192771084337948651
123.26617393945531375721
124.19425601565929184744
124.65829705376128799799
126.18338474630358803097
126.26316349324540055932
126.40574358900393292515
126.43096285929088651301
126.44987731200612302018
126.51635960112429302171
126.51635960112429302171
126.38505708011589945272
126.31857479099771524034
126.52143100651215945618
126.65724152367860710910
126.86009773919305132495
127.20110603251474401532
127.61208745615439852372
128.23133078667439122000
128.98187663820274906357
129.60111996872274175985
129.95102479461007760619
130.15915000273531632047
130.02333948556884024583
129.88518740776160598216
129.67706219963639568959
129.32715737374905984325
128.97725254786166715348
128.62734772197433130714
128.27744289608693861737
128.06931768796175674652
128.20512820512820439944
128.06931768796175674652
127.93350717079530909359
127.79769665362886144067
127.66188613646241378774
127.52607561929596613481
127.39026510212951848189
127.39026510212951848189
127.25445458496307082896
127.25445458496307082896
127.18740888661506005519
127.12036318826707770313
127.12036318826707770313
127.12036318826707770313
126.92090924883015645719
126.58564479222678755832
126.51859909387879099540
126.51859909387879099540
126.72145530939323521125
126.85611730777729633246
126.85611730777728212161
126.72258932031111555716
126.78851527228187023866
126.78851527228187023866
126.65610703119293134478
126.39127652271835700049
125.99063549707733500327
125.72356525760434919903
125.39390072423350375175
125.06969696865719754442
124.92959365445577191167
124.92959365445575770082
125.00493759956519568277
125.23097738256699074100
125.61091659229343520110
125.99407597126871394266
126.52604745759705906494
127.20438146926440481366
127.12661859573293554604
127.25902683682186022907
127.32550912594004444145
127.32550912594004444145
127.19084712755599753109
126.98799091204153910439
126.91979669491115600977
126.85160247778077291514
126.98626447616483403635
126.98626447616483403635
126.85385623507590935333
126.72144799398698467030
126.58903975289805998727
126.45663151180912109339
126.32196951342507418303
125.98952270491443528044
125.46417466183588373951
125.07348861714140753065
124.75196450712013529483
124.43044039709887726985
124.10891628707760503403
123.85063848347205350819
123.52372144194050918031
123.52372144194050918031
123.71662267650840760780
123.84468988618004914315
123.97170519575870173412
124.22785273674230666074
124.54937684676356468572
124.87358060233987089305
125.13401535475324521940
125.33180016487982300077
125.66424697339046190336
126.06152780679735769809
126.52310684671297735804
126.85555365522360204977
127.12262389469658785401
127.39196239932920207139
127.73008047917917906489
128.07108877250087175526
128.27743506743848911356
128.55375449269411092246
128.83007391794967588794
129.24817296096745167233
129.66627200398517061330
130.01617682987256330307
130.29730208054255058414
130.50905482862265216681
130.72080757670278217120
130.86258719446493614669
130.93316878678564307847
130.86197076134419603477
130.79077273590280583448
130.71957471046135879078
130.64837668501991174708
130.57717865957852154679
130.50598063413707450309
130.50598063413707450309
130.43539904181636757130
130.36481744949563221780
130.15489022426703513702
129.80798920669073481804
129.32642619073035916699
128.84486317476998351594
128.36330015880960786490
127.74405682828962937947
127.26659311553180486953
126.78912940277396614874
126.58453931039016993054
126.58453931039016993054
126.78739552590461414638
126.99025174141905836223
126.92376945230088836070
126.92376945230088836070
126.85784350033013367920
126.79191754835937899770
126.72487185001139664564
126.45780161053841084140
125.99232656000111774119
125.65706210339774884233
125.46092555749308417035
125.26478901158840528751
125.26478901158840528751
125.33071496355915996901
125.26423267444097575662
125.19885610540332265828
125.13455789889462721476
124.94004909845202178076
124.74873558236467374627
124.37075584207875067477
123.80134701497658511471
123.16601223590366487315
122.75395395988029179080
122.37961295319367138745
122.29458761564309554615
121.87634557060680151608
121.62745727167532550084
121.62745727167532550084
121.79137020112722211707
122.03824314385269644845
122.02581119647304319642
122.01648723593829970469
121.82829090953059392177
121.89000711373716967501
121.89000711373716967501
122.01394332789895713631
122.13889334789095642009
122.20162335792775820664
122.38981968433544977870
122.57801601074316977247
122.89427954682250287988
123.21054308290185019814
123.58852282318777326964
123.84256634931190887983
124.03387986539925691432
124.22519338148660494880
124.41809461605450337629
124.67637241966005490212
124.80658301359397910346
124.93679360752790330480
125.06700420146182750614
125.19721479539575170747
125.32742538932967590881
125.19935817965804858432
125.00645694509013594597
124.74817914148457020929
124.55367034104196477529
124.55367034104196477529
124.55367034104196477529
124.48829377200431167694
124.42291720296665857859
124.61581843753454279522
124.80871967210245543356
125.00162090667035386105
125.13075313807530619670
125.13075313807530619670
125.13075313807530619670
125.19612970711297350590
125.26150627615062660425
125.26150627615062660425
125.29886431560069581792
125.34867503486749740205
125.41841004184099972463
125.39225941422594701180
125.34867503486749740205
125.26150627615062660425
125.00000000000000000000
  nan
  nan
128.75536480686696449993
129.31274733849841140909
129.68675909523082623309
129.68675909523082623309
129.68675909523082623309
129.68675909523082623309
129.68675909523082623309
129.59265552831965351288
129.73140053349948175310
129.59145427286358653873
129.31034482758622061738
129.31034482758622061738
129.31034482758622061738
129.31034482758622061738
129.49775112443779789828
130.06992079181648591657
130.83962522531402328241
131.60932965881156064825
132.37903409230909801408
133.04953217660030873049
132.92225476685311491565
132.82679670954271955452
132.89799473498413817651
132.53884061476807687541
131.75521599470727096559
130.44624333156795614741
129.13727066842861290752
127.90270276719405728727
126.81497774407989709289
125.47734019035146957322
124.72679433882308330794
124.51866913069787301538
124.58686334782825611001
125.11221139090679344008
125.34870014339965393901
125.66401848005678232312
126.10546415137680753560
127.26745771958538000490
128.02327695944717333987
127.93235133660665781008
126.85499731807617251889
126.05042016806723381706
126.05042016806723381706
126.05042016806723381706
126.05042016806723381706
126.05042016806723381706
126.05042016806723381706
126.05042016806723381706
  nan
  nan
  nan
  nan
  nan
  nan
  nan
121.45748987854250344753
121.45748987854250344753
121.45748987854250344753
121.45748987854250344753
121.35954028993077713494
121.29424056418963573378
121.24759790294595518390
121.21261590701318766605
121.15139741413085516797
121.09017892124853688074
121.03770592734940692026
120.96774193548388609543
120.96774193548387188457
123.08998302207130848274
125.21222410865873087005
127.33446519524618167907
129.22787332988852426752
131.29290617848968736325
131.12128146453088106682
131.29416627183741184126
131.41765541991350119133
131.33557248331436539956
131.17212037478216757336
130.92800358931199866674
130.84733891237402758634
130.84733891237402758634
130.68670494364411638344
130.27981273210357926473
129.55982041202165078175
129.00471716281728618014
128.37837967129894423124
128.12975463816044907617
127.78167959176657575426
127.78167959176657575426
127.39951328281165388034
126.76256943455348391581
126.58453211793192849655
126.05042016806723381706`