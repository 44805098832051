/*
# Copyright ©2020-2021. Biorithm Pte Ltd. All Rights Reserved. 
# This software is the property of Biorithm Pte Ltd. 
# It must not be copied, printed, distributed, or reproduced in whole or in part
# or otherwise disclosed without prior written consent from Biorithm.
# This document may follow best coding practices for JavaScript as suggested in 
# ES2021
#
# Filename: SecondStep.js
# Original Author: PHAM DUY HOAN
# Date created: 17 JUL 2021
# Purpose: Second Step Component to Display Second Step in Login Page
# Revision History Raises (if any issues):
*/
import React, { useState } from 'react'
import './SecondStep.css'
import './Login.css'

const AWS = require('aws-sdk')

const submitStyle = {
  margin: '10px 0 0 0',
  padding: '7px 10px',
  border: '1px solid #efffff',
  borderRadius: '3px',
  background: '#3085d6',
  width: '100%',
  fontSize: '15px',
  color: 'white',
  display: 'block'
};

const SecondStep = React.forwardRef(({ isGetS3Token, s3Token, measCapture }, ref) => {
  const [files, setFiles] = useState()

  console.log('isGetS3Token = ', isGetS3Token)
  async function onFileChange(e) {
    setFiles(e.target.files)
  }

  async function onSubmit(e) {
    e.preventDefault()
    console.log('Start submit files')
    console.log('s3Token = ', s3Token)
    console.log('files = ', files)
    AWS.config.update({
      region: s3Token.region,
      accessKeyId: s3Token.accessKeyId,
      secretAccessKey: s3Token.secretAccessKey,
      sessionToken: s3Token.sessionToken
    })

    const s3 = new AWS.S3()

    const param = {
      Body: files[0],
      Bucket: s3Token.bucket,
      Key: measCapture + '/' + files[0].name
    }

    await s3.putObject(param, (err, data) => {
      if (err) {
        console.log(err.message, err.stack, err);
      } else {
        alert('Upload File Successfully!')
        console.log(data);
      }
    })
  }

  return (
    <div>
      {(isGetS3Token) ? (
        <div className="upload-file">
          <div class="step">2st Step</div>
          <div className="container">
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <input type="file" name="imgCollection" onChange={onFileChange} />
              </div>
              <div className="form-group">
                <button style={submitStyle}> Upload File Each Minute</button>
              </div>
            </form>
          </div>
        </div>
      ) :
        (
          <div>
            <div></div>
          </div>)}
    </div>
  );
});

export default SecondStep