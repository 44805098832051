/*
# Copyright ©2020-2021. Biorithm Pte Ltd. All Rights Reserved. 
# This software is the property of Biorithm Pte Ltd. 
# It must not be copied, printed, distributed, or reproduced in whole or in part
# or otherwise disclosed without prior written consent from Biorithm.
# This document may follow best coding practices for JavaScript as suggested in 
# ES2021
#
# Filename: App.js
# Original Author: PHAM DUY HOAN
# Date created: 15 JUL 2021
# Purpose: Main Component for Routing To Components
# Revision History Raises (if any issues):
*/
import { BrowserRouter, Route, Switch } from "react-router-dom";

import './App.css';

//import Homepage from './components/LiveTrace/Homepage'
import Login from './components/Login/Login'
import LiveTraceDemo from "./components/LiveTraceDemo";
import LiveTraceHomepage from "./components/LiveTraceHomepage";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/tests" render={() => <Login />} />
        {/* <Route exact path="/measurement-captures/:id" render={(props) => <LiveTrace text={props} />} /> */}
        <Route exact path="/trace" render={() => <LiveTraceDemo />} />
        <Route exact path="/" render={() => <LiveTraceHomepage />} />
        {/* <Route exact path="/demo-trace/:hashedString" render={(props) => <LiveTrace text={props} />} /> */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
