/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AlgoResult",
            "endpoint": "https://au96eknbbj.execute-api.ap-southeast-1.amazonaws.com/seconddev",
            "region": "ap-southeast-1"
        },
        {
            "name": "S3STS",
            "endpoint": "https://ob38i4uoni.execute-api.ap-southeast-1.amazonaws.com/seconddev",
            "region": "ap-southeast-1"
        },
        {
            "name": "tokens",
            "endpoint": "https://xqak6fyn5m.execute-api.ap-southeast-1.amazonaws.com/seconddev",
            "region": "ap-southeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://mwpbg5wnbzfavjevecvvvlwbju.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-kl5c6ebuujblnex57qthnlrw44",
    "aws_cognito_identity_pool_id": "ap-southeast-1:2df1e41f-dd8b-45ab-8ccf-c269e4693bdd",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_OIWvRkqaZ",
    "aws_user_pools_web_client_id": "3d9uismobrh2i95a9t9g43sm4j",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "liveviewereachmin92929-seconddev",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
