/*
# Copyright ©2020-2021. Biorithm Pte Ltd. All Rights Reserved. 
# This software is the property of Biorithm Pte Ltd. 
# It must not be copied, printed, distributed, or reproduced in whole or in part
# or otherwise disclosed without prior written consent from Biorithm.
# This document may follow best coding practices for JavaScript as suggested in 
# ES2021
#
# Filename: LiveTrace.js
# Original Author: PHAM DUY HOAN
# Date created: 15 JUL 2021
# Purpose: Live Trace Component to Display Live Trace to The Doctor
# Revision History Raises (if any issues):
*/
import React, { Component } from 'react'

import Box from "@material-ui/core/Box"


import biorithmLogo from './Biorithm-logo.png'
import MultiCTGView from "./MultiCTGView"
import { Helmet } from 'react-helmet'

import { mHR } from "../data/STG001A_rawONON_mSmoothedHR.js"
import { fHR } from "../data/STG001A_rawONON_fSmoothedHR.js"
import './LiveTrace.css'

import { API } from 'aws-amplify'

let fHRArr = fHR.split('\n')
let mHRArr = mHR.split('\n')

const formStyle = {
  margin: 'auto',
  padding: '10px',
  border: '1px solid #c9c9c9',
  borderRadius: '5px',
  background: '#f5f5f5',
  width: '220px',
  display: 'block'
};

const labelStyle = {
  margin: '10px 0 5px 0',
  fontFamily: 'Arial, Helvetica, sans-serif',
  fontSize: '15px',
};

const inputStyle = {
  margin: '5px 0 10px 0',
  padding: '5px',
  border: '1px solid #bfbfbf',
  borderRadius: '3px',
  boxSizing: 'border-box',
  width: '400px'
};

const submitStyle = {
  margin: '10px 0 0 0',
  padding: '7px 10px',
  border: '1px solid #efffff',
  borderRadius: '3px',
  background: '#3085d6',
  width: 'fit-content',
  fontSize: '15px',
  color: 'white',
  display: 'block',

};

const Field = React.forwardRef(({ label, type }, ref) => {
  return (
    <div>
      <label style={labelStyle} >{label}</label>
      <br/>
      <input ref={ref} type={type} style={inputStyle} />
    </div>
  );
});


const heartRate = {
  fHR: [],
  mHR: [],
}

function flatten(a) {
  return Array.isArray(a) ? [].concat(...a.map(flatten)) : a;
}

class LiveTraceHomepage extends Component {
  async getAlgoResultByMeasCapture() {
    const postContent = {
      headers: { 'Content-Type': 'application/json' },
      body: {
        "measCapture": this.measCapture.current.value,
        "traceToken": this.traceToken.current.value
      }
    }
    console.log("start do API.post() getAlgoResultByMeasCapture")
    let algoResult = await API.post('AlgoResult', '/algo-result', postContent)
    console.log('algoResult = ', algoResult)
    return algoResult
  }

  constructor(props) {
    super(props);
    // this.getInfoFromHashedParams = this.getInfoFromHashedParams.bind(this)
    // this.getAlgoResult = this.getAlgoResult.bind(this)
    this.getAlgoResultByMeasCapture = this.getAlgoResultByMeasCapture.bind(this)
    this.parseAlgoResult = this.parseAlgoResult.bind(this)
    this.parseHrResp = this.parseHrResp.bind(this)
    this.updateEachPeriod = this.updateEachPeriod.bind(this)
    this.traceToken = React.createRef()
    this.measCapture = React.createRef()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.fillMissHRorCutHR1Min = this.fillMissHRorCutHR1Min.bind(this)
  }

  state = {
    "token": "",
    "algoResultId": "",
    "heartRate":heartRate,
    "currDispLength": 0,
    "heartRateDisp": {
      "fHR": [],
      "mHR": [],
    },
    "traceToken": "",
    "measCapture": "",
    "isSubmitToken": false,
    "counter": 0
  }
  async runFirstTImeUpdateTrace(measCaptureId) {
    console.log('Run First TIme Update Trace')
    let algoResult = await this.getAlgoResultByMeasCapture(measCaptureId)
    console.log('algoResult = ', algoResult)
    let heartRateGet = this.parseAlgoResult(algoResult)
    console.log('heartRateGet = ', heartRateGet)
    this.setState({"heartRate": heartRateGet})
    this.updateEachPeriod() 
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.target.style.backgroundColor = "orange"
    event.currentTarget.textContent = 'Submitted'
    this.setState({isSubmitToken: true})
    console.log('traceToken = ', this.traceToken.current.value)
    this.setState({traceToken: this.traceToken.current.value})

    console.log('measCapture = ', this.measCapture.current.value)
    this.setState({measCapture: this.measCapture.current.value})

    //await this.runFirstTImeUpdateTrace(this.measCapture)
    console.log('Run First TIme Update Trace')
    let algoResult = await this.getAlgoResultByMeasCapture()
    console.log('algoResult = ', algoResult)
    let heartRateGet = this.parseAlgoResult(algoResult)
    console.log('heartRateGet = ', heartRateGet)
    this.setState({"heartRate": heartRateGet})
    this.updateEachPeriod()

    const PERIOD_GET_DATA_IN_MS = 45000

    console.log("Waiting for this.traceToken !== ''")
      let interval = setInterval(async () => {
        let algoResult = await this.getAlgoResultByMeasCapture()
        let heartRateGet = this.parseAlgoResult(algoResult)
        console.log('heartRateGet = ', heartRateGet)
        this.setState({"heartRate": heartRateGet})
        this.updateEachPeriod()
      }, PERIOD_GET_DATA_IN_MS)    
  }
  fillMissHRorCutHR1Min(heartRateParam) {
    if (heartRateParam.length < 60 *4) {
      return [...heartRateParam, ...Array(60*4 - heartRateParam.length).fill(0)]
    }
    if (heartRateParam.length > 60 *4) {
      return heartRateParam.slice(0, 60 *4)
    }
    return heartRateParam
  }
  parseHrResp(fHRResp) {
    let fhr = []
    let indexs = Object.keys(fHRResp).map(key => {return parseInt(key.split('_')[1])}).sort((a,b)=>{return a-b})
    let maxIndexMinEnd = indexs[indexs.length - 1]

    fhr = [...Array(maxIndexMinEnd).keys()].map(e => {
      const key = `indexMinEnd_${e+1}`
      // console.log('key  = ', key)
      // console.log('fHRResp[key]', fHRResp[key])
      // fHRResp[key] = this.fillMissHRorCutHR1Min(fHRResp[key])
      // console.log('key =', key, 'fHRResp[key] = ', fHRResp[key])
      if (fHRResp[key] !== undefined) {
        fhr = [...fhr, ...this.fillMissHRorCutHR1Min(fHRResp[key])]
      } else {
        fhr = [...fhr, Array(60*4).fill(0)]
      }
      // console.log('fhr inside = ', fhr)
      return fhr
    })
    fhr = fhr[fhr.length - 1]
    fhr = flatten(fhr)
    fhr = fhr.map(val => {
      return val = val !== 0 ? val : null
    })

    return fhr 
  }

  parseAlgoResult(data){
    let fHRResp = JSON.parse(data.algoResult.fHR)
    let mHRResp = JSON.parse(data.algoResult.mHR)
    console.log('fHRResp = ', fHRResp)
    console.log('start parse fhr')
    let fHR = this.parseHrResp(fHRResp)
    let mHR = this.parseHrResp(mHRResp)
    
    return {"fHR": fHR,
            "mHR": mHR}
  }
  async updateEachPeriod(){
    const PERIOD_GET_DATA_IN_MS = 45000
    const PERIOD_UPDATE_IN_SEC = 1
    const SEC_TO_MS = 1000
    const HEARTRATE_FREQ = 4
    const MIN_TO_SEC = 60
    const NUM_HR_SAMPLES_EACH_PERIOD = HEARTRATE_FREQ*PERIOD_GET_DATA_IN_MS/SEC_TO_MS
    let hrLength = this.state.heartRate.fHR.length
    let subInterval = setInterval(async => {
      if (hrLength > NUM_HR_SAMPLES_EACH_PERIOD){
        this.setState({"counter": this.state.counter + 1})
        let fHR = this.state.heartRate.fHR.slice(0,hrLength-NUM_HR_SAMPLES_EACH_PERIOD + this.state.counter * HEARTRATE_FREQ);
        let mHR = this.state.heartRate.mHR.slice(0,hrLength-NUM_HR_SAMPLES_EACH_PERIOD + this.state.counter * HEARTRATE_FREQ);
        let heartRateDisp = {
          "fHR": fHR,
          "mHR": mHR
        }
        console.log('Update heartRateDisp each second', heartRateDisp)
        this.setState({"heartRateDisp":heartRateDisp})
      }
      if(this.state.counter === PERIOD_GET_DATA_IN_MS/(PERIOD_UPDATE_IN_SEC * SEC_TO_MS)){
        clearInterval(subInterval);
        this.setState({"counter": 0})
      }
    }, PERIOD_UPDATE_IN_SEC*SEC_TO_MS)
  }

  async componentDidMount() {
  }
  render() {
    return (
      <Box>
        <div className="page-container" style={{
          padding: '30px 30px 0 30px',
        }}>
          <Helmet>
            <title>Biorithm LiveViewer </title>
          </Helmet>
          <div className="logo-container">
            <img className="logo"
                 src={biorithmLogo}
                 style={{
                   height: '200px',
                   width: 'fit-content'
                 }}
                 alt="biorithmLogo"/>
            <img
                className="logo-mom"
                src={"https://images.squarespace-cdn.com/content/v1/5e6f0164f4b7d15cf77563b1/1606979292909-6P79Z1CMTWEGJ64Z5JDX/femom-femom-on-mannequin"}
                style={{
                  height: '100px',
                  width: 'fit-content'
                }}
                alt="biorithmLogo"/>

            <div>
              <Field ref={this.traceToken} label="Trace Token:" type="text" />
              <Field ref={this.measCapture} label="Measurement Capture:" type="text" />
              <div>
                <button style={submitStyle} onClick={this.handleSubmit}>Click to see trace </button>
              </div>
              {/* <a href='https://www.bio-rithm.com/' className="IntroLink">Biorithm's website</a> */}
            </div>
          </div>
        </div>
        {(this.isSubmitToken) ? (
          <div>
          </div>
      ) :
        (
          <div>
          <MultiCTGView heartRate={this.state.heartRateDisp} />
          </div>)}
      </Box>

    )
  }
}

export default LiveTraceHomepage